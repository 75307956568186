import { IonContent, IonHeader, IonPage, IonRow, IonCol, IonLabel, IonRouterLink } from '@ionic/react';
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { AuthCheckContext } from '../helpers/authHelpers/authService';
import { connect } from 'react-redux';
import HeaderTemplate from '../components/HeaderTemplate';
import NavBarTemplate from '../components/NavBarTemplate';
import SelectAccountBar from '../components/SelectAccountBar';
import Home from '../components/Home';
import AccountDetails from '../components/AccountDetails';
import TransactionsListContainer from '../components/TransactionsListContainer';
import Holdings from '../components/Holdings/Holdings';
import InvitePayers from '../components/InvitePayers';
import Documents from '../components/Documents';
import CaseList from '../components/CaseList';
import CaseContainer from '../components/CaseContainer';
import MyProfileContainer from '../components/MyProfileComponents/MyProfileContainer';
import Billing from '../components/Billing';
import UpdatePaymentMethod from '../components/UpdatePaymentMethod';
import BillPay from '../components/BillPay/BillPayWizard';
import SavedPayees from '../components/SavedPayees';
import Footer from '../components/Footer';
import './Style.css';
import ContributionWizard from '../components/Contributions/ContributionWizard';
import DistributionWizard from '../components/Distributions/DistributionWizard';
import AdditionalBuyWizard from '../components/AdditionalBuy/AdditionalBuyWizard';
import CreateStatement from '../components/CreateStatement';
import MakeInvestment from '../components/MakeInvestment';
import TransactionDetails from '../components/TransactionDetails';
import HoldingContainer from '../components/Holdings/HoldingContainer';
import UpdateAssetWizard from '../components/UpdateAsset/UpdateAssetWizard';
import TradingAccountWithdrawalWizard from '../components/TradingAccountWithdrawalWizard';
import Forms from '../components/Forms';
import Beneficiaries from '../components/Beneficiaries';
import SnapshotStatement from '../components/SnapshotStatement';
import CreditCardPayment from '../components/CreditCardComponents/CreditCardPayments';
import TransferWizard from '../components/TransferWizard';
import TransferDocuSignReturn from '../components/TransferDocuSignReturn';
import RecurringTransactionDetails from '../components/RecurringTransactionDetails'
import DocCustodyPage from '../components/DocCustody/DocCustodyPage';
import ConfirmationPage from '../components/ConfirmationPage';
import InvestorRoster from '../components/InvestorRoster';
import EditInvestorParticipation from '../components/EditInvestorParticipation';
import { isDocCustodyView } from '../helpers/Utils';
import AddInvestorWizard from '../components/AddInvestor/AddInvestorWizard';

const HIDE_ACCOUNT_SELECTOR_LIST = [
  'home',
  'transaction',
  'pay-a-bill',
  'make-a-contribution',
  'submit-distribution-request',
  'trading-account-withdrawal',
  'additional-buy',
  'request-distribution',
  'edit-beneficiaries',
  'generate-statement',
  'my-profile',
  'investor-roster',
  'edit-investor-participation'
]

const Main: React.FC<{viewMode: ViewMode, selectedAccount: RetirementAccount | undefined, setDocCustodyViewMode():void}> = ({viewMode, selectedAccount, setDocCustodyViewMode}) => {
  const history = useHistory();
  const { comp } = useParams<{ comp: string; }>();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef?.current && contentRef.current.scrollToTop();
  }
  const [ showFeedbackModal, setShowFeedbackModal ] = useState<Boolean>(false);
  const isFundManager = selectedAccount?.partyType?.includes('Fund Manager');

  let mainPages = [
    'Home',
    'Account Details',
    'Transactions',
    'Holdings',
    'Documents',
    'Cases',
    'Billing',
    'Saved Payees'
  ]

  if (isFundManager) {
    mainPages.splice(2, 0, 'Investor Roster');
  };

  useEffect(() => {
    scrollToTop();
  }, [comp]);

  useEffect(() => {
    if (selectedAccount && viewMode === 'DEFAULT_TYPE') {
      if (isDocCustodyView(selectedAccount)) {
        setDocCustodyViewMode();
        history.push('/home');
      };
    };
  }, [selectedAccount]);
  
  const displayPage = (pageName: string) => {
    switch (pageName) {
      case 'home':
        return <Home />;
      case 'account-details':
        return <AccountDetails />;
      case 'transactions':
        return <TransactionsListContainer />;
      case 'holdings':
        return <Holdings />;
      case 'documents':
        return <Documents />;
      case 'cases':
        return <CaseList />;
      case 'case':
        return <CaseContainer />;
      case 'my-profile':
        return <MyProfileContainer />;
      case 'billing':
        return <Billing />;
      case 'pay-a-bill':
        return <BillPay scrollToTop={scrollToTop} />;
      case 'saved-payees':
        return <SavedPayees />
      case 'make-a-contribution':
        return <ContributionWizard scrollToTop={scrollToTop} />;
      case 'request-distribution':
        return <DistributionWizard scrollToTop={scrollToTop} />;
      case 'additional-buy':
        return <AdditionalBuyWizard scrollToTop={scrollToTop} />;
      case 'create-statement':
        return <CreateStatement />;
      case 'make-an-investment':
        return <MakeInvestment />;
      case 'transaction':
        return <TransactionDetails />;
      case 'holding':
        return <HoldingContainer />;
      case 'invite-payers':
        return <InvitePayers />;
      case 'update-asset':
        return <UpdateAssetWizard scrollToTop={scrollToTop} />;
      case 'trading-account-withdrawal':
        return <TradingAccountWithdrawalWizard scrollToTop={scrollToTop} />;
      case 'edit-beneficiaries':
        return <Beneficiaries scrollToTop={scrollToTop} />;
      case 'generate-statement':
        return <SnapshotStatement />;
      case 'forms':
        return <Forms />;
      case 'card-payment':
        return <CreditCardPayment />;
      case 'recurring-transaction':
        return <RecurringTransactionDetails />;
      case 'update-payment-method':
        return <UpdatePaymentMethod />;
      case 'request-transfer':
        return <TransferWizard scrollToTop={scrollToTop} />;
      case 'transfer-docusign-return':
        return <TransferDocuSignReturn/>;
      case 'confirmation':
        return <ConfirmationPage/>;
      case 'investor-roster':
        return <InvestorRoster/>;
      case 'edit-investor-participation':
        return <EditInvestorParticipation/>;
      case 'add-investor':
        return <AddInvestorWizard scrollToTop={scrollToTop}/>;
      default:
    }
  }

  const renderMain = () => {
    return <IonPage>
      <IonHeader>
        <HeaderTemplate>
          <IonCol size="8">
            <NavBarTemplate pages={mainPages}/>
          </IonCol>
          <IonCol size="3">
            <MainRouterLinks isFundManager={isFundManager!} />
          </IonCol>
        </HeaderTemplate>
        {(!HIDE_ACCOUNT_SELECTOR_LIST.includes(comp)) && <IonRow class="container white-bg mr-auto ml-auto mt-0 mb-0 p-0">
          <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
            <IonRow className='light-gr-bg'>
              <IonCol sizeXs="5" sizeSm="5" sizeMd="4" sizeLg="4" sizeXl="3" className='p-0 m-0'>
                <IonLabel className='mt-2 d-flex justify-center align-items-center'>Select Account:</IonLabel>
              </IonCol>
              <IonCol sizeXs="7" sizeSm="7" sizeMd="8" sizeLg="8" sizeXl="9" className='p-0 m-0'>
                <SelectAccountBar />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>}
      </IonHeader>
      <IonContent fullscreen ref={contentRef}>
        <IonRow class='main-page'>
          <IonCol>
            {displayPage(comp?.toLowerCase())}
          </IonCol>
        </IonRow>
        <Footer setShowFeedbackModal={setShowFeedbackModal} showFeedbackModal={showFeedbackModal}/>
      </IonContent>
    </IonPage>
  }

  return (
    viewMode !== 'DOC_CUSTODY' ? renderMain() : <DocCustodyPage />
  )
}

const MainRouterLinks: React.FC<{isFundManager: boolean}> = ({isFundManager}) => {
  const authCheckContext = useContext(AuthCheckContext);
  const history = useHistory();

  return (
    <p className='text-right'>
      <IonRouterLink class="hover-cursor nav-item" onClick={() => history.push('/my-profile')} color="light">My Profile</IonRouterLink> 
      | 
      {!isFundManager && <><IonRouterLink class="hover-cursor nav-item" onClick={() => history.push('forms')} color="light">Forms</IonRouterLink>|</>} 
      <IonRouterLink class='hover-cursor nav-item' onClick={()=>{authCheckContext.current.logout()}} color="light">Logout</IonRouterLink>
    </p>
  )
}

const mapStateToProps = (rootState: RootState) => {
  return {
    viewMode: rootState.CachedObjects.viewMode,
    availableAccounts: rootState.CachedObjects.accounts,
    selectedAccount: rootState.UserState.selectedAccount
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setDocCustodyViewMode: () => dispatch(
      {type: 'setViewMode', payload: {viewMode: 'DOC_CUSTODY'}}
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
