export const BUILD_ENV: 'PROD'|'QA'|undefined = (process.env.REACT_APP_BUILD_ENV as 'QA'|'PROD'|undefined) || 'QA' //default to QA

export const ERROR_CODES = {
  'USER_CANCELLATION': 'AADB2C90091',
  'MAX_PASSWORD_ATTEMPTS': 'AADB2C90157'
}

export type ERROR_TYPES = 'access_denied'

const PROD_APP_ID = "7db30b2b-0e41-49cd-b288-158f170d9a16"
const QA_APP_ID = "c29efe81-86d6-49be-91f4-edd68d9b2a81"
const OVERRIDE_APP_ID = process.env.REACT_APP_APP_ID
export const APP_ID = OVERRIDE_APP_ID || (BUILD_ENV === 'PROD' ? PROD_APP_ID : QA_APP_ID)

const PROD_TENNENT = 'MidlandPortal'
const QA_TENNENT = 'testclientportal'
const OVERRIDE_TENANT = process.env.REACT_APP_TENANT
export const TENANT = OVERRIDE_TENANT || (BUILD_ENV === 'PROD' ? PROD_TENNENT : QA_TENNENT)

const PROD_API_SERVER = "https://client-portal-midland.herokuapp.com/"
const QA_API_SERVER = "https://testclient-portal-midland.herokuapp.com/"
const OVERRIDE_API_SERVER = process.env.REACT_APP_API_SERVER
export const API_SERVER = OVERRIDE_API_SERVER || (BUILD_ENV === 'PROD' ? PROD_API_SERVER : QA_API_SERVER)

export const REDIRECT = process.env.REACT_APP_REDIRECT || 'http://localhost:3000'

export const ENVIRONMENT = {
  appId: APP_ID,
  tennent: `${TENANT}.onmicrosoft.com`
}

export const RESET_PASSWORD_STATE = 'reset_password'

type policyAlias = 'passwordReset'|'magicLink'|'signIn'

export const POLICIES: {[key in policyAlias]: Auth.LoginPolicies}  = {
  passwordReset: 'B2C_1A_FIDO_SIGNUP_PASSWORD_RESET',
  magicLink: 'B2C_1A_MAGIC_LINK',
  signIn: 'B2C_1A_fido_signup_signin'
}

const tenantSubdomain = ENVIRONMENT.tennent.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/`;

export const resolveSignInAuthority = (signInPolicy: Auth.LoginPolicies) => `${instance}${ENVIRONMENT.tennent}/${signInPolicy}`;

export const resolveLogoutUrl = (signInPolicy: Auth.LoginPolicies) => `${resolveSignInAuthority(signInPolicy)}/oauth2/v2.0/logout?post_logout_redirect_uri=${REDIRECT}`

export const resolveTokenEndpoint = (signInPolicy: Auth.LoginPolicies) => `${resolveSignInAuthority(signInPolicy)}/oauth2/v2.0/token`