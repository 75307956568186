import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import '../pages/Style.css';
import happy from '../images/happy.svg';
import mediocre from '../images/mediocre.svg';
import sad from '../images/sad.svg';
import happySelected from '../images/happy-selected.svg';
import mediocreSelected from '../images/mediocre-selected.svg';
import sadSelected from '../images/sad-selected.svg';
import {
  IonRow,
  IonCol,
  IonFooter,
  IonRouterLink,
  IonModal,
  IonContent,
  IonTextarea,
  IonButton,
  IonItem,
  IonIcon,
  IonCheckbox,
} from '@ionic/react';
import {
  logoFacebook,
  logoLinkedin,
  logoTwitter,
  logoYoutube,
} from 'ionicons/icons';
import calloutHelpers from '../helpers/calloutHelpers';
import Spinner from './Spinner';
import { SFChat } from '../helpers/SFChat';
import { getLogo } from '../helpers/Utils';

const noRating = 'No rating selected'

type UserFeedback = {
  rating: string,
  comments: string,
  optOut: boolean,
}

const initialPersonDetails: PersonAccountHelpers.LoggedInDetails = {
  olderThan59AndAHalf: false,
  myProfile: {
    id: '',
    firstName: '',
    lastName: '',
    mailingStreet: '',
    mailingCity: '',
    mailingState: '',
    mailingPostalCode: '',
    email: '',
    legalStreet: '',
    legalCity: '',
    legalState: '',
    legalPostalCode: '',
    mailingShippingDiffer: false,
    phones: {
      Primary: {
        phoneNumber: '',
        phoneType: ''
      }
    },
    preferredContactMethod: '',
    marketingOptOut: false,
    multiFactorAuthEnabled: false,
    enableSecurityQuestion: false,
  }
}

const Footer: React.FC<{setShowFeedbackModal?: any, showFeedbackModal?: Boolean}> = ({setShowFeedbackModal, showFeedbackModal}) => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [rating, setRating] = useState<string>(noRating);
  const [isLoading, setIsloading] =  useState<boolean>(false)
  const [selectedHappyRating, setSelectedHappyRating] = useState<string>(happy);
  const [selectedMediocreRating, setSelectedMediocreRating] = useState<string>(mediocre);
  const [selectedSadRating, setSelectedSadRating] = useState<string>(sad);
  const [personDetails, setPersonDetails] = useState<PersonAccountHelpers.LoggedInDetails>()
  const [error, setError] = useState(false);

  const getTodaysYear = () => {
    const date = new Date();
    return date.getUTCFullYear();
  }

  const loadAccountData = useCallback(async () => {
    let personDetailsResponse = await calloutHelpers.getLoggedInPersonAccount()
    setPersonDetails(personDetailsResponse.data)
  }, [setPersonDetails])
  
  useEffect(() => {
    loadAccountData()
  }, [loadAccountData])
  
  useEffect(() => {
    if (personDetails) {
      SFChat(personDetails);
    }
  }, [personDetails])
  
  useEffect(() => {
    if (showFeedbackModal) {
      displayCommentBox();
      setShowFeedbackModal(false);
    };
  }, [showFeedbackModal])

  const {control, handleSubmit, reset , watch } = useForm<UserFeedback>();

  const optOut = watch('optOut')
  
  const displayCommentBox = () => {
    setRating('No rating selected');
    setSelectedHappyRating(happy);
    setSelectedMediocreRating(mediocre);
    setSelectedSadRating(sad);
    setShowCommentBox(true);
  }
  
  const selectedRating = (selection: string) => {
    if (selection === 'happy') {
      setRating("Very Satisfied");
      setError(false)
      setSelectedHappyRating(happySelected);
      setSelectedMediocreRating(mediocre);
      setSelectedSadRating(sad);
    }
    if (selection === 'mediocre') {
      setRating("Somewhat Satisfied");
      setError(false)
      setSelectedHappyRating(happy);
      setSelectedMediocreRating(mediocreSelected);
      setSelectedSadRating(sad);
    }
    if (selection === 'sad') {
      setRating("Dissatisfied");
      setError(false)
      setSelectedHappyRating(happy);
      setSelectedMediocreRating(mediocre);
      setSelectedSadRating(sadSelected);
    }
  }
  
  const onSubmit = async (formFields: UserFeedback) => {
    setIsloading(true)
    if(formFields.comments){
      setError(false)
    }
    try {
      if (!optOut && !formFields.comments) {
        setError(true)
        setIsloading(false)
      }
      else {
        const res = await calloutHelpers.sendFeedback(
          `<b>User Id</b>: ${personDetails?.myProfile?.id}<br/>
          <b>Rating</b>: ${rating}<br/>
          <b>Comments</b>: ${formFields.comments ? formFields.comments : 'No comment provided.'}<br/>
          <b>Opted Out</b>: ${optOut ? optOut : 'false'}`
        )
        if (res) {
          setIsloading(false)
          setShowCommentBox(false)
        }

        const feedbackOptions : FeedbackOptions = {}
        if (optOut) {
          feedbackOptions.FeedbackOptOutDate = new Date()
        }
        if (formFields.comments) {
          feedbackOptions.FeedbackSubmitDate = new Date()
        }
        await calloutHelpers.updatePersonAccFeedbackOptions(feedbackOptions)
        
        setShowCommentBox(false)
        setIsloading(false)
        reset()
      }
      
    } 
    catch (err) {
      setIsloading(false)
      if(!formFields.comments){
        setError(true)  
      }
    }
  };

  return (
    <IonFooter class="footer">
      <IonRow class="container pr-3 pl-3 mt-0 mb-1">
        <IonCol sizeXs="5" sizeSm="3" sizeMd="2" sizeLg="2" sizeXl="2">
          <img src={getLogo()} width="175" alt="Equity Trust logo"/>
        </IonCol>
        <IonCol sizeXs="7" sizeSm="9" sizeMd="3" sizeLg="3" sizeXl="3">
          <p className="mt-0 mb-0 footer-contact-info"><b>ADDRESS</b>:<br /> PO Box 07520<br /> Fort Myers, FL 33919</p>
          <p className="footer-contact-info mb-0"><b>PHONE</b>: (239) 333-1032<br/><br /><IonRouterLink href="https://www.midlandtrust.com" target="_blank">www.midlandtrust.com</IonRouterLink></p>
          <IonItem href="https://www.facebook.com/EquityTrustCompany/" target="_blank" detail={false} lines="none" color="transparent" class="ion-no-padding float-left">
            <IonIcon color="tertiary" icon={logoFacebook}></IonIcon>
          </IonItem>
          <IonItem href="https://twitter.com/EquityTrust" target="_blank" detail={false} lines="none" color="transparent" class="ion-no-padding float-left">
            <IonIcon color="tertiary" icon={logoTwitter}></IonIcon>
          </IonItem>
          <IonItem href="https://www.youtube.com/user/EquityTrustCompany" target="_blank" detail={false} lines="none" color="transparent" class="ion-no-padding float-left">
            <IonIcon color="tertiary" icon={logoYoutube}></IonIcon>
          </IonItem>
          <IonItem href="https://www.linkedin.com/company/equity-trust-company" target="_blank" detail={false} lines="none" color="transparent" class="ion-no-padding float-left">
            <IonIcon color="tertiary" icon={logoLinkedin}></IonIcon>
          </IonItem>
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          <p className="mt-0 disclaimer"><b>MARKET VALUES MAY NOT REFLECT CURRENT VALUATION</b>: Equity Trust updates market values as they are provided. If no updated valuation has been provided to Equity Trust, market values may reflect original purchase price.</p>
          
          <p className="disclaimer"><b>Equity Trust IS NOT A FIDUCIARY</b>: Equity Trust's role as the administrator of self-directed retirement accounts is non-discretionary and/or administrative in nature. The Accountholder or his/her authorized representative must direct all investment transactions and choose the investment(s) for the account. Equity Trust has no responsibility or involvement in selecting or evaluating any investment. Nothing contained herein shall be construed as investment, legal, tax or financial advice or as a guarantee, endorsement, or certification of any investments.</p>
        </IonCol>
      </IonRow>
      <IonRow class="copyright">
        <IonCol>
          <p className="white-color p-0 m-0">&copy;{getTodaysYear()} Equity Trust Company | <a className="white-color" href="https://www.midlandtrust.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a className="white-color" href="https://www.midlandtrust.com/about-midland/" target="_blank" rel="noopener noreferrer">About</a> | <IonRouterLink onClick={displayCommentBox} target="_blank" class="white-color cursor-pointer underline" rel="noopener noreferrer">Submit Feedback</IonRouterLink></p>
        </IonCol>
      </IonRow>
      <IonModal cssClass={'feedback-modal'} isOpen={showCommentBox} onDidDismiss={() => setShowCommentBox(false)} mode="ios">
        <IonContent className={'gr-border white-bg'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonRow class="pl-2 pr-2">
              <IonCol>
                <p><b>How would you rate your experience?</b></p>
              </IonCol>
            </IonRow>
            <IonRow class="divider pl-2 pr-2 pb-2">
              <IonCol size="2">
                <img onClick={() => selectedRating('happy')} src={selectedHappyRating} width="100%" className="rating" alt="happy" />
              </IonCol>
              <IonCol size="2">
                <img onClick={() => selectedRating('mediocre')} src={selectedMediocreRating} width="100%" className="rating" alt="mediocre" />
              </IonCol>
              <IonCol size="2">
                <img onClick={() => selectedRating('sad')} src={selectedSadRating} width="100%" className="rating" alt="sad" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="pr-2 pl-2" size="12">
                <IonRow>
                  <IonCol>
                    <p><b>Did you have a good experience? Think we can do better? Tell us!</b></p>
                    <p><b>If you have account access or other technical issues, please call online support at (239) 333-4863. Otherwise, we'd love to have your feedback.</b></p>
                  </IonCol>
                </IonRow>
                <IonRow class="mt-2 mb-1">
                  <p className='m-0 font-w-600'>Comments</p>
                </IonRow>
                <IonRow class="w-100 gr-border">
                  <IonCol size="12" class="w-100">
                    <Controller name='comments' control={control} render={({ field: { value, name, onChange } }) =>
                      <IonTextarea name={name} value={value} onIonChange={onChange} autocapitalize="sentences" inputmode="text" className="ion-text-left pl-1 pr-1"></IonTextarea>
                    } />
                  </IonCol>
                </IonRow>
                {/* <p className="text-left pr-3 d-flex align-items-center">
                  <Controller name='optOut' control={control} render={({field: {name, onChange}}) => 
                    <IonCheckbox tabIndex={0} name={name} class="gr-border-circle" onIonChange={event => {
                      onChange(event.detail.checked)
                    }}></IonCheckbox> 
                  }/> 
                &nbsp; Do not show again</p> */}
                {error && <IonItem class="mt-1" color="danger"><p className="white-color">Please select a rating or enter comments above.</p></IonItem>}
                <IonButton type="submit" class="ion-float-right white-color" size="large"  disabled={isLoading}> { isLoading ? <Spinner/> : ""} Submit</IonButton>
                <IonButton onClick={() => setShowCommentBox(false)} color="light" class="ion-float-right" size="large">Cancel</IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonContent>
      </IonModal>
    </IonFooter>
  );
};

export default Footer;
