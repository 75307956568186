import React, { useEffect, useState } from 'react';
import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { Controller, useFormContext } from 'react-hook-form';
import { amountRegex } from '../../helpers/Utils';

type TransferAmountFields = {
    fullOrPartial: FullOrPartialType,
    amount?: number
}

type FullOrPartialType = 'Full' | 'Partial';
type FullOrPartialOption = {
    value: FullOrPartialType,
    description: string
}

const fullOrPartialOptions: FullOrPartialOption[] = [
    { value: 'Partial', description: 'Partial - only send amount below' },
    { value: 'Full', description: 'Full - Transfer all available cash' }
]


const TransferAmountFields: React.FC = () => {
    const { control, formState: {errors}, setValue, clearErrors, watch } = useFormContext<TransferAmountFields>();

    const fullOrPartial = watch('fullOrPartial');

    const notifyFullOrPartialChange = (changeEvent: CustomEvent<any>) => {
        setValue('fullOrPartial', changeEvent?.detail?.value || '');
        if (fullOrPartial === 'Partial') {
            clearErrors('fullOrPartial');
        } else if (fullOrPartial === 'Full') {
            setValue('amount', undefined);
        };
    };


    return (
        <>
            <IonRow class='mt-2 mb-1'>
                <IonLabel>Amount</IonLabel>
            </IonRow>
            <IonRow class='w-100'>
                <Controller name='fullOrPartial' control={control} render={({ field: { value, name } }) => 
                    <IonSelect class='w-100 gr-border p-1' interface='action-sheet' placeholder='Please Select' tabIndex={0} mode='ios' onIonChange={event => notifyFullOrPartialChange(event)} name={name} value={value}>
                        {fullOrPartialOptions?.map(option => <IonSelectOption key={option.value} value={option.value}>{option.description}</IonSelectOption>)}
                    </IonSelect>
                }
                rules={{
                    required: {
                        value: true,
                        message: 'Please select partial or full amount.'
                    }
                }}
                />
            </IonRow>
            {errors['fullOrPartial'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['fullOrPartial']?.message}</p></IonItem>}

            {fullOrPartial === 'Partial' &&
                <>
                    <IonRow class='mt-2 mb-1'>
                        <IonLabel>Cash Amount</IonLabel>
                    </IonRow>
                    <IonRow class='m-0 p-0'>
                        <IonCol class='m-0 p-0' sizeXs='12' sizeSm='12' sizeMd='2' sizeLg='2' sizeXl='2'>
                            <IonItem class='gr-border' lines='none'>$
                                <Controller name='amount' control={control} render={({ field: { value, name, onChange, onBlur } }) => 
                                    <IonInput class='ion-text-left ml-1' name={name} value={value} onIonChange={onChange} onBlur={onBlur} />
                                }
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter transfer cash amount.'
                                    },
                                    pattern: {
                                        value: amountRegex,
                                        message: 'Invalid Amount.'
                                    },
                                    validate: {
                                        amountGreaterThanZero: value => (value && (+value > 0) ? undefined : 'Amount must be greater than 0.')
                                    }
                                }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {errors['partialCashAmount'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['partialCashAmount']?.message}</p></IonItem>}
                </>
            }
        </>
    )
}

export default TransferAmountFields;