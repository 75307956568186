import { ControllerRenderProps } from 'react-hook-form';
import { InputChangeEventDetail } from '@ionic/core'
import { CreditCardFormInfo } from './CreditCardInfo'

const clearNumber = (value: unknown) => {
    const valueChecked = typeof value !== 'string' ? '' : value
    return valueChecked.replace(/\D+/g, "");
}

const formatCreditCardNumber = (value) => {
    if (!value) return value;

    const clearValue = clearNumber(value);
    let nextValue;

    nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;

    return nextValue.trim();
}

/**
 * 
 * @param value 
 * @returns decimal/float value with 2 decimals places
 * 
 * Keeping this incase we decide we want decimals in this form
 */
const formatAmount = (value) => {
    const onlyNumbersAndPeriods  = value.replace(/[^0-9.]/g, "");
    const matchArray = /\d+(?:\.\d{0,2})?/.exec(onlyNumbersAndPeriods)
    const output = matchArray ? matchArray[0] : ''
    return output
}

const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
    if (clearValue.length === 4) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
}

const resolveValue = (name: 'creditCardNumber' | keyof CreditCardPayment.CardFormInputs, event: CustomEvent<InputChangeEventDetail>) => {
    const value = event?.detail?.value
    if(name === 'amount') {
        return clearNumber(value)
    }

    if(name === 'number' || name === 'creditCardNumber') {
        return formatCreditCardNumber(value)
    }

    if(name === 'expiry') {
        return formatExpirationDate(value)
    }

    return event
}

export const translateProps = (props: ControllerRenderProps<CreditCardFormInfo> | ControllerRenderProps<CreditCardPayment.CardFormInputs>) => {
    const eventHandler = (event: CustomEvent<InputChangeEventDetail>)=>props.onChange(resolveValue(props.name, event))
    if(props.name === 'saveCardForFutureUse'){
        return {
            onIonChange: eventHandler,
            name: props.name,
            checked: typeof props.value === 'boolean' ? props.value : false,
            onIonBlur: props.onBlur
        }
    }

    return {
        onIonChange: eventHandler,
        name: props.name,
        value: typeof props.value === 'boolean' ? null : props.value,
        onIonBlur: props.onBlur
    }
}

export default {translateProps}