import React from 'react';
import {
    IonRow,
    IonCol,
    IonItem,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { PostedTransactionFilter } from '../helpers/calloutHelpers';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const PostedTransactionsFilterForm: React.FC<{ transactionTypeOptions: string[], assetOptions: string[], onFilterFormChange: (name: string) => any, postedTransactionFilters: PostedTransactionFilter }> = ({ transactionTypeOptions, assetOptions, onFilterFormChange, postedTransactionFilters }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IonRow data-test="posted-trans-filter-form">
                <IonCol class="p-0 mb-1" size="12">
                <IonItem color="tertiary">Filter Results</IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <IonItem class="w-100 gr-border p-0 m-0">
                    <KeyboardDatePicker data-test="from-date" className="date-width" name="fromDate" value={postedTransactionFilters?.fromDate} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={onFilterFormChange('fromDate')} />
                </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <IonItem class="w-100 gr-border">
                    <KeyboardDatePicker data-test="to-date" className="date-width" name="toDate" value={postedTransactionFilters?.toDate} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={onFilterFormChange('toDate')} />
                </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <IonSelect data-test="transaction-type-select" placeholder="Type" class="w-100 gr-border p-1" tabIndex={0} mode="ios" name="Type" onIonChange={onFilterFormChange('Type')} value={postedTransactionFilters?.Type}>
                    {transactionTypeOptions.map((transType, i) => (
                        (<IonSelectOption key={i} value={transType}> {transType}</IonSelectOption>)
                    ))}
                </IonSelect>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <IonSelect data-test="asset-name-select" interface="action-sheet" placeholder="Related Asset" class="w-100 gr-border p-1" tabIndex={0} mode="ios" name="AssetName1" onIonChange={onFilterFormChange('AssetName1')} value={postedTransactionFilters?.AssetName1}>
                    {assetOptions.map((assetName, i) => 
                        (<IonSelectOption key={i} value={assetName}> {assetName}</IonSelectOption>)
                    )}
                </IonSelect>
                </IonCol>
                {(postedTransactionFilters.fromDate && postedTransactionFilters.toDate) && (Date.parse(postedTransactionFilters.toDate) < Date.parse(postedTransactionFilters.fromDate)) && <IonCol size="12"><IonItem class="mt-1" color="danger"><p className="white-color">Please enter valid date range.</p></IonItem></IonCol>}
            </IonRow>
        </MuiPickersUtilsProvider>
    )
}

export default PostedTransactionsFilterForm;