import React, { useState } from 'react';
import '../pages/Style.css';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import { 
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';

interface ToggleComponentProps {
  title: string;
  bodyData: any;
  isActive: boolean;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({title, bodyData, isActive = false}) => {
  
  const [toggleStatus, setToggleStatus] = useState(isActive);

  const toggleHandler = () => {
    setToggleStatus(prevToggle => !prevToggle);
  }

  return (
    <IonRow>
      <IonCol class="mb-2 p-1 light-gr-bg">
        <IonRow class="gr-border">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pb-1 white-bg">
            <IonRow>
              <IonCol sizeXs="10" sizeSm="10" sizeMd="10" sizeLg="10" sizeXl="10">
                <h2 className="ion-text-left ml-1 mb-0">{title}</h2>
              </IonCol>
              <IonCol sizeXs="2" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2" class="white-bg">
                <IonIcon class="toggle-icon hover-cursor" onClick={toggleHandler} icon={toggleStatus === false ? addCircleOutline : removeCircleOutline} size="large"></IonIcon>
                </IonCol>
              <IonCol>
                <div className={toggleStatus === false ? 'inactive' : 'active'}>
                  {bodyData}
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default ToggleComponent;
