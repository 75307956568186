import React from 'react';
import {
    IonCol,
    IonItem,
    IonRow,
} from '@ionic/react';
import {formatNumber} from 'shared-utils';
import { AxiosResponse } from 'axios';
import DataLoader from './../DataLoader';
import calloutHelpers from '../../helpers/calloutHelpers';

interface BillingDetail {
    amount?: number,
    description?: string
}

export const ProjectedBuyBillingDetails: React.FC<{objectId: string | undefined, dependencies: any, sectionLabel: string}> = (props)=> { return <LoadProjectedBillingDetails {...props} apiCall={calloutHelpers.getBuyTransactionBillingDetails}/> }

export const ProjectedBillingDetails: React.FC<{objectId: string | undefined, dependencies: any, sectionLabel: string}> = (props)=>{ return <LoadProjectedBillingDetails {...props} apiCall={calloutHelpers.getTransactionBillingDetails}/> }

const LoadProjectedBillingDetails: React.FC<{apiCall:(objectId: string | undefined)=>Promise<AxiosResponse<BillingDetail>>, objectId: string | undefined, dependencies: any, sectionLabel: string}> = ({apiCall, objectId, dependencies, sectionLabel}) => {
    
    const renderBillingDetails = (data: {billingDetails: Array<BillingDetail>, billingTotal: number}) => {
        let billingDetails = data?.billingDetails;
        let billingTotal = data?.billingTotal;
        
        return data?.billingDetails.length > 0 && (
            <>
                <IonItem class="w-100" color='tertiary'>
                    {sectionLabel}
                </IonItem>
                <IonRow class="p-1">
                    <IonCol>
                        {billingDetails?.map((billingDetail:BillingDetail, i:number) => {
                            return (
                                <p key={i} className="p-0 mt-0 mb-1 mr-0 ml-0">${formatNumber((billingDetail.amount)?.toFixed(2))}<br/>
                                {billingDetail.description}</p>                    
                            ) 
                        })}    
                        <p><b>Billing Total</b>: ${formatNumber(billingTotal?.toFixed(2))}</p>
                        <p><i>*The charges listed above are an estimate of fees for this transaction but do not factor in special circumstances.</i></p>
                    </IonCol>
                </IonRow>
            </>
        )
    }
    
    return (
        <DataLoader apiCall={() => apiCall(objectId)} render={renderBillingDetails} dependencies={[dependencies]} />
    )
}