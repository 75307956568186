import React, { useState, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonInput,
  IonCheckbox,
} from '@ionic/react';
import progressBarStepTwo from '../../../images/contribution-wizard-step-two.svg';
import BankFields, {DefaultValues as DefaultBankValues} from '../../ConditionalFieldComponents/BankFields';
import {defaultValue as defaultProcessDate} from '../../ForwardDatePicker';
import TransactionScheduleFields from '../../TransactionScheduleFields';
import { amountRegex, isPhone } from '../../../helpers/Utils';
import { useForm, Controller, FieldErrors, FormProvider, useWatch } from 'react-hook-form';
import { InputComponents } from '../ContributionTypes'

type StepTwoFields = ContributionWizard.StepTwoFields

const ContributionStepTwo: React.FC<InputComponents & {contributionOptions: ContributionOptions}> = ({contributionOptions, formRef, submitFields, savedFields}) => {
  const currentDate = new Date();
  const taxDay = new Date(`${contributionOptions.previousYearContributionCutoffDay}, ${currentDate.getFullYear()} 00:00:00`);
  const pastTaxDay = currentDate > taxDay;
  const getDefaultValues = ():StepTwoFields => {
    let bankFields: BankFields = {...DefaultBankValues({bankName: savedFields?.bankName, creditAccountName: savedFields?.creditAccountName, creditAccountNumber: savedFields?.creditAccountNumber, creditAccountType: savedFields?.creditAccountType, routingNumber: savedFields?.routingNumber})}
    return {
      amount: savedFields?.amount || null,
      ...bankFields,
      saveBank: savedFields?.saveBank || false,
      taxYear: savedFields?.taxYear || '',
      processDate: savedFields?.processDate || defaultProcessDate,
      frequency: savedFields?.frequency || 'One-Time',
      recurringTransactionData: {
        schedule: savedFields?.recurringTransactionData?.schedule || '',
        duration: savedFields?.recurringTransactionData?.duration || '',
        endDate: savedFields?.recurringTransactionData?.endDate || undefined
      }
    }
   }
  const [bankFieldsChanged, setBankFieldsChanged] = useState(false);
  const [isPreviousTaxYearContribution, setIsPreviousTaxYearContribution] = useState<boolean>(false);
  const useFormMethods = useForm<StepTwoFields> ({
    mode:'onChange',
    defaultValues: getDefaultValues()
  });

  const taxYearSelected = useWatch({
    name: "taxYear",
    control: useFormMethods.control
  })

  const showEditButton = () => {
    return !(savedFields?.selectedBankId === 'Add Payee' || bankFieldsChanged)
  }

  useEffect(() => {
    if (taxYearSelected === 'Previous Tax Year') {
      setIsPreviousTaxYearContribution(true);
    } else {
      setIsPreviousTaxYearContribution(false);
    }
  }, [taxYearSelected])

  let contributionLimit: number;

  const renderStepTwo = () => {
    const taxYears = contributionOptions?.taxYears as Array<string>;
    const accountType = contributionOptions?.accountType;
    const contributionLimits = contributionOptions?.contributionLimits;
    const isPreviousTaxYear = ((currentDate < taxDay) && taxYearSelected === "Previous Tax Year");

    let accountTypeLowerCase = accountType?.toLowerCase()
    
    if (accountTypeLowerCase?.includes('sep')) {
      contributionLimit = contributionLimits.SEP;
    }

    if (accountTypeLowerCase?.includes('education')) {
      contributionLimit = contributionLimits.ESA;
    }

    if (accountTypeLowerCase?.includes('401')) {
      contributionLimit = isPreviousTaxYear ? contributionLimits['401k'].prevYear : contributionLimits['401k'].currentYear;
    }
    
    if (accountTypeLowerCase?.includes("traditional ira") || accountTypeLowerCase?.includes('roth ira')) {
      contributionLimit = isPreviousTaxYear ? contributionLimits.tradRoth.prevYear : contributionLimits.tradRoth.currentYear;
    }

    if (accountTypeLowerCase?.includes('simple')) {
      contributionLimit = isPreviousTaxYear ? contributionLimits.simple.prevYear : contributionLimits.simple.currentYear;
    }

    if (accountTypeLowerCase?.includes('health savings')) {
      contributionLimit = isPreviousTaxYear ? contributionLimits.HSA.prevYear : contributionLimits.HSA.currentYear;
    }

    const amountErrorMessage = () =>{
      let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
      let currentAmount = useFormMethods.getValues('amount');
      if (currentAmount && contributionLimit && +currentAmount > contributionLimit) {
        return `Please enter an amount below the contribution limit of ${currencyFormatter.format(contributionLimit)}`
      }
      return "Please provide a contribution amount."
    }

    const onValid = (formFields: StepTwoFields)=>{
      submitFields({status: 'ok', formFields})
    }
    
    const onInvalid = (errors: FieldErrors) => {
      submitFields({status: 'error', formFields: useFormMethods.getValues(), errors})
    }
 
    return <FormProvider {...useFormMethods}>
    <form ref={formRef} onSubmit={useFormMethods.handleSubmit(onValid, onInvalid)}>
      {!pastTaxDay && !accountType?.includes('SEP') && !accountType?.includes('ESA') && 
          <>
            <IonRow class="mt-2 mb-1">
              <IonLabel>Tax Year</IonLabel>
            </IonRow>
            <IonRow class="w-100">
                <Controller data-test='tax-year-controller' name='taxYear' control={useFormMethods.control} render={({ field: { value, name, onChange } }) => {
                return  <IonSelect data-testid='tax-year' class="w-100 gr-border p-1" interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" name={name} onIonChange={onChange} value={value}>
                  {taxYears?.map && taxYears?.map((taxYear)=>(<IonSelectOption key={taxYear} value={taxYear}>{taxYear}</IonSelectOption>))}
                </IonSelect>
              }
              } rules={{ required: true }} />
            </IonRow>
            {useFormMethods.formState.errors['taxYear'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please select tax year.</p></IonItem>}
          </>
      }
      <IonRow class="mt-2 mb-1">
        <IonLabel>Amount (USD)</IonLabel>
      </IonRow>
      <IonRow class="m-0 p-0">
        <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
        <IonItem class="gr-border" lines="none">$
              <Controller data-test='amount-controller' name='amount' control={useFormMethods.control} render={({ field: { value, onBlur, onChange, name } }) =>(<IonInput class="ion-text-left ml-1" name={name} type="number" value={value} onIonBlur={onBlur} onIonChange={onChange}/>)}
            rules={{
            required: true,
            validate: (value) => {
              if(contributionLimits){
                return (value !== null && (+value > 0) && (+value <= contributionLimit))
              }else{
                return (value !== null && (+value > 0))
              }
          },
            pattern: amountRegex
          }} /> </IonItem>
        </IonCol>
      </IonRow>
      {useFormMethods.formState.errors['amount'] && <IonItem class="mt-1" color="danger"><p className="white-color"> {amountErrorMessage()} </p></IonItem>}

      <TransactionScheduleFields isPreviousTaxYearContribution={isPreviousTaxYearContribution} />

      <BankFields bankFieldsChanged={setBankFieldsChanged} deliveryMethod={'direct deposit'}/>
      <IonItem lines="none" class="mt-2 mb-1 ion-align-self-end" hidden={showEditButton()}>
        <Controller data-test='save-payee-controller' name='saveBank' control={useFormMethods.control} render={({ field: { value, onChange, name } }) =>(
        <IonCheckbox color="primary" slot="start" name={name} tabIndex={0} checked={value} onIonChange={(event)=>{
          onChange(event.detail.checked)
        }}/>)}
        />
        <IonLabel class="agree-text">
          Save banking info for future.
        </IonLabel>
      </IonItem>
      </form>
    </FormProvider>
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepTwo} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.33}></IonProgressBar>}
        </IonCol>
      </IonRow>
      {renderStepTwo()}
    </>
  );
};

export default ContributionStepTwo;
