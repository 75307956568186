import React from 'react';
import {
    IonRow,
    IonCol,
    IonItem,
} from '@ionic/react';
import { BillingFilter } from '../helpers/calloutHelpers/billingItemCalloutHelper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const BillingFilterForm: React.FC<{ onFilterFormChange: (name: string) => any, billingFilters: BillingFilter }> = ({ onFilterFormChange, billingFilters }) => {
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IonRow>
                <IonCol class="p-0 mb-1" size="12">
                    <IonItem color="tertiary">Filter Results by Date Range</IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                    <IonItem class="w-100 gr-border p-0 m-0">
                        <KeyboardDatePicker disableFuture={true} className="date-width" name="fromDate" value={billingFilters?.fromDate} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={onFilterFormChange('fromDate')} />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                    <IonItem class="w-100 gr-border">
                        <KeyboardDatePicker disableFuture={true} className="date-width" name="toDate" value={billingFilters?.toDate} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={onFilterFormChange('toDate')} />
                    </IonItem>
                </IonCol>
                {(billingFilters.fromDate && billingFilters.toDate) && (Date.parse(billingFilters.toDate) < Date.parse(billingFilters.fromDate)) && <IonCol size="12"><IonItem class="mt-1 mb-2" color="danger"><p className="white-color">Please enter valid date range.</p></IonItem></IonCol>}
            </IonRow>
        </MuiPickersUtilsProvider>
    )
}

export default BillingFilterForm;