import React from 'react'
import { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2'
import { Plugins, Capacitor } from "@capacitor/core";
import qs from 'qs'
import validator from 'validator'
import * as AuthHelpers from './authServiceHelpers'
import * as AuthConst from './authConstants'

const resolveOauthMagicLinkPath = async (idTokenHint: string, magicLinkType?: Auth.MagicLinkType) => {
  const codeChallenge = await AuthHelpers.generateCodeChallenge()
  const magicLinkTypeValidated: Auth.MagicLinkType = magicLinkType === 'reset_pw' ? 'reset_pw' :  'login'
  const queryParams = [
    ['id_token_hint',idTokenHint],
    ['client_id', AuthConst.APP_ID],
    ['response_type', 'code+id_token'],
    ['redirect_uri', encodeURIComponent(AuthConst.REDIRECT)], //REPLACE THIS
    ['response_mode', 'fragment'],
    ['scope', encodeURIComponent(`openid offline_access https://${AuthConst.ENVIRONMENT.tennent}/${AuthConst.ENVIRONMENT.appId}/user_impersonation`)],
    ['code_challenge', codeChallenge],
    ['code_challenge_method', 'S256'],
    ['ml_type', magicLinkTypeValidated], // Tells the policy what kind of magic link this is
    ['state', 'ml'] // state cannot be exposed to b2c policy
  ]
  const queryParamString = queryParams.map(val=>(`${val[0]}=${val[1]}`)).join('&')
  return `${AuthConst.resolveSignInAuthority(AuthConst.POLICIES.magicLink)}/oauth2/v2.0/authorize?${queryParamString}`
}

export const getParamOutOfHashList = (paramKey: string,  hashParamList: string[]) => {
  const keyValuePair = hashParamList.find(val=>val.includes(paramKey))?.split('=')
  if(keyValuePair){
    return keyValuePair[1]
  }
  return undefined
}

export const getSignInWithMagicLink = async (hashParams: string) => {
  const hasParamList = hashParams.split('&')
  const idTokenHint = getParamOutOfHashList('id_token_hint', hasParamList)
  const redirectTypeString = getParamOutOfHashList('ml_type', hasParamList)
  const redirectType: Auth.MagicLinkType | undefined = redirectTypeString === 'reset_pw' || redirectTypeString === 'login' ? redirectTypeString : undefined
 
  if(idTokenHint){
    return await resolveOauthMagicLinkPath(idTokenHint, redirectType)
  }

  throw new Error('id_token_hint undefined')
}

type CodeFlowTypes = 'code-flow' | 'magic-link-code' | 'password-reset-code'
type HashTokenTypes = 'magic-link-hint' | 'reset-self-service' | 'error' | 'other' | CodeFlowTypes

export const getHashTokenType = (hashToken: string): HashTokenTypes => {
  if(hashToken.includes('error')){
    return 'error'
  }

  if(hashToken.includes('id_token_hint')){
    return 'magic-link-hint'
  }

  if(hashToken.includes('id_token') && hashToken.includes('state=password_reset')){
    return 'reset-self-service'
  }

  if(hashToken.includes('code') && hashToken.includes('state=ml')){
    return 'magic-link-code'
  }

  if(hashToken.includes('code') && hashToken.includes(`state=${AuthConst.RESET_PASSWORD_STATE}`)){
    return 'password-reset-code'
  }

  if(hashToken.includes('code')){
    return 'code-flow'
  }

  return 'other'
}


/**
 * The function of this url is to terminate the auth cookies/storage related to the b2c login page.
 * the mobile logout params are currently useless.
 * Could look into opening logout without redirect or finding an implicit way to direct the user to logout
 * but for now i'm just forcing mobile to login everytime with a param prompt:'login'
 * this isn't truely forcing login everytime since the user can remove the prompt param from the url and 
 * bypass login if the user has a auth cookie/ session saved on their browser.
 */

export type LoginType = {
    flow: 'regular',
    path?: string
} | {
    flow: 'passwordReset',
    username: string,
    firstName: string
}

type OauthParamOptions = ({
  platform: 'native',
} | { platform: 'web'}) & LoginType

const getAzureLoginParams = async (oauthParams: OauthParamOptions): Promise<OAuth2AuthenticateOptions> => {

  if(oauthParams.platform === 'native'){
    if(oauthParams.flow === 'passwordReset'){
      return {...AuthHelpers.azureLoginParams(AuthConst.POLICIES.passwordReset), state: AuthConst.RESET_PASSWORD_STATE, additionalParameters:{'login_hint': oauthParams.username}}
    }
    return AuthHelpers.azureLoginParams()
  }
  
  //Web Context
  let codeChallenge = await AuthHelpers.generateCodeChallenge()
  
  if(oauthParams.flow === 'passwordReset'){
    const configWithCodeChallenge = AuthHelpers.addCodeChallengeToAdditionalParameters(AuthHelpers.azureLoginParams(AuthConst.POLICIES.passwordReset), codeChallenge)
    const configWithCodeChallengeAndLoginHint = AuthHelpers.addLoginHintToAdditionalParameters(configWithCodeChallenge, oauthParams.username, oauthParams.firstName)
    configWithCodeChallengeAndLoginHint.state = AuthConst.RESET_PASSWORD_STATE

    return configWithCodeChallengeAndLoginHint
  }

  const configWithCodeChallenge = AuthHelpers.addCodeChallengeToAdditionalParameters(AuthHelpers.azureLoginParams(AuthConst.POLICIES.signIn), codeChallenge)
  if(oauthParams.path){
    configWithCodeChallenge.state = oauthParams.path
  }
  return configWithCodeChallenge
}

export const authenticate = async (loginType: LoginType) => {
  const platform = Capacitor.isNative ? 'native' : 'web'
  let azureLoginParamsWithState: OAuth2AuthenticateOptions = await getAzureLoginParams({...loginType, platform})
  return Plugins.OAuth2Client.authenticate(azureLoginParamsWithState)
}

export const resolveCodeFlowToPolicy = (flowType: CodeFlowTypes) => {
  switch(flowType){
      case 'password-reset-code':
          return AuthConst.POLICIES.passwordReset
      case 'magic-link-code':
          return AuthConst.POLICIES.magicLink
      default:
          return AuthConst.POLICIES.signIn
  }
}

//used by WEB, should not be needed for IOS or Android
export const requestRefreshToken = async (hashParams: string, hashType: CodeFlowTypes)=>{
    let codeVerifier = AuthHelpers.getCodeVerifier()
    let paramString = validator.ltrim(hashParams,'#')
    let paramsDirty = qs.parse(paramString) as any
    //let params = WebUtils.getUrlParams(paramString)
    let params = AuthHelpers.validateCodeFlowParamKeys(paramsDirty)

    if(params === null){
      throw new Error('params provided were invalid')
    }
    
    let pathFromState = parseState(params.state)

    const policy = resolveCodeFlowToPolicy(hashType)
    return {
      tokenResponse: await getRefreshToken(params.code, codeVerifier, policy),
      pathFromState
    }
}

type ERROR_STATE = 'SHOW_ERROR' | 'SHOW_TOO_MANY_ATTEMPTS_ERROR' | 'REDIRECT_TO_MY_PROFILE' | 'REDIRECT_TO_LOGIN'

export const parseError = (hashParam: string): ERROR_STATE => {
  if(hashParam.startsWith('#')){
    hashParam = hashParam.slice(1)
  }
  const decodeHashParam = decodeURI(hashParam)
  
  const errorParts = decodeHashParam.split('&')

  if(errorParts.length < 2){
    return 'SHOW_ERROR'
  }

  const errorType = getParamOutOfHashList('error', errorParts)
  const errorDescription = getParamOutOfHashList('error_description', errorParts)
  const oauthState = getParamOutOfHashList('state', errorParts)

  if(!errorType || errorType.length === 0) {
    return 'SHOW_ERROR'
  }

  if(oauthState === 'password_reset') {
    return 'SHOW_TOO_MANY_ATTEMPTS_ERROR'
  }

  if(errorType && errorDescription) {
    if(errorType === 'access_denied' && errorDescription.includes(AuthConst.ERROR_CODES.USER_CANCELLATION)) {
      if(oauthState === AuthConst.RESET_PASSWORD_STATE) {
        return 'REDIRECT_TO_MY_PROFILE'
      } else {
        return 'REDIRECT_TO_LOGIN'
      }
    }

  }

  return 'SHOW_ERROR'
}

export const parseState = (state?: string) => {
  /* 
    creating a whitelist of allowed values. User has control over what could show up in here, trying to avoid possible XSS
    if we converted the switch in main.tsx to use an object for component rendering we could base the whitelist off the object
    
    this validation technique is tricky for persisting other kinds of state like if we're using a object id to tell what object
    we're looking at e.g. transactions. 

    regex patterns can be used, it's possible once we starting building this out more we may find other forms of state we would
    like to persist. 
    It is possible we could persist most of this state via session or local storage if the parameters are not sensitive.
  */

  let stateRedirectWhitelist = ['account-details','transactions','pending-transaction','posted-transaction','documents']
  
  if(state){
    let stateParams = state.split(',')
    if(stateParams.length > 1){//has params other then default crsrf code
      let path = stateParams.pop() || ''
      if(validator.isIn(path,stateRedirectWhitelist)){
        return path
      }else{
        return 'home'
      }
    }
  }
  //base case
  return 'home'
}


export const logout = async ()=>{
  //callout to delete cookie

}
type ContextType = React.MutableRefObject<{
  logout: ()=>Promise<void>,
  resetPassword: (username: string, firstName: string)=>Promise<void>
}>

export const AuthCheckContext = React.createContext<ContextType>({
  current: {
    logout: async ()=>{},
    resetPassword: async ()=>{}
  }
})

//helper
const getRefreshToken = async (authCode: string, codeVerifier: string, signInPolicy: Auth.LoginPolicies) => {
  const azureLoginParams = AuthHelpers.azureLoginParams()
  let refreshResponse = await (await fetch(AuthConst.resolveTokenEndpoint(signInPolicy), {
    method:'POST',
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    },
    body: `grant_type=authorization_code&client_id=${azureLoginParams.appId}&scope=${azureLoginParams.scope}&code=${authCode}&redirect_uri=${AuthConst.REDIRECT}&code_verifier=${codeVerifier}`
  })).json()

  if(refreshResponse.error){
    throw(refreshResponse.error)
  }

  return refreshResponse
}

//exported functions
const exportedFunctions = {
  authenticate,
  logout,
  requestRefreshToken
}

export default exportedFunctions