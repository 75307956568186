import React from 'react'
import {
  IonCol,
  IonRow,
  IonButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
} from '@ionic/react'
import { openSnapshotInWeb } from '../helpers/documentHelper'
import { connect } from 'react-redux'
import { useForm, useWatch, Controller } from 'react-hook-form'
import SelectAccountBar from './SelectAccountBar'


const SnapshotStatement: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {

  const getDefaultValues = () => {
    let defaultForm = {
      snapshot_length_in_days: '30'
    }
    return defaultForm
  } 

  const { control, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
  });

  const snapShotLengthInDays = useWatch({
    name: 'snapshot_length_in_days',
    control: control
  })

  const resolveFile = async () => {
    await openSnapshotInWeb(selectedAccount?.id, snapShotLengthInDays);
  }

  return (
    <IonRow class='container'>
      <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='12'>
        <IonRow>
          <IonCol class='p-1 light-gr-bg'>
            <IonRow>
              <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                <IonRow>
                  <h1 className='ion-text-left'>Generate Snapshot</h1>
                </IonRow>
                <IonRow class="mt-2 mb-1">
                  <IonLabel>Select Account</IonLabel>
                </IonRow>
                <IonRow class="w-100 gr-border p-0 m-0">
                  <IonCol size="12" class="w-100 p-0 m-0">
                    <SelectAccountBar />
                  </IonCol>
                </IonRow>
                {errors['retirementAccount'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['retirementAccount'].message}</p></IonItem>}
                <IonRow className="mt-2 mb-1">
                  <IonLabel>Please choose the date range and click Create to generate a Statement Snapshot</IonLabel>
                </IonRow>
                <Controller name='snapshot_length_in_days' control={control} data-test='full-or-partial' render={({ field: { value, onBlur, onChange } }) =>
                  <IonSelect class={"w-100 gr-border p-1"} interface="action-sheet" tabIndex={0} mode="ios" value={value} onIonBlur={onBlur} onIonChange={onChange}>
                    <IonSelectOption value="30">Last 30 Days</IonSelectOption>
                    <IonSelectOption value="60">Last 60 Days</IonSelectOption>
                    <IonSelectOption value="90">Last 90 Days</IonSelectOption>
                  </IonSelect>
                } rules={{
                  required: true,
                  validate: (value) => { return value !== null }
                }} />
                <IonButton className="ion-float-right mt-2" type='button' onClick={resolveFile}>Generate Statement</IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  )
}

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount
  }
}

export default connect(mapStateToProps)(SnapshotStatement);