import React from 'react';
import { IonRow, IonCol, IonLabel, IonItem, IonInput } from '@ionic/react';
import { useForm, Controller, Control, RegisterOptions, SubmitHandler, FormProvider, FormState, FieldErrors } from 'react-hook-form';
import WizardProgressBar from '../../WizardProgressBar';
import progressBarStepOneImage from '../../../images/distribution-wizard-step-one.svg'
import { StateSelectorWithErrorMessage } from '../../StateSelector';
import { EMAIL_VALIDATION_PATTERN } from '../../../helpers/Utils';
import { AddInvestorWizardStep } from '../AddInvestorTypes';

export interface AddInvestorWizardStepOne extends AddInvestorWizardStep {
    setErrorMessage: Function,
    errorMessage: string
}

const AddInvestorStepOne: React.FC<AddInvestorWizardStepOne> = ({formRef, submitFields, wizardCompletionRatio, savedFields, setErrorMessage, errorMessage}) => {
    const getDefaultValues = (): AddInvestorWizard.AddInvestorStepOneFields => {
        return {
            firstName: savedFields?.firstName || '',
            lastName: savedFields?.lastName || '',
            email: savedFields?.email || '',
            mailingStreet: savedFields?.mailingStreet || '',
            mailingCity: savedFields?.mailingCity || '',
            mailingState: savedFields?.mailingState || '',
            mailingZip: savedFields?.mailingZip || ''
        };
    };

    const methods = useForm<AddInvestorWizard.AddInvestorStepOneFields>({
        mode: 'onSubmit',
        defaultValues: getDefaultValues()
    });

    const {control, formState, handleSubmit, setValue, getValues, clearErrors } = methods;

    const onValid = (formFields: AddInvestorWizard.AddInvestorStepOneFields) => {
        const addressCheck = formFields.mailingStreet && formFields.mailingCity && formFields.mailingState && formFields.mailingZip;
        const noAddressCheck = !formFields.mailingStreet && !formFields.mailingCity && !formFields.mailingState && !formFields.mailingZip;
        if (noAddressCheck && !formFields.email) {
            setErrorMessage('Please add an email or complete address fields.')
        } else if (!(addressCheck || noAddressCheck)) {
            setErrorMessage('Please complete all mailing address fields.');
        } else {
            submitFields({status: 'ok', formFields});
        };
        
    };

    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: getValues(), errors});
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
            <FormProvider {...methods}>
                <WizardProgressBar progressBarImage={progressBarStepOneImage} progressRatio={wizardCompletionRatio} />
                {(errorMessage !== '') &&
                    <IonItem class='mt-1' color='danger'>
                        <p className='white-color'>{errorMessage}</p>
                    </IonItem>
                }
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <FieldInput control={control} name='firstName' formState={formState} rules={{
                            required: {
                                value: true,
                                message: 'Please input first name.'
                            }
                        }} />
                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <FieldInput control={control} name='lastName' formState={formState} rules={{
                            required: {
                                value: true,
                                message: 'Please input last name.'
                            }
                        }} />
                    </IonCol>
                </IonRow>
                <FieldInput control={control} name='email' formState={formState} rules={{
                    validate: {
                        isEmail: (value => (((new RegExp(EMAIL_VALIDATION_PATTERN).test(value || '')) || !value) ? undefined : 'Please provide a valid email address.'))
                    }, maxLength: {
                        value: 50,
                        message: 'Email cannot exceed 50 characters.'
                    }
                }} slightShift={true} />
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <FieldInput control={control} name='mailingStreet' formState={formState} rules={{
                            maxLength: {
                                value: 40,
                                message: 'Mailing street cannot exceed 40 characters.'
                            }
                        }} />
                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <FieldInput control={control} name='mailingCity' formState={formState} rules={{
                            maxLength: {
                                value: 17,
                                message: 'Mailing city cannot exceet 17 characters.'
                            }
                        }} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <IonRow class='mt-1 mb-1'>
                            <IonLabel><b>Mailing State</b></IonLabel>
                        </IonRow>
                        <StateSelectorWithErrorMessage name='mailingState' requiredDisabled={true} />
                        {formState.errors['mailingState'] &&
                            <IonRow>
                                <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{formState.errors['mailingState']?.message}</p></IonItem>
                            </IonRow>
                        }
                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                        <FieldInput control={control} name='mailingZip' formState={formState} rules={{
                            maxLength: {
                                value: 5,
                                message: 'Mailing zip must be five digits.'
                            }, minLength: {
                                value: 5,
                                message: 'Mailing zip must be five digits'
                            }
                        }} />
                    </IonCol>
                </IonRow>
            </FormProvider>
        </form>
    )
};

const FieldInput: React.FC<{
    control: Control<AddInvestorWizard.AddInvestorStepOneFields>,
    name: keyof AddInvestorWizard.AddInvestorStepOneFields,
    formState: FormState<AddInvestorWizard.AddInvestorStepOneFields>,
    rules?: RegisterOptions<AddInvestorWizard.AddInvestorStepOneFields>,
    characterFilter?: RegExp,
    slightShift?: boolean
}> = ({control, name, formState, rules, characterFilter, slightShift}) => {
    const fieldToLabel: {[key in keyof AddInvestorWizard.AddInvestorStepOneFields]: string} = {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        mailingStreet: 'Mailing Street',
        mailingCity: 'Mailing City',
        mailingState: 'Mailing State',
        mailingZip: 'Mailing Zip'
    }
    const label = fieldToLabel[name];

    return <Controller 
        control={control}
        name={name}
        rules={rules}
        render={({ field: {value, ref, name, onBlur, onChange} }) =>
            <>
                <IonRow class={slightShift ? 'mt-1 mb-1 ml-0-5 mr-1-5' : 'mt-1 mb-1 ml-0 mr-1'}>
                    <IonLabel><b>{`${label}`}</b></IonLabel>
                </IonRow>
                <IonRow>
                    <IonInput data-testid={`${name}-input`} class={slightShift? 'gr-border edit-input ml-0-5 mr-1-5' : 'gr-border edit-input ml-0 mr-1'} mode='ios' type='text' autofocus={true} value={value} ref={ref} name={name} onIonBlur={onBlur} onIonChange={event => onChange(event.detail.value)} onKeyPress={(event) => {
                        if (characterFilter && !characterFilter.test(event.key)) {
                            return event.preventDefault();
                        }
                    }} />
                </IonRow>
                {formState.errors[name] &&
                    <IonRow>
                        <IonItem class={slightShift ? 'mt-1 ml-0-5 mr-1-5 w-100' : 'mt-1 ml-0 mr-1 w-100'} color='danger'><p className='white-color'>{formState.errors[name]?.message}</p></IonItem>
                    </IonRow>
                }
            </>
        }
    />
}

export default AddInvestorStepOne;