import React from 'react';
import { FieldErrors, useForm, FormProvider } from 'react-hook-form';
import WizardProgressBar from '../WizardProgressBar';
import { TransferStepThreeFields, TransferWizardStep } from '../TransferFields/TransferWizardTypes';
import progressBarStepThreeImage from '../../images/transfer-step-three.svg'
import { IonRow } from '@ionic/react';
import TransferAmountFields from '../TransferFields/TransferAmountFields';

const TransferStepThree: React.FC<TransferWizardStep> = ({formRef, submitFields, wizardCompletionRatio, savedFields}) => {
    const getDefaultValues = (): TransferStepThreeFields => {
        return {
            fullOrPartial: savedFields?.fullOrPartial || '',
            amount: savedFields?.amount || undefined
        };
    };

    const stepThreeForm = useForm<TransferStepThreeFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    const onValid = (formFields: TransferStepThreeFields) => {
        submitFields({status: 'ok', formFields});
    };

    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: stepThreeForm.getValues(), errors});
    };

    return (
        <FormProvider {...stepThreeForm}>
            <form ref={formRef} onSubmit={stepThreeForm.handleSubmit(onValid, onInvalid)}>
                <WizardProgressBar progressBarImage={progressBarStepThreeImage} progressRatio={wizardCompletionRatio} />

                <IonRow class='divider'>
                    <p>Please provide details on the amount to transfer:</p>
                </IonRow>
                <TransferAmountFields />
            </form>
        </FormProvider>
    )
}

export default TransferStepThree;