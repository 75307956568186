import React from 'react';
import {IonRow, IonCol, IonItem, IonRouterLink,} from '@ionic/react';
import { formatValueAsCurrency } from '../../../helpers/Utils';
import { useHistory } from 'react-router';

type PendingTransactionStep = {
    formRef: any, 
    submitFields: any,  
    transaction: any, 
    multipleTransactionsFound: boolean,
    retirementAccountLabel: string,
}

const AdditionalBuyPendingTransactionStep : React.FC<PendingTransactionStep> = ({formRef, submitFields, transaction, multipleTransactionsFound, retirementAccountLabel}) => {
    const history = useHistory()
    const goToTransactions = () => {
        history.replace('/transactions')
    }
    
    const handleSubmit = () => {
        const emptyFormFields = {}
        submitFields({ status: 'exit', formFields: emptyFormFields})
    }

    return (
        <>
            <form ref={formRef} onSubmit={handleSubmit}/>
            <IonRow>
                <IonCol size="12">
                    <IonRow>
                        <IonCol>
                            <IonRow class="w-100">
                                {multipleTransactionsFound ? 
                                    (<h1>Multiple pending transactions already exists for this asset. You may view the details below.</h1>) :
                                    (<h1>A pending transaction already exists for this asset. You may view the details below.</h1>)
                                }
                            </IonRow>
                            <IonRow class="divider">
                                <IonItem color='tertiary' class="w-100">
                                    Purchase Details
                                </IonItem>
                                <IonCol size="12" class="p-1">
                                    <p className="p-0 m-0">
                                        <b>Account</b>: {retirementAccountLabel}<br />
                                        <b>Related Asset</b>: {`${transaction.assetName} - ${transaction.assetDescription}`}<br />
                                        {transaction.tradingAccountName && <><b>Trading Account</b>: {transaction.tradingAccountName}<br/></>}
                                        <b>Amount</b>: {formatValueAsCurrency(transaction.amountCash)}
                                    </p>
                                </IonCol>
                                <IonItem color='tertiary' class="w-100">
                                    Delivery Details
                                </IonItem>
                                <IonCol size="12" class="p-1">
                                    <p className="p-0 m-0">
                                        <b>Delivery Method</b>: {transaction.deliveryMethod}<br/>
                                        <b>Bank Name</b>: {transaction.receivingBank}<br/>
                                        <b>Routing Number</b>: {transaction.receivingABA}<br/>
                                        <b>Name on Bank Account</b>: {transaction.creditName}<br/>
                                        <b>Bank Account Number</b>: {transaction.creditAccount}<br/>
                                        <b>Bank Street</b>: {transaction.mailingStreet}<br/>
                                        <b>Bank City</b>: {transaction.mailingCity}<br/>
                                        <b>Bank State</b>: {transaction.mailingState}<br/>
                                        <b>Bank Zip</b>: {transaction.mailingZip}
                                    </p>
                                </IonCol>
                            </IonRow>
                            <IonRow class="w-100">
                                <p>If this transaction needs to be changed or canceled, please contact your client service team: 239-333-4863
                                    {(multipleTransactionsFound) && (<><br />Click <IonRouterLink onClick={() => goToTransactions()} class="cursor-pointer">here</IonRouterLink> to view a full list of your pending transactions.</>)}
                                </p>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    )
}

export default AdditionalBuyPendingTransactionStep;