import { TransferFullForm, SubmittableTransferForm } from '../components/TransferFields/TransferWizardTypes';

export const getProgressRatio = <Type>(currentPosition: Type, allPositions: Array<Type>) => {
    if (!allPositions || allPositions.length === 0) {
        return 0;
    }

    const currentPositionIndex = allPositions.findIndex((position) => position === currentPosition);
    const ratio = (currentPositionIndex + 1 * 1.0) / (allPositions.length);
    return ratio;
};

export const changeSavedFieldsToSubmittableForm = (savedFields: Partial<TransferFullForm>, loggedInDetails?: PersonAccountHelpers.LoggedInDetails, files?: SFFile[]) : SubmittableTransferForm => {
    const partialCashAmountAsNumber: number = +(savedFields.amount || 0);

    const submittableForm: SubmittableTransferForm = {
        accountId: savedFields.accountId || '',
        custodianId: savedFields.custodianId || '',
        fromAccountNumber: savedFields.fromAccountNumber || '',
        fromAccountType: savedFields.fromAccountType || '',
        fullOrPartial: savedFields.fullOrPartial || '',
        amount: partialCashAmountAsNumber,
        files: files || []
    };

    return submittableForm;
};