import React from 'react';
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {
  downloadSharp,
} from 'ionicons/icons';
import ToggleComponent from './ToggleComponent';
import Sidebar from './Sidebar';
import { isMobile } from '../helpers/Utils';
import formsTableData from '../helpers/formsTableData.json';

const Forms: React.FC = () => {

  return (
    <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
      <ToggleComponent isActive={false} title="Open an Account" bodyData={
        <>
          {formsTableData[0].openAnAccount.map(form => {
            return <IonRow key={form.id}>
              <IonCol class="forms-table" sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4"><p><b>{form.title}</b></p></IonCol>
              <IonCol class="forms-table" sizeXs="6" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4"><IonButton class={isMobile() ? 'float-left' : 'float-right'} href={form.buttonURL} target="_blank" color="primary"><IonIcon class="pr-1" icon={downloadSharp}/> Download</IonButton></IonCol>
              <IonCol class="forms-table" sizeXs="6" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4">{form.title === 'Open an Account' && <IonButton class={isMobile() ? 'float-left' : 'float-right'} href="https://apply.midlandtrust.com/Welcome" target="_blank" color="light">Apply Online</IonButton>}</IonCol>
            </IonRow>
          })}
        </>
      } />
      <ToggleComponent isActive={false} title="Fund an Account" bodyData={
        <>
          {formsTableData[0].fundAnAccount.map(form => {
            return <IonRow key={form.id}>
              <IonCol class="forms-table" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8"><p><b>{form.title}</b></p></IonCol>
              <IonCol class="forms-table" sizeXs="6" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4"><IonButton class={isMobile() ? 'float-left' : 'float-right'} href={form.buttonURL} target="_blank" color="primary"><IonIcon class="pr-1" icon={downloadSharp}/> Download</IonButton></IonCol>
            </IonRow>
          })}
        </>
      } />
      <ToggleComponent isActive={false} title="Make or Manage an Investment" bodyData={
        <>
          {formsTableData[0].makeOrManageAnInvestment.map(form => {
            return <IonRow key={form.id}>
              <IonCol class="forms-table" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8"><p><b>{form.title}</b></p></IonCol>
              <IonCol class="forms-table" sizeXs="6" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4"><IonButton class={isMobile() ? 'float-left' : 'float-right'} href={form.buttonURL} target="_blank" color="primary"><IonIcon class="pr-1" icon={downloadSharp}/> Download</IonButton></IonCol>
            </IonRow>
          })}
        </>
      } />
      <ToggleComponent isActive={false} title="Manage an Account" bodyData={
        <>
          {formsTableData[0].manageAnAccount.map(form => {
            return <IonRow key={form.id}>
              <IonCol class="forms-table" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8"><p><b>{form.title}</b></p></IonCol>
              <IonCol class="forms-table" sizeXs="6" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4"><IonButton class={isMobile() ? 'float-left' : 'float-right'} href={form.buttonURL} target="_blank" color="primary"><IonIcon class="pr-1" icon={downloadSharp}/> Download</IonButton></IonCol>
            </IonRow>
          })}
        </>
      }/>
      </IonCol>
      <Sidebar />
    </IonRow>
  );
};

export default Forms;
