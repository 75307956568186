import { IonRouterLink } from '@ionic/react';
import React from 'react';

export const transactionSpecificApprovalTerms = (transactionRecordType?: string): JSX.Element => {
    if (typeof transactionRecordType !== 'string') {
        return defaultTerms
    }
    else if (transactionRecordType === 'Bill_Payment') {
        return billPaymentTerms
    }
    else if (transactionRecordType.includes('Distribution')) {
        return distributionTerms
    }
    else if (transactionRecordType.includes('Contribution')) {
        return contributionTerms
    }
    else if (transactionRecordType.includes('Withdrawal')) {
        return withdrawalTerms
    }
    else if (transactionRecordType.includes('Buy')) {
        if (transactionRecordType.includes('Futures') || transactionRecordType.includes('Brokerage')) {
            return futuresOrBrokerageBuyTerms
        }
        else {
            return genericBuyTerms
        }
    }
    else if (transactionRecordType.includes('Sell')) {
        return sellTerms
    }
    else if (transactionRecordType === 'Rollover_IN_Cash') {
        return rolloverInTerms
    }
    else if (transactionRecordType === 'Roth_Conversion_Cash') {
        return rothConversionTerms
    }
    else {
        return defaultTerms
    }
}

export const billPaymentTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I acknowledge and agree that my account will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where the Custodian is organized, and the federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Custodial Account records. I understand that, in lieu of the retention of the original records, the Administrator and Custodian may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
    <p className="text-left pl-3 pr-3">I understand that my account is self-directed and that the Custodian named in the disclosure statement received when the account was established will not review the merits, appropriateness and/or suitability of any expense payment in general, or in connection with my account in particular. I acknowledge that the Custodian does not endorse, approve or recommend any companies, products, services or investments. I acknowledge that I have not requested that the Custodian provide, and Custodian has not provided any advice with respect to the expense payment directive set forth in this Payment Authorization Letter. I understand that the Custodian does not determine whether this payment is acceptable under the Employee Retirement Income Securities Act (ERISA), the Internal Revenue Code (IRC), Securities Laws, or any applicable federal, state, or local laws. I understand that it is my responsibility to review any expense to ensure compliance with these requirements. I assume all responsibility in ensuring that the Custodian is provided with full payment instructions (including, but not limited to, payment amounts, due dates, addresses of payees and account numbers). This Payment Authorization Letter shall be valid and in full force and effect until revoked in writing to the Custodian.</p>
</>)

export const distributionTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">Notice of Withholding: The distributions you receive from your individual retirement account established at this institution are subject to federal income tax withholding unless you elect not to have withholding apply. You may elect not to have withholding apply to your distribution payments by completing the “Withholding Election” section above. If you do not complete the “Withholding Election” section by the date your distribution is scheduled to begin, federal income tax will be withheld from the amount withdrawn at a rate of 10%. If you elect not to have withholding apply to your distribution payments, or if you do not have enough federal income tax withheld from your distribution, you may be responsible for payment of estimated tax. You may incur penalties under the estimated tax rules if your withholding and estimated tax payments are not sufficient.</p>
    <p className="text-left pl-3 pr-3">I certify that I am the proper party to receive payment(s) from this IRA, and that all information provided by me is true and accurate. I acknowledge that I have read the Notice of Withholding and have completed the Withholding Election above. I further certify that no tax advice has been given to me by the Administrator Custodian, and  that distributions are reported to the IRS, and that all decisions regarding this withdrawal are my own. I expressly assume the responsibility for any adverse consequences which may arise from this withdrawal and I agree that the Custodian shall in no way be responsible for those consequences. </p>
</>)

export const contributionTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I acknowledge and agree that my account will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where the Custodian is organized, and the federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Custodial Account records. I understand that, in lieu of the retention of the original records, the Administrator and Custodian may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
    <p className="text-left pl-3 pr-3">I authorize the Administrator to initiate pre-authorized electronic funds transfers and debit the authorized debit amount indicated above from the designated checking account listed above. I understand this debit will be initiated either one-time or on a recurring basis based on my instructions above. If the process date falls on a weekend or holiday, the account will be debited on the next business day. This authorization will remain in effect until I notify the Administrator to terminate this agreement. To cancel a recurring debit, I understand I must contact the Administrator 10 business days prior to the next automatic debit.</p>
</>)

export const withdrawalTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I understand that my account is self-directed and that the Custodian does not review the merits, legitimacy, appropriateness and/or suitability of any investment purchase or sale in general, including, but not limited to, any investigation and/or due diligence prior to selling any investment, or in connection with my account in particular. I acknowledge that I have not requested that the Custodian does not provide, and the Custodian has not provided, any advice with respect to the investment directive set forth in this Sale Authorization.</p>
    <p className="text-left pl-3 pr-3">I understand that it is my responsibility to conduct all due diligence, including, but not limited to, search concerning the validity of title, and all other investigation that a reasonably prudent investor would undertake prior to selling any investment. I understand that neither the Custodian does not determine whether this investment is acceptable under the Employee Retirement Income Securities Act (ERISA), the Internal Revenue Code (IRC), or any applicable federal, state, or local laws, including securities laws.</p>
    <p className="text-left pl-3 pr-3">I understand that it is my responsibility to review any investments to ensure compliance with these requirements. I understand that the Custodian is not a “fiduciary” for my account and/or my investment as such terms are defined in the IRC, ERISA, and/or any applicable federal, state or local laws. I agree to release, indemnify, defend and hold the Custodian harmless from any claims, including, but not limited to, actions, liabilities, losses, penalties, fines and/or claims by others, arising out of this Sell Direction Letter and/or this investment, including, but not limited to, claims that an investment is not prudent, proper, diversified or otherwise in compliance with ERISA, the IRC and/or any other applicable federal, state or local laws. In the event of claims by others related to my account and/or investment wherein Custodian is named as a party, Custodian shall have the full and unequivocal right at their sole discretion to select their own attorneys to represent them in such litigation and deduct from my account any amounts to pay for any costs and expenses, including, but not limited to, all attorneys' fees, and costs and internal costs (collectively “Litigation Costs”), incurred by Custodian in the defense of such claims and/or litigation. If there are insufficient funds in my account to cover the Litigation Costs incurred by Administrator and/or Custodian, on demand by Custodian, I will promptly reimburse Administrator and/or Custodian the outstanding balance of the Litigation Costs. If I fail to promptly reimburse the Litigation Costs, Custodian shall have the full and unequivocal right to freeze my assets, liquidate my assets, and/or initiate legal action in order to obtain full reimbursement of the Litigation Costs. I also understand and agree that the Custodian will not be responsible to take any action should there be any default with regard to this investment.</p>
    <p className="text-left pl-3 pr-3">I am directing you to complete this transaction as specified above. I confirm that the decision to sell this asset is in accordance with the rules of my account, and I agree to hold harmless and without liability the Custodian of my account under the foregoing hold harmless provision. I understand that no one at Custodian has authority to agree to anything different than my foregoing understandings of Custodian's policy. If any provision of this Sale Authorization is found to be illegal, invalid, void or unenforceable, such provision shall be severed and such illegality or invalidity shall not affect the remaining provisions, which shall remain in full force and effect.</p>
    <p className="text-left pl-3 pr-3">For purposes of this Sale Authorization, I declare that I have examined this document, including accompanying information, and to the best of my knowledge and belief, it is true, correct and complete.</p>
</>)

export const futuresOrBrokerageBuyTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I confirm that I am directing Equity Trust to complete this transaction as specified above.  I understand that my account is self-directed, and I take complete responsibility for any investment I choose for my account, including the investment specified in this Purchase Authorization.  I understand that the Custodian (Equity Trust) does not sell or endorse any investment products, and that it is not affiliated in any way with any investment provider. I understand that the role of the Custodian is limited, and its responsibilities do not include investment selection for my account. I acknowledge that the Custodian has not provided or assumed responsibility for any tax, legal or investment advice with respect to this investment, and I agree that they will not be liable for any loss which results from my decision to purchase the investment.   I understand that the Custodian has not reviewed or will review the merits, legitimacy, appropriateness or suitability of this investment, and I certify that I have done my own due diligence investigation prior to instructing the Custodian to make this investment for my account.  I understand that the Custodian does not determine whether this investment is acceptable under the Employee Retirement Income Securities Act (ERISA), the Internal Revenue Code (IRC), or any applicable federal, state, or local laws, including securities laws. I understand that it is my responsibility to review any investments to ensure compliance with these requirements.</p>
    <p className="text-left pl-3 pr-3">I understand that in processing this transaction the Custodian is only acting as my agent, and nothing will be construed as conferring fiduciary status on the Custodian.  I agree that the Custodian will not be liable for any investment losses sustained by me or my account as a result of this transaction. I agree to indemnify and hold harmless the Custodian from any and all claims, damages, liability, actions, costs, expenses  (including reasonable attorneys' fees) and any loss to my account as a result of any action taken in connection with this investment transaction or resulting from serving as the Custodian for this investment, including, without limitation, claims, damages, liability, actions and losses asserted by me.</p>
    <p className="text-left pl-3 pr-3">I understand that if this Purchase Authorization and any accompanying documentation are not received as required, or, if received, are unclear in the opinion of the Custodian, or if there is insufficient Undirected Cash in my account to fully comply with my instructions to purchase the investment and to pay all fees, the Custodian may not process this transaction until proper documentation and/or clarification is received, and the Custodian will have no liability for loss of income or appreciation.</p>
    <p className="text-left pl-3 pr-3">I understand that my account is subject to the provisions of Internal Revenue Code (IRC) §4975, which defines certain prohibited transactions. I acknowledge that neither the Custodian has not made or will not make any determination as to whether this investment is prohibited under §4975 or under any other federal, state or local law.  I certify that making this investment will not constitute a prohibited transaction and that it complies with all applicable federal, state, and local laws, regulations and requirements.</p>
    <p className="text-left pl-3 pr-3">I understand that my account is subject to the provisions of IRC §§511-514 relating to Unrelated Business Taxable Income (UBTI) of tax-exempt organizations.</p>
    <p className="text-left pl-3 pr-3">If this investment generates UBTI, I understand that I will be responsible for preparing or having prepared the required IRS Form 990-T tax return and any other documents that may be required.  I understand that neither the Custodian does not make any determination of whether or not investments in my account generate UBTI.</p>
    <p className="text-left pl-3 pr-3">I understand that the assets in my account are required by the IRS to be valued annually as of the end of each calendar year. I agree to provide the prior year end value of this investment by no later than January 10th of each year on a form provided by the Custodian, with substantiation attached to support the value provided.</p>
    <p className="text-left pl-3 pr-3">I understand that with some types of accounts there are rules for Required Minimum Distributions (RMDs) from the account. If I am now subject to the RMD rules in my account, or if I will become subject to those rules during the term of this investment, I represent that I have verified either that the investment will provide income or distributions sufficient to cover each RMD, or that there are other assets in my account or in other accounts that are sufficiently liquid (including cash) from which I will be able to withdraw my RMDs.  I understand that failure to take RMDs may result in a tax penalty of 50% of the amount I should have withdrawn.</p>
    <p className="text-left pl-3 pr-3">I understand that all communication regarding this transaction must be in writing and must be signed by me or by my authorized agent on my behalf, and that no oral modification of my instructions will be valid.</p>
    <p className="text-left pl-3 pr-3">I understand and agree that neither the Custodian does not bear or assume any responsibility to notify me or to secure or maintain any fire, casualty, liability or other insurance coverage, including but not limited to title insurance coverage, on this investment or on any property which serves as collateral for this investment.  I acknowledge and agree that it is my sole responsibility to decide what insurance is necessary or appropriate for investments in my account, and to direct the Custodian in writing (on a form prescribed by the Custodian) to pay the premiums for any such insurance.</p>
    <p className="text-left pl-3 pr-3">I further understand and agree that the Custodian is not responsible for notification or payments of any real estate taxes, homeowners association dues, utilities or other charges with respect to this investment unless I specifically direct the Custodian to pay these amounts in writing (on a form prescribed by the Custodian), and sufficient funds are available to pay these amounts from my account.  I acknowledge that it is my responsibility to provide to the Custodian or to ensure that the Custodian has received any and all bills for insurance, taxes, homeowners dues, utilities or other amounts due for this investment. Furthermore, I agree that it is my responsibility to determine that payments have been made by reviewing my account statements.</p>
    <p className="text-left pl-3 pr-3">I understand that no person at the office of the Custodian has the authority to modify any of the foregoing provisions.  I certify that I have examined this Purchase Authorization and any accompanying documents or information, and to the best of my knowledge and belief, it is all true, correct and complete.</p>
</>)

export const genericBuyTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I confirm that I am directing Equity Trust to complete this transaction as specified above.  I understand that my account is self-directed, and I take complete responsibility for any investment I choose for my account, including the investment specified in this Purchase Authorization.  I understand that the Custodian (Equity Trust) does not sell or endorse any investment products, and that it is not affiliated in any way with any investment provider. I understand that the role of the Custodian is limited, and its responsibilities do not include investment selection for my account. I acknowledge that the Custodian has not provided or assumed responsibility for any tax, legal or investment advice with respect to this investment, and I agree that they will not be liable for any loss which results from my decision to purchase the investment.   I understand that the Custodian has not reviewed or will review the merits, legitimacy, appropriateness or suitability of this investment, and I certify that I have done my own due diligence investigation prior to instructing the Custodian to make this investment for my account.  I understand that the Custodian does not determine whether this investment is acceptable under the Employee Retirement Income Securities Act (ERISA), the Internal Revenue Code (IRC), or any applicable federal, state, or local laws, including securities laws. I understand that it is my responsibility to review any investments to ensure compliance with these requirements.</p>
    <p className="text-left pl-3 pr-3">I understand that in processing this transaction the Custodian is only acting as my agent, and nothing will be construed as conferring fiduciary status on the Custodian.  I agree that the Custodian will not be liable for any investment losses sustained by me or my account as a result of this transaction. I agree to indemnify and hold harmless the Custodian from any and all claims, damages, liability, actions, costs, expenses  (including reasonable attorneys' fees) and any loss to my account as a result of any action taken in connection with this investment transaction or resulting from serving as the Custodian for this investment, including, without limitation, claims, damages, liability, actions and losses asserted by me.</p>
    <p className="text-left pl-3 pr-3">I understand that if this Purchase Authorization and any accompanying documentation are not received as required, or, if received, are unclear in the opinion of the Custodian, or if there is insufficient Undirected Cash in my account to fully comply with my instructions to purchase the investment and to pay all fees, the Custodian may not process this transaction until proper documentation and/or clarification is received, and the Custodian will have no liability for loss of income or appreciation.</p>
    <p className="text-left pl-3 pr-3">I understand that my account is subject to the provisions of Internal Revenue Code (IRC) §4975, which defines certain prohibited transactions. I acknowledge that neither the Custodian has not made or will not make any determination as to whether this investment is prohibited under §4975 or under any other federal, state or local law.  I certify that making this investment will not constitute a prohibited transaction and that it complies with all applicable federal, state, and local laws, regulations and requirements.</p>
    <p className="text-left pl-3 pr-3">I understand that my account is subject to the provisions of IRC §§511-514 relating to Unrelated Business Taxable Income (UBTI) of tax-exempt organizations.</p>
    <p className="text-left pl-3 pr-3">If this investment generates UBTI, I understand that I will be responsible for preparing or having prepared the required IRS Form 990-T tax return and any other documents that may be required.  I understand that neither the Custodian does not make any determination of whether or not investments in my account generate UBTI.</p>
    <p className="text-left pl-3 pr-3">I understand that the assets in my account are required by the IRS to be valued annually as of the end of each calendar year. I agree to provide the prior year end value of this investment by no later than January 10th of each year on a form provided by the Custodian, with substantiation attached to support the value provided.</p>
    <p className="text-left pl-3 pr-3">I understand that with some types of accounts there are rules for Required Minimum Distributions (RMDs) from the account. If I am now subject to the RMD rules in my account, or if I will become subject to those rules during the term of this investment, I represent that I have verified either that the investment will provide income or distributions sufficient to cover each RMD, or that there are other assets in my account or in other accounts that are sufficiently liquid (including cash) from which I will be able to withdraw my RMDs.  I understand that failure to take RMDs may result in a tax penalty of 50% of the amount I should have withdrawn.</p>
    <p className="text-left pl-3 pr-3">I understand that all communication regarding this transaction must be in writing and must be signed by me or by my authorized agent on my behalf, and that no oral modification of my instructions will be valid.</p>
    <p className="text-left pl-3 pr-3">I understand and agree that neither the Custodian does not bear or assume any responsibility to notify me or to secure or maintain any fire, casualty, liability or other insurance coverage, including but not limited to title insurance coverage, on this investment or on any property which serves as collateral for this investment.  I acknowledge and agree that it is my sole responsibility to decide what insurance is necessary or appropriate for investments in my account, and to direct the Custodian in writing (on a form prescribed by the Custodian) to pay the premiums for any such insurance.</p>
    <p className="text-left pl-3 pr-3">I further understand and agree that the Custodian is not responsible for notification or payments of any real estate taxes, homeowners association dues, utilities or other charges with respect to this investment unless I specifically direct the Custodian to pay these amounts in writing (on a form prescribed by the Custodian), and sufficient funds are available to pay these amounts from my account.  I acknowledge that it is my responsibility to provide to the Custodian or to ensure that the Custodian has received any and all bills for insurance, taxes, homeowners dues, utilities or other amounts due for this investment. Furthermore, I agree that it is my responsibility to determine that payments have been made by reviewing my account statements.</p>
    <p className="text-left pl-3 pr-3">I understand that no person at the office of the Custodian has the authority to modify any of the foregoing provisions.  I certify that I have examined this Purchase Authorization and any accompanying documents or information, and to the best of my knowledge and belief, it is all true, correct and complete.</p>
</>)

export const sellTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I understand that my account is self-directed and that the Custodian does not review the merits, legitimacy, appropriateness and/or suitability of any investment purchase or sale in general, including, but not limited to, any investigation and/or due diligence prior to selling any investment, or in connection with my account in particular. I acknowledge that I have not requested that the Custodian does not provide, and the Custodian has not provided, any advice with respect to the investment directive set forth in this Sale Authorization.</p>
    <p className="text-left pl-3 pr-3">I understand that it is my responsibility to conduct all due diligence, including, but not limited to, search concerning the validity of title, and all other investigation that a reasonably prudent investor would undertake prior to selling any investment. I understand that neither the Custodian does not determine whether this investment is acceptable under the Employee Retirement Income Securities Act (ERISA), the Internal Revenue Code (IRC), or any applicable federal, state, or local laws, including securities laws.</p>
    <p className="text-left pl-3 pr-3">I understand that it is my responsibility to review any investments to ensure compliance with these requirements. I understand that the Custodian is not a “fiduciary” for my account and/or my investment as such terms are defined in the IRC, ERISA, and/or any applicable federal, state or local laws. I agree to release, indemnify, defend and hold the Custodian harmless from any claims, including, but not limited to, actions, liabilities, losses, penalties, fines and/or claims by others, arising out of this Sell Direction Letter and/or this investment, including, but not limited to, claims that an investment is not prudent, proper, diversified or otherwise in compliance with ERISA, the IRC and/or any other applicable federal, state or local laws. In the event of claims by others related to my account and/or investment wherein Custodian is named as a party, Custodian shall have the full and unequivocal right at their sole discretion to select their own attorneys to represent them in such litigation and deduct from my account any amounts to pay for any costs and expenses, including, but not limited to, all attorneys' fees, and costs and internal costs (collectively “Litigation Costs”), incurred by Custodian in the defense of such claims and/or litigation. If there are insufficient funds in my account to cover the Litigation Costs incurred by Administrator and/or Custodian, on demand by Custodian, I will promptly reimburse Administrator and/or Custodian the outstanding balance of the Litigation Costs. If I fail to promptly reimburse the Litigation Costs, Custodian shall have the full and unequivocal right to freeze my assets, liquidate my assets, and/or initiate legal action in order to obtain full reimbursement of the Litigation Costs. I also understand and agree that the Custodian will not be responsible to take any action should there be any default with regard to this investment.</p>
    <p className="text-left pl-3 pr-3">I am directing you to complete this transaction as specified above. I confirm that the decision to sell this asset is in accordance with the rules of my account, and I agree to hold harmless and without liability the Custodian of my account under the foregoing hold harmless provision. I understand that no one at Custodian has authority to agree to anything different than my foregoing understandings of Custodian's policy. If any provision of this Sale Authorization is found to be illegal, invalid, void or unenforceable, such provision shall be severed and such illegality or invalidity shall not affect the remaining provisions, which shall remain in full force and effect.</p>
    <p className="text-left pl-3 pr-3">For purposes of this Sale Authorization, I declare that I have examined this document, including accompanying information, and to the best of my knowledge and belief, it is true, correct and complete.</p>
</>)

export const rolloverInTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <p className="text-left pl-3 pr-3">I hereby agree to the terms and conditions set forth in this Rollover form and acknowledge having established a Self-Directed Account through execution of an account application. I understand the rules and conditions applicable to a rollover. I qualify for the Indirect Rollover or Direct Rollover of assets listed in the Asset Description above and authorize such transactions. If this is a Indirect Rollover or Direct Rollover, I have been advised to see a tax advisor due to the important tax consequences of rolling assets into an self-direct account. If this is a Indirect Rollover or Direct Rollover, I assume full responsibility for this Indirect Rollover or Direct Rollover transaction and will not hold the Custodian or Issuer of either the distributing or receiving plan liable for any adverse consequences that may result. I understand that no one at Equity Trust or any of its licensees has authority to agree to anything different than my foregoing understandings of Equity Trusts policy. If this is a Indirect Rollover or Direct Rollover, I irrevocably designate this contribution of the assets listed above as a rollover contribution. If this is an Indirect Rollover, I further certify the following by signing this form:</p>
    <ol className="pl-5 pr-3" type="A">
        <li className="text-left pl-1">This rollover contribution is being made within 60 days after my receipt of funds from another IRA</li>
        <li className="text-left pl-1">. During the 12-month period prior to my receipt of the distribution being rolled over, I have not received a rollover distribution from any IRA which was subsequently rolled over to another IRA</li>
        <li className="text-left pl-1">I am not rolling over any Required Minimum Distributions with respect to the distributing plan.</li>
    </ol>
</>)

export const rothConversionTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following
        statements:</b></p>
    <ol className="pl-5 pr-3" type="1">
        <li className="text-left pl-1">I certify that the information provided including my Social Security Number is true and correct to the best of my knowledge.</li>
        <li className="text-left pl-1">I certify that no tax advice has been given to me by the Custodian.</li>
        <li className="text-left pl-1"> This conversion is being made within 60 days after my receipt of funds from my traditional IRA plan or Employer Sponsored plan, if applicable.</li>
        <li className="text-left pl-1">I hereby irrevocably elect, to treat this transaction as a conversion as permitted under the IRS Regulations.</li>
        <li className="text-left pl-1">It is recommended that I consult with my tax advisor before completing this transaction.</li>
        <li className="text-left pl-1">I acknowledge that the distribution and conversion transactions will be reported to the IRS.</li>
        <li className="text-left pl-1">I acknowledge that I am responsible for the record keeping of the Roth Account information.</li>
        <li className="text-left pl-1">I expressly assume the responsibility for any adverse consequences which may arise from this conversion request and I agree that the Custodian shall in no way be responsible for those consequences.</li>
        <li className="text-left pl-1">I hereby release the Custodian from any claim for damages on account of the failure of this transaction to qualify as a valid conversion.</li>
    </ol>
</>)

export const defaultTerms = (<>
    <p className="text-left pl-3 pr-3"><b>By clicking “APPROVE”, you affirm that you have fully read, acknowledge, and agree to the following statements:</b></p>
    <p className="text-left pl-3 pr-3">I acknowledge and agree that my account and this transaction will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where Equity Trust is organized, and the Federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Custodial Account records.</p>
    <p className="text-left pl-3 pr-3">I understand that, in lieu of the retention of the original records, Equity Trust, as the Custodian and/or Administrator, may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
    <p className="text-left pl-3 pr-3">I hereby acknowledge and agree that I am solely responsible for any and all transactions. I shall hold harmless, protect, and indemnify Equity Trust, the Custodian and/or Administrator, from and against any and all liabilities, losses, damages, expenses, penalties, taxes and charges that Equity Trust, the Custodian and/or Administrator, may sustain or might sustain resulting directly or indirectly from this disbursement.</p>
</>)
