import React, { useState, useEffect } from 'react';
import '../pages/Style.css';
import ContainerComponent from './ContainerComponent';
import {useHistory} from 'react-router-dom';
import { 
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonToast
} from '@ionic/react';
import {
  mailOutline,
  duplicateOutline,
  exitOutline,
  cashOutline,
  walletOutline,
  peopleOutline,
  createOutline,
  documentOutline,
  swapHorizontalOutline,
  logInOutline
} from 'ionicons/icons';
import { connect } from 'react-redux';
import calloutHelper from '../helpers/calloutHelpers';
import AccountDetails from './AccountDetails';
import { checkPartyTypeFeatureAccess, checkPartyTypeFeatureAndHomeQuickLinkAccess } from './PartyTypeConditionalAccess';
import { throwError } from 'shared-utils';
import FileUpload from './FileUpload/FileUpload';

const generateButtons = (history: any, selectedAccount: RetirementAccount | undefined , showTradingAccountWithdrawalButton: Boolean, showBillPayButton: Boolean, setShowFileUploadModal: Function) =>{
  const pathname = history?.location?.pathname;
  const selectedPartyType = selectedAccount?.partyType;

  let buttons: Array<any> = [];

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'updateAssetValue')) {
    if (history.location.state && history.location.state.MVFromATG) {
      let holding = history.location.state;
      let holdingButton = {
        text: 'Update Value',
        icon: createOutline,
        handler: () => {
          history.push({
            pathname: '/update-asset',
            state: { holding }
          })
        }
      }
      buttons.push(holdingButton);
    }
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createTradingAccountWithdrawal')) {
    if (showTradingAccountWithdrawalButton) {
      let tradingAccountWithdrawalButton = {
        text: 'Submit Trading Account Withdrawal',
        icon: duplicateOutline,
        handler: () => { history.push("/trading-account-withdrawal") }
      }
      buttons.push(tradingAccountWithdrawalButton);
    }
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createAdditionalBuy')) {
    let additionalBuyButton = {
      text: 'Send Funds to an Existing Investment',
      icon: exitOutline,
      handler: () => {
        history.push("/additional-buy", { fromHolding: false });
      }
    }
    buttons.push(additionalBuyButton)
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createBillPayment')) {
    if (showBillPayButton || pathname.toLowerCase().includes('transactions') || selectedPartyType?.includes('Fund Manager - Full')) {
      let payBillButton = {
        text: selectedPartyType === 'Fund Manager - Full' ? 'Pay an Expense' : 'Pay a Bill',
        icon: mailOutline,
        handler: () => { history.push("/pay-a-bill") }
      }
      buttons.push(payBillButton)
    }
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createContribution')) {
    let contributionButton = {
      text: 'Make a Contribution',
      icon: cashOutline,
      handler: () => { history.push("/make-a-contribution") }
    }
    buttons.push(contributionButton)
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createDistribution')) {
    let distributionButton = {
      text: selectedPartyType === 'Fund Manager - Full' ? 'Request Disbursement' : 'Request Distribution',
      icon: walletOutline,
      handler: () => { history.push('/request-distribution') }
    }
    buttons.push(distributionButton)
  }

  if (pathname.toLowerCase().includes('account-details') && checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'editRelatedParties')) {
    let editBeneficiariesButton = {
      text: 'Edit Beneficiaries',
      icon: peopleOutline,
      handler: () => {
        history.push({ pathname: '/edit-beneficiaries', state: { accountId: selectedAccount?.id, } })
      }
    }
    buttons.push(editBeneficiariesButton)
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createTransfer')) {
    let createTransferButton = {
      text: 'Transfer Cash from another IRA',
      icon: swapHorizontalOutline,
      handler: () => {
        history.push({ pathname: '/request-transfer' })
      }
    }
    buttons.push(createTransferButton)
  }

  if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'generateStatement') && !selectedPartyType?.includes('Fund Manager')) {
    let generateStatementButton = {
      text: 'Generate Statement',
      icon: documentOutline,
      handler: () => {
        history.push({ pathname: '/generate-statement' })
      }
    }
    buttons.push(generateStatementButton)
  }

  let uploadFileButton = {
    text: 'Upload a File',
    icon: logInOutline,
    handler: () => {
      setShowFileUploadModal(true)
    }
  }
  buttons.push(uploadFileButton)
  
  return buttons
}

const Sidebar: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {
  const [showTradingAccountWithdrawalButton, setShowTradingAccountWithdrawalButton] = useState<Boolean>(false);
  const [ showBillPayButton, setShowBillPayButton ] = useState<Boolean>(false);
  const [ showFileUploadModal, setShowFileUploadModal ] = useState<boolean>(false);
  const [ filesToast, setFilesToast ] = useState<{message: string, color: 'success' | ''}>();
  const history = useHistory<{MVFromATG?: any, holding?: any}>();
  const menuItems: Array<any> = generateButtons(history, selectedAccount, showTradingAccountWithdrawalButton, showBillPayButton, setShowFileUploadModal);

  useEffect(() => {

    const displayTradingAccountWithdrawalAndBillPayButtons = async () => {
      try {
        const holdingResult = await calloutHelper.getNonCashHoldings(selectedAccount?.id)
        const assetTypes = holdingResult.data.map(holding => holding.AssetType);
  
        if (checkPartyTypeFeatureAccess(selectedAccount, 'createTradingAccountWithdrawal')) {
          if (assetTypes.includes('Futures/Forex Acct') || assetTypes.includes('Brokerage Acct') || assetTypes.includes('Bank Acct - Other Institution') || assetTypes.includes('Cryptocurrency')) {
            setShowTradingAccountWithdrawalButton(true);
          } else {
            setShowTradingAccountWithdrawalButton(false);
          };
        }

        if (checkPartyTypeFeatureAccess(selectedAccount, 'createBillPayment')) {
          if (assetTypes.includes('Property Owned')) {
            setShowBillPayButton(true);
          } else {
            setShowBillPayButton(false);
          };
        };
      } catch (err) {
        throwError(err);
      };
    };
    
    displayTradingAccountWithdrawalAndBillPayButtons();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, AccountDetails]);
  
  const finishUpload = () => {
    setFilesToast({
      message: 'Successfully uploaded files.',
      color: 'success'
    });
    setTimeout(() => setFilesToast(undefined), 4000);
    setShowFileUploadModal(false);
  }

  return ((menuItems.length > 0) ?
    <IonCol class='display-desktop' sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
      <IonToast isOpen={filesToast !== undefined} color={filesToast?.color} position='top' message={filesToast?.message} onDidDismiss={() => setFilesToast(undefined)} buttons={[{ icon: 'close-circle' }]} />
      <IonRow>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
          <IonList class="p-0">
            <ContainerComponent className="p-1 light-gr-bg mb-0" title="I want to:" content={
                menuItems.map(menuItem => {
                  return <IonItem key={menuItem.text} onClick={() => menuItem.handler()} class="cursor-pointer text-branded-orange"><IonIcon color="tertiary" slot="start" icon={menuItem?.icon} class="m-0 pr-1"></IonIcon>{menuItem.text}</IonItem>;
                })
            } />
          </IonList>
        </IonCol>
      </IonRow>
      <FileUpload setShowFileUploadModal={setShowFileUploadModal} showFileUploadModal={showFileUploadModal} finishUpload={finishUpload} />
    </IonCol> : null
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
  }
}

export default connect(mapStateToProps)(Sidebar);
