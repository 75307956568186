import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IonRow, IonLabel, IonSelect, IonSelectOption, IonCol } from '@ionic/react';
import TooltipComponent from './TooltipComponent/TooltipComponent';
import helpCircleOutline from '../images/help-circle-outline.svg';
import ForwardDatePicker from './ForwardDatePicker';

type recurringTransactionFields = {
    processDate?: string|undefined,
    frequency: string,
    recurringTransactionData: {
        schedule: string,
        duration: string,
        endDate?: string|undefined,
        processDate?: string|undefined
    }
};

const TransactionScheduleFields: React.FC<{transactionType?: string, isPreviousTaxYearContribution?: boolean}> = ({transactionType, isPreviousTaxYearContribution}) => {
    const { setValue, watch, control } = useFormContext<recurringTransactionFields>();

    /* If transactionType is 'distribution, processDate will be undefined.
    This is done as Salesforce does not accept empty strings in Date fields */
    useEffect(() => {
        if (transactionType === 'distribution') {
            setValue('recurringTransactionData.processDate', undefined);
        };
    }, [])

    useEffect(() => {
        if (isPreviousTaxYearContribution) {
            setValue('frequency', 'One-Time')
        };
    }, [isPreviousTaxYearContribution])

    const onRecurringFieldChange = (onChange, event, name) => {
        onChange(event)
        if (name === 'frequency' && event.detail.value === 'One-Time' && transactionType === 'distribution') {
          setValue('recurringTransactionData.processDate', undefined);
        };

        if (name === 'frequency' && event.detail.value === 'One-Time') {
          setValue('recurringTransactionData.schedule', '');
          setValue('recurringTransactionData.duration', '');
          setValue('recurringTransactionData.endDate', undefined);
        } else if (name === 'frequency' && event.detail.value === 'Recurring') {
          setValue('recurringTransactionData.duration', 'Until I Cancel');
          setValue('recurringTransactionData.endDate', undefined);
        } else if (name === 'recurringTransactionData.duration' && event.detail.value === 'Until I Cancel') {
          setValue('recurringTransactionData.endDate', undefined);
        };
    };

    const frequency = watch('frequency');
    const duration = watch('recurringTransactionData.duration');

    return (
        <>
            <IonRow class="mt-2 mb-1">
                <IonLabel>Frequency</IonLabel>
            </IonRow>
            <IonRow>
                <Controller name='frequency' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonSelect id='conditional-select' class="w-100 gr-border p-1" disabled={isPreviousTaxYearContribution} name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={event => onRecurringFieldChange(onChange, event, name)} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                        <IonSelectOption data-testid='one-time-option' value='One-Time'>One-Time</IonSelectOption>
                        {!isPreviousTaxYearContribution && <IonSelectOption data-testid='recurring-option' value='Recurring'>Recurring</IonSelectOption>}
                    </IonSelect>
                } rules={{
                    required: true
                }} />
            </IonRow>
                {frequency === 'Recurring' &&
                    <IonRow>
                        <IonCol class='ml-0 pl-0' sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <IonRow class="mt-2 mb-1">
                                <IonLabel class="align-items-center">Schedule<TooltipComponent icon={helpCircleOutline} message={'Recurring transactions will be created every 1, 3, or 12 months after the chosen process date based on selected schedule.'} /></IonLabel>
                            </IonRow>
                            <IonRow class="mt-1 mb-1">
                                <Controller name='recurringTransactionData.schedule' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                                    <IonSelect class="w-100 gr-border p-1" name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                                        <IonSelectOption value='Monthly'>Monthly</IonSelectOption>
                                        <IonSelectOption value='Quarterly'>Quarterly</IonSelectOption>
                                        <IonSelectOption value='Annually'>Annually</IonSelectOption>
                                    </IonSelect>
                                } rules={{
                                    required: true
                                }} />
                            </IonRow>
                        </IonCol>
                        <IonCol class='ml-0 pl-0 mr-0 pr-0' sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <IonRow class="mt-2 mb-1-4">
                                <IonLabel>Duration</IonLabel>
                            </IonRow>
                            <IonRow class="mt-1 mt-1">
                                <Controller name='recurringTransactionData.duration' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                                <IonSelect class="w-100 gr-border p-1" name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={event => onRecurringFieldChange(onChange, event, name)} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                                    <IonSelectOption value='Until I Cancel'>Until I Cancel</IonSelectOption>
                                    <IonSelectOption value='Specified Date'>Specified Date</IonSelectOption>
                                </IonSelect>
                                } rules={{
                                    required: true
                                }} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                }

            {transactionType === 'distribution' && frequency === 'Recurring'  &&
                <>
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>First Process Date</IonLabel>
                    </IonRow>
                    <ForwardDatePicker fieldName='recurringTransactionData.processDate'/> 
                </>
            }

            {transactionType !== 'distribution' &&
                <>
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>{frequency === 'Recurring' ? 'First Process Date' : 'Process Date'}</IonLabel>
                    </IonRow>
                    <ForwardDatePicker />
                </>
            }
           
            {frequency === 'Recurring' && duration === 'Specified Date' &&
            <>
                <IonRow class="mt-2 mb-1" >
                    <IonLabel>End Date</IonLabel>
                </IonRow>
                <ForwardDatePicker fieldName='recurringTransactionData.endDate'/>
            </>
            }
        </>
    )
}

export default TransactionScheduleFields;