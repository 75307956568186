import React from 'react';
import { IonRow, IonCol, IonItem } from '@ionic/react'
import { TransferWizardStep } from '../TransferFields/TransferWizardTypes';
import { formatValueAsCurrency } from '../../helpers/Utils';
import WizardProgressBar from '../WizardProgressBar';
import progressBarStepFourImage from '../../images/transfer-step-four.svg'


interface ReviewStep extends TransferWizardStep {
    availableAccounts: RetirementAccount[]
}

const TransferReview: React.FC<ReviewStep> = ({ formRef, submitFields, wizardCompletionRatio, savedFields, availableAccounts }) => {
    const selectedAccount = availableAccounts.find(account => account.id === savedFields?.accountId);

    const submitForm = (e) => {
        e.preventDefault();
        const noSubmittableFields = {};
        submitFields({status: 'ok', formFields: noSubmittableFields});
    };

    return (
        <form ref={formRef} onSubmit={submitForm}>
            <WizardProgressBar progressBarImage={progressBarStepFourImage} progressRatio={wizardCompletionRatio} />

            <IonRow>
                <IonCol size='12'>
                    <IonRow class='w-100'>
                        <p>Please review your transfer request. If the details are correct, click the "Next" button, and documents for signature will be provided.</p>
                    </IonRow>
                    <IonRow class='divider'>
                        <IonCol size='12'>
                            <IonItem color='tertiary'>
                                Transfer Details
                            </IonItem>
                        </IonCol>
                        <IonCol size='12'>
                            <IonItem lines='none'>
                                <p>
                                    <b>Equity Trust Account</b>: {selectedAccount?.accountNumber} - {selectedAccount?.firstName} {selectedAccount?.lastName} - {selectedAccount?.accountType} <br />
                                    <b>External Account</b>: {savedFields?.custodianName} - {savedFields?.fromAccountNumber} <br />
                                    <b>Amount</b>: {savedFields?.fullOrPartial === 'Partial'
                                        ? `Partial Transfer - ${formatValueAsCurrency(savedFields?.amount || '')}`
                                        : 'Full Transfer'}
                                </p>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </form>
    )
};

export default TransferReview;