import { formatDateToUTCString, convertStringDateToDate } from "./Utils"

export type BeneficiaryType = 'primary' | 'contingent'

export type EditableBeneficiary = {
    firstName: string,
    lastName: string,
    email: string,
    mailingStreet: string,
    mailingCity: string,
    mailingState: string,
    mailingZip: string,
    phone: string,
    dateOfBirth: string | null,
    ssn: string,
    share: string,
    type: BeneficiaryType,
    relatedPartyId: string,
    personAccountId: string,
    deleteBeneficiary: boolean,
    action: EditBeneficiary.Action
    disableFullEdit: boolean,
}

export const getNewBeneficiary = (type: BeneficiaryType): EditableBeneficiary => ({
    firstName: '',
    lastName: '',
    email: '',
    mailingStreet: '',
    mailingCity: '',
    mailingState: '',
    mailingZip: '',
    phone: '',
    dateOfBirth: null,
    ssn: '',
    share: '0',
    type,
    relatedPartyId: '',
    personAccountId: '',
    deleteBeneficiary: false,
    action: 'add',
    disableFullEdit: false
})

const relatedPartyTypeFilter = (relatedParty: RelatedParties, desiredPartyType: string): boolean | undefined => {
    return (relatedParty.PersonAccount && !relatedParty.isCurrentUserRP && (relatedParty.PersonAccount.IsActiveAccountHolderRP !== undefined) && (relatedParty.partyType === desiredPartyType))
}

export const configureBeneficiaries = (relatedParties: RelatedParties[], beneficiaryType: string): EditableBeneficiary[] => {
    const beneficiaryRelatedParties: RelatedParties[] = relatedParties?.filter(relatedParty => relatedPartyTypeFilter(relatedParty, beneficiaryType))
    return (beneficiaryRelatedParties.length > 0) ? beneficiaryRelatedParties.map(relatedParty => transformRelatedParty(relatedParty)) : []
}

export const transformRelatedParty = (relatedParty: RelatedParties): EditableBeneficiary => {
    const parsedDateOfBirth = relatedParty.PersonAccount?.DateOfBirth ? new Date(relatedParty.PersonAccount.DateOfBirth) : new Date()
    const dateStringWithoutTimeZone = formatDateToUTCString(parsedDateOfBirth, 'MM-DD-YYYY')

    return {
        firstName: relatedParty.PersonAccount?.FirstName || '',
        lastName: relatedParty.PersonAccount?.LastName || '',
        email: relatedParty.PersonAccount?.Email || '',
        mailingStreet: relatedParty.PersonAccount?.MailingStreet || '',
        mailingCity: relatedParty.PersonAccount?.MailingCity || '',
        mailingState: relatedParty.PersonAccount?.MailingState || '',
        mailingZip: relatedParty.PersonAccount?.MailingPostalCode || '',
        phone: relatedParty.PersonAccount?.Phone || '',
        dateOfBirth: dateStringWithoutTimeZone,
        ssn: '',
        share: relatedParty.share || '0',
        type: transformStringToType(relatedParty.partyType),
        relatedPartyId: relatedParty.id || '',
        personAccountId: relatedParty.PersonAccount?.Id || '',
        deleteBeneficiary: false,
        action: 'edit',
        disableFullEdit: relatedParty.PersonAccount?.IsActiveAccountHolderRP !== 0
    }
}

export const transformStringToType = (type: string): BeneficiaryType => {
    return type.toLowerCase().includes('contingent') ? 'contingent' : 'primary'
}

export const transformTypeToString = (type: BeneficiaryType): string => {
    return type === 'contingent' ? 'Beneficiary (Contingent)' : 'Beneficiary (Primary)'
}

export const uppercaseFirstCharacter = (word: string): string => {
    return `${word.charAt(0).toUpperCase()}${word.toLowerCase().substring(1)}`
}

export const buildActionableBeneficiaryList = (editableBeneficiaries: EditableBeneficiary[], relatedParties: RelatedParties[]): EditBeneficiary.ActionableBeneficiary[] => {
    const actionAbleBeneficaries: EditBeneficiary.ActionableBeneficiary[] = editableBeneficiaries.map(beneficiary => {
        if (beneficiary.action === 'delete' || beneficiary.action === 'limitedEdit') {
            const reducedBeneficiary: EditBeneficiary.ReducedBeneficiary = {
                relatedPartyId: beneficiary.relatedPartyId,
                share: +beneficiary.share,
                type: transformTypeToString(beneficiary.type),
                action: beneficiary.action
            }
            return reducedBeneficiary
        }
        else {
            const fullBeneficiary: EditBeneficiary.FullBeneficiary = {
                personAccountId: beneficiary.personAccountId,
                firstName: beneficiary.firstName,
                lastName: beneficiary.lastName,
                mailingCity: beneficiary.mailingCity,
                mailingState: beneficiary.mailingState,
                mailingStreet: beneficiary.mailingStreet,
                mailingZip: beneficiary.mailingZip,
                email: beneficiary.email,
                phone: beneficiary.phone,
                dateOfBirth: formatDateToUTCString(convertStringDateToDate(beneficiary.dateOfBirth)!, 'YYYY-MM-DD'),
                ssn: beneficiary.ssn.replaceAll('-', ''),
                relatedPartyId: beneficiary.relatedPartyId,
                share: +beneficiary.share,
                type: transformTypeToString(beneficiary.type),
                action: beneficiary.action,
            }
            return fullBeneficiary
        }
    })

    return actionAbleBeneficaries
}

export const validateBeneficiaryNameLengths = (beneficiaries: EditBeneficiary.ActionableBeneficiary[]): boolean => {
    for (const beneficiary of beneficiaries) {
        if (beneficiary.action === 'add' || beneficiary.action === 'edit') {
            if (beneficiary.firstName.length + beneficiary.lastName.length > 32) {
                return false
            }
        }
    }
    return true
}

