import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IonCol, IonItem, IonRow } from '@ionic/react';
import Spinner from '../Spinner';
import { AddInvestorStep, AddInvestorHistory, SubmitAddInvestorFieldsCallback } from './AddInvestorTypes';
import { GenericWizardButtons } from '../WizardButtons';
import { useHistory } from 'react-router';
import { getNextArrayMember, getPreviousArrayMember } from './AddInvestorHelpers';
import { TokenServices, getProgressRatio } from '../../helpers/wizardHelpers';
import calloutHelper from '../../helpers/calloutHelpers';
import { connect } from 'react-redux';
import AddInvestorStepOne from './AddInvestorSteps/AddInvestorStepOne';
import AddInvestorStepTwo from './AddInvestorSteps/AddInvestorStepTwo';
import AddInvestorStepReview from './AddInvestorSteps/AddInvestorReview';
import { formatDateToUTCString } from '../../helpers/Utils';

const AddInvestorWizard: React.FC<{ scrollToTop: Function, availableAccounts:RetirementAccount[] }> = ({scrollToTop, availableAccounts}) => {
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
    const [ nextStepReady, setNextStepReady ] = useState<boolean>(false);

    const history = useHistory<AddInvestorHistory>();
    const addInvestorTokenFromLocation = history?.location?.state?.wizardToken || null;
    const savedFields = history?.location?.state?.formFields || {};

    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
    const direction = useRef<'next' | 'back'>();
    const stepsArray: Array<AddInvestorStep> = ['step-one', 'step-two', 'review'];
    const currentStep: AddInvestorStep = history?.location?.state?.step || 'step-one';
    const openAvailableAccounts = availableAccounts.filter(account => (account.status === 'Open' || account.status === 'Watch' || account.status === 'Frozen'));
    const availableFundAccounts: FundAccount[] = openAvailableAccounts.map(fundAccount => {
        return {
            RetirementAccountId: fundAccount.id,
            RetirementAccount: {
                ClientFirstName: fundAccount.firstName,
                ClientLastName: fundAccount.lastName,
                Name: fundAccount.accountNumber
            },
            Active: true
        }
    });

    const mergeSavedFieldsAndFormFields = useCallback<(formFields: Partial<AddInvestorWizard.FullForm>) => Partial<AddInvestorWizard.FullForm>>((formFields) => {
        const mergedFields: Partial<AddInvestorWizard.FullForm> = {...savedFields, ...formFields};
        return mergedFields;
    }, [savedFields]);

    const submitStep = (navigationDirection: 'next' | 'back') => () => {
        setNextStepReady(true);
        direction.current = navigationDirection;
        formRef?.current?.dispatchEvent(new Event('submit', {cancelable: true}));
    };

    const submitFields = useCallback<SubmitAddInvestorFieldsCallback>((submitState) => {
        if (submitState.status === 'error' && direction.current === 'next') {
            return;
        };

        let nextStep: AddInvestorStep = direction?.current === 'back' ? getPreviousArrayMember<AddInvestorStep>(currentStep, stepsArray) : getNextArrayMember<AddInvestorStep>(currentStep, stepsArray);
        if (nextStep === currentStep) {
            return;
        };

        const historyAddInvestorToken = currentStep === 'step-one' ? TokenServices.updateTokenFromSession() : addInvestorTokenFromLocation;
        const mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields);

        history.push('/add-investor', {
            step: nextStep,
            formFields: mergedFields,
            wizardToken: historyAddInvestorToken
        });
    }, [history, direction, currentStep, stepsArray, addInvestorTokenFromLocation])

    const clearErrorMessage = () => {
        setTimeout(() => {
            setErrorMessage('');
        }, 3000);
    };

    const submitFailed = () => {
        setShowSpinner(false);
        setErrorMessage('There was an issue while trying to create your investor.');
        clearErrorMessage();
    };

    const submitAddInvestor = async () => {
        scrollToTop();
        setShowSpinner(true);

        const accountIds = availableAccounts.map(account => account.id);
        const investor: AddInvestorWizard.AddInvestorStepOneFields = {
            firstName: savedFields.firstName || '',
            lastName: savedFields.lastName || '',
            email: savedFields.email || '',
            mailingStreet: savedFields.mailingStreet || '',
            mailingCity: savedFields.mailingCity || '',
            mailingState: savedFields.mailingState || '',
            mailingZip: savedFields.mailingZip || ''
        };
        let accountRelationships = savedFields.accountRelationships || [];
        accountRelationships.forEach((relationship, i) => {
            accountRelationships[i].activeDate = formatDateToUTCString(new Date(relationship.activeDate), 'YYYY-MM-DD');
        })

        try {
            const addInvestorResult = await calloutHelper.addInvestor(accountIds, investor, accountRelationships);
            TokenServices.clearTokenFromSession();
            if (addInvestorResult.status === 200) {
                setShowSpinner(false);
                const textArray = savedFields.accountRelationships!.map(relationship => {
                   const fundAccount = availableAccounts.find(account => account.id === relationship.accountId);
                   return `${fundAccount?.accountNumber} - ${fundAccount?.firstName} ${fundAccount?.lastName} (Active Date: ${relationship.activeDate})` 
                });
                history.replace('/confirmation', {
                    transactionType: 'Investor',
                    customTextArray: [
                        `Investor: ${savedFields.firstName} ${savedFields.lastName}`,
                        ...textArray
                    ]
                });
            } else {
                submitFailed();
            };
        } catch (err) {
            submitFailed();
        };
    };

    const displayStep = useCallback((step: AddInvestorStep) => {
        const addInvestorTokenSession = TokenServices.getTokenFromSession();
        if (step !== 'step-one' && addInvestorTokenSession !== addInvestorTokenFromLocation) {
            history.replace('/home');
        };

        const componentProps = {formRef, submitFields, savedFields};

        const renderStepOne = () => {
            const stepOneProgressRatio = getProgressRatio<AddInvestorStep>('step-one', stepsArray);
            return <AddInvestorStepOne {...componentProps} wizardCompletionRatio={stepOneProgressRatio} setErrorMessage={setErrorMessage} errorMessage={errorMessage} />;
        };

        const renderStepTwo = () => {
            const stepTwoProgressRatio = getProgressRatio<AddInvestorStep>('step-two', stepsArray);
            return <AddInvestorStepTwo {...componentProps} wizardCompletionRatio={stepTwoProgressRatio} availableFundAccounts={availableFundAccounts} setErrorMessage={setErrorMessage} errorMessage={errorMessage} nextStepReady={nextStepReady} setNextStepReady={setNextStepReady} />;
        };

        const renderReview = () => {
            const rewivewStepProgressRatio = getProgressRatio<AddInvestorStep>('review', stepsArray);
            return <AddInvestorStepReview {...componentProps} wizardCompletionRatio={rewivewStepProgressRatio} availableFundAccounts={availableFundAccounts} errorMessage={errorMessage} />;
        };

        switch(step) {
            case 'step-two':
                return renderStepTwo();
            case 'review':
                return renderReview();
            default:
                return renderStepOne();
        };
    }, [history, stepsArray, addInvestorTokenFromLocation, submitFields, availableAccounts])

    useEffect(() => {
        scrollToTop();
    }, [currentStep, scrollToTop, errorMessage])

    return (
        <IonRow class='container'>
            <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='12'>
                <IonRow>
                    <IonCol class='p-1 light-gr-bg'>
                        <IonRow>
                            <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                                <IonRow>
                                    <h1>Add Investor</h1>
                                </IonRow>
                                
                                {showSpinner ?
                                    <Spinner /> :
                                    <>
                                        {displayStep(currentStep)}
                                        <IonRow>
                                            <IonCol>
                                                <IonRow>
                                                    <IonCol>
                                                        {(currentStep !== 'step-two' || currentStep === 'step-two') &&
                                                            <GenericWizardButtons steps={stepsArray} activeStep={currentStep} disableSubmit={false} handleStep={submitStep} handleSubmit={submitAddInvestor} />
                                                        }
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(AddInvestorWizard);