import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import '../pages/Style.css';
import { 
  IonItem,
  IonLabel,
} from '@ionic/react';

const NavBarTemplate: React.FC<{pages: string[]}> = ({pages}) => {
  const history = useHistory();
  const location = useLocation();

  return <>
    {(pages.map(page => {
      const path = '/' + page.toLowerCase().replace(/ /g, '-');
      return (
        <IonItem detail={false} lines="none" key={page} onClick={() => history.push(path)} class="float-left cursor-pointer" color={(location.pathname === path) ? 'primary' : 'transparent'}>
          <IonLabel class="nav-item">{page}</IonLabel>
        </IonItem>
      )
    }))}
  </>
};

export default NavBarTemplate;