import React, { Fragment } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
} from '@ionic/react';
import ContainerComponent from './ContainerComponent';
import Sidebar from './Sidebar';

const MakeInvestment: React.FC = () => {
  return (
    <IonRow class="container">
      <IonCol size="12">
        <h1 className="page-title">Make An Investment</h1>
      </IonCol>
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        <ContainerComponent content={
          <Fragment>
            <IonItem>
              Coming Soon
            </IonItem>
          </Fragment>
        }/>
      </IonCol>
      <Sidebar />
    </IonRow>
  );
};

export default MakeInvestment;
