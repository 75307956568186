import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { BillPayStepString, SubmitFieldsCallback, AltPayloads, ReviewFields, InputComponents } from './BillPayTypes'
import BillPayStepOne from './BillPaySteps/BillPayStepOne';
import BillPayStepTwo from './BillPaySteps/BillPayStepTwo';
import BillPayStepThree from './BillPaySteps/BillPayStepThree';
import BillPayStepFour from './BillPaySteps/BillPayStepFour';
import BillPayReview from './BillPaySteps/BillPayReview';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import calloutHelpers from '../../helpers/calloutHelpers';
import { AuthCheckContext } from '../../helpers/authHelpers/authService';
import { GenericWizardButtons } from '../WizardButtons';
import { GenericHistory, TokenServices } from '../../helpers/wizardHelpers'
import axios from 'axios'

export const allBillPaySteps: Array<BillPayStepString> = ['select-account', 'select-payee', 'bill-pay-type', 'delivery-method', 'review'];
export const initialBillPaySteps : Array<BillPayStepString> = ['select-account', 'select-payee','review']
export const NEW_PAYEE_ID = 'Add Payee'

type FullForm = BillPaymentWizard.FullForm

const BillPay: React.FC<{scrollToTop: Function, availableAccounts: RetirementAccount[], selectedAccount: RetirementAccount | undefined }> = ({scrollToTop, availableAccounts, selectedAccount}) => {
  type BillPayHistory = GenericHistory<BillPayStepString, Partial<FullForm>> & (TransactionHistoryType | RecurringTransactionHistoryType) & {PreselectedFields?: Partial<FullForm>}
  let history = useHistory<BillPayHistory>();
  const billPaySteps = useRef<BillPayStepString[]>(initialBillPaySteps)
  const [files, setFiles] = useState<Array<SFFile>>([]);

  const authCheckContext = useContext(AuthCheckContext)
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const [failedAttempts, setFailedAttempts] = useState<number>(0);
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const tokenFromLocation = history?.location?.state?.wizardToken || null
  const step = history?.location?.state?.step || allBillPaySteps[0];
  const preselectedFields = history?.location?.state?.PreselectedFields || {};
  const savedFields = history?.location?.state?.formFields as Partial<FullForm>
  const direction = useRef<'next'|'back'|'submit'>()
  const [ billPayParams, setBillPayParams ] = useState<{
    savedPayees: Partial<SavedPayee>[]
  }>({
    savedPayees: []
  });
  const [ isFundManagerFull, setIsFundManagerFull ] = useState<boolean>(false)

  //Load Date
  useEffect(()=>{
    if (selectedAccount?.partyType === 'Fund Manager - Full') {
      setIsFundManagerFull(true)
    };
    let source = axios.CancelToken.source();
    const getSavedPayees = async (token: any) => {
      setShowSpinner(true);
      try {
        let savedPayeeResults = await calloutHelpers.getSavedPayees(token);
        if (savedPayeeResults?.data) {
          setBillPayParams({
            savedPayees: savedPayeeResults.data
          });
        }
        setShowSpinner(false);
      } catch(err) {
        setShowSpinner(false);
      }
    }
    getSavedPayees(source.token);
    return(() => {
      source.cancel('Cancelling Saved Payees in cleanup');
    })
  }, [])

  useEffect(() => {
    scrollToTop();
  }, [step, scrollToTop]);

  useEffect(() => {
    if (failedAttempts >= 4) {
      authCheckContext.current.logout();
    }
  }, [failedAttempts, authCheckContext?.current?.logout])

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000)
  }

  const createTransaction = useCallback( async( ssnFields: ReviewFields ) => {
    if (!savedFields.retirementAccount) return;
    if (savedFields.amount && (+savedFields.amount >= 1000) && (!ssnFields.ssnSigFirstThree || !ssnFields.ssnSigNextTwo) && !isFundManagerFull) {
      return; 
    }
    scrollToTop();
    if (savedFields.amount && (+savedFields.amount > 5000) && files.length === 0 && !isFundManagerFull) {
      setErrorMessage('Please upload a Supporting Document to verify the amount of this bill payment');
      clearErrorMessage();
      return; 
    }
    
    setShowSpinner(true);
    setErrorMessage('');
    try {
      let ssnSignature; 

      ssnSignature = ssnFields.ssnSigFirstThree + ssnFields.ssnSigNextTwo

      const billPaymentBody : BillPaymentWizard.ClientBillPaymentBody = {
        ...(savedFields as FullForm),
        failedAttempts,
        ssnSignature: ssnSignature,
        files: files || []
      }
      let response = await calloutHelpers.postBillPayment(billPaymentBody);
      
      setShowSpinner(false);
      TokenServices.clearTokenFromSession();
      if(response.data.status === 'ok' && response.data.transactionId ){
        history.push('/confirmation', {
          PendingOrPosted: 'pending',
          TransactionId: response.data.transactionId,
          transactionType: isFundManagerFull ? 'Expense Payment' : 'Bill Payment',
          customTextArray: [
            'Equity Trust reserves the right to verify any and all client and or/payee information before funds are sent out for any transaction.'
          ]
        });
      } else if (response.data.status === 'recurring-ok' && response.data.recurringTransactionId) {
        history.push({
          pathname: '/confirmation',
          state: {
            recurringTransactionId: response.data.recurringTransactionId,
            transactionType: isFundManagerFull ? 'Expense Payment' : 'Bill Payment',
            customTextArray: [
              'Equity Trust reserves the right to verify any and all client and or/payee information before funds are sent out for any transaction.'
            ]
          }
        });
      } else {
        history.push('/transactions')
      }
      
    }
    catch (errorResponse) {
      if(axios.isAxiosError(errorResponse)){ 
        let errorMessage = (typeof errorResponse.response?.data.message === 'string') ? errorResponse.response?.data.message : 'Error creating bill payment.';
        
        if (errorMessage.toLowerCase().includes('error confirming ssn signature')) {
          setFailedAttempts((value)=>{
            return value+1
          });
        }
        
        setErrorMessage(errorMessage);
        clearErrorMessage();
      }

      setShowSpinner(false);
      setHasAgreed(false)
    }
  }, [failedAttempts, files, history, savedFields, scrollToTop])

  const getForwardStep = useCallback((stepsArray:BillPayStepString[]) => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === stepsArray.length - 1){
      return stepsArray[stepPosition];
    }

    let nextStep = stepPosition + 1;
    return stepsArray[nextStep];
  }, [step])

  const getPrevStep = useCallback( (stepsArray:BillPayStepString[]) => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === 0){
      return stepsArray[stepPosition];
    }

    let prevStep = stepPosition - 1;
    return stepsArray[prevStep];
  }, [step])

  const resolveGotoStep = (goTo: BillPayStepString) => {
    if(initialBillPaySteps.includes(goTo)){
      return initialBillPaySteps;
    }
    return allBillPaySteps
  }

  const getNextStep = useCallback( (formFields: Partial<FullForm>, goTo?: BillPayStepString) => {
    let newBillPaySteps: BillPayStepString[] = billPaySteps.current

    if(goTo){
      billPaySteps.current = resolveGotoStep(goTo)
      return goTo
    }

    
    //update steps
    if(step === 'select-payee'){
      if (formFields.payeeId === NEW_PAYEE_ID || formFields.editPayee) {
        newBillPaySteps = [...allBillPaySteps];
      } else {
        newBillPaySteps = [...initialBillPaySteps];
      }
      billPaySteps.current = newBillPaySteps
    }

    if(goTo) return goTo

    let newStep = direction.current === 'next' ? (getForwardStep(newBillPaySteps)) : (getPrevStep(newBillPaySteps));

    return newStep
  }, [billPaySteps, getForwardStep, getPrevStep, step])

  const mergeSavedFieldsAndFormFields = useCallback<(formFields: Partial<FullForm>, altPayload?: AltPayloads) => Partial<FullForm>>((formFields, altPayload) => {
    const mergeSelectAccount = () => {
      if(formFields?.retirementAccount !== savedFields?.retirementAccount){
        setFiles([])
        return formFields
      }else{
        return {...savedFields, ...formFields}  
      }
    }

    const mergeSelectPayee = () => {
      if(altPayload?.type === 'files'){
        setFiles(altPayload.files || [])
      }
      return {...savedFields, ...formFields}
    }

    switch(step){
      case 'select-account':
        return mergeSelectAccount()
      case 'select-payee':
        return mergeSelectPayee()
      case 'review':
        return {...savedFields, editPayee: formFields.editPayee}
      default: 
      return {...savedFields, ...formFields}
    }
  }, [savedFields, setFiles, step])

  const incrementStep = useCallback<SubmitFieldsCallback>((submitState) => {
    let goTo = submitState.status === 'goTo' ? submitState.step : undefined

    if(submitState.status === 'ok' && direction.current ==='submit'){
      if(submitState.altPayload?.type === 'ssnFields' && submitState.altPayload.ssn){
        return createTransaction(submitState.altPayload.ssn)
      }else{
        return //ssn fields missing
      }
    }

    if(submitState.status === 'error' && (direction.current === 'next' || direction.current ==='submit')){
      return;
    }

    const mergeFields = mergeSavedFieldsAndFormFields(submitState.formFields, submitState.altPayload)
    
    let newStep = getNextStep(mergeFields, goTo)
    
    if(step === newStep){
      return;
    }
    let historyToken = step === 'select-account' ? TokenServices.updateTokenFromSession() : tokenFromLocation
    
    history.push('/pay-a-bill',{
        step: newStep,
        formFields: mergeFields,
        wizardToken: historyToken,
        PreselectedFields: preselectedFields
      })

  },[mergeSavedFieldsAndFormFields, history, getNextStep, step, createTransaction, tokenFromLocation, preselectedFields])

  const submitStep = (go: 'next'|'back'|'submit') => () => {
    direction.current = go
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
    }
  }

  const props: InputComponents = {formRef, setErrorMessage, clearErrorMessage, submitFields: incrementStep, savedFields, preselectedFields}
  const displayStep = () => {
    let tokenSession = TokenServices.getTokenFromSession()
    if(step !==  'select-account' && tokenSession !== tokenFromLocation){
      history.replace('/home')
    }
    switch (step) {
      case 'select-account':
        return <BillPayStepOne {...props} availableAccounts={availableAccounts} isFundManagerFull={isFundManagerFull}/>
      case 'select-payee':
        return <BillPayStepTwo {...props} savedFiles={files} savedPayees={billPayParams?.savedPayees} isFundManagerFull={isFundManagerFull}/>
      case 'bill-pay-type':
        return <BillPayStepThree {...props} isFundManagerFull={isFundManagerFull}/>
      case 'delivery-method':
        return <BillPayStepFour {...props} isFundManagerFull={isFundManagerFull}/>
      case 'review':
        return <BillPayReview {...props} selectedAccount={availableAccounts.find((value)=>(value.id === savedFields.retirementAccount))} hasAgreed={hasAgreed} setHasAgreed={setHasAgreed} isFundManagerFull={isFundManagerFull}/>;
      default:
    }
  }

  return (
    <IonRow class="container" data-test="bill-pay">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
        <IonRow>
          <IonCol class="p-1 light-gr-bg">
            <IonRow>
              <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                {(errorMessage !== '') &&
                (<IonItem class="mt-1" color="danger">
                  <p className="white-color">{errorMessage}</p>
                  </IonItem>)}
                {showSpinner ? <Spinner /> : 
                  <>
                    {displayStep()}
                    <GenericWizardButtons steps={billPaySteps.current} activeStep={step} handleStep={submitStep} handleSubmit={submitStep('submit')} disableSubmit={!hasAgreed} />
                  </>
                }
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts,
    selectedAccount: rootState.UserState.selectedAccount
  }
}

export default connect(mapStateToProps)(BillPay);