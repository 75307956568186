import React, { useEffect, useState } from 'react';
import PostedTransactionsFilterForm from './PostedTransactionsFilterForm';
import { Transaction } from './TransactionItem';
import { connect } from 'react-redux';
import calloutHelper, {PostedTransactionFilter} from '../helpers/calloutHelpers';
import TransactionsList from './TransactionsList';
import Spinner from './Spinner';
import { dateOneYearAgo } from '../helpers/Utils';
import { dateRegex } from 'shared-utils';
import { IonButton, IonRow } from '@ionic/react';
import { formatDateToUTCString } from '../helpers/Utils';

const TransactionsListLoader: React.FC<{pendingOrPosted: 'pending' | 'posted', selectedAccount: RetirementAccount | undefined, accounts: RetirementAccount[] | undefined, queryPendingTransactionsBy: 'selectedAccount' | 'allAccounts', setPendingApprovalTransactions?: Function}> = ({pendingOrPosted, selectedAccount, accounts, queryPendingTransactionsBy = 'selectedAccount', setPendingApprovalTransactions}) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [postedTransactions, setPostedTransactions] = useState<Transaction[]>([]);
    const [pendingTransactions, setPendingTransactions] = useState<Transaction[]>([]);
    const [transactionTypeOptions, setTransactionTypeOptions] = useState<string[]>([]);
    const [assetOptions, setAssetOptions] = useState<string[]>([]);
    const [postedTransactionFilters, setPostedTransactionFilters] = useState<PostedTransactionFilter>({});

    useEffect(() => {
        setPostedTransactionFilters({
            fromDate: new Date(dateOneYearAgo()).toLocaleDateString(),
            toDate: new Date().toLocaleDateString()
        });
    }, []);

    useEffect(() => {
        if (pendingOrPosted === 'pending') {
            setPostedTransactionFilters({});
        }
    }, [pendingOrPosted])

    useEffect(() => {
        if (!selectedAccount) return;
        const getAllPostedTransactions = async () => {
            const fromDate = postedTransactionFilters.fromDate;
            const toDate = postedTransactionFilters.toDate;
            if (fromDate && toDate && dateRegex.test(fromDate) && dateRegex.test(toDate)) {
                setShowSpinner(true);
                try {
                    let accountId = selectedAccount.id;
                    let postedTransactionsResult = await calloutHelper.getPostedTransactions(accountId, postedTransactionFilters);
                
                    if (postedTransactionsResult.data && postedTransactionsResult.data.data) {
                        let postedTransactionsList = transformReceivedDataToTransactions(postedTransactionsResult.data.data, transformPostedTransaction);
                        setPostedTransactions([...postedTransactionsList]);
                    }
            
                    let filterFormOptionResults = await calloutHelper.getPostedTransFilterFormOptions(accountId);
            
                    setTransactionTypeOptions([...filterFormOptionResults.data.transactionTypeOptions, 'All']);
                    setAssetOptions([...filterFormOptionResults.data.assetNameOptions, 'All']);
                    setShowSpinner(false);
                } catch(err) {
                    setTransactionTypeOptions([]);
                    setAssetOptions([]);
                    setPostedTransactions([]);
                    setShowSpinner(false);
                }
            }
        }

        if (pendingOrPosted === 'posted') {
            getAllPostedTransactions();
        }
    }, [postedTransactionFilters, pendingOrPosted, selectedAccount])

    useEffect(() => {
       if (!selectedAccount || !accounts || accounts?.length === 0) return;
       const getPendingTransactions = async(accountIds: string[]) => {
            setShowSpinner(true);
            try {
                const pendingTransactionsResult = await calloutHelper.getPendingTransactions(accountIds);

                if (pendingTransactionsResult.data && pendingTransactionsResult.data.data) {
                    let pendingClientApprovalTransactions = pendingTransactionsResult.data.data.filter(pendingTrans => pendingTrans.ClientApprovalPending);
                    let transformedPendingClientApprovalTransactions = transformReceivedDataToTransactions(pendingClientApprovalTransactions, transformPendingTransaction);
                    let pendingTransactionsList = transformReceivedDataToTransactions(pendingTransactionsResult.data.data, transformPendingTransaction);
                    if (setPendingApprovalTransactions) {
                        setPendingApprovalTransactions([...transformedPendingClientApprovalTransactions]);
                    }
                    setPendingTransactions([...pendingTransactionsList]);
                }
                setShowSpinner(false);
            } catch(err) {
                setShowSpinner(false);
                setPendingTransactions([]);
                if (setPendingApprovalTransactions){
                    setPendingApprovalTransactions([]);
                }
            }
        }

       let accountId = selectedAccount.id; 

       if (pendingOrPosted === 'pending') {
           let accountIds : string[] = [accountId];
           
           if (accounts && queryPendingTransactionsBy === 'allAccounts') {
            accountIds = accounts.map(acct => acct.id);
           }
           
           getPendingTransactions(accountIds);
       }
    }, [selectedAccount, pendingOrPosted, accounts, queryPendingTransactionsBy, setPendingApprovalTransactions]);

    const exportCsv = (postedTransactions: Transaction[]) => {
        if (postedTransactions) {
            const csvData = postedTransactions.map(transaction => {
                const removeCommas = (value: string) => {
                    return value.replace(/,/g, '');
                }
                return {
                    'Account Name': removeCommas(`${selectedAccount?.accountNumber} - ${selectedAccount?.firstName} ${selectedAccount?.lastName}` || ''),
                    'Transaction Date': formatDateToUTCString(new Date(transaction.TransactionDate || ''), 'MM/DD/YYYY') || '',
                    'Category': removeCommas(transaction.Category || ''),
                    'Transaction Type': removeCommas(transaction.TransactionType || ''),
                    'Tax Year': removeCommas(transaction.TaxYear || ''),
                    'Asset Name': removeCommas(transaction?.Cusip?.AssetName1 || ''),
                    'Units': transaction.Units || 0,
                    'Net Proceeds': transaction.NetProceeds || 0,
                    'Check': removeCommas(transaction.Check || ''),
                    'Comment 1': removeCommas(transaction.Comment1 || ''),
                    'Comment 2': removeCommas(transaction.Comment2 || ''),
                    'Comment 3': removeCommas(transaction.Comment3 || ''),
                    'Comment 4': removeCommas(transaction.Comment4 || ''),
                    'Comment 5': removeCommas(transaction.Comment5 || ''),
                    'Comment 6': removeCommas(transaction.Comment6 || ''),
                }
            });

            let rows: string[] = [];

            const headers = Object.keys(csvData[0]).join(',');
            rows.push(headers);

            csvData.forEach(data => {
                const values = Object.values(data).join(',');
                rows.push(values);
            })

            const joinedCsvRows = rows.join('\n');
            const blob = new Blob([joinedCsvRows], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'transaction.csv';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        };
    };

    const transformPostedTransaction = (postedTransData : any) : Transaction => {
        let postedTrans : Transaction = {
            ...postedTransData, 
            Amount: postedTransData.MarketValue ? postedTransData.MarketValue : postedTransData.NetProceeds,
            TransactionType: postedTransData.Type,
            PendingOrPosted: 'posted'
        };
        return postedTrans; 
    }

    const transformPendingTransaction = (pendingTransData : any) : Transaction => {
        let pendingTrans: Transaction = {
                ...pendingTransData,
                PendingOrPosted: 'pending', 
                TransactionType: pendingTransData.Type,
                ToFrom: pendingTransData.PayableToFrom,
                Amount: pendingTransData.TotalAmount,
        }
        return pendingTrans;
    }

    const transformReceivedDataToTransactions = (data : any[], transformToPostedOrPending: Function) : Transaction[] => {
        let transactionsList: Transaction[] = [];
        for (let i=0; i < data.length; i++) {
            let transData = data[i];
            let transaction = transformToPostedOrPending(transData);
            transactionsList.push(transaction);
        }

        return transactionsList; 
    }

    const setChangedFilterName = (filterKey: string) => {
        const onFilterFormChange: (filterValue: any) => void = (filterValue: any) => {
          let value: string;
          if (filterValue) { 
              if (filterKey === 'Type' || filterKey === 'AssetName1') {
                value = filterValue.detail.value
              } else {
                value = filterValue.toLocaleDateString()
              }
              setPostedTransactionFilters(prevState => {
                return {
                  ...prevState,
                  [filterKey]: value
                }
              });
          }
        }
        return onFilterFormChange
      }

    const displayTransactionsList = () => { 
        if (showSpinner) {
          return (
           <Spinner />
          )
        }
        let transactions = pendingOrPosted === 'posted' ? postedTransactions : pendingTransactions;
        return (
            <>
                {pendingOrPosted === 'posted' &&
                <PostedTransactionsFilterForm transactionTypeOptions={transactionTypeOptions} assetOptions={assetOptions} onFilterFormChange={setChangedFilterName} postedTransactionFilters={postedTransactionFilters}/>
                }
                <TransactionsList pendingOrPostedTransactions={transactions}/>
                {pendingOrPosted === 'posted' && postedTransactions && postedTransactions.length > 0 &&
                    <IonRow class='d-flex justify-end'>
                        <IonButton size='small' onClick={() => exportCsv(postedTransactions)}>Export CSV</IonButton>
                    </IonRow>
                }
            </>   
        )
    }

    return (
        <div data-test="transaction-list-loader">
            {displayTransactionsList()}
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
      selectedAccount: state.UserState.selectedAccount,
      accounts: state.CachedObjects.accounts
    }
  }

export default connect(mapStateToProps)(TransactionsListLoader);