import React from 'react'
import { IonItem, IonCheckbox, IonLabel } from '@ionic/react'
import { Controller, useFormContext } from 'react-hook-form'

export type SaveBankField = {
    saveBankInfo?: boolean,
}

const SaveBankCheckbox: React.FC = () => {
    
    const { control } = useFormContext<SaveBankField>()

    return (
        <>
            <IonItem lines="none" class="mt-2 mb-1 ion-align-self-end">
                <Controller data-test='save-bank-controller' name='saveBankInfo' control={control} render={({ field: { value, onChange, name } }) => (
                    <IonCheckbox color="primary" slot="start" name={name} tabIndex={0} checked={value} onIonChange={event => onChange(event.detail.checked)} />)}
                />
                <IonLabel class="agree-text">
                    Save banking info for future.
                </IonLabel>
            </IonItem>
        </>
    )
}


export default SaveBankCheckbox