import React from 'react';
import '../pages/Style.css';
import logo from '../images/logo-dark.png';
import { 
  IonHeader,
  IonToolbar,
  IonRow,
  IonCol,
} from '@ionic/react';

const LoginHeader: React.FC = () => {
  return (
      <IonHeader class="header-shadow">
        <IonToolbar mode="md">
          <IonRow class="header-row">
            <IonCol>
              <img src={logo} width="90" alt="Equity Trust logo"/>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
  );
};

export default LoginHeader;
