import React, { useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarThreeStepReviewStep from '../../../images/three-steps-review-step.svg';
import progressBarTwoStepReviewStep from '../../../images/two-step-review-step.svg';
import { isPhone } from '../../../helpers/Utils';
import {ProjectedBuyBillingDetails} from '../../helperComponents/ProjectedBillingDetails';

export type AdditionalBuyReviewStep = Partial<AdditionalBuyWizard.FullForm> & {
  holding: any,
  fromHolding: boolean,
  savedFields?: Partial<AdditionalBuyWizard.FullForm>,
  retirementAccountLabel: string,
  tosState: boolean,
  additionalBuyFundingInstructions?: AdditionalBuyFundingInstructions,
  setTOSState(state: boolean): void
}

const AdditionalBuyReview: React.FC<AdditionalBuyReviewStep> = ({ additionalBuyFundingInstructions, fromHolding, savedFields, retirementAccountLabel, tosState, setTOSState }) => {
  const amountFormatted = useCallback( () => {
    let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
    return currencyFormatter.format(savedFields?.amount || 0)
  }, [savedFields?.amount])

  const displayProgressBar = () => {
    if(fromHolding) {
      return (!isPhone()) ? <img src={progressBarTwoStepReviewStep} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={1.0}></IonProgressBar>
    } else {
      return (!isPhone()) ? <img src={progressBarThreeStepReviewStep} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={1.0}></IonProgressBar>
    }
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {displayProgressBar()}
        </IonCol>
      </IonRow>
      {<>
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol>
                <IonRow class="w-100">
                  <h1>Review details below.</h1>
                </IonRow>
                <IonRow>
                  <IonItem color='tertiary' class="w-100">
                    Purchase Details
                  </IonItem>
                  <IonRow>
                    <IonCol class="p-1">
                      <p className="m-0 p-0">
                        <b>Account</b>: {(fromHolding) ? savedFields?.account : retirementAccountLabel}<br />
                        <b>Related Asset</b>: {savedFields?.assetLabel}<br />
                        {savedFields?.tradingAccountId && <><b>Trading Account</b>: {savedFields?.tradingAccountLabel}<br /></>}
                        <b>Amount</b>: {amountFormatted()}
                      </p>
                    </IonCol>
                  </IonRow>
                  <IonItem color='tertiary' class="w-100">
                    Delivery Details
                  </IonItem>
                  <IonRow>
                    <IonCol class="p-1">
                      {additionalBuyFundingInstructions?.deliveryMethod &&
                        <p key={additionalBuyFundingInstructions.bankName} className="p-0 m-0">
                          <b>Delivery Method</b>: {additionalBuyFundingInstructions.deliveryMethod}<br/>
                          <b>Name on Bank Account</b>: {additionalBuyFundingInstructions.creditName}<br/>
                          <b>Bank Name</b>: {additionalBuyFundingInstructions.bankName}<br/>
                          <b>RoutingNumber</b>: {additionalBuyFundingInstructions.aba}<br/>
                          <b>Bank Account Number</b>: {additionalBuyFundingInstructions.creditAccount}<br/>
                          {additionalBuyFundingInstructions.bankStreet && 
                            <><b>Bank Address</b>: {additionalBuyFundingInstructions.bankStreet}<br/></>
                          }
                          {additionalBuyFundingInstructions.bankCity && 
                            <><b>Bank City</b>: {additionalBuyFundingInstructions.bankCity}<br/></>
                          }
                          {additionalBuyFundingInstructions.bankState && 
                            <><b>Bank State</b>: {additionalBuyFundingInstructions.bankState}<br/></>
                          }
                          {additionalBuyFundingInstructions.bankZip && 
                            <><b>Bank Zip</b>: {additionalBuyFundingInstructions.bankZip}<br/></>
                          }
                        </p>
                      }
                      <p className='p-0 m-0'>
                        {savedFields?.deliveryMethod && <><b>Delivery Method</b>: {savedFields?.deliveryMethod}<br/></>}
                        {savedFields?.payableTo && <><b>Payable To</b>: {savedFields?.payableTo}<br/></>}
                        {savedFields?.creditAccountName && <><b>Name on Bank Account</b>: {savedFields?.creditAccountName}<br/></>}
                        {savedFields?.bankName && <><b>Bank Name</b>: {savedFields?.bankName}<br/></>}
                        {savedFields?.routingNumber && <><b>Routing Number</b>: {savedFields?.routingNumber}<br/></>}
                        {savedFields?.creditAccountNumber && <><b>Bank Account Number</b>: {savedFields?.creditAccountNumber}<br/></>}
                        {savedFields?.creditAccountType && <><b>Bank Account Type</b>: {savedFields?.creditAccountType}</>}
                        {savedFields?.mailingStreet && savedFields?.mailingCity && savedFields?.mailingState && savedFields?.mailingZip && <><b>Mailing Address</b>: {`${savedFields?.mailingStreet} ${savedFields?.mailingCity}, ${savedFields?.mailingState} ${savedFields?.mailingZip}`}<br/></>}
                      </p>
                    </IonCol>
                  </IonRow>
                </IonRow>
                <ProjectedBuyBillingDetails objectId={savedFields?.holdingId} dependencies={savedFields?.holdingId} sectionLabel='Billing Details'/>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </>}
    </>
  );
};

export default AdditionalBuyReview;
