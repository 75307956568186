import React from 'react';
import {
  IonItem
} from '@ionic/react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { convertStringDateToDate } from '../helpers/Utils';
import { formatDateToString } from 'shared-utils'
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useFormContext } from 'react-hook-form';

const getNextBusinessDay = ()=>{
  let workingDate = new Date(); //constructs today's date
  workingDate.setDate(workingDate.getDate() + 1); //jump to tomorrow's date

  if(workingDate.getDay() === 6){ //if Saturday, jump to monday
    workingDate.setDate(workingDate.getDate() + 2)
  }

  if(workingDate.getDay() === 0){
    workingDate.setDate(workingDate.getDate() + 1) //if Sunday, jump to monday
  }

  return workingDate; //else return tomorrows date
}

export const defaultValue = formatDateToString(getNextBusinessDay());

const ForwardDatePicker: React.FC<{fieldName?: string}> = ({fieldName='processDate'}) => {


  const { setValue, control, formState: { errors } } = useFormContext<{[key: string]: string}>();
  return (
    <>
    <IonItem className="ion-text-left gr-border date-width white-bg" lines="none">
          <Controller data-test={`${fieldName}-controller`} name={fieldName} control={control} render={({ field: { value, name } }) =>
              {
                return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 <KeyboardDatePicker error={false} helperText={undefined}
                  className="date-width" name={name} disablePast={true} margin='normal' placeholder="mm/dd/yyyy" value={convertStringDateToDate(value)} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={(event)=>{
                    if(event){
                      setValue(name, formatDateToString(event), {shouldValidate: true});
                    }
                  }}/>
                  </MuiPickersUtilsProvider>
              }} rules={{
                    required: true,
                    validate: (value) => {
                      const inputDate = convertStringDateToDate(value), currentDate = new Date();
                      currentDate.setHours(0,0,0,0)
                    let validation =  (inputDate !== null && (
                        // Check input is not a weekend
                        inputDate?.getUTCDay() !== 0 && inputDate?.getUTCDay() !== 6) 
                        // check input is greater than or equal to today
                        && (inputDate >= currentDate)
                      )
                      return validation ? undefined : 'Please select the date which you would like the payment to process. Must be a week day.'
                  },
                  pattern: {
                    value: /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/,
                    message: 'Invalid date format.'
                  }
              } } />
          </IonItem>
        {errors[fieldName] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors[fieldName]?.message}</p></IonItem>}
        </>
  );
};

/**
 * Legacy Date validation
 * Keeping incase we want to switch back. 
 */
      // Check input unix epoch time is not less than current
      /*(inputDate.getTime() >= currentDate.getTime() || 
          // Check input month is not less than current
          (inputDate.getUTCMonth() >= currentDate.getUTCMonth() && 
              // Check the input day of the month is not less than current
              (inputDate.getUTCDate()) >= currentDate.getUTCDate() && 
                  // Check input year is not less than current
                  inputDate.getUTCFullYear() >= currentDate.getUTCFullYear()))*/
 


export default ForwardDatePicker;
