import { IonRow, IonLabel, IonCol } from '@ionic/react'
import React from 'react'
import { formatValueAsCurrency } from '../../../helpers/Utils'

const AccountInformationLabels: React.FC<{accountLabel?: string, availableBalance?: number}> = ({accountLabel, availableBalance}) => {
    return (
        <>
            {(accountLabel || availableBalance) && (
                <IonRow className="mt-2 mb-1">
                    {accountLabel && (
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6" className="pl-0">
                            <IonLabel><b>Account</b>: {accountLabel}</IonLabel>
                        </IonCol>
                    )}

                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6" className="pl-0">
                        <IonLabel><b>Available Cash</b>: {formatValueAsCurrency(((availableBalance || 0) >= 0) ? (availableBalance || 0) : 0)}</IonLabel>
                    </IonCol>
                    
                </IonRow>
            )}
        </>
    )
}


export default AccountInformationLabels