import { GenericInputComponents, GenericSubmitFieldsCallback } from '../../helpers/wizardHelpers'

export type AltPayloads = {type: 'files', files?: SFFile[]} | {type: 'ssnFields', ssn?: ReviewFields}

export type SubmitFieldsCallback = GenericSubmitFieldsCallback<BillPaymentWizard.FullForm, AltPayloads, BillPayStepString>

export type InputComponents  = GenericInputComponents<BillPaymentWizard.FullForm, SubmitFieldsCallback> & {
    setErrorMessage: Function,
    clearErrorMessage: Function,
    preselectedFields: Partial<BillPaymentWizard.FullForm>
}

export type ReviewFields = {
    ssnSigFirstThree: string
    ssnSigNextTwo: string
}

export type BillPayStepString = 'select-account' | 'select-payee'| 'bill-pay-type' | 'delivery-method'| 'review';

export const deliveryMethods = [
    { description: 'Direct Deposit ($5 Fee)', value: 'ACH' },
    { description: 'Domestic Wire ($30 Fee)', value: 'Domestic Wire' },
    { description: 'Check via Regular Mail ($5 Fee)', value: 'Check (via Regular Mail)'},
    { description: 'Check via FedEx Overnight ($30 Fee)', value: 'Check (via Fedex Overnight)'},
    { description: 'Cashier Check ($30 Fee)', value: 'Official Check'},
    { description: 'Check Hold for Pickup - Ft Myers ($5 Fee)', value: 'Check (Hold for Pickup - Ft Myers)'}
]