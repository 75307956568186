import React, { useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepFour from '../../images/contribution-wizard-step-review.svg';
import { isPhone } from '../../helpers/Utils';
import TermsOfServiceComponent, {standardTOS} from '../TermsOfServiceComponent';

const TradingAccountWithdrawalStepReview: React.FC<{savedFields?:Partial<TradingAccountWithdrawalWizard.FullForm>, retirementAccountLabel: string, tosState: boolean, setTOSState(state:boolean):void}> = ({ savedFields, retirementAccountLabel, tosState, setTOSState}) => {
  const amountFormatted = useCallback( () => {
    let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
    return currencyFormatter.format(savedFields?.amount || 0)
  }, [savedFields?.amount])
  
  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepFour} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={1.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      {
      (<>
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol>
                <IonRow class="w-100">
                  <h1>Review details below.</h1>
                </IonRow>
                <IonRow class="divider">
                  <IonItem lines="none">
                    <p>
                      <b>Account</b>: {retirementAccountLabel}<br />
                      <b>Related Asset</b>: {savedFields?.assetLabel}<br />
                      <b>Full/Partial Withdrawal</b>:  {savedFields?.fullOrPartial}<br />
                        {(savedFields?.fullOrPartial?.includes('Partial')) && <>
                          <b>Trading Account</b>: {savedFields?.tradingAccountLabel}<br />
                          <b>Withdrawal Amount</b>:  {amountFormatted()}<br />
                        </>}
                      <b>Request Distribution</b>: {savedFields?.distributionRequest}
                    </p>
                  </IonItem>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <TermsOfServiceComponent modalBodyText={ standardTOS() } checked={ tosState } checkboxEvent={(event)=>{ setTOSState( event.detail.checked) }} modalButtonText="Close" ionFloat="ion-float-right" />
      </>)}
    </>
  );
};

export default TradingAccountWithdrawalStepReview;