import React, {useState} from 'react';
import {
    IonRow,
    IonList,
    IonButton,
    IonCol,
} from '@ionic/react';
import TransactionItem, { Transaction } from './TransactionItem';
import { isMobile } from '../helpers/Utils';

// displays Pending or Posted Transactions
const TransactionsList: React.FC<{ pendingOrPostedTransactions: Transaction[] }> = ({ pendingOrPostedTransactions }) => {
const [showIndex, setShowIndex] = useState(5);
    const loadData = (e: any) => {
        let newIndex = showIndex + 5
        if (newIndex >= pendingOrPostedTransactions.length) {
            newIndex = pendingOrPostedTransactions.length
            e.target.style.display = 'none';
        }
        setShowIndex(newIndex)
    }

    const renderList = () => {
        return <IonList data-test="transactions-list" class="w-100">
            {
                pendingOrPostedTransactions.map((trans: Transaction) => (
                    <TransactionItem key={trans.Id} transaction={trans} />
                )).slice(0,showIndex)
            }             
        </IonList>
    }
    
  return (
    (pendingOrPostedTransactions.length < 1) ?
        <p data-test="no-transactions" className="pl-1 pr-1">We could not find any transactions for your account.</p> 
        : 
        <>
            {!isMobile() && <IonRow class="pl-1 pr-1">
                {pendingOrPostedTransactions[0].PendingOrPosted === 'posted' &&
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="1" sizeLg="1" sizeXl="1">
                        <p className="p-0 m-0"><b>Date</b></p>
                    </IonCol>
                }
                <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <p className="p-0 m-0"><b>Type</b></p>
                </IonCol>
                  {pendingOrPostedTransactions[0].PendingOrPosted === 'pending' ? <>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                        <p className="p-0 m-0"><b>To/From</b></p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                        <p className="p-0 m-0"><b>Asset Name</b></p>
                    </IonCol>
                  </> : <>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                        <p className="p-0 m-0"><b>Asset Name</b></p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                        <p className="p-0 m-0"><b>Comments</b></p>
                    </IonCol>
                  </>}
                <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <p className="p-0 m-0"><b>Amount</b></p>
                </IonCol>
            </IonRow>}
            <IonRow data-test="transaction-list-wrapper">
                {renderList()}
                {(pendingOrPostedTransactions.length > 5) && (
                    <IonButton onClick={loadData} class="m-auto">Load More...</IonButton>
                )}
            </IonRow >
        </>
  );
};


export default TransactionsList;
