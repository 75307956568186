import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {  IonRow, IonCol, IonItem, IonToolbar, IonButton, IonIcon } from '@ionic/react';
import { addCircleOutline, removeCircleOutline, arrowBack } from 'ionicons/icons';
import calloutHelper from '../helpers/calloutHelpers';
import Spinner from './Spinner';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { dateRegex } from 'shared-utils';
import { formatDateToUTCString } from '../helpers/Utils';

const addOneYear = (date) => {
    date.setFullYear(date.getFullYear() + 1);
    return date;
};

const minusOneYear = (date) => {
    date.setFullYear(date.getFullYear() - 1);
    return date;
}

const EditInvestorParticipation: React.FC<{availableAccounts: RetirementAccount[]}> = ({availableAccounts}) => {
    const history = useHistory<{personAccountId: string, fundAccountsToEdit: FundAccount[], investorName: string, toast?: string}>();
    const stateDetail = history.location.state;
    const personAccountId = stateDetail.personAccountId;
    const fundAccountsToEdit = stateDetail.fundAccountsToEdit;
    const investorName = stateDetail.investorName;
    const openAvailableAccounts = availableAccounts.filter(account => (account.status === 'Open' || account.status === 'Watch' || account.status === 'Frozen'));
    const availableFundAccounts: FundAccount[] = openAvailableAccounts.map(fundAccount => {
        return {
            RetirementAccountId: fundAccount.id,
            RetirementAccount: {
                ClientFirstName: fundAccount.firstName,
                ClientLastName: fundAccount.lastName,
                Name: fundAccount.accountNumber
            }
        }
    });
    
    // The constant variable activeFundAccounts is declared to work around bug -
    // fundAccountsToEdit state from InvestorRoster would change when referenced
    // in EditInvestorParticipationModal.
    const activeFundAccounts = fundAccountsToEdit.filter(fundAccount => fundAccount.Active);
    const [ submittingSpinner, setSubmittingSpinner ] = useState<boolean>(false);
    const [ submitError, setSubmitError ] = useState<string>('');
    const [ participatingFundAccounts, setParticipatingFundAccounts ] = useState<FundAccount[]>([]);
    const [ nonParticipatingFundAccounts, setNonParticipatingFundAccounts ] = useState<FundAccount[]>([]);
    const [ addedFundAccounts, setAddedFundAccounts ] = useState<{retirementAccountId: string, name: string, firstName: string, lastName: string, activeDate: string}[]>([]);
    const [ removedFundAccounts, setRemovedFundAccounts ] = useState<{retirementAccountId: string, relatedPartyId: string, name: string, firstName: string, lastName: string, inactiveDate: string}[]>([]);

    const initialNonParticipating = availableFundAccounts.filter(fundAccount => !activeFundAccounts.some(fAccount => fAccount.RetirementAccountId === fundAccount.RetirementAccountId));

    useEffect(() => {
        setParticipatingFundAccounts(activeFundAccounts);
        setNonParticipatingFundAccounts(initialNonParticipating);
    }, []);

    const handleFundAccount = (fundAccount: FundAccount, addOrRemove: 'add' | 'remove') => {
        let fundAccountsWithRemoved = addOrRemove === 'add' ? nonParticipatingFundAccounts : participatingFundAccounts;
        const fundAccountIndex = fundAccountsWithRemoved.findIndex(fAccount => fAccount.RetirementAccountId === fundAccount.RetirementAccountId);
        fundAccountsWithRemoved.splice(fundAccountIndex, 1);
        if (addOrRemove === 'add') {
            setNonParticipatingFundAccounts(fundAccountsWithRemoved);
            setAddedFundAccounts(prevState => {
                return [ ...prevState, {retirementAccountId: fundAccount.RetirementAccountId, name: fundAccount.RetirementAccount.Name, firstName: fundAccount.RetirementAccount.ClientFirstName, lastName: fundAccount.RetirementAccount.ClientLastName, activeDate: new Date().toLocaleDateString()} ]
            });
        };

        if (addOrRemove === 'remove') {
            setParticipatingFundAccounts(fundAccountsWithRemoved);
            setRemovedFundAccounts(prevState => {
                return [ ...prevState, {retirementAccountId: fundAccount.RetirementAccountId, relatedPartyId: fundAccount.Id!, name: fundAccount.RetirementAccount.Name, firstName: fundAccount.RetirementAccount.ClientFirstName, lastName: fundAccount.RetirementAccount.ClientLastName, inactiveDate: new Date().toLocaleDateString()} ]
            });
        };
    };

    const cancelEditParticipation = (retirementAccountId: string, addOrRemove: 'add' | 'remove') => {
        if (addOrRemove === 'add') {
            const addedFundAccountIndex = addedFundAccounts.findIndex(fundAccount => fundAccount.retirementAccountId === retirementAccountId);
            const returningIndex = initialNonParticipating.findIndex(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
            const returningNonParticipatingFundAccount = initialNonParticipating.find(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
            setAddedFundAccounts(prevState => {
                prevState.splice(addedFundAccountIndex, 1);
                return [...prevState]
            });
            setNonParticipatingFundAccounts(prevState => {
                prevState.splice(returningIndex, 0, returningNonParticipatingFundAccount!);
                return [...prevState]
            });
        };

        if (addOrRemove === 'remove') {
            const addedFundAccountIndex = removedFundAccounts.findIndex(fundAccount => fundAccount.retirementAccountId === retirementAccountId);
            const returningIndex = activeFundAccounts.findIndex(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
            const returningParticipatingFundAccount = activeFundAccounts.find(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
            setRemovedFundAccounts(prevState => {
                prevState.splice(addedFundAccountIndex, 1);
                return [...prevState]
            });
            setParticipatingFundAccounts(prevState => {
                prevState.splice(returningIndex, 0, returningParticipatingFundAccount!);
                return [...prevState]
            });
        };
    };

    const handleDateChange = (addOrRemove: 'add' | 'remove', id: string, date: any) => {
        let stringDate = '';
        if (date) {
            stringDate = date.toLocaleDateString()
        };

        const handleActiveDate = (date: string) => {
            const fundAccountIndex = addedFundAccounts.findIndex(fundAccount => fundAccount.retirementAccountId === id);
                setAddedFundAccounts(prevState => {
                    prevState[fundAccountIndex].activeDate = date;
                    return [...prevState]
                });
        };

        const handleInactiveDate = (date: string) => {
            const fundAccountIndex = removedFundAccounts.findIndex(fundAccount => fundAccount.relatedPartyId === id);
                setRemovedFundAccounts(prevState => {
                    prevState[fundAccountIndex].inactiveDate = date;
                    return [...prevState]
                });
        };

        if (dateRegex.test(stringDate)) {
            if (addOrRemove === 'add') {
                handleActiveDate(stringDate);
            };
    
            if (addOrRemove === 'remove') {
                handleInactiveDate(stringDate);
            };
        } else {
            if (addOrRemove === 'add') {
                handleActiveDate(stringDate);
            };

            if (addOrRemove === 'remove') {
                handleInactiveDate(stringDate);
            };
        }
    };

    const handleSubmit = async () => {
        if (removedFundAccounts.length < 1 && addedFundAccounts.length < 1) {
            setSubmitError('Please select an account to add or remove.');
        } else if (removedFundAccounts.some(fundAccount => !fundAccount.inactiveDate) && addedFundAccounts.some(fundAccount => !fundAccount.activeDate)) {
            setSubmitError('Please enter active and inactive dates.')
        } else if (removedFundAccounts.some(fundAccount => !fundAccount.inactiveDate)) {
            setSubmitError('Please enter inactive date(s).');
        } else if (addedFundAccounts.some(fundAccount => !fundAccount.activeDate)) {
            setSubmitError('Please enter active date(s).');
        } else {
            setSubmitError('');
            setSubmittingSpinner(true);
            const accountIds = availableAccounts.map(account => account.id);
            const addedRelationships = addedFundAccounts.map(fundAccount => {
                return {
                    accountId: fundAccount.retirementAccountId,
                    activeDate: formatDateToUTCString(new Date(fundAccount.activeDate), 'YYYY-MM-DD')
                }
            });
    
            const removedRelationships = removedFundAccounts.map(fundAccount => {
                return {
                    relatedPartyId: fundAccount.relatedPartyId,
                    inactiveDate: formatDateToUTCString(new Date(fundAccount.inactiveDate), 'YYYY-MM-DD')
                }
            });
    
            const accountRelationships = [ ...addedRelationships, ...removedRelationships ];
            
            try {
                const updateInvestorParticipationResult = await calloutHelper.updateInvestorParticipation(accountIds, personAccountId, accountRelationships);
                if (updateInvestorParticipationResult.status === 200) {
                    history.push('investor-roster', {
                        personAccountId: '',
                        fundAccountsToEdit: [],
                        investorName: '',
                        toast: 'success'
                    });
                };
            } catch (err) {
                setSubmitError('Participation update error.')
                setSubmittingSpinner(false);
            };
        };
    };

    const CancelButton: React.FC = () => {
        return <IonButton
            class='edit-investor-button' 
            slot='end'
            color='primary'
            onClick={() => {
                history.push('/investor-roster')
            }}>Cancel</IonButton>
    }

    const SubmitButton: React.FC = () => {
        return <IonButton
            data-testid='submit-button'
            class='mr-2 edit-investor-button'
            slot='end'
            color='primary'
            onClick={() => {handleSubmit()}}
        >Save</IonButton>
    }

    return (
        <IonRow class='container'>
            <IonCol class='p-1' size='12'>
                <IonRow>
                    <IonCol class='p-1 light-gr-bg'>
                        <IonRow>
                            <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                                <IonRow>
                                    <h1>{`Edit Participation - ${investorName}`}</h1>
                                </IonRow>
                                {submitError && !submittingSpinner &&
                                    <IonRow>
                                        <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{submitError}</p></IonItem>
                                    </IonRow>
                                }
                                {submittingSpinner ?
                                    <Spinner /> :
                                    <IonCol class='w-100 edit-participation-content'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <IonRow class='d-flex justify-space-around'>
                                                <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                                                    <IonRow class='mt-1 mb-1 d-flex justify-center'>Current Accounts</IonRow>
                                                    <IonRow>
                                                        <IonCol data-testid='participating-accounts-select' class='gr-border fund-account-list'>
                                                            {participatingFundAccounts.map(fundAccount => {
                                                                return (
                                                                    <IonItem data-testid={`remove-account-${fundAccount.Id}`} class='d-flex' button detail={false} onClick={() => handleFundAccount(fundAccount, 'remove')}>
                                                                        <IonCol>
                                                                            {`${fundAccount.RetirementAccount?.Name} - ${fundAccount.RetirementAccount?.ClientFirstName} ${fundAccount.RetirementAccount?.ClientLastName}`}
                                                                        </IonCol>
                                                                        <IonIcon class='d-flex align-self-end mb-0-5' size='large' icon={removeCircleOutline}/>
                                                                    </IonItem>
                                                                )
                                                            })}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol data-testid='remove-list' sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                                                    <IonRow class='mt-2 mb-1 d-flex justify-center'>
                                                        Remove
                                                    </IonRow>
                                                    {removedFundAccounts.map(fundAccount => {
                                                        return (
                                                            <IonRow class='d-flex align-items-center' key={fundAccount.retirementAccountId}>
                                                                <IonCol size='2'>
                                                                    <IonItem data-testid={`cancel-remove-${fundAccount.retirementAccountId}`} lines='none' button onClick={() => cancelEditParticipation(fundAccount.retirementAccountId, 'remove')} detail={false}>
                                                                        <IonIcon size='small' icon={arrowBack}/>
                                                                    </IonItem>
                                                                </IonCol>
                                                                <IonCol size='5'>
                                                                    {`${fundAccount.name} - ${fundAccount.firstName} ${fundAccount.lastName}`}
                                                                </IonCol>
                                                                <IonCol size='5'>
                                                                    <IonRow>Inactive Date</IonRow>
                                                                    <KeyboardDatePicker data-testid={`inactive-date-picker-${fundAccount.retirementAccountId}`} className='gr-border' format='MM/dd/yyyy' maxDate={addOneYear(new Date())} minDate={minusOneYear(new Date())} InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} value={fundAccount.inactiveDate} onChange={(event: any) => handleDateChange('remove', fundAccount.relatedPartyId, event)}/>
                                                                </IonCol>
                                                            </IonRow>
                                                        )
                                                    })}
                                                </IonCol>
                                                <AddFundParticipation fundAccounts={nonParticipatingFundAccounts} addedFundAccounts={addedFundAccounts} handleFundAccount={handleFundAccount} cancelEditParticipation={cancelEditParticipation} handleDateChange={handleDateChange} />
                                            </IonRow>
                                        </MuiPickersUtilsProvider>
                                    </IonCol>
                                }
                                
                                {!submittingSpinner &&
                                    <IonToolbar>
                                        <CancelButton />
                                        <SubmitButton />
                                    </IonToolbar>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>

        </IonRow>
    )
}

export const AddFundParticipation: React.FC<{
    fundAccounts: FundAccount[],
    addedFundAccounts: {retirementAccountId: string, name: string, firstName: string, lastName: string, activeDate: string}[],
    handleFundAccount: Function,
    cancelEditParticipation: Function
    handleDateChange: Function
}> = ({fundAccounts, addedFundAccounts, handleFundAccount, cancelEditParticipation, handleDateChange}) => {
    return (
        <>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                <IonRow class='mt-1 mb-1 d-flex justify-center'>Available Accounts</IonRow>
                <IonRow>
                    <IonCol data-testid='available-accounts-select' class='gr-border fund-account-list'>
                        {fundAccounts.map(fundAccount => {
                            return (
                                <IonItem data-testid={`add-account-${fundAccount.RetirementAccountId}`} button detail={false} onClick={() => handleFundAccount(fundAccount, 'add')}>
                                    <IonCol>
                                        {`${fundAccount.RetirementAccount?.Name} - ${fundAccount.RetirementAccount?.ClientFirstName} ${fundAccount.RetirementAccount?.ClientLastName}`}
                                    </IonCol>
                                    <IonIcon class='d-flex align-self-end mb-0-5' size='large' icon={addCircleOutline}/>
                                </IonItem>
                            )
                        })}
                    </IonCol>
                </IonRow>
            </IonCol>
            <IonCol data-testid='add-list' sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                <IonRow class='mt-2 mb-1 d-flex justify-center'>
                    Add
                </IonRow>
                {addedFundAccounts.map(fundAccount => {
                    return (
                        <IonRow class='d-flex align-items-center' key={fundAccount.retirementAccountId}>
                            <IonCol size='2'>
                                <IonItem data-testid={`cancel-add-${fundAccount.retirementAccountId}`} lines='none' button onClick={() => cancelEditParticipation(fundAccount.retirementAccountId, 'add')} detail={false}>
                                    <IonIcon size='small' icon={arrowBack}/>
                                </IonItem>
                            </IonCol>
                            <IonCol size='5'>
                                {`${fundAccount.name} - ${fundAccount.firstName} ${fundAccount.lastName}`}
                            </IonCol>
                            <IonCol size='5'>
                                <IonRow>Active Date</IonRow>
                                <KeyboardDatePicker data-testid={`active-date-picker-${fundAccount.retirementAccountId}`} className='gr-border' format='MM/dd/yyyy' maxDate={addOneYear(new Date())} minDate={minusOneYear(new Date())} InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} value={fundAccount.activeDate} onChange={(event: any) => handleDateChange('add', fundAccount.retirementAccountId, event)} onAccept={(event: any) => handleDateChange('add', fundAccount.retirementAccountId, event)} />
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonCol>
        </>
    )
};

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(EditInvestorParticipation)