import axios from 'axios';

export type BillingItem = {
    id: number,
    amount: string,
    description: string,
    date: string,
    transactionType: string
}

export interface BillingFilter {
    fromDate?: string,
    toDate?: string
}

const addChargesToBillingItems = (billingItems: Array<BillingItem>, charges: Array<any>) => {
    for (let i = 0; i < charges.length; i++) {
        let billingItem: BillingItem = {
            id: charges[i].Id,
            amount: '$' + (charges[i].Amount).toFixed(2),
            description: charges[i].Description,
            date: charges[i].ChargeDate,
            transactionType: charges[i].TransactionType
        }
        billingItems.push(billingItem)
    }
}

const addPaymentsToBillingItems = (billingItems: Array<BillingItem>, payments: Array<any>) => {
    for (let i = 0; i < payments.length; i++) {
        let billingItem: BillingItem = {
            id: payments[i].Id,
            amount: '$' + (payments[i].Amount).toFixed(2),
            description: payments[i].Description,
            date: payments[i].PaymentDate,
            transactionType: payments[i].TransactionType
        }

        billingItems.push(billingItem)
    }
}

const sortBillingItemsByDate = (billingItems) => {
    billingItems.sort((a: any, b: any) => {
        a = a.date || '';
        b = b.date || '';
        return b.localeCompare(a);
    })
}

const billingItemCalloutHelper = {
    getBillingItems: async (accountId: string | undefined, filters?: BillingFilter) => {
        let billingItems: Array<BillingItem> = [];
        let chargeResponse = await axios.get('/api/charge/charges', { params: { accountId, filters } });
        let paymentResponse = await axios.get('/api/payment/payments', { params: { accountId, filters } });

        let charges = chargeResponse.data;
        let payments = paymentResponse.data;

        addChargesToBillingItems(billingItems, charges);
        addPaymentsToBillingItems(billingItems, payments);

        sortBillingItemsByDate(billingItems);
        
        return { data: billingItems };
    }
}

export default billingItemCalloutHelper; 