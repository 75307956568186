import React, { useEffect, useState } from 'react';
import { useForm, Controller, Control, RegisterOptions, SubmitHandler, FormProvider, FormState } from 'react-hook-form';
import { IonModal, IonRow, IonCol, IonLabel, IonInput, IonItem, IonButton, IonTitle, IonToolbar } from '@ionic/react';
import calloutHelper from '../helpers/calloutHelpers';
import { StateSelectorWithErrorMessage } from './StateSelector';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import { EMAIL_VALIDATION_PATTERN } from '../helpers/Utils';

const EditInvestorInformationModal: React.FC<{
    availableAccounts: RetirementAccount[],
    setShowEditModal: Function,
    showEditModal: boolean,
    fundParticipants: FundParticipant[],
    setFundParticipants: Function,
    filteredFundParticipants: FundParticipant[],
    setFilteredFundParticipants: Function,
    investorInformation: {
        id?: string,
        personAccountId?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        mailingStreet?: string,
        mailingCity?: string,
        mailingState?: string,
        mailingPostalCode?: string
    },
    setToast: Function
}> = ({availableAccounts, setShowEditModal, showEditModal, fundParticipants, setFundParticipants, filteredFundParticipants, setFilteredFundParticipants, investorInformation, setToast}) => {
    const { personAccountId, firstName, lastName, email, mailingStreet, mailingState, mailingCity, mailingPostalCode} = investorInformation;
    const [ submittingSpinner, setSubmittingSpinner ] = useState<boolean>(false);
    const [ submitError, setSubmitError ] = useState<'Please complete address.' | 'Please input at least one change to update.' | ''>('');

    const getDefaultValues = (): InvestorFields => {
        return {
            email: email || '',
            mailingStreet: mailingStreet || '',
            mailingCity: mailingCity || '',
            mailingState: mailingState || '',
            mailingPostalCode: mailingPostalCode || ''
        }
    };

    const methods = useForm<InvestorFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    const {control, reset, formState, handleSubmit} = methods;

    useEffect(() => {
        reset(getDefaultValues());
    }, [showEditModal]);

    const onSubmit: SubmitHandler<InvestorFields> = async (data) => {
        setSubmittingSpinner(true);
        const investor = {
            email: data?.email,
            mailingStreet: data?.mailingStreet,
            mailingCity: data?.mailingCity,
            mailingState: data?.mailingState,
            mailingZip: data?.mailingPostalCode
        }
        
        try {
            if (JSON.stringify(data) != JSON.stringify(getDefaultValues())) {
                if ((investor.mailingStreet && investor.mailingCity && investor.mailingState && investor.mailingZip) || (!investor.mailingStreet && !investor.mailingCity && !investor.mailingState && !investor.mailingZip)) {
                    const accountIds = availableAccounts.map(account => account.id)
                    const updateInvestorResult = await calloutHelper.updateInvestor(accountIds, personAccountId || '', investor);
                    if (updateInvestorResult.status === 200) {
                        const investorData = updateInvestorResult.data;
                        const updateFundParticipants = (fundParticipants: FundParticipant[], type: 'filtered' | 'not filtered') => {
                            let updatedFundParticipants = fundParticipants;
                            const fundParticipantIndex = fundParticipants.findIndex(fundParticipant => fundParticipant.personAccountId === personAccountId);
                            updatedFundParticipants[fundParticipantIndex] = {
                                ...updatedFundParticipants[fundParticipantIndex],
                                email: investorData.PersonEmail,
                                mailingStreet: investorData.PersonMailingStreet,
                                mailingCity: investorData.PersonMailingCity,
                                mailingState: investorData.PersonMailingState,
                                mailingPostalCode: investorData.PersonMailingPostalCode
                            };

                            if (type === 'filtered') {
                                setFilteredFundParticipants(updatedFundParticipants);
                            } else {
                                setFundParticipants(updatedFundParticipants);
                            };
                        };
                        updateFundParticipants(fundParticipants, 'not filtered');
                        updateFundParticipants(filteredFundParticipants, 'filtered');
                        setSubmitError('');
                        setToast({showToast: true, message: 'Information updated.', color: 'success'});
                        setSubmittingSpinner(false);
                        setShowEditModal(false);
                    }
                } else {
                    setSubmittingSpinner(false);
                    setSubmitError('Please complete address.');
                }
            } else {
                setSubmittingSpinner(false);
                setSubmitError('Please input at least one change to update.');
            }
        } catch (err) {
            setToast({showToast: true, message: 'Failed saving investor data.', color: 'danger'});
            setSubmittingSpinner(false);
            setShowEditModal(false);
        }
    }

    const SubmitButton: React.FC = () => {
        return <IonButton
        class='mr-2 edit-investor-button'
            slot='end'
            color='primary'
            onClick={
                () => {
                    handleSubmit(onSubmit, (errors) => {})()
                }
            }
        >Save</IonButton>
    }
 
    return (
        <IonModal data-testid='edit-investor-modal' cssClass={'edit-investor-modal'} mode='ios' isOpen={showEditModal} onDidDismiss={() => {
            setShowEditModal(false);
            setSubmittingSpinner(false);
            setSubmitError('');
            reset();
        }}>
            <FormProvider {...methods}>
                <IonToolbar>
                    <IonTitle>Edit Investor</IonTitle>
                    <IonButton slot='end' size='small' color='primary' onClick={() => (setShowEditModal(false), setSubmittingSpinner(false), setSubmitError(''), reset())}>X</IonButton>
                </IonToolbar>
                {submitError && !submittingSpinner &&
                    <IonRow>
                        <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{submitError}</p></IonItem>
                    </IonRow>
                }
                {submittingSpinner ? <Spinner/> :
                    <form className='edit-form'>
                        <IonRow class='mt-2 mb-2 ml-1 mr-1'><b>Investor</b>: {`${firstName} ${lastName}`}</IonRow>
                        <FieldInput control={control} name='email' formState={formState} rules={{
                            validate: {
                                isEmail: (value => (((new RegExp(EMAIL_VALIDATION_PATTERN).test(value || '')) || !value) ? undefined : 'Please provide a valid email address.'))
                            }, maxLength: {
                                value: 50,
                                message: 'Email cannot exceed 50 characters.'
                            }
                        }}/>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <FieldInput control={control} name='mailingStreet' formState={formState} rules={{
                                    maxLength: {
                                        value: 40,
                                        message: 'Mailing street cannot exceed 40 characters.'
                                    } 
                                }}/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <FieldInput control={control} name='mailingCity' formState={formState} rules={{
                                    maxLength: {
                                        value: 17,
                                        message: 'Mailing city cannot exceed 17 characters.'
                                    }
                                }}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonRow class='mt-1 mb-1 ml-1'>
                                    <IonLabel><b>Mailing State</b></IonLabel>
                                </IonRow>
                                <StateSelectorWithErrorMessage name='mailingState' disableField={false} requiredDisabled={true} editingInvestor={true} />
                                {formState.errors['mailingState'] &&
                                    <IonRow>
                                        <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{formState.errors['mailingState']?.message}</p></IonItem>
                                    </IonRow>
                                }
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <FieldInput control={control} name='mailingPostalCode' formState={formState} characterFilter={/\d/} rules={{
                                    maxLength: {
                                        value: 5,
                                        message: 'Mailing zip must be five digits.'
                                    }
                                }}/>
                            </IonCol>
                        </IonRow>
                    </form>
                }
                {!submittingSpinner &&
                    <IonToolbar class=''>
                        <IonButton class='edit-investor-button' slot='end' color='primary' onClick={() => (setShowEditModal(false), setSubmittingSpinner(false), setSubmitError(''), reset())}>Cancel</IonButton>
                        <SubmitButton />
                    </IonToolbar>
                }
            </FormProvider>
        </IonModal>
    )
}

const FieldInput: React.FC<{
    control: Control<InvestorFields>,
    name: keyof InvestorFields,
    formState: FormState<InvestorFields>,
    rules?: RegisterOptions<InvestorFields>,
    characterFilter?: RegExp
}> = ({control, name, formState, rules, characterFilter}) => {
    const fieldToLabel: {[key in keyof InvestorFields]: string} = {
        email: 'Email',
        mailingStreet: 'Mailing Street',
        mailingCity: 'Mailing City',
        mailingState: 'Mailing State',
        mailingPostalCode: 'Mailing Zip'
    }
    const label = fieldToLabel[name];

    return <Controller 
        control={control}
        name={name}
        rules={rules}
        render={({ field: {value, ref, name, onBlur, onChange} }) =>
            <>
                <IonRow class='mt-1 mb-1 ml-1 mr-1'>
                    <IonLabel><b>{`${label}`}</b></IonLabel>
                </IonRow>
                <IonRow>
                    <IonInput data-testid={`${name}-input`} class='gr-border edit-input ml-1 mr-1' mode='ios' type='text' autofocus={true} value={value} ref={ref} name={name} onIonBlur={onBlur} onIonChange={event => onChange(event.detail.value)} onKeyPress={(event) => {
                        if (characterFilter && !characterFilter.test(event.key)) {
                            return event.preventDefault();
                        }
                    }} />
                </IonRow>
                {formState.errors[name] &&
                    <IonRow>
                        <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{formState.errors[name]?.message}</p></IonItem>
                    </IonRow>
                }
            </>
        }
    />
}

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(EditInvestorInformationModal);