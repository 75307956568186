const initialState:CachedObjectState = {
    accounts: [],
    relatedParties: [],
    accountAssetHoldings: [],
    recurringTransactions: [],
    documents: [],
    transactionBilling: [],
    accessDefinitions: {
      clientFeatureAccessDefinitions: [],
      hasAccessToHomeQuickLinks: false
    },
    viewMode: 'DEFAULT_TYPE'
  }

  export function CachedObjectReducer(
    state = initialState,
    action: CachedObjectActions
  ): CachedObjectState {
    switch (action.type) {
        case "setAccounts":
          return { ...state, accounts: action.payload.accounts }
        case "setAccessDefinitions":
          return { ...state, ...action.payload }
        case "setViewMode":
          return {...state, viewMode: action.payload.viewMode}
        case "setInitCache":
          return {...state, ...action.payload}
        default:
            return state
    }
}

export default CachedObjectReducer