import React from "react";
import { AddInvestorWizardStep } from "../AddInvestorTypes";
import WizardProgressBar from "../../WizardProgressBar";
import progressBarReviewStepImage from '../../../images/distribution-wizard-step-review.svg';
import { IonRow, IonItem } from "@ionic/react";

export interface AddInvestorWizardStepReview extends AddInvestorWizardStep {
    availableFundAccounts: FundAccount[],
    errorMessage: string
}

const AddInvestorStepReview: React.FC<AddInvestorWizardStepReview> = ({formRef, submitFields, wizardCompletionRatio, savedFields, availableFundAccounts, errorMessage}) => {
    const submitForm = (e) => {
        e.preventDefault();
        const noSubmittableFields = {};
        submitFields({status: 'ok', formFields: noSubmittableFields});
    };

    return (
        <form ref={formRef} onSubmit={submitForm}>
            <WizardProgressBar progressBarImage={progressBarReviewStepImage} progressRatio={wizardCompletionRatio} />
            {(errorMessage !== '') &&
                <IonItem class='mt-1' color='danger'>
                    <p className='white-color'>{errorMessage}</p>
                </IonItem>
            }
            <IonRow class='ml-2 mb-2'>
                <h2>Review New Investor:</h2>
            </IonRow>
            <IonRow class='ml-3'>
                <b>Investor Name</b>: {`${savedFields.firstName} ${savedFields.lastName}`}
            </IonRow>
            {savedFields?.email &&
                <IonRow class='ml-3 mt-1'>
                    <b>Email</b>: {`${savedFields.email}`}
                </IonRow>
            }
            {savedFields?.mailingStreet && savedFields?.mailingCity && savedFields?.mailingState && savedFields?.mailingZip &&
                <IonRow class='ml-3 mt-1'>
                    <b>Address</b>: {`${savedFields.mailingStreet} ${savedFields.mailingCity}, ${savedFields.mailingState} ${savedFields.mailingZip}`}
                </IonRow>
            }
            <IonRow class='ml-3 mt-1 mb-1'>
                <b>Participating Accounts</b>:
            </IonRow>
            {savedFields.accountRelationships?.map(account => {
                const participatingAccount = availableFundAccounts.find(acc => acc.RetirementAccountId === account.accountId);
                return <IonRow class='ml-4'>{`${participatingAccount?.RetirementAccount.Name} - ${participatingAccount?.RetirementAccount.ClientFirstName} ${participatingAccount?.RetirementAccount.ClientLastName} (Active Date: ${account.activeDate})`}</IonRow>
            })}
        </form>
    )
};

export default AddInvestorStepReview;