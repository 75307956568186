import React, { useState, useEffect, useReducer } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonLabel
} from '@ionic/react';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import ContainerComponent from '../components/ContainerComponent';
import CreditCardInfo from './CreditCardComponents/CreditCardInfo';
import calloutHelper, { UpdatePaymentMethod } from '../helpers/calloutHelpers';
import Spinner from './Spinner';
import PartyTypeConditionalAccess from './PartyTypeConditionalAccess';
import {useHistory} from "react-router-dom"
import DocCustodySidebar from './DocCustody/DocCustodySidebar';

const CREDIT_CARD = 'Credit Card'
const ACCOUNT = 'Account'
const SEND_INVOICE = 'Send Invoice'
const MASK_VALUE = 'xxxx xxxx xxxx '

interface IDropdown {
  id: number,
  name: string,
  value: string
}

enum IActionsTypes {
  setIsShowToast = "setIsShowToast",
  setIsLoading = "setIsLoading",
  setStatusCode = "setStatusCode",
  setErrorMessage = "setErrorMessage"
}

interface IReducerAction {
  type: string;
  payload: boolean | number | string
}

type CreditCardFormInfo = {
  creditCardNumber: string,
  expiry: string,
  zipCode: string,
}

const reducer = (state, action: IReducerAction) => {
  const { type, payload } = action;
  switch (type) {
    case 'setIsShowToast':
      return { ...state, isShowToast: payload }
    case 'setIsLoading':
      return { ...state, isLoading: payload }
    case 'setErrorMessage':
      return { ...state, errorMessage: payload }
    default:
      throw new Error()
  }
}

const initialStates = { isShowToast: false, isLoading: false, errorMessage: "" }
const UpdatePayment: React.FC<{ selectedAccount?: RetirementAccount, viewMode: ViewMode }> = ({ selectedAccount, viewMode }) => {
  
  const [reducerState, dispatch] = useReducer(reducer, initialStates)
  const { isLoading, isShowToast, errorMessage } = reducerState
  const history = useHistory()

  const preferredBillingMethods: Array<IDropdown> = [
    {
      id: 1,
      name: viewMode === 'DOC_CUSTODY' ? SEND_INVOICE : "Deduct IRA",
      value: viewMode === 'DOC_CUSTODY' ? SEND_INVOICE : ACCOUNT
    },
    {
      id: 2,
      name: CREDIT_CARD,
      value: CREDIT_CARD
    }
  ];
  const [paymentMethod, setPaymentMethod] = useState<string>(selectedAccount?.preferredBillingMethod || '')
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardFormInfo>({
    creditCardNumber: '',
    expiry: '',
    zipCode: '',
  })
  const [isShowCardForm, setIsShowCardForm] = useState<boolean>(true)

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  useEffect(() => {
    setIsShowCardForm(false)
    if (selectedAccount?.id) {
      calloutHelper
        .getRetirementAccountWithCreditCard(selectedAccount?.id)
        .then((result) => {
          if (result) {
            let expiry: string = ''
            if (result?.data?.creditCardExpirationDate) {
              const date = new Date(result?.data?.creditCardExpirationDate);
              const month = date.getMonth() + 1
              const year = date.getFullYear().toString().slice(2, 4)
              expiry = zeroPad(month, 2) + '/' + year
            }
            setCreditCardInfo({
              creditCardNumber: result?.data?.creditCardNumber ? MASK_VALUE + result?.data?.creditCardNumber : '',
              expiry: expiry,
              zipCode: result?.data?.billingZip,
            })

            if (result?.data?.preferredBillingMethod === "Deduct Checking" && preferredBillingMethods.length < 3) {
              preferredBillingMethods.push({
                id: 3,
                name: "Deduct Checking",
                value: "Deduct Checking"
              })
            }

            setPaymentMethod((viewMode === 'DOC_CUSTODY' && result?.data?.preferredBillingMethod === ACCOUNT) ? SEND_INVOICE : result?.data?.preferredBillingMethod)
            setIsShowCardForm(true)
          }
        })
        .finally(() => {
          setIsShowCardForm(true)
        })
    }
  }, [selectedAccount?.id])

  const onSubmit = (value: any) => {
    dispatch({ type: IActionsTypes.setIsLoading, payload: true })
    
    const data: UpdatePaymentMethod = {
      paymentMethod: paymentMethod,
      creditCardData: value,
      accountId: selectedAccount?.id
    }

    if (value) {
      try {
        calloutHelper.updatePaymentMethod(data).then((response) => {
          if (response?.data) {
            history.push("/billing", {
              Toast: { message: response?.data, color: "success"}
            })
          }
        }).catch(() => {
          dispatch({ type: IActionsTypes.setErrorMessage, payload: 'Error updating payment method settings'})
          dispatch({ type: IActionsTypes.setIsShowToast, payload: true })
          dispatch({ type: IActionsTypes.setIsLoading, payload: false })
        })
      } catch (error) {
        dispatch({ type: IActionsTypes.setErrorMessage, payload: 'Error updating payment method settings'})
        dispatch({ type: IActionsTypes.setIsShowToast, payload: true })
        dispatch({ type: IActionsTypes.setIsLoading, payload: false })
      }
    }
  }

  return (
    <IonRow class="container">
      <IonToast
        isOpen={isShowToast}
        color={"danger"}
        onDidDismiss={() => dispatch({ type: IActionsTypes.setIsShowToast, payload: false })}
        position={"top"}
        message={errorMessage}
        duration={1500}
      />
      <IonCol class="pt-1" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8">
        <ContainerComponent title="Update Preferred Payment Method" content={
          <PartyTypeConditionalAccess featureDescription='updatePaymentMethod' noAccessRender={'You do not have access to update the payment method for this account.'}>
            <>
              <IonRow>
                <ul>
                    <li>Your administrative fees due can be paid by deducting available cash in your IRA, or by credit card.</li>
                    <li>Fees will be automatically paid as they are assessed, for both annual charges and per occurrence charges.</li>
                    <li>You can change your payment preferences for future charges at any time.</li>
                </ul>
              </IonRow>
              <IonRow>
                <IonCol class="pt-2" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                  <IonLabel class="pl-1">Primary Payment Method</IonLabel>
                  <IonItem>
                    <IonSelect
                      mode='ios'
                      onIonChange={(e) => { setPaymentMethod(e?.detail.value) }}
                      value={paymentMethod}
                      interface="action-sheet"
                      placeholder = {paymentMethod === 'Deduct Checking' ? 'Deduct Checking' : 'Preferred Payment Method'}
                    >
                      {preferredBillingMethods.map((data: IDropdown) => (
                        <IonSelectOption key={data.id} value={data.value}>{data.name}</IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol class="pt-1" sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4" >
                </IonCol>
              </IonRow>
              {(paymentMethod === ACCOUNT || paymentMethod === SEND_INVOICE) && <IonRow>
                <IonItem><p className="mt-0">Please provide credit card information below as a backup payment source.</p></IonItem>
              </IonRow>}
              {isShowCardForm &&
                <IonRow>
                  <CreditCardInfo 
                    onCardSubmit={onSubmit} 
                    isLoading={isLoading} 
                    creditCardInfo={creditCardInfo} 
                    validateFields={paymentMethod === CREDIT_CARD} 
                    maskValue={MASK_VALUE} 
                    showUpdateButton={paymentMethod === CREDIT_CARD || paymentMethod === ACCOUNT || paymentMethod === SEND_INVOICE}
                  />
                </IonRow>}
              {!isShowCardForm && 
                <Spinner/> 
              }
            </>
          </PartyTypeConditionalAccess>
        } />
      </IonCol>
      {viewMode !== 'DOC_CUSTODY' ? <Sidebar /> : <DocCustodySidebar/>}
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
    viewMode: rootState.CachedObjects.viewMode
  }
}

export default connect(mapStateToProps)(UpdatePayment);