import React from 'react';
import { IonCol, IonRow, IonButton } from '@ionic/react';

const TransferSign: React.FC<{ signatureUrl: string, signatureType: string }> = ({ signatureUrl, signatureType }) => {

    const renderPenSignInstrustions = () => {
        return (
            <>
                <IonCol size='12'>
                    <IonRow>
                        <IonCol>
                            <IonRow class='w-100'>
                                <h1>Final Steps</h1>
                            </IonRow>
                            <IonRow>
                                <p>
                                    Before Equity Trust can initiate your transfer request, you must download the transfer form using the "Download Transfer Form" button below.
                                </p>
                            </IonRow>
                            <IonRow class='m-1 p-1'>
                                <IonButton href={signatureUrl}>Download Transfer Form</IonButton>
                            </IonRow>
                            <IonRow>
                                <p>
                                    Please enter your Social Security Number in Section 1 of the downloaded transfer form and physically sign and date in Section 4. Please return the completed transfer form to Equity Trust using our Secure Upload:
                                </p>
                            </IonRow>
                            <IonRow class='m-1 p-1'>
                                <IonButton href='https://www.midlandtrust.com/secure-upload' target='_blank'>Secure Upload</IonButton>
                            </IonRow>
                            <IonRow>
                                <p>
                                    You may also fax your form to 239-466-5496 or mail it to:
                                </p>
                            </IonRow>
                            <IonRow class='m-1 p-1'>
                                PO Box 07520 <br />
                                Fort Myers, FL 33919
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </>
        )
    }

    const renderESignInstructions = () => {
        return (
            <>
                <IonCol size='12'>
                    <IonRow>
                        <IonCol>
                            <IonRow class='w-100'>
                                <h1>Final Steps</h1>
                            </IonRow>
                            <IonRow>
                                <p>
                                    Your transfer form has been created, now it can be electronically signed. To begin the e-signature process, click the “Proceed to E-Signature” button below.
                                </p>
                            </IonRow>
                            <IonRow>
                                <p>
                                    You will be redirected to DocuSign, our electronic signature system partner.
                                </p>
                            </IonRow>
                            <IonRow class='m-1 p-1'>
                                <IonButton href={signatureUrl}>Proceed to E-signature</IonButton>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </>
        )
    }

    return (
        <>
            {signatureType === 'e-sign'
                ? renderESignInstructions()
                : renderPenSignInstrustions()
            }
        </>
    )
}

export default TransferSign;