import React, { useState, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput
} from '@ionic/react';
import progressBarStepTwo from '../../images/contribution-wizard-step-two.svg';
import { amountRegex, isPhone } from '../../helpers/Utils';
import { useForm, Controller, FieldErrors, useWatch, FormProvider } from 'react-hook-form';
import { InputComponents } from './TradingAccountWithdrawalTypes';
import ConditionalSelect from '../ConditionalSelect';
import tradingAccountCalloutHelper from '../../helpers/calloutHelpers/tradingAccountCalloutHelper';
import axios from 'axios';

interface TradingAccountOption {
  value: string,
  description: string
}

const TradingAccountWithdrawalStepTwo: React.FC<InputComponents> = ({ formRef, submitFields, savedFields, setErrorMessage, clearErrorMessage}) => {
  type StepTwoFields = TradingAccountWithdrawalWizard.StepTwoFields

  const [tradingAccountOptions, setTradingAccountOptions] = useState<TradingAccountOption[]>([])

  const getDefaultValues = ():StepTwoFields => {
    return {
      fullOrPartial: savedFields?.fullOrPartial || '',
      tradingAccountId: savedFields?.tradingAccountId || '',
      tradingAccountLabel: savedFields?.tradingAccountLabel || '',
      amount: savedFields?.amount || null,
      distributionRequest: savedFields?.distributionRequest || ''
    }
  }

  const stepTwoForm = useForm<StepTwoFields>({
    mode: 'onChange',
    defaultValues: getDefaultValues()
  })
  
  const { control, formState: { errors }, handleSubmit, getValues } = stepTwoForm;

  const fullOrPartial = useWatch({
    name: 'fullOrPartial',
    control
  })

  useEffect(() => {
    let source = axios.CancelToken.source();
    const getTradingAccountOptions = async (token: any) => {
      try
      {
        const tradingAccountOptionsResult = await tradingAccountCalloutHelper.getTradingAccounts(savedFields?.holdingId || '', token);
        setTradingAccountOptions([...tradingAccountOptionsResult.data]);
      } catch (err) {
        if( axios.isAxiosError(err) && (err)?.message !== 'Cancelling in cleanup') {
          setTradingAccountOptions([]);
          setErrorMessage('Error retrieving asset options');
          clearErrorMessage();
        }
      }
    };
    
    getTradingAccountOptions(source.token);
    return(() => {
      source.cancel('Cancelling in cleanup');
    });
  }, [clearErrorMessage, savedFields?.holdingId, setErrorMessage]);



  const renderStepTwo = () => {
    const onValid = (formFields: StepTwoFields) => {

      let selectedTradingAccount = tradingAccountOptions.find(tradingAccount => tradingAccount.value === formFields.tradingAccountId);

      if (selectedTradingAccount) {
        formFields.tradingAccountLabel = selectedTradingAccount?.description;
      }
      
      submitFields({status: 'ok', formFields})
    }
    
    const onInvalid = (errors: FieldErrors) => {
      submitFields({status: 'error', formFields: getValues(), errors})
    }

    return <>
      <FormProvider {...stepTwoForm}>
        <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
          <IonRow class="mt-2 mb-1">
            <IonLabel>Please select withdrawal option</IonLabel>
          </IonRow>
          <IonRow class="w-100">
            <Controller control={control} name='fullOrPartial' render={({ field: { value, onBlur, onChange, name }}) => {
              return <IonSelect data-testid='full-or-partial-select' class="w-100 gr-border p-1" name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={event => onChange(event.detail.value)} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                <IonSelectOption>Full - All available funds and close trading account</IonSelectOption>
                <IonSelectOption>Partial - Indicate amount below</IonSelectOption>
              </IonSelect>
            }} 
            rules={{required: {
              value: true,
              message:'Please select whether this is a full or partial payment.'
            } }} />
          </IonRow>
          {errors['fullOrPartial'] && <IonItem class="mt-1" color="danger" className="white-color"><p className="white-color">{errors['fullOrPartial'].message}</p></IonItem>}

          {fullOrPartial.includes('Full') && <p>By requesting a full withdrawal, the trading or bank account will be closed.</p>}

          {fullOrPartial.includes('Partial') && 
            <>
              <IonRow class="w-100">
                <ConditionalSelect controllerName="tradingAccountId" title="Trading Account" errorMessage="Please select a trading account." dataList={tradingAccountOptions} />
              </IonRow>
              <IonRow class="mt-2 mb-1">
                <IonLabel>Amount (USD)</IonLabel>
              </IonRow>
              <IonRow class="m-0 p-0">
                <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                  <Controller name='amount' control={control} render={({ field: { value, onBlur, name, onChange } }) =>
                    <IonItem class="gr-border" lines="none">$<IonInput class="ion-text-left ml-1" data-testid='amount-input' name={name} type="number" value={value} onIonBlur={onBlur} onIonChange={onChange}/></IonItem>
                  } rules={{
                    required: {
                      value: true,
                      message: 'Please enter an amount to withdraw.'
                    },
                    pattern: {
                      value: amountRegex,
                      message: 'Invalid Amount'
                    },
                    validate: {
                      greaterThanZero: (value) => ((value && (+value > 0)) ? undefined : 'Amount value must be greater than 0'),
                      isANumber: (value) => ((value && isNaN(value)) ? 'Value must be a valid number': undefined),
                    }
                  }} />
                </IonCol>
              </IonRow>
              {errors['amount'] && errors['amount']?.type !== 'fileUploadNeed' && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['amount']?.message}</p></IonItem>}
            </>}
            
            {fullOrPartial !== '' &&
            <>
              <IonRow class="mt-2 mb-1">
                <IonLabel>Do you want to request a distribution with these funds?</IonLabel>
              </IonRow>
              <IonRow class="w-100">
              <Controller name='distributionRequest' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                <IonSelect class="w-100 gr-border p-1" data-testid='distribution-request' name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                <IonSelectOption>Yes</IonSelectOption>
                <IonSelectOption>No</IonSelectOption>
              </IonSelect>
              } rules={{
                required: {
                  value: true,
                  message: 'Please select expected use of funds.'
                },
                validate: (value) => { return value !== null }
              }} />
            </IonRow>
            {errors['expectedUseOfFunds'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['expectedUseOfFunds'].message}</p></IonItem>}
          </>}
        </form>
      </FormProvider>
    </>
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepTwo} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.33}></IonProgressBar>}
        </IonCol>
      </IonRow>
      {renderStepTwo()}
    </>
  );
};

export default TradingAccountWithdrawalStepTwo;