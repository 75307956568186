import { BlobWithName } from "./FileSelectButton";

export const readFiles = async (files: BlobWithName[]) => {
    const base64 = 'base64,';
    let loadedFiles: Array<FileAndUploadStatus> = [];

    for (let i = 0; i < files.length; i++) {
        let readerListener = new Promise<FileAndUploadStatus>((resolve, reject) => {
            let reader = new FileReader();
            reader?.addEventListener('load', () => {
                let fileContents = typeof reader.result === 'string' ? reader.result : '';
                let dataStart = fileContents?.indexOf(base64) + base64.length;
                let blob = fileContents?.substring(dataStart);

                let newFile = {
                    'name': files[i].name,
                    'base64_string': blob
                };
                resolve({ file: newFile, status: 'added' });
            });

            reader.addEventListener('error', () => {
                reject();
            });

            reader.addEventListener('abort', () => {
                reject();
            });

            reader.readAsDataURL(files[i]);
        })
        try {
            loadedFiles.push((await readerListener));
        } catch (err) {
            console.error(err);
        };
    };
    return loadedFiles;
};