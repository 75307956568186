import React, { useState, useEffect } from 'react';
import { IonRow, IonCol, IonList, IonButton, IonModal, IonPage, IonToolbar, IonInput } from '@ionic/react';
import { connect } from 'react-redux';
import calloutHelper from '../../helpers/calloutHelpers';
import ToggleComponent from '../ToggleComponent';
import { formatDateToUTCString } from '../../helpers/Utils';
import Spinner from '../Spinner';

const DocumentsHeld: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {
    const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
    const [ showFileModal, setShowFileModal ] = useState<boolean>(false);
    const [ showModalSpinner, setShowModalSpinner ] = useState<boolean>(false);
    const [ documentLink, setDocumentLink ] = useState<string>('');
    const [ documentHoldings, setDocumentHoldings ] = useState<DocumentHolding[]>([]);
    const [ filteredDocumentHoldings, setFilteredDocumentHoldings ] = useState<DocumentHolding[]>([]);
    const [ documentHoldingFilter, setDocumentHoldingFilter ] = useState<string>('');

    useEffect(() => {
        try {
            setShowSpinner(true);
            calloutHelper.getDocumentHoldings(selectedAccount?.id || '').then((result) => {
                setDocumentHoldings(result.data);
                setFilteredDocumentHoldings(result.data);
                setDocumentHoldingFilter('');
                setShowSpinner(false);
            })
        } catch {
            setDocumentHoldings([]);
            setFilteredDocumentHoldings([]);
            setDocumentHoldingFilter('');
            setShowSpinner(false);
        };
    }, [selectedAccount])

    useEffect(() => {
        let updatedDocumentHoldings = documentHoldings

        if (documentHoldingFilter) {
            updatedDocumentHoldings = updatedDocumentHoldings.filter(documentHolding => documentHolding.AssetDescription.toLowerCase().includes(documentHoldingFilter.toLowerCase()));
        }

        setFilteredDocumentHoldings(updatedDocumentHoldings);
    }, [documentHoldingFilter]);
    
    const handleViewFileButton = async (contentVersionId: string, assetDescription: string, cusipId: string) => {
        setShowFileModal(true);
        setShowModalSpinner(true);
        if (!contentVersionId) {
            setDocumentLink('');
            setShowFileModal(false);
            setShowModalSpinner(false);
        }

        try {
            const contentDistributionData: ContentDistributionData = {
                contentVersionId: contentVersionId,
                assetDescription: assetDescription
            };

            const createContentDistributionResult = await calloutHelper.createContentDistribution(cusipId, contentDistributionData);
            if (createContentDistributionResult.status === 200) {
                setDocumentLink(createContentDistributionResult.data[0].DistributionPublicUrl);
                setShowModalSpinner(false);
            } else {
                setDocumentLink('');
                setShowFileModal(false);
                setShowModalSpinner(false);
            };
        } catch (err) {
            setDocumentLink('');
            setShowFileModal(false);
            setShowModalSpinner(false);
        };
    };

    const renderDocumentsHeld = () => {  
        if (!documentHoldings || (documentHoldings.length < 1)) {
            return <p>This account does not have any documents.</p>
        };

        return (
            <IonList data-testid='document-holdings-list' class='pl-2 pr-2'>
                <IonRow class='ml-1 mr-1 mb-2'>
                    <IonInput data-testid='search-input' placeholder='Search Documents' onIonChange={event => setDocumentHoldingFilter(event.detail.value || '')} class='gr-border p-1'/>
                </IonRow>
                {filteredDocumentHoldings.map(documentHolding => {
                    return (
                        <IonRow key={documentHolding.Id} class='divider-top'>
                            <IonCol class='m-0 p-0 ml-1' size='10'>
                                <IonRow class='mt-1'>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Document</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{documentHolding.AssetDescription}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow class='mb-1'>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Vaulted Date</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p data-testid={`vaulted-date-${documentHolding.Id}`} className="m-0">{formatDateToUTCString(new Date(documentHolding.DateAcquired), 'MM/DD/YYYY')}</p>
                                    </IonCol>
                                </IonRow>
                                {documentHolding.ContentDocuments.length === 1 ?
                                    <IonRow class='mb-1'>
                                        <IonButton data-testid={`view-file-button-${documentHolding.Id}`} onClick={() => handleViewFileButton(documentHolding.ContentDocuments[0].ContentVersionId, documentHolding.AssetDescription, documentHolding.CusipId)} size='small'>Open File</IonButton>
                                    </IonRow>
                                    :
                                    documentHolding.ContentDocuments.map((contentDocument, index)=> {
                                        return <IonRow class='mb-1' key={contentDocument.Id}>
                                            <IonButton data-testid={`view-file-button-${documentHolding.Id}-${index + 1}`} onClick={() => handleViewFileButton(contentDocument.ContentVersionId, documentHolding.AssetDescription, documentHolding.CusipId)} size='small'>Open File {index + 1}</IonButton>
                                        </IonRow>
                                })}
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonList>
        )
    };

    return (
        <>
            <IonModal cssClass={'file-modal'} mode='ios' isOpen={showFileModal} onDidDismiss={() => (setShowFileModal(false), setDocumentLink(''))}>
                {showModalSpinner 
                    ? <IonRow class='mt-5 justify-center'>
                        <Spinner />
                    </IonRow>
                    : <IonPage>
                        <IonToolbar>
                            <IonButton slot='end' size='small' onClick={() => (setShowFileModal(false), setDocumentLink(''))}>X</IonButton>
                        </IonToolbar>
                        <iframe data-testid='document-iframe' title='file' src={documentLink} width="100%" height="100%"/>
                    </IonPage>
                    }
            </IonModal>
            <ToggleComponent isActive={false} title='Documents Held' bodyData={
                <>
                    {showSpinner ? <Spinner /> : renderDocumentsHeld()}
                </>
            } />
        </>
    )
};

const mapStateToProps = (rootState: RootState) => {
    return {
        selectedAccount: rootState.UserState.selectedAccount
    }
}

export default connect(mapStateToProps)(DocumentsHeld)