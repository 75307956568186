export const getNextArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember);
    if (currentMemberIndex === array.length - 1 || currentMemberIndex === -1) {
        return array[currentMemberIndex];
    };
    return array[currentMemberIndex + 1];
};

export const getPreviousArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember);
    if (currentMemberIndex === 0 || currentMemberIndex === -1) {
        return array[currentMemberIndex];
    };
    return array[currentMemberIndex - 1];
};