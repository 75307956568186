import React, { useEffect } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import WizardProgressBar from '../WizardProgressBar';
import { TransferStepOneFields, TransferWizardStep } from '../TransferFields/TransferWizardTypes';
import progressBarStepOneImage from '../../images/transfer-step-one.svg'
import { IonCol, IonItem, IonLabel, IonRow } from '@ionic//react';
import { AccountIonSelect } from '../SelectAccountBar';
import { checkPartyTypeAccessBuilder } from '../PartyTypeConditionalAccess';

interface TransferWizardStepOne extends TransferWizardStep {
    availableAccounts: RetirementAccount[]
}

const TransferStepOne: React.FC<TransferWizardStepOne> = ({availableAccounts, formRef, submitFields, wizardCompletionRatio, savedFields}) => {

    const availableAccountHolders = availableAccounts.filter(account => account.partyType === 'Account Holder');

    const getDefaultValues = ():TransferStepOneFields => {
        return {
            accountId: savedFields?.accountId || '',
        };
    };

    const { control, formState: {errors}, handleSubmit, setValue, getValues, clearErrors } = useForm<TransferStepOneFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    useEffect(() => {
        if (savedFields?.accountId) {
            handleSelectedAccountId(savedFields.accountId)
        };
    }, []);

    const notifyRetirementAccountChanged = (event: CustomEvent<any>) => {
        const retirementAccountId = event?.detail?.value || '';
        handleSelectedAccountId(retirementAccountId);
    };

    const handleSelectedAccountId = (accountId) => {
        setValue('accountId', accountId)
        const selectedAccount = availableAccounts.find(account=> account.id === accountId);
        if (selectedAccount) {
            clearErrors('accountId');
        };
    };

    const onValid = (formFields: TransferStepOneFields) => {
        submitFields({status: 'ok', formFields});
    };

    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: getValues(), errors});
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
            <WizardProgressBar progressBarImage={progressBarStepOneImage} progressRatio={wizardCompletionRatio} />

            <IonItem><h2>Transfer Cash into my Equity Trust Account</h2></IonItem>
            <IonRow class='divider'>
                <p>This feature allows you to initiate an IRA-to-IRA custodial transfer of uninvested cash. For any other type of transfer, please contact our office.</p>
                <p>While we make every effort to use e-signatures, many custodians require a pen signature. You may be required to download, print, sign, and return a transfer form to Equity Trust.</p>
            </IonRow>

            <IonRow class='mt-2 mb-1'>
                <IonLabel>Select Account</IonLabel>
            </IonRow>
            <IonRow class='w-100 gr-border p-0 m-0'>
                <IonCol size='12' class='w-100 p-0 m-0'>
                    <Controller control={control} name='accountId' render={({ field: { name, value } }) => (
                        <AccountIonSelect accountID={value} availableAccounts={availableAccountHolders} name={name} accountFilter={checkPartyTypeAccessBuilder('createTransfer')} onChangeHandler={event => {notifyRetirementAccountChanged(event)}} />
                    )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please select an account.'
                            }
                        }} />
                </IonCol>
            </IonRow>
            {errors['accountId'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['accountId']?.message}</p></IonItem>}
        </form>
    )
}

export default TransferStepOne