import React from 'react'
import WizardProgressBar from '../../WizardProgressBar'
import { DistributionWizardStep, deliveryMethodOptions } from '../DistributionFields/distributionWizardTypes'

import progressBarReviewStepImage from '../../../images/distribution-wizard-step-review.svg'
import { IonRow, IonCol, IonItem } from '@ionic/react'
import TermsOfServiceComponent, { standardTOS } from '../../TermsOfServiceComponent'
import { formatValueAsCurrency } from '../../../helpers/Utils'
import { fullOrPartialOptions } from '../DistributionFields/AmountFields'
import { taxWithholdingOptions } from '../DistributionFields/TaxWithholdingFields'
import { formatDateString } from 'shared-utils'

export interface ReviewStep extends DistributionWizardStep {
    tosConfirmed: boolean,
    setTOSConfirmed: Function,
    isFundManagerFull: Boolean
}

const DistributionStepReview: React.FC<ReviewStep> = ({ formRef, submitFields, wizardCompletionRatio, savedFields, tosConfirmed, setTOSConfirmed, isFundManagerFull }) => {
    const submitForm = (e) => {
        // The review step doesn't have any form data to submit but
        // a submitForm is still needed to inform the wizard if the
        // user selects back.
        e.preventDefault();
        const noSubmittableFields = {}
        submitFields({status: 'ok', formFields: noSubmittableFields})
    }
    
    const fullOrPartialText = fullOrPartialOptions.find(option => option.value === savedFields.fullOrPartial)?.description || ''
    const deliveryMethodText = deliveryMethodOptions.find(option => option.value === savedFields.deliveryMethod)?.description || ''
    const taxWithholdingText = taxWithholdingOptions.find(option => option.value === savedFields.electTaxWithholding)?.description || ''

    const calculateTaxWithholdingAmount = (): number => {
        if(savedFields.electTaxWithholding === 'noTaxWithholding') {
            return 0
        }
        if(savedFields.fullOrPartial === 'partial') {
            const amount: number = +(savedFields.amount || 0)
            const taxPercentage: number = +(savedFields.taxWithholdingPercentage || 0)
            return amount * (taxPercentage / 100)
        }
        return 0
    }

    const calculateNetAmountToClient = () => {
        return +(savedFields.amount || 0) - calculateTaxWithholdingAmount()
    }

    return (
        <>
            <form ref={formRef} onSubmit={submitForm}>
                <WizardProgressBar progressBarImage={progressBarReviewStepImage} progressRatio={wizardCompletionRatio} />

                <IonRow>
                    <IonCol size="12">
                        <IonRow>
                            <IonCol>
                                <IonRow class="w-100" data-testid='review-details-title'>
                                    <h1>Review details below.</h1>
                                </IonRow>
                                <IonRow>
                                    {!isFundManagerFull &&
                                        <>
                                            <IonCol size="12">
                                                <IonItem color='tertiary' data-testid='distribution-details-title'>
                                                    Distribution Details
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="12">
                                                <IonItem lines="none">
                                                    <p>
                                                        <span data-testid='account' ><b>Account</b>: {savedFields?.retirementAccountLabel}<br/></span>
                                                        <span data-testid='distribution-type'><b>Distribution Type</b>: {savedFields?.distributionType || ''}<br/></span>
                                                        <span data-testid='full-or-partial'><b>Full or Partial Distribution</b>: {fullOrPartialText}<br/></span>
            
                                                        {taxWithholdingText &&
                                                            <span data-testid='tax-withholding-text'><b>Federal Withholding Election</b>: {taxWithholdingText}<br/></span>
                                                        }
                                                        {savedFields.fullOrPartial === 'partial' && (<>
                                                            <span data-testid='total-distribution-amount'><b>Total Distribuition Amount</b>: {formatValueAsCurrency(savedFields?.amount || '0')}<br/></span>
                                                        </>)}
                                                        {savedFields.electTaxWithholding === 'taxWithholding' && (<>
                                                            <span data-testid='tax-withholding-percentage'><b>Tax Withholding Percentage</b>: {savedFields?.taxWithholdingPercentage || 0} %<br/></span>
                                                        </>)}
                                                        {savedFields.fullOrPartial === 'partial' && (<>
                                                            {savedFields.electTaxWithholding === 'taxWithholding' && (<>
                                                                <span data-testid='tax-withholding-amount'><b>Tax Withholding Amount</b>: {formatValueAsCurrency(calculateTaxWithholdingAmount())}<br/></span>
                                                            </>)}
                                                            <span data-testid='net-distribution-amount'><b>Net Distribution Amount</b>: {formatValueAsCurrency(calculateNetAmountToClient())}<br/></span>
                                                        </>)}
                                                    </p>
                                                </IonItem>
                                            </IonCol>
                                        </>
                                    }

                                    <IonCol size="12">
                                        <IonItem color='tertiary'>
                                            Delivery Details
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem lines="none">
                                            <p>
                                                <b>Delivery Method</b>: {deliveryMethodText}<br />
                                                {isFundManagerFull &&
                                                    <>
                                                        <b>Total Disbursement Amount</b>: {formatValueAsCurrency(savedFields?.amount || '0')} <br />
                                                    </>
                                                }
                                                {(savedFields.deliveryMethod === 'directDeposit' || savedFields.deliveryMethod === 'wireTransfer') && (<>
                                                    <b>Name on Bank Account</b>: {savedFields?.creditAccountName}<br />
                                                    <b>Bank Account Number</b>:  {savedFields?.creditAccountNumber}<br />
                                                    <b>Bank Routing Number</b>:  {savedFields?.routingNumber}<br />
                                                    <b>Bank Name</b>:  {savedFields?.bankName}<br />

                                                </>)}
                                                {savedFields.deliveryMethod === 'directDeposit' && (<>
                                                    <b>Bank Account Type</b>:  {savedFields?.creditAccountType}<br />
                                                </>)}

                                                {(savedFields.deliveryMethod === 'checkStandard' || savedFields.deliveryMethod === 'checkOvernight') && (<>
                                                    <b>To</b>: {!savedFields?.payableTo ? savedFields?.CO : savedFields?.payableTo}<br />
                                                    <b>Mailing Street</b>:  {savedFields?.mailingStreet}<br />
                                                    <b>Mailing City</b>:  {savedFields?.mailingCity}<br />
                                                    <b>Mailing State</b>:  {savedFields?.mailingState}<br />
                                                    <b>Mailing Zip</b>:  {savedFields?.mailingZip}<br />
                                                </>)} 
                                            </p>
                                        </IonItem>
                                    </IonCol>

                                    {savedFields?.fullOrPartial === 'partial' && !isFundManagerFull &&
                                        <>
                                            <IonCol size='12'>
                                                <IonItem color='tertiary'>
                                                    Schedule Details
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size='12'>
                                                <IonItem lines='none'>
                                                    <p>
                                                        <b>Frequency</b>: {savedFields?.frequency} <br />
                                                        {savedFields?.frequency === 'Recurring' &&
                                                            <>
                                                                <b>Schedule</b>: {savedFields?.recurringTransactionData?.schedule} <br />
                                                            </>
                                                        }
                                                        {savedFields?.frequency === 'Recurring' && savedFields?.recurringTransactionData?.duration === 'Until I Cancel' &&
                                                            <>
                                                                <b>Duration</b>: {savedFields?.recurringTransactionData?.duration} <br />
                                                            </>
                                                        }
                                                        {savedFields?.frequency === 'Recurring' &&
                                                            <>
                                                                <b>Process Date</b>: {formatDateString(savedFields?.recurringTransactionData?.processDate)} <br />
                                                            </>
                                                        }
                                                        {savedFields?.frequency === 'Recurring' && savedFields?.recurringTransactionData?.duration === 'Specified Date' &&
                                                            <>
                                                                <b>End Date</b>: {formatDateString(savedFields?.recurringTransactionData?.endDate)}
                                                            </>
                                                        }
                                                    </p>
                                                </IonItem>
                                            </IonCol>
                                        </>
                                    }
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <TermsOfServiceComponent modalBodyText={standardTOS()} checked={tosConfirmed} checkboxEvent={(event) => { setTOSConfirmed(event.detail.checked) }} modalButtonText="Close" ionFloat="ion-float-right" />
            </form>
        </>
    )
}

export default DistributionStepReview