import React, { useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, IonItem, IonCheckbox, IonLabel, IonRouterLink, IonModal, IonContent, IonTextarea, IonSelect, IonSelectOption, IonToast } from '@ionic/react';
import {useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
import { formatNumber, formatDateString } from 'shared-utils';
import { useForm, Controller } from 'react-hook-form';
import calloutHelpers from '../../helpers/calloutHelpers';
import '../../pages/Style.css';
import { transactionSpecificApprovalTerms } from './PendingTransactionApprovalTerms';
import { ProjectedBillingDetails } from '../helperComponents/ProjectedBillingDetails';
import PartyTypeConditionalAccess from '../PartyTypeConditionalAccess';
import CancelTransaction from './CancelTransaction';
import FileUpload from '../FileUpload/FileUpload';

export interface PendingTrans {
  Id?: string, 
  Name: string,
  ClientApprovalPending?: boolean;
  Account?: {Name?: string, ClientFirstName?: string, ClientLastName?: string},
  Cusip?: {Name?: string, AssetName1?: string, AssetDescription?: string}
  RecordType?: string,
  Status? : string,
  TotalAmount? : number,
  AmountCash? : number,
  TransactionDate?: string,
  Type?: string,
  PayableToFrom?: string,
  FullOrPartialTransfer?: string,
  Units?: number,
  PricePerUnit?: number,
  CurrentNote? : string,
  NextStep?: string,
  BillingOverride?: boolean,
  DeliveryMethod?: string,
  DeliveryDetails?: string,
  DateDue?: string,
  CreditOrDebit?: string,
  ReceivingABA?: string,
  ReceivingBank?: string,
  CreditName?: string,
  CreditAccount?: string,
  BankAccountType?: string,
  FFCDetail?: string,
  RolloverType?: string,
  CurrentAccountType?: string,
  ThirdPartyAccount?: string,
  FundsDeliveryBy?: string,
  CheckNumber?: string,
  RothAccountName?: string,
  TotalFee?: number,
  CO?: string,
  MailingStreet?: string,
  MailingCity?: string,
  MailingState?: string,
  MailingZip?: string,
  CheckWireMemo?: string,
  SWIFTCode?: string,
  IntBankName?: string,
  IntBeneficiaryName?: string,
  IntBeneficiaryAccountNumber?: string,
  IsCancellable?: boolean
}
const PendingTransaction: React.FC<{ transaction: Partial<PendingTrans>, toast?: Toast}> = ({ transaction, toast }) => {
  const [showTermsOfService, setShowTermsOfService] = useState(false)
  const [reject, setReject] = useState(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [nextSteps, setNextSteps] = useState<any>([]);
  const [ showFileUploadModal, setShowFileUploadModal ] = useState<boolean>(false);
  const [ filesToast, setFilesToast ] = useState<{message: string, color: 'success' | ''}>();

  const finishUpload = () => {
    setFilesToast({
      message: 'Successfully uploaded files.',
      color: 'success'
    });
    setTimeout(() => setFilesToast(undefined), 4000);
    setShowFileUploadModal(false);
  }

  interface FormType {
    rejection_reason: string;
    agree_to_terms: boolean;
    full_or_partial: string;
  }

  const getDefaultValues = () => {
    let defaultForm: FormType = {
      rejection_reason: '',
      agree_to_terms: false,
      full_or_partial: transaction.FullOrPartialTransfer || ''
    }
    return defaultForm
  } 

  const {control, formState: { errors }, watch, handleSubmit} = useForm<FormType> ({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    let nextSteps = transaction?.NextStep?.split(')');
    //Salesforce may send back the string null as the first note, filtering it out
    let newNextSteps = nextSteps?.map(step => step + ')').filter(step => !step.includes('null)'));
    setNextSteps(newNextSteps);
  }, [transaction]);

  const agreeToTerms = watch('agree_to_terms')

  const fullOrPartial = watch('full_or_partial')

  const getPaymentToFromLabel = (recordType: string | undefined) => {
    
    let paymentToFromLabel = 'Current Custodian:';
    
    if (recordType?.includes('Buy') || recordType === 'Distribution_Cash' || recordType === 'Bill_Payment') {
      paymentToFromLabel = 'Payment To:';
    }
    else if (recordType?.includes('Sell') || recordType === 'Deposit_Contribution' || recordType === 'Trading_Bank_Acct_Withdrawal') {
      paymentToFromLabel = 'Payment From:';
    }

    return paymentToFromLabel;
  }

  const disableApproveButton = (fullOrPartial: string) => {
    if (transaction.RecordType === 'Trading_Bank_Acct_Withdrawal' || transaction.RecordType?.includes('Sell')) {
      return !(agreeToTerms && fullOrPartial);
    }
    else if (transaction.RecordType === 'Roth_Conversion_Cash') {
      return !(agreeToTerms && fullOrPartial);
    }
    else {
      return !agreeToTerms;
    }
  }

  let history = useHistory();

  const onSubmit = async(data: FormType) => {
    setShowSpinner(true);
    
    try {
      let approvalStatus : TransactionApprovalStatus = {
        rejectionReason: data.rejection_reason,
        fullOrPartial: data.full_or_partial
      };

      if (transaction?.Id) {
        const postTransactionApprovalStatusResult = await calloutHelpers.postTransactionApprovalStatus(transaction?.Id, approvalStatus);
        if (postTransactionApprovalStatusResult.status === 200) {
          history.push('/confirmation', {
            approvalOrRejection: reject ? 'Rejection' : 'Approval',
            PendingOrPosted: 'pending',
            TransactionId: transaction.Id,
            customTextArray: [
              'A member of our team will review your transaction and get back to you shortly.'
            ]
          });
        }
      }
    }
    catch (err) {
      setErrorMessage('Failed approving or rejecting due to an error.');
    }
    
    setShowSpinner(false);
  }

  return (
    <>
      {filesToast !== undefined &&
              <IonToast isOpen={filesToast !== undefined} color={filesToast?.color} position='top' message={filesToast?.message} onDidDismiss={() => setFilesToast(undefined)} buttons={[{ icon: 'close-circle' }]} />
      }
      <IonToast isOpen={(toast?.message !== undefined && toast.color !== undefined)} color={toast?.color} position='top'  message={toast?.message} duration={3 * 1000} buttons={[{icon:'close-circle'}]} />
      {(errorMessage !== '') && (
        <IonCol class="mb-2">
          <IonItem class="mt-1" color="danger"><p className="white-color">{errorMessage}</p></IonItem>
        </IonCol>
      )}
      <PartyTypeConditionalAccess featureDescription='viewTransactionApproval'>
        {transaction.ClientApprovalPending && !errorMessage ? <>
          <IonItem color='danger'>
            Client Approval Pending
          </IonItem>
          <IonRow>
            <IonCol data-test='approval-help-text' class="p-1">
              <p className="mt-1">This transaction was either recently created or updated with new information and requires your approval. Please CAREFULLY review all information, including related asset details and funds delivery information to ensure absolute accuracy.</p>
              <p>If the details are correct, please agree to the terms and click the Approve button. If details are not correct, click the Reject button and let us know what needs to change.</p>
            </IonCol>
          </IonRow>
        </> : ''}
      </PartyTypeConditionalAccess>

      <IonItem data-test='pending-transaction-title' color='tertiary'>
        Pending Transaction Details
      </IonItem>
      <IonRow class="p-1">
        <IonCol>
          {transaction.RecordType !== 'Rollover_IN_Cash' && transaction.RecordType !== 'Roth_Conversion_Cash' &&
            <>
              <p data-test='account-name' className="p-0 m-0"><b>Account</b>: {transaction?.Account?.Name} - {transaction?.Account?.ClientFirstName} {transaction?.Account?.ClientLastName}</p>
              <p data-test='transaction-amount' className="p-0 m-0"><b>Transaction Amount</b>: {transaction.FullOrPartialTransfer === 'Full' ? 'To Be Determined' : '$' + formatNumber(transaction.TotalAmount?.toFixed(2))}</p>
            </>
          }
          {(transaction.RecordType === 'Rollover_IN_Cash' || transaction.RecordType === 'Roth_Conversion_Cash') &&
            <p data-test='amount-cash' className="p-0 m-0"><b>Amount (Cash)</b>: {transaction?.AmountCash}</p>
          }        
          {transaction.RecordType !== 'Roth_Conversion_Cash' &&
            <p data-test='payable-to-from' className="p-0 m-0"><b>{getPaymentToFromLabel(transaction?.RecordType)}</b> {transaction?.PayableToFrom}</p>
          }
          {transaction.RecordType !== 'Rollover_IN_Cash' && transaction.RecordType !== 'Roth_Conversion_Cash' &&
            <>
              <p data-test='transaction-type' className="p-0 m-0"><b>Transaction Type</b>: {transaction.Type}</p>
            </>
          }
          {transaction.RecordType !== 'Rollover_IN_Cash' && transaction.RecordType !== 'Roth_Conversion_Cash' &&
            <>
              {transaction.Status && <p data-test='transaction-status' className="p-0 m-0"><b>Transaction Status</b>: {transaction.Status}</p>}
              {transaction.CurrentNote && <p data-test='last-update' className="p-0 m-0"><b>Last Update</b>: {transaction.CurrentNote}</p>}
            </>
          }
          {transaction.RecordType?.includes('Sell') && transaction.FullOrPartialTransfer &&
            <p data-test='sale-type' className="p-0 m-0"><b>Sale Type</b>: {transaction.FullOrPartialTransfer}</p>
          }
          {(transaction.RecordType === 'Roth_Conversion_Cash' || transaction.RecordType === 'Trading_Bank_Acct_Withdrawal') && transaction.FullOrPartialTransfer &&
            <p data-test='full-or-partial-display' className="p-0 m-0"><b>Full/Partial Withdrawal</b>: {transaction.FullOrPartialTransfer}</p>
          }
          {transaction.RecordType === 'Roth_Conversion_Cash' && 
            <p data-test='roth-account-name' className="p-0 m-0"><b>Roth Acct #</b>: {transaction?.RothAccountName}</p>
          }
          {transaction.RecordType === 'Rollover_IN_Cash' &&
            <>
              <p data-test='rollover-type' className="p-0 m-0"><b>Rollover Type</b>: {transaction?.RolloverType}</p>
              <p data-test='custodian-account-type' className="p-0 m-0"><b>Current Custodian Account Type</b>: {transaction?.CurrentAccountType}</p>
              <p data-test='custodian-account-number' className="p-0 m-0"><b>Account Number with Current Custodian</b>: {transaction?.ThirdPartyAccount}</p>
              <p data-test='funds-delivery-by' className="p-0 m-0"><b>Funds Delivery By</b>: {transaction?.FundsDeliveryBy}</p>
            </>
          }
          {transaction.FundsDeliveryBy === 'Check' &&
            <p data-test='check-number' className="p-0 m-0"><b>Check Number</b>: {transaction?.CheckNumber}</p>
          }
          {transaction.RecordType === 'Roth_Conversion_Cash' && 
            <p data-test='billing-total' className="p-0 m-0"><b>Billing Total</b>: {transaction?.TotalFee}</p>
          }
          {transaction.CreditOrDebit === 'Credit' &&
            <p data-test='funds-delivery-by' className="p-0 m-0"><b>Funds Delivery By</b>: {transaction?.FundsDeliveryBy}</p>
          }
        </IonCol>
      </IonRow>
      
      {transaction.Cusip?.Name && 
        <>
          <IonItem data-test='related-asset-title' color='tertiary'>
            Related Asset Information
          </IonItem>
          <IonRow class="p-1">
            <IonCol>
              <p data-test='asset-name' className="p-0 m-0"><b>Related Asset</b>: {transaction.Cusip?.Name + ' - ' + transaction.Cusip?.AssetName1}</p>
              {transaction.Units && transaction.Units > 0 ? <p data-test='asset-units' className="p-0 m-0"><b>Units</b>: {transaction.Units}</p> : ''}
              <p data-test='asset-description' className="p-0 m-0"><b>Asset Description</b>: {transaction.Cusip?.AssetDescription}</p>
              {transaction.PricePerUnit && transaction.PricePerUnit > 0 ? <p data-test='price-per-unit' className="p-0 m-0"><b>Price Per Unit: $</b>{transaction.PricePerUnit}</p> : ''}
            </IonCol>
          </IonRow>
        </>
      }
      
      {transaction.CreditOrDebit === 'Debit' && transaction.RecordType !== 'Roth_Conversion_Cash' &&
        <>
          <IonItem data-test='delivery-information-title' color='tertiary'>
            Delivery Information
          </IonItem>
          <IonRow class="p-1">
            <IonCol>
              <p data-test='delivery-method' className="p-0 m-0"><b>Delivery Method</b>: {transaction.DeliveryMethod === 'ACH' ? 'Direct Deposit' : transaction.DeliveryMethod}</p>
              {transaction.DateDue &&
                <p data-test='expected-process-date' className="p-0 m-0"><b>Expected Process Date</b>: {formatDateString(transaction.DateDue)}</p>
              }
              {(transaction.DeliveryMethod === 'ACH' || transaction.DeliveryMethod === 'Domestic Wire') &&
                <>
                  <p data-test='bank-name' className="p-0 m-0"><b>Bank Name</b>: {transaction.ReceivingBank}</p>
                  <p data-test='bank-routing-number' className="p-0 m-0"><b>Bank ABA/Routing</b>: {transaction.ReceivingABA}</p>
                  <p data-test='credit-name' className="p-0 m-0"><b>Name on Bank Account</b>: {transaction.CreditName}</p>
                  <p data-test='credit-account' className="p-0 m-0"><b>Bank Account Number</b>: {transaction.CreditAccount}</p>
                  {transaction.BankAccountType &&
                    <p data-test='bank-account-type' className="p-0 m-0"><b>Bank Account Type</b>: {transaction.BankAccountType}</p>
                  }
                  <p data-test='ach-wire-memo' className="p-0 m-0"><b>ACH/Wire Memo</b>: {transaction.FFCDetail}</p>
                </>
              }
              {transaction.DeliveryMethod?.includes('Check') && !transaction.DeliveryMethod?.includes('Hold') &&
                <p data-test='co' className="p-0 m-0"><b>CO</b>: {transaction.CO}</p>
              }
              {((transaction.DeliveryMethod?.includes('Check') && !transaction.DeliveryMethod?.includes('Hold')) || transaction.DeliveryMethod === 'Domestic Wire') &&
                <>
                  <p data-test='mailing-street' className="p-0 m-0"><b>Mailing Street</b>: {transaction.MailingStreet}</p>
                  <p data-test='mailing-city' className="p-0 m-0"><b>Mailing City</b>: {transaction.MailingCity}</p>
                  <p data-test='mailing-state' className="p-0 m-0"><b>Mailing State</b>: {transaction.MailingState}</p>
                  <p data-test='mailing-zip' className="p-0 m-0"><b>Mailing Zip</b>: {transaction.MailingZip}</p>                 
                </>
              }
              {transaction.DeliveryMethod?.includes('Check') &&
                <p data-test='check-wire-memo' className="p-0 m-0"><b>Check Memo</b>: {transaction.CheckWireMemo}</p>
              }
              {transaction.DeliveryMethod === 'International Wire' &&
                <>
                  <p data-test='swift-code' className="p-0 m-0"><b>SWIFT Code</b>: {transaction.SWIFTCode}</p>
                  <p data-test='int-bank-name' className="p-0 m-0"><b>Bank Name</b>: {transaction.IntBankName}</p>
                  <p data-test='corresponding-bank-name' className="p-0 m-0"><b>Corresponding Bank Name</b>: {transaction.ReceivingBank}</p>
                  <p data-test='corresponding-bank-routing-number' className="p-0 m-0"><b>Corresponding Bank Routing Number</b>: {transaction.ReceivingABA}</p>
                  <p data-test='beneficiary-name' className="p-0 m-0"><b>Beneficiary Name</b>: {transaction.IntBeneficiaryName}</p>
                  <p data-test='beneficiary-account-number' className="p-0 m-0"><b>Beneficiary Account Number</b>: {transaction.IntBeneficiaryAccountNumber}</p>
                </>
              }
            </IonCol>
          </IonRow>
        </>
      }
      {transaction.RecordType === 'Deposit_Contribution' &&
        <>
          <IonItem data-test='bank-information-title' color='tertiary'>
            Bank Information
          </IonItem>
          <IonRow class="p-1">
            <IonCol>
              <p data-test='bank-aba' className="p-0 m-0"><b>Bank ABA</b>: {transaction.ReceivingABA}</p>
              <p data-test='bank-name' className="p-0 m-0"><b>Bank Name</b>: {transaction.ReceivingBank}</p>
              <p data-test='credit-account-name' className="p-0 m-0"><b>Name on Bank Account</b>: {transaction.CreditName}</p>
              <p data-test='credit-account-number' className="p-0 m-0"><b>Bank Account Number</b>: {transaction.CreditAccount}</p>
            </IonCol>
          </IonRow>
        </>
      }
      {transaction.NextStep && !transaction.RecordType?.includes('Rollover') && !transaction.RecordType?.includes('Roth_Conversion') &&
        <>
          <IonItem data-test='transaction-history-title' color='tertiary'>
            Transaction History
          </IonItem>
          <IonRow class="p-1">
            <IonCol>
              {nextSteps.map((nextStep:string, index: number) => {
                if (nextStep && nextStep !== ')') {
                  return (
                    <p key={index} className="p-0 m-0">{nextStep}</p>
                  ) 
                }
                return null;
              })}
            </IonCol>
          </IonRow>
        </>
      }
      {transaction && transaction.Id && !transaction.BillingOverride && transaction.RecordType !== 'Deposit_Contribution' &&   
        <ProjectedBillingDetails objectId={transaction.Id} dependencies={transaction} sectionLabel='Billing Details'/>
      } 
      <IonRow>
        <CancelTransaction transaction={transaction}/>
        {!transaction.ClientApprovalPending &&
          <IonCol>
            <IonButton data-test='file-upload-button' onClick={() => setShowFileUploadModal(true)} >Upload File</IonButton> 
          </IonCol>
        }
      </IonRow>
      <FileUpload setShowFileUploadModal={setShowFileUploadModal} showFileUploadModal={showFileUploadModal} finishUpload={finishUpload} pendingTransactionName={transaction?.Name} />
      {(transaction.ClientApprovalPending) ? <form onSubmit={handleSubmit(onSubmit)}>
        {(errorMessage !== '' && !showSpinner) && (
          <IonItem class="mt-1" color="danger"><p className="white-color">{errorMessage}</p></IonItem>
        )}
        {showSpinner ? (<Spinner />) : (
          <PartyTypeConditionalAccess featureDescription='viewTransactionApproval'>
            <IonRow>
              <IonCol>
                <h2>Electronic Signature and Acknowledgement</h2>
                <IonRow>
                  {(reject === false) ? <IonCol>
                    <IonModal cssClass={'user-agreement-modal'} isOpen={showTermsOfService} onDidDismiss={() => setShowTermsOfService(false)} mode="ios">
                      <IonContent>
                        {transactionSpecificApprovalTerms(transaction.RecordType)}
                      </IonContent>
                      <IonButton onClick={() => setShowTermsOfService(false)}>Close</IonButton>
                    </IonModal>
                    {(transaction.RecordType === 'Roth_Conversion_Cash' || transaction.RecordType === 'Trading_Bank_Acct_Withdrawal' || transaction.RecordType?.includes('Sell')) &&
                      <>
                        <IonRow class="mt-2 mb-1">
                          <IonLabel>Full/Partial Withdrawal</IonLabel>
                        </IonRow> 
                        <IonRow class="w-100">
                        <Controller name='full_or_partial' control={control} data-test='full-or-partial' render={({ field: { value, onBlur, onChange } }) =>
                          <IonSelect class="w-100 gr-border p-1" name="full_or_partial" tabIndex={0} interface="action-sheet" mode="ios" onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                            <IonSelectOption value="Full">Full (Investment to be removed from Account)</IonSelectOption>
                            <IonSelectOption value="Partial">Partial (Investment to remain in Account)</IonSelectOption>
                          </IonSelect>
                        } rules={{
                          required: true,
                          validate: (value) => { return value !== null }
                          }} />
                        </IonRow>
                      </>
                    }
                    {transaction.RecordType === 'Roth_Conversion_Cash' &&
                      <>
                        <IonRow className='mt-2 ml-1 mr-1'>1) I acknowledge that prior to 2018, I could undo a conversion (recharacterize) up until October 15th of the following year. Beginning, on January 1, 2018, the new Tax Cuts and Jobs Act abolished the Roth IRA Recharacterization. The ability to monitor earnings/losses and then decide to "undo" the conversion is no longer allowed. Thus, once this conversion occurs, I understand that no recharacterization will be allowed.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>2) I certify that the information provided including my Social Security Number is true and correct to the best of my knowledge.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>3) I certify that no tax advice has been given to me by the Administrator or Custodian.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>4) This conversion is being made within 60 days after my receipt of funds from my traditional IRA plan or Employer Sponsored plan, if applicable</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>5) I hereby irrevocably elect, to treat this transaction as a conversion as permitted under the IRS Regulations.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>6) I acknowledge that the distribution and conversion transactions will be reported to the IRS.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>7) I acknowledge that I am responsible for the record-keeping of the Roth IRA information.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>8) I expressly assume the responsibility for any adverse consequences which may arise from this conversion request and I agree that the Administrator and/or Custodian shall in no way be responsible for those consequences.</IonRow>
                        <IonRow className='mt-1 ml-1 mr-1'>9) I hereby release the Administrator, Office, and/or Custodian from any claim for damages on account of the failure of this transaction to qualify as a valid conversion.</IonRow>
                      </>
                    }
                    <IonItem lines="none" class="mb-1" data-test='agree-to-terms-elements'>
                      <Controller name='agree_to_terms' control={control} data-test='agree-to-terms-checkbox' render={({ field: { value, name, onChange } }) =>
                        <IonCheckbox color="primary" slot="start" name={name} tabIndex={0} checked={value} onIonChange={(event) => {
                          onChange(event.detail.checked)
                        }} />
                      } rules={{
                        required: false, validate: (value) => {
                          return value;
                        }
                      }} />
                      <IonLabel class="agree-text">
                        {transaction.RecordType === 'Roth_Conversion_Cash' ? <>
                          By checking this box and clicking "Approve", you affirm that you have fully read, acknowledge, and agree to the above statement and these <IonRouterLink onClick={() => setShowTermsOfService(true)} className="underline cursor-pointer">Terms of Service</IonRouterLink>.
                        </> : <>
                          By clicking “Approve”, you affirm that you have fully read, acknowledge, and agree to the following <IonRouterLink onClick={() => setShowTermsOfService(true)} className="underline cursor-pointer">statements</IonRouterLink>.
                        </>}
                      </IonLabel>
                    </IonItem>
                    <IonButton type="submit" disabled={disableApproveButton(fullOrPartial)} class="ion-float-left" size="large" data-test='approve-button'>Approve</IonButton>
                    <IonButton disabled={agreeToTerms ? true : false} onClick={() => setReject(true)} class="ion-float-left" size="large" color="light" data-test='reject-button'>Reject</IonButton>
                  </IonCol> : ''}
                  {(reject === true && !agreeToTerms) &&
                    <IonCol class="mt-2" size="12" data-test='rejection-reason-elements'>
                      <IonLabel>Reason for rejection</IonLabel>
                    <Controller name='rejection_reason' control={control} render={({ field: { value, onBlur, onChange } }) =>
                        <IonTextarea onIonChange={onChange} onIonBlur={onBlur} value={value} maxlength={200} class="ion-text-left gr-border mb-2"></IonTextarea>
                      } rules={{ required: true }} />
                      {errors['rejection_reason'] && <IonItem class="mt-1 mb-2" color="danger"><p className="white-color">Please enter the reason for rejection.</p></IonItem>}
                      <IonButton type="submit" class="ion-float-left" size="large">Submit</IonButton>
                      <IonButton onClick={() => setReject(false)} class="ion-float-left" size="large" color="light">Cancel</IonButton>
                    </IonCol>}
                </IonRow>
              </IonCol>
            </IonRow>
          </PartyTypeConditionalAccess>
        )}
      </form> : ''}
    </>
  );
};

export default PendingTransaction;