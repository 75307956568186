import React, { useEffect, useState } from 'react'
import { IonRow, IonLabel, IonSelect, IonSelectOption, IonItem, IonCol, IonInput } from '@ionic/react';
import { Controller, useFormContext } from 'react-hook-form';

export type TaxWithholdingFields = {
    electTaxWithholding: taxWithholdingType,
    taxWithholdingPercentage?: string,
}

export type taxWithholdingType = 'taxWithholding' | 'noTaxWithholding'  | 'notSet'
export type taxWithholdingOption = {value: taxWithholdingType, description: string}

export const taxWithholdingOptions: taxWithholdingOption[] = [
    {value: 'noTaxWithholding', description: 'I elect NOT to have Federal Income Tax withheld.'},
    {value: 'taxWithholding', description: 'I ELECT to have Federal Income Tax withheld in the amount indicated.'},
]

const TaxWithholdingFields: React.FC = () => {

    const [showWithholdingPercentage, setShowWithholdingPercentage] = useState<boolean>(false);

    const { control, formState: {errors}, getValues, setValue} = useFormContext<TaxWithholdingFields>()

    const notifyTaxWithholdingChange = (changeEvent: CustomEvent<any>, onChange) => {
        onChange(changeEvent.detail.value)
        setValue('electTaxWithholding', changeEvent?.detail?.value || 'notSet')
        setShowWithholdingPercentage(getValues('electTaxWithholding') === 'taxWithholding')
    }    
    useEffect(() => {
        setShowWithholdingPercentage(getValues('electTaxWithholding') === 'taxWithholding')
    }, [])

    return (
        <>
           <IonRow class="mt-2 mb-1">
                <IonLabel>Federal Withholding Election</IonLabel>
            </IonRow>
            <IonRow class="w-100">
                <Controller data-test='tax-withholding-controller' name='electTaxWithholding' control={control} render={({ field: { value, name, onChange } }) => {
                    return <IonSelect data-testid='tax-withholding-select' class="w-100 gr-border p-1" interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" onIonChange={event => notifyTaxWithholdingChange(event, onChange)} name={name} value={value}>
                        {taxWithholdingOptions?.map((option) => (<IonSelectOption key={option.value} value={option.value}>{option.description}</IonSelectOption>))}
                    </IonSelect>
                }
                } rules={{
                    required: true,
                    validate: {
                        validOptionSelected: selected => selected === 'notSet' ? 'Please select if taxes should be withheld as part of the distribution.' : undefined
                    }
                }} />
            </IonRow>
            {errors['electTaxWithholding'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['electTaxWithholding']?.message}</p></IonItem>}

            {showWithholdingPercentage && (
                <>
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Tax Withholding Percentage</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                            <IonItem class="gr-border" lines="none">
                                <Controller data-test='tax-percentage-controller' name='taxWithholdingPercentage' control={control} render={({ field: { value, name, onChange } }) => 
                                    (<IonInput data-testid='tax-percentage-input' class="ion-text-right mr-1" name={name} value={value} onIonChange={event => onChange(event.detail.value)}/>)}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Please enter the tax withholding percentage"
                                        },
                                        validate: {
                                            notGreaterThan100: (value) => (value && +value <= 100) ? undefined : 'Federal Tax Withholding must not be greater than 100% of the Total Distribution Amount',
                                            greaterThan10: (value) => (value && (+value >= 10)) ? undefined : 'Federal Tax Withholding must be 10% or greater of the Total Distribution Amount',
                                        }
                                    }} /> %
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {errors['taxWithholdingPercentage'] && <IonItem data-testid='tax-percentage-error' class="mt-1" color="danger"><p className="white-color">{errors['taxWithholdingPercentage']?.message}</p></IonItem>}
                </>)}

        </>
    )
}

export default TaxWithholdingFields