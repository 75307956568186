import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormProvider, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonInput,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepTwo from '../../../images/step-two.svg';
import ForwardDatePicker, {defaultValue as defaultProcessDate} from '../../ForwardDatePicker';
import progressBarSavedPayeeReviewDeselected from '../../../images/saved-payee-review-deselected.svg';
import progressBarStepsAfterTwoDeselected from '../../../images/steps-after-two-deselected.svg';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isPhone, amountRegex } from '../../../helpers/Utils';
import DateFnsUtils from '@date-io/date-fns';
import InputMask from 'react-input-mask';
import { NEW_PAYEE_ID } from '../BillPayWizard';
import FileUpload, { uploadCallBackInput } from '../../helperComponents/FileUpload'
import { InputComponents } from '../BillPayTypes'
import TransactionScheduleFields from '../../TransactionScheduleFields'

export interface StepTwo extends InputComponents {
  savedFiles: SFFile[],
  savedPayees: Partial<SavedPayee>[],
  isFundManagerFull: Boolean
}

const BillPayStepTwo: React.FC<StepTwo> = ({ formRef, savedFields, submitFields, savedPayees, savedFiles, preselectedFields, isFundManagerFull }) => {
  const blankPayee: SavedPayee = {
    payeeId: NEW_PAYEE_ID,
    name: '',
    payableTo: '',
    deliveryMethod: '',
    mailingStreet: '',
    mailingCity: '',
    mailingState: '',
    mailingZip: '',
    bankName: '',
    routingNumber: '',
    creditAccountName: '',
    creditAccountNumber: '',
    creditAccountType: '',
    paymentMemo: '',
    billPaymentType: '', 
    expenseType: '',
    CO: '',
  }
  
  const [files, setFiles] = useState<SFFile[]>(savedFiles)
  const [deliveryMethod, setDeliveryMethod] = useState<string>('')
  
  const getDefaultValues = () => {
    const payeeId = savedFields?.payeeId || preselectedFields?.payeeId || ''
    const SelectedPayeeFields: Partial<SavedPayee> | undefined = payeeId ? savedPayees.find(payee => payee.payeeId === payeeId) : undefined
    return {
      payeeId,
      amount: savedFields?.amount || null,
      paymentMemo: savedFields?.paymentMemo || SelectedPayeeFields?.paymentMemo || '',
      processDate: savedFields?.processDate || defaultProcessDate,
      payableTo: savedFields?.payableTo || SelectedPayeeFields?.payableTo || '',
      taxYear: savedFields?.taxYear || '',
      fullOrPartial: savedFields?.fullOrPartial || '',
      frequency: savedFields?.frequency || 'One-Time',
      recurringTransactionData: {
        schedule: savedFields?.recurringTransactionData?.schedule || '',
        duration: savedFields?.recurringTransactionData?.duration || '',
        endDate: savedFields?.recurringTransactionData?.endDate || undefined
      }
    }
  }

  type SelectPayeesFields = BillPaymentWizard.SelectPayeesFields

const useFormMethods = useForm<Partial<SelectPayeesFields>> ({
  mode: "onChange",
  defaultValues: getDefaultValues()
});
const {setValue, clearErrors, trigger, getValues, watch} = useFormMethods;

  const getSavedPayeeInfo = (id?: string) => {
    if(!id) {
      return
    }
  
    if(id === NEW_PAYEE_ID) {
      return blankPayee
    }

    let payeeChosen = savedPayees?.find(payee => payee.payeeId === id)
    if(id === savedFields?.payeeId && savedFields.billPaymentType) {
      return
    }

    return payeeChosen; 
  }

  const selectedPayee = watch('payeeId')

  const showProgressBarStep = () => {
    if (!selectedPayee) {
      return (!isPhone()) ? <img data-test="image-with-two-steps" src={progressBarStepTwo} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.25}></IonProgressBar>
    }

    if ((savedFields?.editPayee && selectedPayee === savedFields?.payeeId) || selectedPayee === NEW_PAYEE_ID) {
      return (!isPhone()) ? <img data-test="image-with-four-steps" src={progressBarStepsAfterTwoDeselected} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.25}></IonProgressBar>
    }

    return (!isPhone()) ? <img data-test="image-with-two-steps-review" src={progressBarSavedPayeeReviewDeselected} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.8}></IonProgressBar> 
  }

  const customActionSheetOptions = () => {
    let actionSheetOptions : any = { 
      animated: true, 
      mode: 'ios'
    }
    if (savedPayees.length > 5) {
      actionSheetOptions = {
        ...actionSheetOptions, 
        cssClass: 'long-select' 
      }
    }
    return actionSheetOptions;
  }

  //check if payee is of a certain type
  const realEstateTaxPaymentCheck = () => {
     let showRealEstateInputs = false;
     if (selectedPayee !== NEW_PAYEE_ID && !savedFields?.editPayee) {
      
      const payeeChosen = savedPayees.filter(payee => payee.payeeId === selectedPayee)[0];

      showRealEstateInputs =  (payeeChosen && payeeChosen.billPaymentType === 'Real Estate Tax Payment');
     }
     return showRealEstateInputs; 
  }

  const deleteFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files])
  }

  const onFileChange = async(fileUploadResponse : uploadCallBackInput) => {
    useFormMethods.clearErrors('amount');
   if(fileUploadResponse.status === 'success'){
     setFiles(fileUploadResponse.files)
   }else{
    setFiles([]);
   }
  }

  useEffect(() => {
    if(selectedPayee) {
      const SelectedPayeeFields = savedPayees.find(payee => payee.payeeId === selectedPayee)
      setDeliveryMethod(SelectedPayeeFields?.deliveryMethod || '')
    }
  }, [])

  const notifyPayeeChanged = (onChange, event) => {
    const payeeId = event.detail.value
    onChange(payeeId)
    const selectedPayee = savedPayees.find(payee => payee.payeeId === payeeId)
    setDeliveryMethod(selectedPayee?.deliveryMethod || '')

    if(selectedPayee?.payeeId === NEW_PAYEE_ID) {
      setValue('paymentMemo', '');
      setValue('payableTo', '');
    }
    else {
      setValue('paymentMemo', selectedPayee?.paymentMemo || '')
      setValue('payableTo', selectedPayee?.payableTo || '')
      setDeliveryMethod(selectedPayee?.deliveryMethod || '')
    }

    if (selectedPayee?.billPaymentType === 'Real Estate Tax Payment') {
      setValue('frequency', 'One-Time')
      setValue('recurringTransactionData.schedule', '')
      setValue('recurringTransactionData.duration', '')
      setValue('recurringTransactionData.endDate', undefined)
    }

    clearErrors('amount')
    const inputAmount = getValues('amount') 
    if(inputAmount) {
      trigger('amount')
    }
  }

   const removeUndefined = (obj: any)=>JSON.parse(JSON.stringify(obj || {}))

   const onValid: SubmitHandler<Partial<SelectPayeesFields>> = (formFields)=>{
    let editPayee = (savedFields?.editPayee && (formFields.payeeId === savedFields?.payeeId) && (formFields.payeeId !== NEW_PAYEE_ID)) || false
    let savedPayeeInfo = removeUndefined(getSavedPayeeInfo(formFields.payeeId))
    let formFieldsCleaned = removeUndefined(formFields)
    return submitFields({status: 'ok', formFields: {...savedPayeeInfo, ...formFieldsCleaned, editPayee}, altPayload: {type: 'files', files}})
  }

  const onInvalid: SubmitErrorHandler<Partial<SelectPayeesFields>> = (errors) => {
    let formFields = useFormMethods.getValues()
    submitFields({status: 'error', formFields: formFields, errors, altPayload: {type: 'files', files}})
  }

  const PayeeSelectOptions:React.FC<{payees : Partial<SavedPayee>[]}> = ({payees}) => {
    
    const getPayeeDisplayName = (payee : Partial<SavedPayee>) => {
      if (payee.payableTo && payee.bankName) {
        return payee.payableTo + ' - ' + payee.bankName
      }
    
      return payee.payableTo || payee.bankName || payee.creditAccountName || 'No name listed for this for this payee'
    }

    payees.sort((a, b) => (getPayeeDisplayName(a).toLowerCase().localeCompare(getPayeeDisplayName(b).toLowerCase())))
    
    return (
      <>
        {payees.map((payee : Partial<SavedPayee>) => (
          <IonSelectOption data-testid={'payee-select-option-' + payee.payeeId} key={payee.payeeId} value={payee.payeeId}>
            {getPayeeDisplayName(payee)}  
          </IonSelectOption>))
        }
        <IonSelectOption data-testid={'payee-select-option-' + NEW_PAYEE_ID} key={NEW_PAYEE_ID} value={NEW_PAYEE_ID}>Add Payee</IonSelectOption>
      </>
    )
  }

  return (
    <>
      {
      <FormProvider {...useFormMethods}>
        <form ref={formRef} onSubmit={useFormMethods.handleSubmit(onValid, onInvalid)} data-test="bill-pay-step-two" data-testid="bill-pay-step-two">
          <IonRow class={(!isPhone()) ? "divider" : ''}>
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
              {showProgressBarStep()}
            </IonCol>
          </IonRow> 
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <IonRow class="w-100">
                <IonCol class="w-100">
                  <IonRow class="mt-2 mb-1">
                    <IonLabel>Who would you like to pay?</IonLabel>
                  </IonRow>
                  <IonRow class="w-100">
                    <Controller name='payeeId' control={useFormMethods.control} render={({ field: { value, onChange, name } }) =>
                      <IonSelect data-testid='select-payee' class="w-100 gr-border p-1" name={name} tabIndex={0} interface='action-sheet' onIonChange={event => notifyPayeeChanged(onChange, event)} value={value} interfaceOptions={customActionSheetOptions()}>
                        <PayeeSelectOptions payees={savedPayees}/>
                      </IonSelect>
                    } rules={{
                      required: true,
                      validate: (value) => { return value !== null }
                      }} />
                  </IonRow>
                  {useFormMethods.formState.errors['payeeId'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please select a payee.</p></IonItem>}

                  {(selectedPayee) &&
                    <>
                      {(selectedPayee === NEW_PAYEE_ID || savedFields?.editPayee) &&
                        <>
                          <IonRow class="mt-2 mb-1">
                            <IonLabel>Payable To</IonLabel>
                          </IonRow>
                          <IonRow class="w-100">
                        <Controller name='payableTo' control={useFormMethods.control} render={({ field: { value, onBlur, onChange, name } }) =>
                          <IonInput class="ion-text-left gr-border" name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={30}/>
                        } rules={{
                          required: "Please add the name for who you'd like to pay."
                          }} />
                          </IonRow>
                          {useFormMethods?.formState.errors?.payableTo?.message && <IonItem class="mt-1" color="danger"><p className="white-color">{useFormMethods?.formState.errors?.payableTo?.message}</p></IonItem>}
                        </>
                      }

                      <IonRow class="mt-2 mb-1">
                        <IonLabel>Amount (USD)</IonLabel>
                      </IonRow>
                      <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                          <Controller name='amount' control={useFormMethods.control} render={({ field: { value, onBlur, name, onChange } }) =>
                            <IonItem class="gr-border" lines="none">$<IonInput data-testid='amount' class="ion-text-left ml-1" name={name} type="number" value={value} onIonBlur={onBlur} onIonChange={(event=>{onChange(event.detail.value)})}/></IonItem>
                          } rules={{
                            required: 'Amount is required',
                            pattern: {
                              value: amountRegex,
                              message: 'Invalid Amount'
                            },
                            validate: {
                              greaterThanZero: (value) => ((value && (+value > 0)) ? undefined : 'Amount value must be greater than 0'),
                              isANumber: (value:unknown) => {
                                const valueConverted = typeof value === 'string' ? parseFloat(value) : typeof value === 'number' ? value : null
                                return (valueConverted === null || isNaN(valueConverted) || !isFinite(valueConverted)) ? 'Value must be a valid number': undefined
                              },
                              fileUploadNeed: (value) => (((value && +value >= 5000) && files.length === 0) ? 'File upload needed' : undefined),
                              amountLessThanAccountBalance: (value) => {
                                let accountBalance: number = savedFields?.retirementAccountBalance || 0
                                const accountBalanceIsValid = typeof accountBalance === 'number' && !isNaN(accountBalance) && isFinite(accountBalance)
                                if (value && accountBalanceIsValid && +value > accountBalance) {
                                    let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
                                    if (accountBalance > 0) {
                                        return `Amount may not be greater than the account's available cash: ${currencyFormatter.format(accountBalance)}`
                                    }
                                    return `Amount may not be greater than the account's available cash.`
                                }
                              },
                              amountValidForNonWire: (value) => (value && (+value > 100000) && !(deliveryMethod === '' || deliveryMethod.toLowerCase().includes('wire'))) ? 'Amounts over $100,000 are only possible by wire transfer.' : undefined,
                            }
                              
                          }} />
                        </IonCol>
                      </IonRow>
                      {useFormMethods.formState.errors['amount'] && useFormMethods.formState.errors['amount']?.type !== 'fileUploadNeed' && <IonItem data-testid='amount-error' class="mt-1" color="danger"><p className="white-color">{useFormMethods.formState.errors['amount']?.message}</p></IonItem>}
                      <IonRow class="mt-2 mb-1">
                        <IonLabel>Supporting Document(s)</IonLabel>
                      </IonRow>
                      <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0" sizeXs="12" sizeSm="10" sizeMd="8" sizeLg="6" sizeXl="4">
                          <FileUpload files={files} filesUploadedCallback={onFileChange} deleteFile={deleteFile}/>
                        </IonCol>
                      </IonRow>
                      {useFormMethods.formState.errors?.amount?.type === 'fileUploadNeed' &&  (
                        <IonItem class="mt-1" color="danger"><p className="white-color">{useFormMethods.formState.errors.amount.message}</p></IonItem>
                      )}
                      
                      {realEstateTaxPaymentCheck() && (
                        <>
                          <IonRow class="mt-2 mb-1">
                            <IonLabel>Tax Year</IonLabel>
                          </IonRow>
                          <IonRow>
                            <Controller name="taxYear" control={useFormMethods.control} render={({ field: { name, value, onChange }})=>
                              <InputMask mask='9999' className="input-mask mt-0 gr-border" type="text" name={name} value={value} onChange={onChange}/>
                            } rules={{
                              required: true,
                              validate: (value) => { return value !== null },
                              pattern: /^(19|20)\d{2}$/
                            }} />
                          </IonRow>
                          {useFormMethods.formState.errors['taxYear'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter the tax year.</p></IonItem>}
                          <IonRow class="mt-2 mb-1">
                            <IonLabel>Full/Partial Payment</IonLabel>
                          </IonRow>
                          <IonRow class="w-100">
                            <Controller name='fullOrPartial' control={useFormMethods.control} render={({ field: { value, onBlur, onChange, name } }) =>
                              <IonSelect class="w-100 gr-border p-1" name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                                <IonSelectOption>Full Payment</IonSelectOption>
                                <IonSelectOption>Partial Payment</IonSelectOption>
                              </IonSelect>
                          } rules={{
                            required: true
                            }} />
                          </IonRow>
                          {useFormMethods.formState.errors['fullOrPartial'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please select whether this is a full or partial payment.</p></IonItem>}
                        </>
                      )}
                      
                      {(selectedPayee !== NEW_PAYEE_ID && !savedFields?.editPayee) && //do we really want to hide this on edit?
                        <>
                          <IonRow class="mt-2 mb-1">
                              <IonLabel>Payment Memo (This memo will be provided to the payee to help recipient properly credit your payment. Examples include an invoice number, account number or reference number. Maximum 30 characters.)</IonLabel>
                          </IonRow>
                          <IonRow>
                            <Controller name='paymentMemo' control={useFormMethods.control} render={({ field: { value, onBlur, onChange, name } }) =>
                              <IonInput class="ion-text-left mt-0 gr-border pl-1 pr-1" type="text" name={name} maxlength={30} value={value} onIonBlur={onBlur} onIonChange={onChange}/>
                            } rules={{
                              required: true
                          }} />
                          </IonRow>
                          {useFormMethods.formState.errors['paymentMemo'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please add a payment memo to help the recipient properly credit your payment.</p></IonItem>}
                        </>}

                      {realEstateTaxPaymentCheck() || isFundManagerFull ?
                        <>
                          <IonRow class='mt-2 mb-1'>
                            <IonLabel>Process Date</IonLabel>
                          </IonRow>
                          <ForwardDatePicker />
                        </>
                        : <TransactionScheduleFields />
                      }
                      
                    </>}
                </IonCol>
              </IonRow>
            </MuiPickersUtilsProvider>
          </form>
        </FormProvider>
      }
    </>
  );
};


export default BillPayStepTwo;
