import React, { useState, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import { useForm, Controller, useWatch, FieldErrors } from 'react-hook-form';
import progressBarStepOne from '../../../images/step-one.svg';
import {AccountIonSelect} from '../../SelectAccountBar';
import calloutHelpers from '../../../helpers/calloutHelpers';
import axios from 'axios';
import Spinner from '../../Spinner';
import { isPhone } from '../../../helpers/Utils';
import { InputComponents } from '../BillPayTypes'
import { checkPartyTypeAccessBuilder } from '../../PartyTypeConditionalAccess';
import { getAvailableCashBalance } from '../../../helpers/Utils';

export interface StepOne extends InputComponents {
  availableAccounts?: RetirementAccount[],
  isFundManagerFull: Boolean
}

interface RelatedAssetOption {
  Id: string,
  Label: string
}

const BillPayStepOne: React.FC<StepOne> = ({
  formRef, availableAccounts, savedFields, setErrorMessage, clearErrorMessage, submitFields, isFundManagerFull
}) => {
  type SelectAccountFields = BillPaymentWizard.SelectAccountFields
  const [assetOptions, setAssetOptions] = useState<RelatedAssetOption[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const CASH00002_CUSIP_ID = 'a080M00000nBIcWQAW'

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm<SelectAccountFields>({
    mode: 'onChange',
    defaultValues: {
      retirementAccount: savedFields?.retirementAccount || '',
      assetId: savedFields?.assetId || '',
      assetLabel: savedFields?.assetLabel || '',
      retirementAccountBalance: savedFields?.retirementAccountBalance || 0,
    }
  });

  const selectedAccountId = useWatch({name: 'retirementAccount', control: control, defaultValue: savedFields?.retirementAccount || ''})

  useEffect(() => {
    if (!selectedAccountId) return; 
    let source = axios.CancelToken.source();
    const getAssetOptions = async (token: any) => {
      setShowSpinner(true);
      try
      {
        if (!selectedAccountId) return;
        const assetOptionsResult = await calloutHelpers.getAccountHoldingsForBillPay(selectedAccountId, token);
        if (!isFundManagerFull) {
          setAssetOptions([...assetOptionsResult.data.relatedAssetOptions]);
        } else {
          setAssetOptions([{Id: CASH00002_CUSIP_ID, Label: 'General Account Expense'}, ...assetOptionsResult.data.relatedAssetOptions]);
        }
        setShowSpinner(false);
      } catch (err) {
        if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
          setAssetOptions([]);
          setErrorMessage('Error retrieving asset options');
          clearErrorMessage();
        }
        setShowSpinner(false);
      }
    };
    getAssetOptions(source.token);
    return(() => {
      source.cancel('Cancelling in cleanup');
    });
  }, [selectedAccountId, clearErrorMessage, setErrorMessage]);

  const onValid = (formFields: SelectAccountFields)=>{
    if(!formFields.assetId){
      return; //hack to deal with hitting next while asset options are loading
    }

    let selectedAsset = assetOptions.filter(asset => (asset.Id === formFields.assetId))[0];
    formFields.assetLabel = selectedAsset?.Label

    submitFields({status: 'ok', formFields})
  }
  
  const onInvalid = (errors: FieldErrors) => {    
    let formFields = getValues()
    let selectedAsset = assetOptions.filter(asset => (asset.Id === formFields.assetId))[0];
    formFields.assetLabel = selectedAsset?.Label
    submitFields({status: 'error', formFields: formFields, errors})
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <IonRow class="divider">
        <h1 className="ion-text-left">{!isFundManagerFull ? 'Bill Pay Instructions' : 'Pay an Expense Instruction'}:</h1>
        <ul>
          {!isFundManagerFull
            ? <li>Using this system, you can request to pay expenses related to your IRA-owned assets. Commonly allowed expenses include tax bills, insurance bills, homeowner dues and utility bills.</li>
            : <li>Using this system, you can request to pay expenses related to the accounts you have access to.</li>
          }
          <li>The Process Date must be at least one business day in the future from today. Equity Trust reserves the right to verify any and all client and or/payee information before funds are sent out for any transaction.</li>
        </ul> 
      </IonRow>
      {(<form ref={formRef} data-test="bill-pay-step-one-form" onSubmit={handleSubmit(onValid, onInvalid)}>
          <IonRow>
            <IonCol size="12">
              <IonRow>
                <IonCol>
                  <IonRow class="mt-2 mb-1">
                    <IonLabel>Select Account</IonLabel>
                  </IonRow>
                  <IonRow class="w-100 gr-border p-0 m-0">
                    <IonCol class="p-0 m-0" size="12">
                      <Controller name='retirementAccount' control={control} render={({ field: { value, onChange, name }})=><AccountIonSelect accountID={value} name={name} availableAccounts={availableAccounts || []} accountFilter={checkPartyTypeAccessBuilder('createBillPayment')} onChangeHandler={(event)=>{
                        setValue('assetId', '')
                        setValue('retirementAccount', event.detail.value, {shouldValidate: true})
                        const selectedAccount = availableAccounts?.find(account => account.id === event.detail.value)
                        let availableCashBalance = getAvailableCashBalance(selectedAccount)
                        setValue('retirementAccountBalance', availableCashBalance)
                      }}/>} 
                      rules={{
                        required: {
                          value: true,
                          message: 'Plese select an account.'
                        }
                      }}/>
                    </IonCol>
                  </IonRow>
                    {errors['retirementAccount'] && <IonItem class="mt-1" color="danger">{errors['retirementAccount'].message}<p className="white-color"></p></IonItem>}
                  { selectedAccountId && <>
                  <IonRow class="mt-2 mb-1">
                    <IonLabel>Related Asset</IonLabel>
                  </IonRow>
                  <IonRow class="w-100">
                      <Controller data-test='select-asset-controller' name='assetId' control={control} render={({ field: { value, onBlur, onChange, name }}) => {
                        return showSpinner ? (<Spinner />) : <IonSelect class="w-100 gr-border p-1" interface="action-sheet" tabIndex={0} mode="ios" name={name} onIonChange={onChange} onIonBlur={onBlur} value={value}>
                          {assetOptions.map((asset) => (
                            <IonSelectOption key={asset.Id} value={asset.Id}>{asset.Label}</IonSelectOption>
                            ))}
                        </IonSelect>
                      }
                    } rules={{required: {
                      value: true,
                      message:'Please select a related asset.'
                    }}} />
                  </IonRow>
                  {assetOptions?.length > 0 && errors['assetId'] && <IonItem class="mt-1" color="danger" className="white-color">{errors['assetId'].message}<p className="white-color"></p></IonItem>}
                  </>
                  }
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </form>)
      }
    </>
  );
};
export default BillPayStepOne;