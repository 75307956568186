import React, { useState, useEffect, useReducer } from "react"
import { IonRow, IonCol, IonLoading, IonToast } from "@ionic/react"
import CreditCardForm from "./CreditCardForm"
import { connect } from "react-redux"
import {useHistory} from "react-router-dom"
import calloutHelpers from "../../helpers/calloutHelpers"
import { CreditCardHeader } from "./CreditCardHeader"
import ContainerComponent from "../ContainerComponent"
import PartyTypeConditionalAccess from '../PartyTypeConditionalAccess';

enum IResponseCode {
  SUCCESS = 200,
  SERVER = 500,
  WARNING = 409,
}

interface IProps {
  selectedAccount?: RetirementAccount
}

enum IActionsTypes{
  setIsShowToast = "setIsShowToast",
  setIsLoading = "setIsLoading",
  setStatusCode = "setStatusCode",
  setSuccessMessage = "setSuccessMessage"
}
interface IReducerAction{
  type: string;
  payload: boolean | number | string
}

const reducer = (state, action:IReducerAction) =>{
  const { type, payload } = action;
  switch (type) {
    case 'setIsShowToast':
      return {...state, isShowToast: payload}
    case 'setIsLoading':
      return {...state, isLoading : payload}
    case 'setStatusCode':
      return {...state, statusCode : payload}
    case 'setSuccessMessage':
      return {...state, successMessage : payload}
    default:
      throw new Error()
    }
  }
  
const initialStates = { isShowToast: false, isLoading: false, statusCode: null, successMessage: "" }
const CreditCardPayment: React.FC<IProps> = ({ selectedAccount }) => {
  const initialState = {
    amount: null,
    number: "",
    expiry: "",
    zipCode: "",
    openChargesLeft: null,
    saveCardForFutureUse: false
  };

  const initialStatus = {
    isCardAdded: false,
    isEditing: false
  }

  const [cardStatus, setCardStatus] = useState(initialStatus)
  const [openChargesLeft, setOpenChargesLeft] = useState<number | null>()
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [state, setState] = useState<CreditCardPayment.CardFormInputs>(initialState)
  const [reducerState, dispatch] = useReducer(reducer, initialStates)
  const history = useHistory()
  const { isLoading, isShowToast, statusCode, successMessage } = reducerState

  useEffect(() => {
    setShowLoading(true)
    setOpenChargesLeft(null)
    if (selectedAccount?.id) {
      calloutHelpers
        .getRetirementAccountWithCreditCard(selectedAccount?.id)
        .then((result) => {
          if (result) {
            setOpenChargesLeft(result?.data?.openChargesLeft)
            setState({
              ...initialState,
              openChargesLeft: result?.data?.openChargesLeft || null,
              amount: result?.data?.openChargesLeft || null,
            })
            setShowLoading(false)
          }
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }, [selectedAccount?.id])
  
  const handleSubmit = async (obj) => {
    dispatch({type:IActionsTypes.setIsLoading, payload: true})
    setState({
      ...obj,
    });
    try {
      const response = await calloutHelpers.postCreditCardPayment({
        ...obj,
        accountId: selectedAccount?.id,
      });
      if (response) {
        dispatch({type:IActionsTypes.setSuccessMessage, payload: response?.data})
        dispatch({type:IActionsTypes.setStatusCode, payload: response.status})
        dispatch({type:IActionsTypes.setIsShowToast, payload: true})
        dispatch({type:IActionsTypes.setIsLoading, payload: false})
        history.push("/billing", {
          Toast: { message: 'Credit card payment made successfully', color: 'success'}
        })
      }
    } catch (error: any) {
      dispatch({type:IActionsTypes.setStatusCode, payload: error?.response.status})
      dispatch({type:IActionsTypes.setSuccessMessage, payload: error?.response?.data?.errorMessage || 'An error occurred'})
      dispatch({type:IActionsTypes.setIsLoading, payload: false})
      dispatch({type:IActionsTypes.setIsShowToast, payload: true})
    }
  }

  const handleRemove = () => {
    setState(initialState)
    setCardStatus(initialStatus)
  }

  const RenderCreditCardForm: React.FC = () => {
    if(cardStatus.isCardAdded && !cardStatus.isEditing){
      return <CreditCardHeader handleRemove={handleRemove} state={state} setCardStatus={setCardStatus} cardStatus={cardStatus}/>
    }

    if((typeof openChargesLeft === 'number' || openChargesLeft !== null) && !showLoading) {
      return <CreditCardForm
        state={state}
        cardStatus={cardStatus}
        onCardSubmit={handleSubmit}
        openChargesLeft={openChargesLeft}
        isLoading={isLoading}
      />
    }

    return <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
   }

  return (
    <IonRow className="container">
      <IonToast
        isOpen={isShowToast}
        color={
          statusCode === IResponseCode.SUCCESS
            ? "success" : "danger"
        }
        onDidDismiss={() => dispatch({type:IActionsTypes.setIsShowToast, payload: false})}
        position={"top"}
        message={successMessage}
        duration={3000}
      />
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        <ContainerComponent
          title="Make a Payment"
          content={
            <>
              {selectedAccount?.status !== 'Closed' &&
                <PartyTypeConditionalAccess featureDescription='updatePaymentMethod' noAccessRender={'You do not access to make a payment for this account.'}>
                  <RenderCreditCardForm />
                </PartyTypeConditionalAccess> 
              }
              {selectedAccount?.status === 'Closed' &&
                <IonRow class="mt-1 mb-1 ml-1 mr-1">This account has been closed. Please select another account to make a payment.</IonRow>
              }
            </>
          }
        />
      </IonCol>
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount
  }
}

export default connect(mapStateToProps)(CreditCardPayment)
