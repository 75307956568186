import React, { useEffect, useState } from 'react';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonItem,
  IonSelect, 
  IonSelectOption
} from '@ionic/react';
import { Controller, useFormContext } from 'react-hook-form';
import calloutHelper from '../../helpers/calloutHelpers';
import StateSelector from '../StateSelector';

export const DefaultValues = (newValues: Partial<BankFields> = {}): BankFields => {
  return {
    routingNumber: newValues?.routingNumber || '',
    bankName: newValues.bankName || '',
    creditAccountName: newValues.creditAccountName || '',
    creditAccountNumber: newValues.creditAccountNumber || '',
    creditAccountType: newValues.creditAccountType || ''
  }
}

const BankFields: React.FC<{bankFieldsChanged?: (hasChanged:boolean)=>void, hideCreditAccountType?: boolean, disableEditingAccountName?: boolean, deliveryMethod: string, isFundManagerFull?: Boolean}> = ({bankFieldsChanged, hideCreditAccountType, disableEditingAccountName, deliveryMethod, isFundManagerFull}) => {
  const isDirty = React.useRef(false)
  const {control, formState: { errors }, setValue, setError, clearErrors} = useFormContext<BankFields>();

  const fieldsChanged = ()=>{
    !isDirty.current && bankFieldsChanged && bankFieldsChanged(true);
    isDirty.current = true;
  }

  const validateRoutingNumber = (event: any) => {
    fieldsChanged()
    let routingNumber = event.detail.value; 
    if(routingNumber && routingNumber.length <=9){
      setValue('routingNumber',routingNumber)
    }

    if(routingNumber && routingNumber.length !== 9){
      setValue('bankName', '');
    }
    if (routingNumber && routingNumber.length === 9) {
      calloutHelper.checkRoutingNumber(routingNumber, deliveryMethod).then(result => {
        if (result.data.status === 'Valid') {
          setValue('bankName', result.data.bankName);
          clearErrors('routingNumber')
          clearErrors('bankName')
        }
        else if(result.data.status === 'Invalid') {
          let errorMessage = 'Routing numbers needs a valid delivery method.'
          if(deliveryMethod.includes('wire')) {
            errorMessage = 'The provided routing number cannot be used for wire transfers.'
          }
          else if(deliveryMethod.includes('direct')) {
            errorMessage = 'The provided routing number cannot be used for direct deposits.'
          }

          setError && setError('routingNumber', {type:'validate', message: errorMessage})
          setValue('bankName', '');
        }
        else {
          setError && setError('routingNumber', {type:'validate', message: 'Invalid routing number, try a different one.'})
          setValue('bankName', '');
        }
      }).catch(err => {
        setError && setError('routingNumber', {type:'validate', message: 'Failed to lookup routing number.'})
        setValue('bankName', '');
      })
    }
  }

  const resolveError = (error) => {
    if (!error) {
      return undefined
    }
    if (error.message) {
      return error.message
    }
    return 'Input Invalid'
  }

  const handleOnChange = (onChangeFunction: Function)=>{
    return (event)=>{
      onChangeFunction(event);
      fieldsChanged();
    }
  }

  const [showCreditAccountType, setShowCreditAccountType] = useState<boolean>(true)
  const [creditColumnSize, setCreditColumnSize] = useState<string>('4')
  useEffect(() => {
    if(hideCreditAccountType) {
      setShowCreditAccountType(false)
      setCreditColumnSize('6')
    }
    else {
      setShowCreditAccountType(true)
      setCreditColumnSize('4')
    }
  }, [hideCreditAccountType])

  return (
    <>
      <IonRow className="m-0 p-0">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6" className='pl-0'>
            <IonRow className="mt-2 mb-1">
              <IonLabel>Bank Routing Number</IonLabel>
            </IonRow>
            <IonRow className="m-0 p-0">
              <IonCol className="m-0 pt-1 pb-1 pl-0">
                <Controller name='routingNumber' control={control} render={({ field: { value, onBlur, name } }) =>
                  <IonInput data-testid='routing-number-input' className="ion-text-left gr-border pl-1 pr-1" name={name} type="number" onIonChange={(e) => {
                    validateRoutingNumber(e);
                  }} onIonBlur={onBlur} value={value} />
                } rules={{ required: {
                  value: true,
                  message: 'Please enter a routing number.'
                }
                }} />
              </IonCol>
            </IonRow>
              {(errors.routingNumber || errors.bankName) && <IonItem className="mt-1" color="danger">{resolveError(errors.routingNumber) || resolveError(errors.bankName)}<p className="white-color"></p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6" className='pr-0'>
            <IonRow className="mt-2 mb-1">
              <IonLabel>Bank Name</IonLabel>
            </IonRow>
            <IonRow className="m-0 p-0">
              <IonCol className="m-0 p-1 lt-gr-bg">
                <Controller name='bankName' control={control} render={({ field: { value, name }}) =>
                  <IonInput data-testid='bank-name-input' className="ion-text-left gr-border pr-1 pl-1" type="text" name={name} id="bankName" maxlength={100} disabled value={value} />
                } rules={{required: {
                  value: true,
                  message: 'A valid routing number is required.'
                }}}/>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow className="m-0 p-0">
        <IonCol sizeXs="12" sizeSm="12" sizeMd={creditColumnSize} sizeLg={creditColumnSize} sizeXl={creditColumnSize}>
            <IonRow className="mt-2 mb-1">
              <IonLabel>Name on Bank Account</IonLabel>
            </IonRow>
            <IonRow className="m-0 p-0">
              <IonCol className="m-0 p-0 ">
                <Controller name='creditAccountName' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                  <IonInput data-testid='account-name-input' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={handleOnChange(onChange)} onIonBlur={onBlur} value={value} readonly={disableEditingAccountName}/>
                } rules={{ required: {
                  message: 'Please enter name on bank account (first and last).',
                  value: true
                }, maxLength: {
                  message: 'Name on bank account cannot exceed 35 characters',
                  value: 35
                }
                }} />
              </IonCol>
            </IonRow>
              {errors.creditAccountName && <IonItem className="mt-1" color="danger"><p className="white-color">{resolveError(errors.creditAccountName)}</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd={creditColumnSize} sizeLg={creditColumnSize} sizeXl={creditColumnSize}>
            <IonRow className="mt-2 mb-1">
              <IonLabel>Bank Account Number</IonLabel>
            </IonRow>
            <IonRow className="m-0 p-0">
              <IonCol className="m-0 p-0 ">
                <Controller name='creditAccountNumber' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                  <IonInput data-testid='account-number-input' class="ion-text-left gr-border pl-1 pr-1" name={name} type="number" onIonChange={handleOnChange(onChange)} onIonBlur={onBlur} value={value} />
                } rules={{ required: {
                  message: 'Please enter bank account number.',
                  value: true
                }, maxLength: {
                  message: 'Bank account number cannot exceed 17 characters',
                  value: 17
                } }} />
              </IonCol>
            </IonRow>
              {errors.creditAccountNumber && <IonItem className="mt-1" color="danger"><p className="white-color">{resolveError(errors.creditAccountNumber)}</p></IonItem>}
          </IonCol>
        {showCreditAccountType && (
          <IonCol sizeXs="12" sizeSm="12" sizeMd={creditColumnSize} sizeLg={creditColumnSize} sizeXl={creditColumnSize}>
            <IonRow className="mt-2 mb-1">
              <IonLabel>Bank Account Type</IonLabel>
            </IonRow>
            <IonRow className="m-0 p-0">
              <IonCol className="m-0 p-0">
                <Controller name='creditAccountType' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                  <IonSelect data-testid='account-type-select' className="ion-text-left gr-border pl-1 pr-1" interface="action-sheet" name={name} tabIndex={0} onIonChange={handleOnChange(onChange)} onIonBlur={onBlur} value={value} mode="ios">
                    <IonSelectOption>Checking</IonSelectOption>
                    <IonSelectOption>Savings</IonSelectOption>
                  </IonSelect>
                } rules={{ required: true }} />
              </IonCol>
            </IonRow>
            {errors.creditAccountType && <IonItem className="mt-1" color="danger"><p className="white-color">Please enter bank account type.</p></IonItem>}
          </IonCol>)}
        {deliveryMethod === 'wireTransfer' &&
          <>
            <IonRow class='m-0 p-0 w-100'>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                <IonRow class="mt-2 mb-1">
                  <IonLabel>Mailing Street</IonLabel>
                </IonRow>
                <IonRow class="m-0 p-0">
                  <IonCol class="m-0 p-0">
                  <Controller name='mailingStreet' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                    <IonInput data-testid='mailing-street-input' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={33} onIonChange={onChange} onIonBlur={onBlur} value={value} />
                  } rules={{ required: true }} />
                  </IonCol>
                </IonRow>
                {errors.mailingStreet &&
                  <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing street {deliveryMethod ==='wireTransfer' && ('(PO Boxes not available with the selected delivery method)')}. </p></IonItem>}
              </IonCol>
            </IonRow>
            <IonRow class='m-0 p-0 w-100'>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                <IonRow class="mt-2 mb-1">
                  <IonLabel>Mailing City</IonLabel>
                </IonRow>
                <IonRow class="m-0 p-0">
                  <IonCol class="m-0 p-0">
                    <Controller name='mailingCity' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                      <IonInput data-testid='mailing-city-input' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={30} onIonChange={onChange} onIonBlur={onBlur} value={value} />
                    } rules={{ required: true }} />
                  </IonCol>
                </IonRow>
                  {errors.mailingCity && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing city.</p></IonItem>}
              </IonCol>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                <IonRow class="mt-2 mb-1">
                  <IonLabel>Mailing State</IonLabel>
                </IonRow>
                <IonRow class="m-0 p-0">
                  <IonCol class="m-0 p-0 ">
                    <StateSelector name='mailingState' disableField={false} />
                  </IonCol>
                </IonRow>
                {errors.mailingState && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing state.</p></IonItem>}
              </IonCol>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                <IonRow class="mt-2 mb-1">
                  <IonLabel>Mailing Zip</IonLabel>
                </IonRow>
                <IonRow class="m-0 p-0">
                  <IonCol class="m-0 p-0">
                    <Controller name='mailingZip' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                      <IonInput data-testid='zip-select' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text"  onIonChange={onChange} onIonBlur={onBlur} value={value} maxlength={5} readonly={false}/>
                    } rules={{ required: 'Please enter mailing zip.' }} />
                  </IonCol>
                </IonRow>
                {errors.mailingZip?.message && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['mailingZip']?.message}</p></IonItem>}
              </IonCol>
            </IonRow>
          </>
        }
      </IonRow>
      {isFundManagerFull &&
        <>
          <IonRow class='mt-2 mb-1'>
            <IonLabel>Memo</IonLabel>
          </IonRow>
          <IonRow class='m-0 p-0'>
            <Controller name="memo" control={control} render={({ field: { value, name, onBlur, onChange } }) => 
              <IonInput class='ion-text-left mt-0 gr-border pl-1 pr-1' type='text' name={name} maxlength={30} onIonBlur={onBlur} onIonChange={onChange} value={value} />
            } rules = {{
              required: true,
              validate: (value) => { return value !== null }
            }} />
          </IonRow>
        </>
      }
    </>
  );
};

export default BankFields;
