import { hasEnoughTimeElapsed } from "../../helpers/Utils"

const normalDistribution = 'Normal Distribution'
const earlyDistribution = 'Early Distribution, No Known Exception' 

const normalDistributionRothFiveYearUnknown = 'Normal Distribution, Roth 5YR Unknown (Code T)' 
const normalDistributionRothFiveYearQualified = 'Normal Distribution, Roth 5YR Qualified (Code Q)'
const earlyDistributionRoth = 'Early Distribution, Roth (Code J)'

const normalDistributionBeneficiary = 'Beneficiary Distribution'
const coverdellEsaDistribution = 'Coverdell ESA Distribution'
const hsaDistribution = 'HSA Distribution'
const fsaDistribution = 'FSA Distribution'
const custodialDistribution = 'Custodial Distribution' 

export const getDistributionTypeOptions = (accountType: string, accountCreationDate: string, personOlderThan59AndAHalf: boolean): Array<{value: string, description: string}> => {
    if(!Date.parse(accountCreationDate)) {
        return []
    }

    const fiveYearsInMonths = 5 * 12
    const accountOlderThanFiveYears = hasEnoughTimeElapsed(accountCreationDate, fiveYearsInMonths)
    
    let availableDistributionOptions: Array<{value: string, description: string}> = []
    
    switch(accountType) {
        case 'Traditional IRA':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'SEP IRA':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Individual 401k - Traditional':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Individual 401k - Roth':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Individual 401k - DYO':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Missing Participant (Trad)':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Roth IRA':
            availableDistributionOptions = personOlderThan59AndAHalf ? ( accountOlderThanFiveYears ? [createSelectOption(normalDistributionRothFiveYearQualified)] : [createSelectOption(normalDistributionRothFiveYearUnknown)] ) : [createSelectOption(earlyDistributionRoth)]
            break
        case 'SIMPLE IRA':
            availableDistributionOptions = personOlderThan59AndAHalf ? [createSelectOption(normalDistribution)] : [createSelectOption(earlyDistribution)]
            break
        case 'Inherited IRA - Traditional':
            availableDistributionOptions = [createSelectOption(normalDistributionBeneficiary)]
            break
        case 'Inherited IRA - Roth':
            availableDistributionOptions = [createSelectOption(normalDistributionBeneficiary)]
            break
        case 'Education Savings':
            availableDistributionOptions = [createSelectOption(coverdellEsaDistribution)]
            break
        case 'Health Savings':
            availableDistributionOptions = [createSelectOption(hsaDistribution)]
            break
        case 'Flexible Spending':
            availableDistributionOptions = [createSelectOption(fsaDistribution)]
            break
        case 'Custodial Individual':
            availableDistributionOptions = [createSelectOption(custodialDistribution)]
            break
        case 'Custodial Joint':
            availableDistributionOptions = [createSelectOption(custodialDistribution)]
            break
        case 'Custodial Trust':
            availableDistributionOptions = [createSelectOption(custodialDistribution)]
            break
    }
    return availableDistributionOptions
}

const createSelectOption = (distributionType: string): {value: string, description: string} => {
    return {value: distributionType, description: distributionType}
}