import React, { useEffect, useState } from 'react'
import { FieldErrors, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router'
import WizardProgressBar from '../../WizardProgressBar'
import { IonRow, IonCol, IonButton } from '@ionic/react'
import { DistributionHistory, DistributionStepTwoFields, DistributionWizardStep } from '../DistributionFields/distributionWizardTypes'
import progressBarStepTwoImage from '../../../images/distribution-wizard-step-two.svg'
import AmountFields from '../DistributionFields/AmountFields'
import TaxWithholdingFields from '../DistributionFields/TaxWithholdingFields'
import AccountInformationLabels from '../DistributionFields/AccountInformationLabels'
import SaveBankCheckbox from '../DistributionFields/SaveBankCheckbox'
import CheckFields from '../../ConditionalFieldComponents/CheckFields'
import ConditionalSelect from '../../ConditionalSelect'
import BankFields from '../../ConditionalFieldComponents/BankFields'
import TransactionScheduleFields from '../../TransactionScheduleFields'
import { defaultValue as defaultProcessDate } from '../../ForwardDatePicker'
import { deliveryMethodsFeeRemover } from '../../../helpers/Utils'
export interface StepTwo extends DistributionWizardStep {
    availableBanks: Bank[],
    distributionTypes: Array<{value: string, description: string}>,
    availableDeliveryMethods: Array<{value: string, description: string}>,
    isFundManagerFull: Boolean,
    is401kAccount: boolean
}

const DistributionStepTwo: React.FC<StepTwo> = ({formRef, submitFields, wizardCompletionRatio, savedFields, loggedInDetails, distributionTypes, availableDeliveryMethods, availableBanks, isFundManagerFull, is401kAccount}) => {
    const [deliveryFieldsToShow, setDeliveryFieldsToShow] = useState<'bank' | 'check' | 'none'>('none')
    const [showSaveBankCheckbox, setShowSaveBankCheckbox] = useState<boolean>(false)

    const history = useHistory<DistributionHistory>();

    const getDefaultValues = ():DistributionStepTwoFields => {
        return {
            deliveryMethod: savedFields?.deliveryMethod || 'notSet',
            distributionType: savedFields?.distributionType || '',
            fullOrPartial: savedFields?.fullOrPartial || 'notSet',
            amount: savedFields?.amount || '',
            electTaxWithholding: savedFields?.electTaxWithholding || 'notSet',
            taxWithholdingPercentage: savedFields?.taxWithholdingPercentage || '',
            mailingStreet: savedFields?.mailingStreet || '',
            mailingCity: savedFields?.mailingCity || '',
            mailingState: savedFields?.mailingState || '',
            mailingZip: savedFields?.mailingZip || '',
            CO: savedFields?.CO || '', 
            payableTo: savedFields?.payableTo || '',
            bankName: savedFields?.bankName || '',
            routingNumber: savedFields?.routingNumber || '',
            creditAccountNumber: savedFields?.creditAccountNumber || '',
            creditAccountType: savedFields?.creditAccountType,
            creditAccountName: savedFields?.creditAccountName || '',
            saveBankInfo: savedFields?.saveBankInfo || false,
            memo: savedFields?.memo || '',
            frequency: savedFields?.frequency || 'One-Time',
            recurringTransactionData: {
                schedule: savedFields?.recurringTransactionData?.schedule || '',
                duration: savedFields?.recurringTransactionData?.duration || '',
                endDate: savedFields?.recurringTransactionData?.endDate || undefined,
                processDate: savedFields?.recurringTransactionData?.processDate || defaultProcessDate
            }
        }
    }
    
    const stepTwoForm = useForm<DistributionStepTwoFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    })

    const { watch } = stepTwoForm;

    const deliveryMethod = useWatch({
        name: 'deliveryMethod',
        control: stepTwoForm.control,
        defaultValue: getDefaultValues().deliveryMethod
    })

    const fullOrPartial = watch('fullOrPartial');
    const distributionType = watch('distributionType');

    useEffect(() => {
        if (!isFundManagerFull) {
            if(loggedInDetails?.myProfile?.firstName && loggedInDetails?.myProfile?.lastName) {
                stepTwoForm.setValue('CO', `${loggedInDetails.myProfile.firstName} ${loggedInDetails.myProfile.lastName}`)
                stepTwoForm.setValue('creditAccountName', `${loggedInDetails.myProfile.firstName} ${loggedInDetails.myProfile.lastName}`)
            }
            stepTwoForm.setValue('mailingStreet', loggedInDetails?.myProfile?.mailingStreet || '')
            stepTwoForm.setValue('mailingCity', loggedInDetails?.myProfile?.mailingCity || '')
            stepTwoForm.setValue('mailingState', loggedInDetails?.myProfile?.mailingState || '')
            stepTwoForm.setValue('mailingZip', loggedInDetails?.myProfile?.mailingPostalCode || '')
        }
    }, [ loggedInDetails ])
    
    useEffect(() => {
        showAppropriateDeliveryFields()
    }, [])

    const deliveryMethodChanged = () => {
        // The amount, fullOrPartial, bankName, and routinNumber fields are
        // validated based on the delivery method.
        // If the delivery method is changed, the error on these fields need
        // to be cleared so that they can be validated again. 
        stepTwoForm.clearErrors('amount')
        stepTwoForm.clearErrors('fullOrPartial')
        stepTwoForm.clearErrors('bankName')
        stepTwoForm.clearErrors('routingNumber')

        stepTwoForm.setValue('bankName', '')
        stepTwoForm.setValue('routingNumber', '')
        showAppropriateDeliveryFields()      
    }

    const bankFieldsChanged = (hasChanged: boolean) => {
        if(hasChanged) {
            setShowSaveBankCheckbox(true)
        }
    }

    const showAppropriateDeliveryFields = () => {
        switch (stepTwoForm.getValues('deliveryMethod')) {
            case 'checkStandard':
                setDeliveryFieldsToShow('check')
                break
            case 'checkOvernight':
                setDeliveryFieldsToShow('check')
                break
            case 'checkHoldForPickup':
                setDeliveryFieldsToShow('none')
                break
            case 'wireTransfer':
                setDeliveryFieldsToShow('bank')
                break
            case 'directDeposit':
                setDeliveryFieldsToShow('bank')
                break
            default:
                setDeliveryFieldsToShow('bank')
        }
        handleSaveBankCheckbox()
    }

    const handleSaveBankCheckbox = () => {
        if(stepTwoForm.getValues('deliveryMethod') === 'checkStandard' || stepTwoForm.getValues('deliveryMethod') === 'checkOvernight' || stepTwoForm.getValues('deliveryMethod') === 'checkHoldForPickup') {
            setShowSaveBankCheckbox(false)
            stepTwoForm.setValue('saveBankInfo', false)
        }
        else if(!savedFields.bankId || savedFields.bankId === '') {
            setShowSaveBankCheckbox(true)
        }
        else {
            const savedBank: Bank | undefined = availableBanks.find(bank => bank.bankId === savedFields.bankId)
            if(savedBank) {
                const hasBankInfoChanged = bankInfoChanged(savedBank)
                setShowSaveBankCheckbox(hasBankInfoChanged)
                if(!hasBankInfoChanged) stepTwoForm.setValue('saveBankInfo', false)
            }
            else {
                setShowSaveBankCheckbox(true)
            }
        }
    }

    const bankInfoChanged = (savedBank: Bank): boolean => {  
        if(savedFields.creditAccountNumber !== savedBank.creditAccountNumber) {
            return true
        }

        if(savedFields.routingNumber !== savedBank.routingNumber) {
            return true
        }

        if(savedFields.bankName !== savedBank.bankName) {
            return true
        }
        
        if(deliveryMethod === 'directDeposit' && savedFields.creditAccountType !== savedBank.creditAccountType) {
            return true
        }

        return false
    }

    const deliveryMethodsFundManagerFull = availableDeliveryMethods.map(deliveryMethod => {
        deliveryMethod = deliveryMethodsFeeRemover(deliveryMethod);
        return deliveryMethod;
    });

    const onValid = (formFields: DistributionStepTwoFields) => {
        submitFields({status: 'ok', formFields})
    }
    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: stepTwoForm.getValues(), errors})
    }

    const showTaxWithholding  = !isFundManagerFull && distributionType !== 'HSA Distribution'

    return (
        <FormProvider {...stepTwoForm}>
            <form data-testid="distribution-step-two" ref={formRef} onSubmit={stepTwoForm.handleSubmit(onValid, onInvalid)}>
                <WizardProgressBar progressBarImage={progressBarStepTwoImage} progressRatio={wizardCompletionRatio} />
                {!is401kAccount &&
                    <>
                        <AccountInformationLabels accountLabel={savedFields?.retirementAccountLabel || ''} availableBalance={savedFields?.retirementAccountBalance}/>
                        {!isFundManagerFull &&
                            <ConditionalSelect  controllerName={'distributionType'} title={'Distribution Type'} errorMessage={'Please select a Distribution Type.'} dataList={distributionTypes} />
                        }
                        <AmountFields availableAccountBalance={savedFields?.retirementAccountBalance} deliveryMethod={deliveryMethod} isFundManagerFull={isFundManagerFull} />
                        {fullOrPartial === 'partial' && <TransactionScheduleFields transactionType='distribution'/>}  
                        {showTaxWithholding && <TaxWithholdingFields />}
                        <ConditionalSelect controllerName={'deliveryMethod'} title={'How would you like your funds to be delivered?'} errorMessage={'Please select a delivery method.'} dataList={isFundManagerFull ? deliveryMethodsFundManagerFull : availableDeliveryMethods} notifySelectChanged={deliveryMethodChanged}/>
                        {deliveryFieldsToShow === 'bank' && deliveryMethod !== 'notSet' && <BankFields bankFieldsChanged={bankFieldsChanged}  deliveryMethod={deliveryMethod} hideCreditAccountType={deliveryMethod === 'wireTransfer'} disableEditingAccountName={!isFundManagerFull ? true : false} isFundManagerFull={isFundManagerFull}/>}
                        {deliveryFieldsToShow === 'check' && deliveryMethod !== 'notSet' && <CheckFields deliveryMethod={deliveryMethod === 'checkOvernight' ? 'Check FedEx Overnight Delivery ($30 Fee)' : ''} disableFields={isFundManagerFull ? false : true} isFundManagerFull={isFundManagerFull}/>}
                        {showSaveBankCheckbox && <SaveBankCheckbox/>}
                    </>
                }
                {is401kAccount &&
                <>
                    <IonRow class='mt-2'>
                        Distributions from 401k accounts must be requested using the following form:
                    </IonRow>
                    <IonRow>
                        <IonButton class='mt-2' href='https://entrust.my.salesforce.com/sfc/p/#30000000Yy42/a/4u000000lzPn/RNw4Q479XoU2oIBD0qOqDZIzwzCY7ys43DEDdjp7Zyk' target="_blank">401k Distribution Form</IonButton>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {is401kAccount &&
                                <IonButton disabled={false} onClick={() => history.push('/home')} class='ion-float-right' size='large'>Close</IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </>
                }
            </form>
        </FormProvider>
    )
}


export default DistributionStepTwo