import React from 'react';
import { Controller, useWatch, useForm, FormProvider, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import {
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepFour from '../../../images/step-four.svg';
import { isPhone, deliveryMethodsFeeRemover } from '../../../helpers/Utils';
import CheckFields from '../../ConditionalFieldComponents/CheckFields';
import BankFields from '../../ConditionalFieldComponents/BankFields';
import DomesticWireFields from '../../ConditionalFieldComponents/DomesticWireFields';
import InternationalWireFields from '../../ConditionalFieldComponents/InternationalWireFields';
import { InputComponents, deliveryMethods } from '../BillPayTypes';
interface StepFour extends InputComponents {
  isFundManagerFull: Boolean
}

const BillPayStepFour: React.FC<StepFour> = ({ formRef, savedFields, submitFields, isFundManagerFull}) => {
  
  type DeliveryMethodFields = BillPaymentWizard.DeliveryMethodFields
  
  const getDefaultValues = ()=>{
    let defaultForm: DeliveryMethodFields = {
      deliveryMethod: savedFields?.deliveryMethod || '',
      mailingState: savedFields?.mailingState || '',
      mailingStreet: savedFields?.mailingStreet || '',
      mailingCity: savedFields?.mailingCity || '',
      mailingZip: savedFields?.mailingZip || '',
      CO: savedFields?.CO || '',
      routingNumber: savedFields?.routingNumber || '',
      bankName: savedFields?.bankName || '',
      creditAccountName: savedFields?.creditAccountName || '',
      creditAccountNumber: savedFields?.creditAccountNumber || '',
      creditAccountType: savedFields?.creditAccountType || '',
    }
    return defaultForm
  }

  const useFormMethods = useForm<Partial<DeliveryMethodFields>> ({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  const deliveryMethod = useWatch({
    name: 'deliveryMethod',
    control: useFormMethods.control,
    defaultValue: getDefaultValues().deliveryMethod
  });

  const deliveryMethodsFundManagerFull = deliveryMethods.map(deliveryMethod => {
    deliveryMethod = deliveryMethodsFeeRemover(deliveryMethod);
    return deliveryMethod
  });

  const deliveryMethodChanged = (event, onChange: Function) => {
    onChange(event)
    // The routing number is validated based on the delivery method.
    // If the delivery method is changed, the routing number and bank name
    // need to be cleared so that they can be validated again.
    useFormMethods.clearErrors('bankName')
    useFormMethods.clearErrors('routingNumber')
    useFormMethods.setValue('bankName', '')
    useFormMethods.setValue('routingNumber', '')
  }

  const onValid: SubmitHandler<Partial<DeliveryMethodFields>> = (formFields)=>{
    return submitFields({status: 'ok', formFields})
  }

  const onInvalid: SubmitErrorHandler<Partial<DeliveryMethodFields>> = (errors) => {
    let formFields = useFormMethods.getValues()
    submitFields({status: 'error', formFields: formFields, errors})
  }

  const renderDeliveryFields = (deliveryMethod: string) => {
    
    if (deliveryMethod.toLowerCase() === 'ach') {
      return <BankFields deliveryMethod={'direct deposit'}/> 
    }
    if (deliveryMethod?.toLowerCase().includes('hold')) {
      return <></>
    }
    if (deliveryMethod?.toLowerCase().includes('check')) {
      return <CheckFields deliveryMethod={deliveryMethod}/>
    }
    if (deliveryMethod?.toLowerCase().includes('domestic')) {
      return <DomesticWireFields control={useFormMethods.control} setValue={useFormMethods.setValue} errors={useFormMethods.formState.errors} />
    }
    if (deliveryMethod?.toLowerCase().includes('international')) {
      return <InternationalWireFields />
    }
  }

  return (
    <div data-test="bill-pay-step-four">
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepFour} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.75}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <FormProvider {...useFormMethods}>
        <form ref={formRef} onSubmit={useFormMethods.handleSubmit(onValid, onInvalid)} data-test="step-four-form">
          <IonRow class="mt-2 mb-1">
            <IonLabel>How would you like to send the payment?</IonLabel>
          </IonRow>
          <IonRow class="w-100">
            <Controller name='deliveryMethod' data-test="delivery-method" control={useFormMethods.control} render={({ field: { value, onBlur, onChange, name } }) => <IonSelect name={name} class="w-100 gr-border p-1" tabIndex={0} interface='action-sheet' onIonChange={e => deliveryMethodChanged(e, onChange)} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                  {isFundManagerFull
                    ? deliveryMethodsFundManagerFull.map((deliveryMethod, index) => <IonSelectOption value={deliveryMethod.value} key={index}>{deliveryMethod.description}</IonSelectOption>)
                    : deliveryMethods.map((deliveryMethod, index) => <IonSelectOption value={deliveryMethod.value} key={index}>{deliveryMethod.description}</IonSelectOption>)
                  }
                </IonSelect>
            } rules={{
                required: {
                  value: true,
                  message: 'Please select a delivery method.'
                },
                validate: {
                  amountValidForNonWire: deliveryMethod => (savedFields?.amount && savedFields.amount > 100000 && deliveryMethod && !(deliveryMethod === '' || deliveryMethod.toLowerCase().includes('wire'))) ? 'Amounts over $100,000 are only possible by wire transfer.' : undefined
                }
              }} />
          </IonRow>
          {useFormMethods?.formState.errors?.deliveryMethod?.message && <IonItem class="mt-1" color="danger" data-test="delivery-method-error"><p className="white-color">{useFormMethods?.formState.errors?.deliveryMethod?.message}</p></IonItem>}
          {deliveryMethod && renderDeliveryFields(deliveryMethod)}
        </form>
        </FormProvider>
    </div>
  );
};

export default BillPayStepFour;