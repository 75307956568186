import React, { useState, useEffect } from 'react';
import '../pages/Style.css';
import {useHistory} from 'react-router-dom';
import { 
  IonActionSheet,
  IonFabButton,
  IonFab
} from '@ionic/react';
import { connect } from 'react-redux';
import calloutHelper from '../helpers/calloutHelpers';
import AccountDetails from './AccountDetails';
import { checkPartyTypeFeatureAccess, checkPartyTypeFeatureAndHomeQuickLinkAccess } from './PartyTypeConditionalAccess';
import { menuController } from '@ionic/core';
import { throwError } from 'shared-utils';

const generateButtons = (history: any, selectedAccount: RetirementAccount | undefined, viewMode: ViewMode, showTradingAccountWithdrawalButton: Boolean, showBillPayButton: Boolean) =>{
  const pathname = history?.location?.pathname;
  const selectedPartyType = selectedAccount?.partyType;
  
  let buttons: Array<any> = [];
  const generateDefaultViewButtons = () => {
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'updateAssetValue')) {
      if (history.location.state && history.location.state.MVFromATG) {
        let holding = history.location.state;
        let holdingButton = {
          text: 'Update Value',
          handler: () => {
            history.push({
              pathname: '/update-asset',
              state: { holding }
            })
          }
        }
  
        buttons.push(holdingButton);
      }
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createTradingAccountWithdrawal')) {
      if (showTradingAccountWithdrawalButton) {
        let tradingAccountWithdrawalButton = {
          text: 'Submit Trading Account Withdrawal',
          handler: () => { history.push("/trading-account-withdrawal") }
        }
        buttons.push(tradingAccountWithdrawalButton);
      }
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createAdditionalBuy')) {
      let additionalBuyButton = {
        text: 'Send Funds to an Existing Investment',
        handler: () => {
          history.push("/additional-buy", { fromHolding: false });
        }
      }
      buttons.push(additionalBuyButton)
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createBillPayment')) {
      if (showBillPayButton || pathname.toLowerCase().includes('transactions') || selectedPartyType?.includes('Fund Manager - Full')) {
        let payBillButton = {
          text: selectedPartyType === 'Fund Manager - Full' ? 'Pay an Expense' : 'Pay a Bill',
          handler: () => { history.push("/pay-a-bill") }
        }
        buttons.push(payBillButton)
      }
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createContribution')) {
      let contributionButton = {
        text: 'Make a Contribution',
        handler: () => { history.push("/make-a-contribution") }
      }
      buttons.push(contributionButton)
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createDistribution')) {
      let distributionButton = {
        text: selectedPartyType === 'Fund Manager - Full' ? 'Request Disbursement' : 'Request Distribution',
        handler: () => { history.push('/request-distribution') }
      }
      buttons.push(distributionButton)
    }
  
    if ((pathname.toLowerCase().includes('account-details') && checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'editRelatedParties'))) {
      let editBeneficiariesButton = {
        text: 'Edit Beneficiaries',
        handler: () => {
          history.push({ pathname: '/edit-beneficiaries', state: { accountId: selectedAccount?.id, } })
        }
      }
      buttons.push(editBeneficiariesButton)
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createTransfer')) {
      let createTransferButton = {
        text: 'Transfer Cash from another IRA',
        handler: () => {
          history.push({ pathname: '/request-transfer' })
        }
      }
      buttons.push(createTransferButton)
    }
  
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'generateStatement') && !selectedPartyType?.includes('Fund Manager')) {
      let generateStatementButton = {
        text: 'Generate Statement',
        handler: () => {
          history.push({ pathname: '/generate-statement' })
        }
      }
      buttons.push(generateStatementButton)
    }
  };

  const addDocCustodyButtons = () => {
    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createVaultIn')) {
      let generateVaultInButton = {
        text: 'Vault In Document',
        handler: () => {
          history.push({ pathname: '/vault-in' })
        }
      }
      buttons.push(generateVaultInButton)
    };

    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createVaultOut')) {
      let generateVaultOutButton = {
        text: 'Vault Out Document',
        handler: () => {
          history.push({ pathname: '/vault-out' })
        }
      }
      buttons.push(generateVaultOutButton)
    }
  };

  if (viewMode === 'DEFAULT_TYPE') {
    generateDefaultViewButtons();
  };

  if (viewMode === 'DOC_CUSTODY') {
    addDocCustodyButtons();
  };
  
  return buttons
}

const MobileSidebar: React.FC<{ selectedAccount?: RetirementAccount | undefined, viewMode: ViewMode }> = ({ selectedAccount, viewMode }) => {
  const history : any = useHistory();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showTradingAccountWithdrawalButton, setShowTradingAccountWithdrawalButton] = useState(false);
  const [ showBillPayButton, setShowBillPayButton ] = useState<Boolean>(false);
  const menuItems: Array<any> = generateButtons(history, selectedAccount, viewMode, showTradingAccountWithdrawalButton, showBillPayButton);
  
  useEffect(() => {
    const displayTradingAccountWithdrawalButton = async () => {
      
      if (checkPartyTypeFeatureAccess(selectedAccount, 'createTradingAccountWithdrawal')) {
        try {
          const assetOptionsResult = await calloutHelper.getBankAndTradingHoldings(selectedAccount?.id);

          if (assetOptionsResult.data.relatedAssetOptions.length > 0) {
            setShowTradingAccountWithdrawalButton(true)
          } else {
            setShowTradingAccountWithdrawalButton(false)
          }

        } catch (err) {
          throwError(err)
        }
      }
    };

    const displayBillPayButton = async () => {
      if (checkPartyTypeFeatureAccess(selectedAccount, 'createBillPayment')) {
        try {
          const holdingsResult = await (await calloutHelper.getNonCashHoldings(selectedAccount?.id)).data;
          const assetTypes = holdingsResult.map(holding => holding.AssetType);

          if (assetTypes.includes('Property Owned')) {
            setShowBillPayButton(true);
          } else {
            setShowBillPayButton(false);
          };
        } catch (err) {
          throwError(err);
        };
      };
    };

    displayTradingAccountWithdrawalButton();
    displayBillPayButton();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, AccountDetails]);

  return (menuItems.length > 0) ? (
    <>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => { menuController.close(); setShowActionSheet(false) }}
        header = 'I want to:'
        buttons = {menuItems}
      />
      <IonFab>
        <IonFabButton class='fixed-sidebar-button display-mobile' color="tertiary" onClick={() => setShowActionSheet(true)}>I want <br /> to...</IonFabButton>
      </IonFab>
    </>
  ) : null;
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
    viewMode: rootState.CachedObjects.viewMode
  }
}

export default connect(mapStateToProps)(MobileSidebar);
