import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router'
import {
  IonRow,
  IonCol,
  IonItem,
} from '@ionic/react';
import ContributionStepOne, {NEW_BANK} from './ContributionSteps/ContributionStepOne';
import ContributionStepTwo from './ContributionSteps/ContributionStepTwo';
import ContributionReview from './ContributionSteps/ContributionReview';
import { SubmitFieldsCallback, steps } from './ContributionTypes'
import calloutHelper from '../../helpers/calloutHelpers';
import Spinner from '../Spinner'
import { connect } from 'react-redux'
import { DefaultValues as DefaultBankValues } from '../ConditionalFieldComponents/BankFields'
import { GenericWizardButtons } from '../WizardButtons'
import { GenericHistory, TokenServices } from '../../helpers/wizardHelpers'

type FullForm = ContributionWizard.FullForm
type ContributionHistory = GenericHistory<steps, Partial<FullForm>> & (TransactionHistoryType | RecurringTransactionHistoryType) & {PreselectedFields?: Partial<FullForm>}

const NewCashContributionWizard: React.FC<{availableAccounts: Array<RetirementAccount>, scrollToTop: Function}> = ({scrollToTop, availableAccounts}) => {
  /**
   * Initialized
   * If wizzard is not initialized, no matter what step you're on you get redirected to step one which flips the init state
   * Then you can travel to whatever step you wish
   * Initialize must be reset on 
   */

  const direction = useRef<'next'|'back'>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [contributionParams, setContributionParams] = useState<{
    banks: Array<Bank>,
    ContributionOptionsArray: Array<ContributionOptions>
  }>({
    banks:[],
    ContributionOptionsArray: [],
  })
  const [TOSConfirmed, setTOSConfirmed] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const stepsArray: Array<steps> = ['step-one',  'step-two' , 'review'];
  const history = useHistory<ContributionHistory>();
  const step: steps = history?.location?.state?.step || 'step-one';
  const preselectedFields = history?.location?.state?.PreselectedFields || {};
  const savedFields = history?.location?.state?.formFields || {};
  const contributionTokenFromLocation = history?.location?.state?.wizardToken || null
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const getForwardStep = useCallback(() => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === stepsArray.length - 1){
      return stepsArray[stepPosition];
    }

    let nextStep = stepPosition + 1;
    return stepsArray[nextStep];
  }, [step, stepsArray])

  const getPrevStep = useCallback( () => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === 0){
      return stepsArray[stepPosition];
    }

    let prevStep = stepPosition - 1;
    return stepsArray[prevStep];
  }, [step, stepsArray])

  const loadPrequesites = useCallback(async () => {
    let accountIds = availableAccounts.map((value)=>{
      return value.id
    })
    let banksResponse = await calloutHelper.getSavedBanks();
    const banks = banksResponse.data.filter(bank => bank.deliveryMethod?.toLowerCase().includes('ach'))
    let taxOptionsResponse = await calloutHelper.getContributionOptions(accountIds);
    setContributionParams(()=>({
      banks,
      ContributionOptionsArray: taxOptionsResponse.data.contributionOptions || [],
    }))
    
  }, [availableAccounts])

  useEffect(()=>{
    setShowSpinner(true)
    loadPrequesites().then(()=>{
      setShowSpinner(false);
    })
  },[availableAccounts, loadPrequesites])

  useEffect(() => {
    scrollToTop();
  }, [step, scrollToTop]); 

  const mergeSavedFieldsAndFormFields = useCallback<(formFields:Partial<FullForm>)=>Partial<FullForm>>((formFields)=>{
    const stepOneFieldMerge = () => {
      let mergedFields: Partial<FullForm> = {...savedFields}
      if(mergedFields?.retirementAccount !== formFields.retirementAccount){
        if(mergedFields?.taxYear){
          mergedFields.taxYear = '';
        }
      }

      if(mergedFields?.selectedBankId !== formFields.selectedBankId){
        if(formFields.selectedBankId === NEW_BANK.bankId){
          mergedFields = {...mergedFields, ...DefaultBankValues()}
        }else{

          let bankFields = contributionParams.banks.find((bank)=>{
            return bank.bankId === formFields.selectedBankId
          })
  
          if(bankFields){
            mergedFields = {...mergedFields, ...bankFields}
          }
        }
      }
      mergedFields = {...mergedFields, ...formFields}
      return mergedFields
    }

    switch(step){
      case 'step-one':
        return stepOneFieldMerge()
      default:
        return {...savedFields, ...formFields}
    }
  },[step, contributionParams, savedFields])
  
  const incrementStep = useCallback<SubmitFieldsCallback>((submitState) => {
    if(submitState.status === 'error' && direction.current === 'next'){
      return;
    }
    
    let newStep = direction.current === 'next' ? (getForwardStep()) : (getPrevStep())

    if(step === newStep){
      return;
    }

    let mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields)
    let historyContributionToken = step === 'step-one' ? TokenServices.updateTokenFromSession() : contributionTokenFromLocation

    history.push('/make-a-contribution',{
      step: newStep,
      formFields: mergedFields,
      wizardToken: historyContributionToken,
      PreselectedFields: preselectedFields
    })
  }, [step, history, getPrevStep, getForwardStep, mergeSavedFieldsAndFormFields, contributionTokenFromLocation, preselectedFields]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000)
  }

  const submitStep = (go: 'next'|'back') => () => {
    direction.current = go
    if(step === 'review'){
      return incrementStep({'status':'ok', formFields: savedFields})
    }

    if (formRef && formRef.current) {
    
      formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
    }
  }

  const displayStep = useCallback((currentStep: steps) => {
    let step = currentStep;
    let contributionTokenSession = TokenServices.getTokenFromSession()
    const componentProps = {formRef, submitFields: incrementStep, savedFields, preselectedFields}

    if(step !==  'step-one' && contributionTokenSession !== contributionTokenFromLocation){
      history.replace('/home')
    }

    const renderStepTwo = () => {
      let contributionOptions: ContributionOptions = contributionParams?.ContributionOptionsArray.find((value)=>{ 
        return value?.id === savedFields?.retirementAccount
      }) || {id:''}

      return <ContributionStepTwo contributionOptions={contributionOptions} {...componentProps}/>
    }

    const renderReview = () => { 
      let retirementAccount = availableAccounts.find((account)=>{
        return account.id === savedFields?.retirementAccount
      })
      let retirementAccountLabel = `${retirementAccount?.accountNumber} - ${retirementAccount?.firstName} ${retirementAccount?.lastName} - ${retirementAccount?.accountType}`
      
      return <ContributionReview retirementAccountLabel={retirementAccountLabel} setTOSState={setTOSConfirmed} tosState={TOSConfirmed} savedFields={savedFields}/>
    }
    //return components[step]
    switch(step){
      case 'step-two':
        return renderStepTwo()
      case 'review':
        return renderReview()
      default:
        return <ContributionStepOne availableAccounts={availableAccounts} bankOptions={contributionParams.banks} {...componentProps}/>
    }
  },[availableAccounts, contributionParams, savedFields, incrementStep, TOSConfirmed, contributionTokenFromLocation, history, preselectedFields])

  const submitFailed = () => {
    setShowSpinner(false)
    setErrorMessage('There was an issue while trying to create your contribution.');
    clearErrorMessage();
  }

  const submitContribution = () => {
    scrollToTop();
    setShowSpinner(true);
    calloutHelper.postContribution(savedFields).then((result)=>{
      TokenServices.clearTokenFromSession()
      if(result.data.status === 'ok'){
        history.replace('/confirmation',{
          PendingOrPosted: 'pending',
          TransactionId: result.data.transactionId,
          transactionType: 'Contribution',
          customTextArray: [
            'Requests submitted will be reviewed within one business day. We will contact you if we need further information. You will receive an email confirmation when your contribution request has been successfully processed.',
            'Once your contribution has been processed, there will be a 5 day hold on the funds for verification purposes.'
          ]
        })
      }
      else if (result.data.status === 'recurring-ok' && result.data.recurringTransactionId) {
        history.push({
          pathname: '/confirmation',
          state: {
            recurringTransactionId: result.data.recurringTransactionId,
            transactionType: 'Contribution',
            customTextArray: [
              'Requests submitted will be reviewed within one business day. We will contact you if we need further information. You will receive an email confirmation when your contribution request has been successfully processed.',
              'Once your contribution has been processed, there will be a 5 day hold on the funds for verification purposes.'
            ]
          }
        });
      }
      else { 
        submitFailed()
      }
    }).catch(err=>{
      submitFailed()
    })
  }

  return (
      <IonRow class="container" data-test="bill-pay">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
          <IonRow>
            <IonCol class="p-1 light-gr-bg">
              <IonRow>
                <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                  {(errorMessage !== '') &&
                  (<IonItem class="mt-1" color="danger">
                    <p className="white-color">{errorMessage}</p>
                    </IonItem>)}
                    {showSpinner ? <Spinner/> :<>
                      {displayStep(step)}
                      <IonRow>
                        <IonCol>
                          <IonRow>
                            <IonCol>
                              <GenericWizardButtons steps={stepsArray} activeStep={step} disableSubmit={!TOSConfirmed} handleStep={submitStep} handleSubmit={submitContribution}/>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </>}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts //could either apply filter here, in a const up top or in the selection box. I'm leaning toward the front two
  }
}

export default connect(mapStateToProps)(NewCashContributionWizard);