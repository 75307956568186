import React, { useEffect } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import WizardProgressBar from '../../WizardProgressBar'
import { deliveryMethodOptions, deliveryMethodType, DistributionStepOneFields, DistributionWizardStep } from '../DistributionFields/distributionWizardTypes' 
import progressBarStepOneImage from '../../../images/distribution-wizard-step-one.svg'
import { IonCol, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { AccountIonSelect } from '../../SelectAccountBar'
import { parseDeliveryMethod, getAvailableCash } from '../distributionWizardHelpers'
import { getDistributionTypeOptions } from '../distributionTypeData'
import { checkPartyTypeAccessBuilder } from '../../PartyTypeConditionalAccess'
import { POBoxRegex } from 'shared-utils'
import { SelectChangeEventDetail } from '@ionic/core';
import calloutHelper from '../../../helpers/calloutHelpers'


export interface DistributionWizardStepOne extends DistributionWizardStep {
    availableAccounts: RetirementAccount[],
    availableBanks: Bank[],
    deliveryDestinationOptions: deliveryDestinationOption[],
    setDistributionTypes: Function,
    setAvailableDeliveryMethods: Function,
    isFundManagerFull: Boolean,
    setIs401kAccount: Function
}

export type deliveryDestinationOption = {
    value: string, 
    description: string
}

const DistributionStepOne: React.FC<DistributionWizardStepOne> = ({availableAccounts, availableBanks, formRef, submitFields, wizardCompletionRatio, savedFields, loggedInDetails, setDistributionTypes, deliveryDestinationOptions, setAvailableDeliveryMethods, isFundManagerFull, setIs401kAccount}) => {    

    const getDefaultValues = ():DistributionStepOneFields => {
        return {
            accountId: savedFields?.accountId || '',
            retirementAccountLabel: savedFields?.retirementAccountLabel || '',
            retirementAccountBalance: savedFields?.retirementAccountBalance,
            deliveryDestination: savedFields?.deliveryDestination || '',
            bankId: savedFields?.bankId || '',
            deliveryMethod: savedFields?.deliveryMethod || 'notSet',
            accountType: savedFields?.accountType || ''
        }
    }

    const { control, formState: {errors}, handleSubmit, setValue, getValues, clearErrors } = useForm<DistributionStepOneFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    })

    useEffect(() => {
        if(savedFields?.accountId) {
            handleSelectedAccountId(savedFields.accountId)
        }
    }, [])

    const notifyRetirementAccountChanged = (event: CustomEvent<any>) => {
        const retirementAccountId = event?.detail?.value || ''
        handleSelectedAccountId(retirementAccountId)
    }

    const handleSelectedAccountId = async (accountId: string) => {
        setValue('accountId', accountId)
        const selectedAccount = availableAccounts.find(account => account.id === accountId)
        if(selectedAccount) {
            setValue('retirementAccountLabel', `${selectedAccount.accountNumber} - ${selectedAccount.firstName} ${selectedAccount.lastName} - ${selectedAccount.accountType}`)
            setValue('accountType', selectedAccount.accountType)
            let availableCashBalance: number = await getAvailableCash(selectedAccount)
            setValue('retirementAccountBalance', availableCashBalance)
            setDistributionTypes(getDistributionTypeOptions(selectedAccount.accountType, selectedAccount.openDate, loggedInDetails?.olderThan59AndAHalf || false))
            setIs401kAccount(selectedAccount.accountType.includes('401k'))
            clearErrors('accountId')
        }
    }

    const notifyDeliveryDestinationChanged = (onChange: Function, event: CustomEvent<SelectChangeEventDetail>) => {
        const deliveryDestination = event?.detail?.value || ''
        onChange(deliveryDestination)
        clearSavedBankData()

        let parsedDeliveryMethod: deliveryMethodType = 'notSet'

        if (deliveryDestination.includes('newDestination')) {
            setValue('deliveryMethod', 'notSet')
        }
        else {
            const bank: Bank | undefined = availableBanks.find((bank) => bank.bankId === deliveryDestination)
            if (bank) {
                setSavedBankFields(bank)
                parsedDeliveryMethod = parseDeliveryMethod(bank?.deliveryMethod || '')
                setValue('deliveryMethod', parsedDeliveryMethod)
            }
        }
        createDeliveryMethodOptions(parsedDeliveryMethod)
    }

    const createDeliveryMethodOptions = (parsedDeliveryMethod: deliveryMethodType) => {
        let relatedDeliveryOptions: deliveryDestinationOption[] = []
    
        if(parsedDeliveryMethod === 'directDeposit' || parsedDeliveryMethod === 'wireTransfer') {
            relatedDeliveryOptions = deliveryMethodOptions.filter(option => option.value === parsedDeliveryMethod)   
        }
        else if(parsedDeliveryMethod === 'notSet' ) {
            if(hasPOBoxAddress()) {
                relatedDeliveryOptions = deliveryMethodOptions.filter(option => option.value !== 'checkOvernight')
            }
            else {
                relatedDeliveryOptions = deliveryMethodOptions
            }
        }
        setAvailableDeliveryMethods(relatedDeliveryOptions)
    }

    const hasPOBoxAddress = (): boolean => {
        // Overnight checks can't be sent to PO boxes, so this removes it as a possible delivery option if the user's account has one as its address.
        return !POBoxRegex.test(loggedInDetails?.myProfile.mailingStreet || '')
    }

    const setSavedBankFields = (bank: Bank) => {
        setValue('bankId', bank.bankId)
        setValue('bankName', bank.bankName)
        setValue('routingNumber', bank.routingNumber)
        setValue('creditAccountNumber', bank.creditAccountNumber)
        setValue('creditAccountType', bank.creditAccountType)
    }

    const clearSavedBankData = () => {
        setValue('bankId', '')
        setValue('bankName', '')
        setValue('routingNumber', '')
        setValue('creditAccountNumber', '')
        setValue('creditAccountType', '')
        setValue('creditAccountName', '')
    }

    const onValid = (formFields: DistributionStepOneFields) => {
        submitFields({status: 'ok', formFields})
    }
    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: getValues(), errors})
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)} data-testid="distribution-step-two">
            <WizardProgressBar progressBarImage={progressBarStepOneImage} progressRatio={wizardCompletionRatio} />
            
            <IonRow class="divider">
                <h1 className="ion-text-left">{isFundManagerFull ? 'Disbursement' : 'Distribution'} Request Instructions:</h1>
                <ul>
                    <li>Equity Trust reserves the right to require verbal verification before processing distribution requests. We will contact you if we need verbal verification or further information.</li>
                    {!isFundManagerFull &&
                        <li>Requests submitted will be reviewed and processed within 3 business days. You will receive an email confirmation when your distribution request has been successfully processed.</li>
                    }
                </ul>
            </IonRow>

            <IonRow class="mt-2 mb-1">
                <IonLabel>Select Account</IonLabel>
            </IonRow>
            <IonRow class="w-100 gr-border p-0 m-0">
                <IonCol size="12" class="w-100 p-0 m-0">
                    <Controller control={control} name='accountId' render={({ field: { name, value } }) => (
                        <AccountIonSelect accountID={value} availableAccounts={availableAccounts} name={name} accountFilter={checkPartyTypeAccessBuilder('createDistribution')} onChangeHandler={event => {notifyRetirementAccountChanged(event)}}  />
                    )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please select an account.'
                            }
                        }} />
                </IonCol>
            </IonRow>
            {errors['accountId'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['accountId']?.message}</p></IonItem>}

            <IonRow class="mt-2 mb-1">
                <IonLabel>Delivery Method</IonLabel>
            </IonRow>
            <IonRow class="w-100">
                <Controller data-test='select-bank-account-controller' name='deliveryDestination' control={control} render={({ field: { value, name, onChange } }) => {
                    return (
                        <IonSelect data-testid='delivery-destination-select' class="w-100 gr-border p-1" interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" name={name} value={value}
                            onIonChange={(event) => {
                                notifyDeliveryDestinationChanged(onChange, event)
                            }}>
                            {deliveryDestinationOptions.map((option: deliveryDestinationOption) => (<IonSelectOption key={option.value} value={option.value}>{option.description}</IonSelectOption>))}
                        </IonSelect>)
                }
                } rules={{
                    required: {
                        value: true,
                        message: 'Please select a delivery method.'
                    },
                    validate: {
                        validOptionSelected: option => option === '' ? 'Please select a delivery method.' : undefined 
                    }
                }} />
            </IonRow>
            {errors['deliveryDestination'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['deliveryDestination']?.message}</p></IonItem>}
        </form>
    )
}

export default DistributionStepOne