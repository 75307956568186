import React, {  useEffect, useState } from 'react';
import './CreditCardForm.css';
import {
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonText,
    IonInput,
    IonItem,
    IonCheckbox,
    IonSpinner
} from '@ionic/react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Spinner from '../Spinner'
import { formatValueAsCurrency } from '../../helpers/Utils'
import {Link} from "react-router-dom"
import CreditCardFields from './CreditCardFields';

import { translateProps } from './CreditCardFormHelpers'

type  CardFormInputs = CreditCardPayment.CardFormInputs;

interface CreditCardProps {
    onCardSubmit: (obj: object) => void;
    cardStatus: {
        isCardAdded: boolean,
        isEditing: boolean
    },
    openChargesLeft?: number,
    state: CardFormInputs,
    isLoading?: boolean | undefined
}

const CreditCardForm = ({ state, isLoading, cardStatus, onCardSubmit, openChargesLeft }: CreditCardProps,) => {
    const  [saveCardForFutureUse, setSaveCardForFutureUse] =  useState<boolean>(false)
    const defaultValues: CardFormInputs = state || {
        openChargesLeft: openChargesLeft || null,
        amount: openChargesLeft || null,
        number: '',
        expiry: '',
        zipCode: '',
        saveCardForFutureUse
    }
    useEffect(()=>{
        if(state.saveCardForFutureUse){
            setSaveCardForFutureUse(state.saveCardForFutureUse)
        }
    },[state.saveCardForFutureUse])

    const cardForm = useForm<CardFormInputs>({
        mode: 'onChange',
        defaultValues: cardStatus.isEditing ? state : defaultValues
    });

    const { control, formState: { errors },  handleSubmit, clearErrors} = cardForm;

    const onSubmit = handleSubmit((data) => {
        onCardSubmit({...data, saveCardForFutureUse});
    });
    
    return (
        <IonRow className="container">
            <IonCol>
                <FormProvider {...cardForm}>
                    <form onSubmit={onSubmit}>
                     {
                        isLoading ? (
                            <Spinner/>
                        ) : (
                            <>
                                <IonRow className='mt-1'>
                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="6">
                                        <IonLabel className="p-0 m-0">Current Balance:</IonLabel>
                                        <IonText className="mt-1 d-flex">{formatValueAsCurrency(Number(openChargesLeft))}</IonText>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="6">
                                        <IonLabel className="p-0 m-0"><span className="text-danger">*</span> Amount:</IonLabel>
                                        <Controller name='amount' defaultValue={defaultValues?.amount} control={control} render={({field}) =>
                                            <IonInput class="ion-text-left gr-border" {...translateProps(field)}
                                            maxlength={5} />
                                        } 
                                        rules = {                                    
                                            {
                                                required: ' Please provide an amount.',
                                                validate: {
                                                    isGreaterAmount: async (value) => {
                                                        if (Number(value) <=  0){
                                                            return  "Please provide an amount greater than $0."
                                                        }
                                                        else if (Number(value) > Number(openChargesLeft)) {
                                                            return "Please provide an amount less than or equal to the current balance of " + formatValueAsCurrency(Number(openChargesLeft)) + "."
                                                        }
                                                        else if (Number(value) > 10000) {
                                                            return "Please provide an amount less than $10,000."
                                                        }
                                                        else if (Number(value) > 0) {
                                                            clearErrors()
                                                        }
                                                    },
                                                }
                                        }} />
                                        {errors['amount'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['amount']?.message}</p></IonItem>}
                                    </IonCol>
                                </IonRow>
                                <CreditCardFields 
                                    errors={errors}
                                    control={control}
                                    defaultValues={{
                                        creditCardNumber:  defaultValues.number,
                                        expiry: defaultValues.expiry,
                                        zipCode: defaultValues.zipCode
                                }}/>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" className="d-flex align-items-center ">
                                        <IonCheckbox  checked={saveCardForFutureUse} onIonChange={(event) => setSaveCardForFutureUse(event.detail.checked) }  className='ml-0-5' />
                                            <IonLabel class="agree-text  visibility-unset ml-1 mt-0-5">
                                            Use this card for future automatic billing. 
                                            </IonLabel>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" class="text-end pr-0 d-flex justify-end">
                                        <button disabled={isLoading} type='submit' className='btn-credit-card'>
                                            <IonButton class='mr-0'> {isLoading ? <IonSpinner  name="bubbles"/> : ""} Pay</IonButton>
                                        </button>
                                        <Link to="/billing">
                                            <button  className='btn-credit-card'>
                                                <IonButton color='light' class='mr-0'> Cancel</IonButton>
                                            </button>
                                        </Link>
                                  </IonCol>
                                </IonRow>
                            </>
                        )
                     }
                    </form>
                </FormProvider>
            </IonCol>
        </IonRow>);
};

export default CreditCardForm
