import React from 'react';
import { useHistory } from 'react-router';
import { IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { checkboxOutline } from 'ionicons/icons';

const ConfirmationPage: React.FC = () => {
    const history = useHistory<{PendingOrPosted?: string, TransactionId?: string, recurringTransactionId?: string, transactionType?: string,  customTextArray?: string[], approvalOrRejection?: string}>();
    const stateDetail = history.location.state;

    const goHome = () => {
        history.push('/home');
    };

    const goToPendingTransaction = () => {
        history.push('/transaction', {
            PendingOrPosted: stateDetail.PendingOrPosted,
            TransactionId: stateDetail.TransactionId
        });
    };

    const goToRecurringTransaction = () => {
        history.push('/recurring-transaction', {
            recurringTransactionId: stateDetail.recurringTransactionId
        });
    };

    const goToInvestorRoster = () => {
        history.push('/investor-roster');
    };

    return (
        <IonRow class='container'>
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <IonRow>
                    <IonCol class="p-1 light-gr-bg">
                        <IonRow>
                            <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                <IonRow>
                                    <IonCol sizeXs="2" sizeSm="1" sizeMd="1" sizeLg="1" sizeXl="1">
                                        <IonIcon class='mt-2 mr-1' size='large' color='success' icon={checkboxOutline}/>
                                    </IonCol>
                                    <IonCol>
                                        {stateDetail.approvalOrRejection ?
                                            <h1>Your {stateDetail.approvalOrRejection} has been received</h1> :
                                            stateDetail.transactionType === 'Investor' ? <h1>Investor Added Successfully</h1> :
                                            <h1>Your {stateDetail.transactionType} was created successfully</h1>
                                        }
                                    </IonCol>
                                </IonRow>
                                {stateDetail.customTextArray?.map(customText => 
                                    <IonRow class='ml-1 mt-1 mb-1'>{customText}</IonRow>
                                )}
                                <IonRow class='ml-1 mt-3'><b>What would you like to do next?</b></IonRow>
                                <IonRow class='ml-1 mt-1'>
                                    <IonButton onClick={() => goHome()}>Go Home</IonButton>
                                    {stateDetail.recurringTransactionId &&
                                        <IonButton onClick={() => goToRecurringTransaction()}>View Recurring Transaction</IonButton>
                                    }
                                    {stateDetail.TransactionId &&
                                        <IonButton onClick={() => goToPendingTransaction()}>View Pending Transaction</IonButton>
                                    }
                                    {stateDetail.transactionType === 'Investor' &&
                                        <IonButton onClick={() => goToInvestorRoster()}>View Investor Roster</IonButton>
                                    }
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
                
    )
}

export default ConfirmationPage;