import React, { useState } from 'react';
import '../pages/Style.css';
import { 
  IonRow,
  IonCol,
  IonModal,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonRouterLink,
  
} from '@ionic/react';

interface TermsOfServiceProps {
  modalBodyText?: any;
  modalButtonText?: string;
  dynamicContent?: any;
  checkboxEvent?(event: CustomEvent<{value: any, checked: boolean}>):void;
  checked?: any;
  ionFloat?: string;
  fieldName?: string;
}

const TermsOfServiceComponent: React.FC<TermsOfServiceProps> = ({ modalBodyText, modalButtonText, dynamicContent, checkboxEvent, checked, ionFloat, fieldName='tos_confirmation' }) => {
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  return (
    <IonRow>
      <IonCol>
        <IonModal cssClass={'user-agreement-modal'} isOpen={showTermsOfService} onDidDismiss={() => setShowTermsOfService(false)} mode="ios">
          <IonContent class="tos">
            { modalBodyText }
          </IonContent>
          <IonButton onClick={() => setShowTermsOfService(false)}>{ modalButtonText }</IonButton>
        </IonModal>      
          { dynamicContent }
        <IonItem lines="none" className={`${ionFloat} mt-2 mb-1 ion-align-self-end`}>
          <IonCheckbox name={fieldName} color="primary" slot="start" tabIndex={0} checked={checked} onIonChange={checkboxEvent}/>  
          <IonLabel class="agree-text">
            I agree to the <IonRouterLink onClick={() => setShowTermsOfService(true)} className="underline cursor-pointer">Terms of Service </IonRouterLink>.
          </IonLabel> 
        </IonItem>
      </IonCol>
    </IonRow>
  );
};

export const standardTOS = () => {
  return <>
    <h3 className="pl-3 pr-3">Terms of Service</h3>
    <p className="text-left pl-3 pr-3">I acknowledge and agree that my account and this transaction will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where Equity Trust is organized, and the Federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Custodial Account records.</p>
    <p className="text-left pl-3 pr-3">I understand that, in lieu of the retention of the original records, Equity Trust, as the Custodian and/or Administrator, may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
    <p className="text-left pl-3 pr-3">I hereby acknowledge and agree that I am solely responsible for any and all transactions. I shall hold harmless, protect, and indemnify Equity Trust, the Custodian and/or Administrator, from and against any and all liabilities, losses, damages, expenses, penalties, taxes and charges that Equity Trust, the Custodian and/or Administrator, may sustain or might sustain resulting directly or indirectly from this disbursement.</p>
  </>
}

export default TermsOfServiceComponent;
