import React, {useCallback, useEffect} from 'react'
import {MyProfileFormInputs, PhoneFormInputs, PhoneTypes, PhoneOptions} from '../myProfileTypes'
import {IonCol, IonLabel, IonSelect, IonSelectOption} from '@ionic/react'
import {Controller, useFormContext} from 'react-hook-form'
import InputMask from 'react-input-mask'
import {ShowFieldError, mapFieldPropsToIonProps} from './MyProfileUtils'
import {validators} from 'shared-utils'

const PhoneFields: React.FC = () => {
    return <>
        <PhoneField phoneOption='Primary'  required={true}/>
        <PhoneField phoneOption='Secondary' defaultValues={{phoneNumber: '', phoneType: ''}}/>
    </>
}

const PhoneField :React.FC<{phoneOption: PhoneOptions, required?: boolean, defaultValues?: PhoneFormInputs }> = ({phoneOption, defaultValues, required = false }) =>{
    const { formState:{errors}, control, watch, setValue } = useFormContext<MyProfileFormInputs>()
    const phoneNumber = watch(`${phoneOption}.phoneNumber`)

    const otherPhoneType = phoneOption === 'Primary' ? watch(`Secondary.phoneType`) : watch('Primary.phoneType')
    const otherPhoneNumber = phoneOption === 'Primary' ? watch(`Secondary.phoneNumber`) : watch('Primary.phoneNumber')

    const phoneNumberIsBlank = useCallback(()=>{
        const numberList = phoneNumber?.match(/\d/g)
        return Boolean(!numberList || numberList.length === 0)
    },[phoneNumber])

    useEffect(()=>{
        if(phoneNumberIsBlank()){
            setValue(`${phoneOption}.phoneType`, '')
        }
    },[phoneNumberIsBlank, phoneOption, setValue])

    type PhoneTypeSelection = { [property in PhoneTypes]: JSX.Element}

    const phoneTypeSelectOptions: PhoneTypeSelection = {
        'mobile': <IonSelectOption value={'mobile'}>Mobile</IonSelectOption>, 
        'home': <IonSelectOption value={'home'}>Home</IonSelectOption>,
        'office': <IonSelectOption value={'office'}>Office</IonSelectOption>
    }

    const clearSpecialCharacters = (val: unknown) =>{
        if(typeof val === 'string'){
            return val.replace(/[^\d]/g, '')
        }
        return ''
    }
  return <>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonCol size="12" class="d-block mt-1">
                <IonLabel className="p-0 m-0">{phoneOption} Phone:</IonLabel>
            </IonCol>
            <Controller name={`${phoneOption}.phoneNumber`} defaultValue={defaultValues?.phoneNumber} control={control} render={({field}) =>
                <InputMask name={field.name} className='gr-border p-1 w-100 inherit-background' value={field.value} onChange={field.onChange} onBlur={field.onBlur} inputRef={field.ref} type='tel' mask="(999) 999-9999"/>
            } rules={{
                required: required && 'Phone number required.',
                validate: (val) => {
                    const phoneNumberCleaned = clearSpecialCharacters(val)
                    const otherPhoneNumberCleaned = clearSpecialCharacters(otherPhoneNumber)
                    const phoneStartsWithZeroOrOne = (phoneNumberCleaned.charAt(0) === '0' || phoneNumberCleaned.charAt(0) === '1')
                    const phoneNumbersMatch = phoneNumberCleaned === otherPhoneNumberCleaned && phoneOption === 'Secondary'
                    const phoneValid = validators.isPhone(val) && !phoneStartsWithZeroOrOne && !phoneNumbersMatch
                    const phoneNumberIsBlank = typeof val === 'string' && val === ''

                    if(!required && phoneNumberIsBlank){
                        return
                    }
                    
                    if(phoneValid){
                        return
                    }
                
                    if(phoneNumbersMatch){
                        return 'Phone numbers can not match.'
                    }
                    
                    if(phoneStartsWithZeroOrOne){
                        return 'Phone number must not start with a 0 or 1.'
                    }

                    return 'Phone number invalid'
                }
            }} />
            <ShowFieldError name={phoneOption} subName={'phoneNumber'} errors={errors}/>
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonCol size="12" class="d-block mt-1">
                <IonLabel className="p-0 m-0">{phoneOption} Phone Type:</IonLabel>
            </IonCol>
        <Controller control={control} defaultValue={defaultValues?.phoneType} name={`${phoneOption}.phoneType`}
            rules={{
                required: !phoneNumberIsBlank() && `Please select a Phone Type for ${phoneOption} Phone`
            }}
            render={
                props=>{
                    return <IonSelect {...mapFieldPropsToIonProps(props.field)} placeholder={`Please type in a ${phoneOption} Phone number first`} interface="action-sheet" class={"w-100 gr-border p-1"} mode="ios" disabled={phoneNumberIsBlank()}>
                        {otherPhoneType !== 'home' && phoneTypeSelectOptions.home}
                        {otherPhoneType !== 'mobile' && phoneTypeSelectOptions.mobile}
                        {otherPhoneType !== 'office' && phoneTypeSelectOptions.office}
                    </IonSelect>
                }
            }
        />
        <ShowFieldError name={phoneOption} subName={'phoneType'} errors={errors}/>
        </IonCol>
    </> 
}

export default PhoneFields