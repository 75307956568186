import React, { FC } from 'react';
import {
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonItem,
} from '@ionic/react';
import {  Controller } from 'react-hook-form';
import { expirationValidaton } from 'shared-utils'
import validator from 'validator'
import { translateProps } from './CreditCardFormHelpers'

interface IProps {
    creditCardNumberFieldName?: 'number' | 'creditCardNumber'
    defaultValues:{
        creditCardNumber: string,
        expiry: string
        zipCode: string
    },
    errors: any,
    control: any,
    validateFields?: boolean,
    maskValue?: string
}

const CreditCardFields: FC<IProps> = ({defaultValues, errors, control, validateFields = true, maskValue, creditCardNumberFieldName = 'number'}) => { 
 
    return (
        <>
            <IonRow className='mt-1'>
                <IonCol sizeSm="12" sizeMd="12" sizeLg="12">
                    {validateFields && <span className="text-danger">* </span>}
                    <IonLabel className="p-0 m-0">Credit Card Number:</IonLabel>
                    <Controller name={creditCardNumberFieldName} defaultValue={defaultValues.creditCardNumber} control={control} render={({field}) =>
                        <IonInput  class="ion-text-left gr-border" {...translateProps(field)} maxlength={19} />
                    } rules={{
                        required: validateFields ? 'Please provide a credit card number.' : undefined,
                        validate: {
                            isCardValid: async (value) => {
                                if ((validateFields || value) && !value.startsWith(maskValue) && !validator.isCreditCard(value)) {
                                    return "Please enter a valid credit card number."
                                }
                            }
                        }
                    }} />
                    {errors[creditCardNumberFieldName] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors[creditCardNumberFieldName]?.message}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className='mt-1'>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="6">
                    {validateFields && <span className="text-danger">* </span>} 
                    <IonLabel className="p-0 m-0">Expiration Date:</IonLabel>
                    <Controller name='expiry' defaultValue={defaultValues.expiry} control={control} render={({ field }) =>
                        <IonInput  placeholder='MM/YY'  class="ion-text-left gr-border" {...translateProps(field)}  maxlength={5} />
                    } rules={
                        {
                        required: validateFields ? 'Please provide an expiration date.' : undefined,
                        validate: {
                            isExpiry: async (value) => {
                                if (value || validateFields) {
                                    return expirationValidaton(value)
                                }
                            }
                        }
                    }} />
                    {errors['expiry'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['expiry']?.message}</p></IonItem>}
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="6">
                    {validateFields && <span className="text-danger">* </span>} 
                    <IonLabel className="p-0 m-0">Zip Code:</IonLabel>
                        <Controller name='zipCode' defaultValue={defaultValues.zipCode} control={control} render={({ field }) =>
                            <IonInput  class="ion-text-left gr-border"  {...translateProps(field)} maxlength={5} />
                        } rules={{
                            required: validateFields ? 'Please provide a Zip Code.' : undefined
                        }} />
                        {errors['zipCode'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['zipCode']?.message}</p></IonItem>}
                </IonCol>
            </IonRow>
        </>          
    )};

export default CreditCardFields
