import './Documents.css';
import React, { Fragment, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { openFileNatively, openFileInWeb, openFileInWebMobile } from '../helpers/documentHelper';
import {
  IonItem,
  IonButton,
  IonModal,
  IonPage,
  IonList,
  IonRow,
  IonCol,
} from '@ionic/react';
import {  formatNumberOfUnits, isMobileWeb } from '../helpers/Utils';
import calloutHelper from '../helpers/calloutHelpers';
import DataLoader from './DataLoader';
import {formatNumber, formatDateString} from 'shared-utils'

const CaseDetails: React.FC<{ caseItem: Case }> = ({ caseItem }) => {
  const [ downloading, setDownloading ] = useState<boolean>(false)

  const valuationChangeRecordTypeId = '0120M000000ASPjQAO';

  const renderDocumentList = (docs: Array<Documents>) => {
    return <>
      <IonModal backdropDismiss={false} swipeToClose={false} keyboardClose={false} isOpen={downloading} cssClass={"document-modal"}>
        <IonPage><div className="loading">Downloading</div></IonPage>
      </IonModal>
      {(docs.length > 0) && <IonItem data-test='documents-title' color="tertiary">
        Documents
      </IonItem>}
      <IonList class="m-0 p-0">
        {docs.map(value => {
          return <IonItem key={value.Id}>{renderDocElement(value)}</IonItem>
        })}
      </IonList>
    </>
  }

  const resolveFile =  (attachmentId: string, fileName: string, mimeType:string) => async () => {
    let fileNameCleaned = fileName.replace(/\//g,'_')

    if(Capacitor.isNative){
      setDownloading(true)
      await openFileNatively(attachmentId, fileNameCleaned, mimeType)
      setTimeout(()=>{
        setDownloading(false)
      }, 100)
      return
    }

    if(isMobileWeb()){
      setDownloading(true)
      await openFileInWebMobile(attachmentId, fileNameCleaned)
      setTimeout(()=>{
      }, 100)
      return
    }

    //web => open special link in new tab
    setDownloading(true)
    await openFileInWeb(attachmentId, mimeType)
    setTimeout(()=>{
      setDownloading(false)
    }, 100)
  }

  const renderDocElement = (value: Documents) => {
    return (
      <p>
        <b>Document Name</b>: {value.Name}<br />
        <b>Document File Name</b>: {value.FileName}<br />
        <b>Document Type</b>: {value.Type}<br />
        {value.Description && <Fragment><b>Description</b>: {value.Description}<br/></Fragment>}
        <b>Document Date</b>: {formatDateString(value.DocDate)}<br />
        <IonButton onClick={resolveFile(value.AttachmentId, value.FileName, value.MimeType)}>Open File</IonButton>
      </p>
    )
  }

  return (
    <>
      <IonItem data-test='case-details-title' color="tertiary">
          Case Details
      </IonItem>
      <IonRow class="p-1">
        <IonCol>
          <p data-test='account' className="p-0 m-0"><b>Account</b>: {caseItem?.Account?.Name} - {caseItem?.Account?.ClientAccount?.FirstName} {caseItem?.Account?.ClientAccount?.LastName}</p>
          <p data-test='reason' className="p-0 m-0"><b>Case Reason</b>: {caseItem.Reason}</p>
          {caseItem.Description && <p data-test='description'><b>Description</b>: {caseItem.Description}</p>}
          <p data-test='status' className="p-0 m-0"><b>Status</b>: {caseItem.Status}</p>
        </IonCol>
      </IonRow>
          
      {(caseItem.RecordTypeId === valuationChangeRecordTypeId) && 
      <>
        <IonItem data-test='valuation-details-title' color="tertiary">
          Valuation Details
        </IonItem>
        <IonRow class="p-1">
          <IonCol>
            {(caseItem.CurrentValuePerUnit) ? <p data-test='current-value-per-unit' className="p-0 m-0"><b>Current Value Per Unit</b>: ${formatNumber((caseItem.CurrentValuePerUnit).toFixed(2))}</p> : ''}
            {(caseItem.CurrentNumberOfUnits) ? <p data-test='current-number-of-units' className="p-0 m-0"><b>Current Number of Units</b>: {formatNumberOfUnits(formatNumber((caseItem.CurrentNumberOfUnits).toFixed(6)))}</p> : ''}
            {(caseItem.CurrentMarketValue) ? <p data-test='current-market-value' className="p-0 m-0"><b>Current Market Value</b>: ${formatNumber((caseItem.CurrentMarketValue).toFixed(2))}</p> : ''}
            {(caseItem.NewValuePerUnit) ? <p data-test='new-value-per-unit' className="p-0 m-0"><b>New Value Per Unit</b>: ${formatNumber((caseItem.NewValuePerUnit).toFixed(2))}</p> : ''}
            {(caseItem.NewNumberOfUnits) ? <p data-test='new-number-of-units' className="p-0 m-0"><b>New Number of Units</b>: {formatNumberOfUnits(formatNumber((caseItem.NewNumberOfUnits).toFixed(6)))}</p> : ''}
            {(caseItem.NewMarketValue) ? <p data-test='new-market-value' className="p-0 m-0"><b>New Market Value</b>: ${formatNumber((caseItem.NewMarketValue).toFixed(2))}</p> : ''}
            {(caseItem.MarketValueChange) ? <p data-test='market-value-change' className="p-0 m-0"><b>Market Value Change</b>: ${formatNumber((caseItem.MarketValueChange).toFixed(2))}</p> : ''}
          </IonCol>
        </IonRow>
      </>}
  
      <DataLoader apiCall={() => calloutHelper.getCaseDocs(caseItem?.Id)} render={renderDocumentList} dependencies={[caseItem]} />
    </>
  );
};


export default CaseDetails;
