import React from 'react';
import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonToast,
  IonButton,
} from '@ionic/react';        
import Sidebar from './Sidebar';
import ToggleComponent from './ToggleComponent';
import HoldingList from './Holdings/HoldingList';
import TooltipComponent from './TooltipComponent/TooltipComponent';
import helpCircleOutline from '../images/help-circle-outline.svg';
import { connect } from 'react-redux';
import calloutHelpers from '../helpers/calloutHelpers'
import DataLoader from './DataLoader';
import { isMobile, getAvailableCashBalance } from '../helpers/Utils';
import { formatNumber, formatDateString } from 'shared-utils'
import { useHistory } from 'react-router-dom';
import PartyTypeConditionalAccess from './PartyTypeConditionalAccess';

const AccountDetails: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {
  const history = useHistory()
  const toastHistory = useHistory<{ Toast?: { message: '', color: 'success' } }>();
  const toast = toastHistory?.location?.state?.Toast;

  const renderRelatedParties = (data: { relatedParties: Array<RelatedParties> }) => {
    
    let relatedParties = data?.relatedParties;
        
    const navigateToEditRelatedParties = () => {
      history.push({
        pathname: '/edit-beneficiaries',
        state: { accountId: selectedAccount?.id, }
      })
    }

    return <>
      {(!relatedParties || (relatedParties.length < 1)) ? <p>This account does not have any related parties.</p> :
        <IonList class="p-0 m-0">
          {relatedParties.map((relatedParty: RelatedParties) => {
            return (relatedParty.partyType !== 'Account Holder' && relatedParty.partyType !== 'Fund Participant') && <IonItem key={relatedParty.id}>
              <p>
                <b>Name</b>: {relatedParty.name} <br />
                <b>Address</b>: {relatedParty.address} <br />
                <b>Party Type</b>: {relatedParty.partyType} <br />
                {relatedParty.share && <><b>Share</b>: {relatedParty.share}%<br /></>}
                {relatedParty.onlineAccess && <TooltipComponent title={'Online Access '} titleColor='success' icon={helpCircleOutline} message={'This individual has online access to view your account'} />}
              </p>
            </IonItem>}
          )}
        </IonList>}
      <PartyTypeConditionalAccess featureDescription={'editRelatedParties'}>
        <IonButton className="ion-float-right mt-2" onClick={navigateToEditRelatedParties}>Edit Beneficiaries</IonButton>
      </PartyTypeConditionalAccess>
    </>
  }

  const undirectedCashBalanceLabel = 'Undirected Cash Balance (+) '
  const pendingCashCreditsLabel = 'Pending Cash Credits (+) '
  const holdingsOnDepositsLabel = 'Deposits on Hold (-) '
  const mimimumCashBalanceLabel = 'Minimum Cash Balance (-) '
  const pendingCashDebitsLabel = 'Pending Cash Debits (-) '
  const pendingFeesLabel = 'Pending Fees (-) '
  const totalAccountValueLabel = 'Total Account Value '
  
  const undirectedCashBalanceHelpText = 'This is your undirected cash balance on deposit. This balance may be subject to availability as indicated below.'
  const pendingCashCreditsHelpText = 'This amount represents cash credits received today, but not yet posted.'
  const holdingsOnDepositsHelpText = 'This amount represents recently deposited checks that are not yet available for use.'
  const mimimumCashBalanceHelpText = 'This amount represents the minimum undirected cash balance you are required to leave in your Equity Trust account.'
  const pendingCashDebitsHelpText = 'This amount represents pending outgoing cash transactions such as bill payments, distributions or purchase of an asset.'
  const pendingFeesHelpText = 'This amount represents Equity Trust administration fees that are scheduled to be deducted from your account cash balance.'
  const totalAccountValueHelpText = 'If this value appears incorrect, a Fair Market Value update may be required on one or more of your holdings to bring the value of the account to an accurate total.'

  return (
    <>
      <IonToast isOpen={(toast?.message !== undefined && toast.color !== undefined)} color={toast?.color} position='top' message={toast?.message} duration={3 * 1000} buttons={[{ icon: 'close-circle' }]} />
      <IonRow class="container">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
          <ToggleComponent isActive={true} title="Account Information" bodyData={
            isMobile() ? <IonList>
              {selectedAccount &&  
                <>
                  <IonItem><p><b>Account Number</b>: {selectedAccount.accountNumber}</p></IonItem>
                  <IonItem><p><b>Type</b>: {selectedAccount.accountType}</p></IonItem>
                  <IonItem><p><b>Open Date</b>: {formatDateString(selectedAccount.openDate)}</p></IonItem>
                  <IonItem><p><b>Asset Titling</b>: {selectedAccount.assetTitling}</p></IonItem>

                  <IonItem><p><TooltipComponent title={undirectedCashBalanceLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.undirectedCashBalance?.toFixed(2))} message={undirectedCashBalanceHelpText} /></p></IonItem>

                  <IonItem><p><TooltipComponent title={pendingCashCreditsLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.pendingCashCredits?.toFixed(2))} message={pendingCashCreditsHelpText} /></p></IonItem>
                  
                  <IonItem><p><TooltipComponent title={holdingsOnDepositsLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.holdingsOnDeposits?.toFixed(2))} message={holdingsOnDepositsHelpText} /></p></IonItem>

                  <IonItem><p><TooltipComponent title={mimimumCashBalanceLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.minimumCashBalance?.toFixed(2))} message={mimimumCashBalanceHelpText} /></p></IonItem>

                  <IonItem><p><TooltipComponent title={pendingCashDebitsLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.pendingCashDebits?.toFixed(2))} message={pendingCashDebitsHelpText} /></p></IonItem>

                  <IonItem><p><TooltipComponent title={pendingFeesLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.pendingFees?.toFixed(2))} message={pendingFeesHelpText} /></p></IonItem>

                  <IonItem><p><b>Cash Available</b>: ${formatNumber((getAvailableCashBalance(selectedAccount))?.toFixed(2))}</p></IonItem>

                  <IonItem><p><TooltipComponent title={totalAccountValueLabel} icon={helpCircleOutline} body={': $' + formatNumber(selectedAccount.totalAccountValue?.toFixed(2))} message={totalAccountValueHelpText} /></p></IonItem>
                  <PartyTypeConditionalAccess featureDescription={'generateStatement'}>
                    <IonButton className="ion-float-right mt-2" type='button' onClick={() => history.push('/generate-statement')}>Generate Statement</IonButton>
                  </PartyTypeConditionalAccess>
                </> 
              }
            </IonList> : <IonList>
              {selectedAccount &&  
                <>
                  <IonRow class="pl-1">
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="text-left m-0"><b>Account Number</b>: </p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="m-0">{selectedAccount.accountNumber}</p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={undirectedCashBalanceLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={undirectedCashBalanceHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.undirectedCashBalance?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="text-left m-0"><b>Type</b>: </p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="m-0">{selectedAccount.accountType}</p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={pendingCashCreditsLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={pendingCashCreditsHelpText} /> 
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.pendingCashCredits?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="text-left m-0"><b>Open Date</b>: </p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="m-0">{formatDateString(selectedAccount.openDate)}</p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={holdingsOnDepositsLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={holdingsOnDepositsHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.holdingsOnDeposits?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={mimimumCashBalanceLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={mimimumCashBalanceHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.minimumCashBalance?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={pendingCashDebitsLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={pendingCashDebitsHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.pendingCashDebits?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <TooltipComponent title={pendingFeesLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={pendingFeesHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.pendingFees?.toFixed(2))}</p>
                    </IonCol>
                    
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <TooltipComponent title={totalAccountValueLabel} className="tooltip-aligned-left" icon={helpCircleOutline} message={totalAccountValueHelpText} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                      <p className="m-0">{'$' + formatNumber(selectedAccount.totalAccountValue?.toFixed(2))}</p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      <p className="text-left m-0"><b>Cash Available</b>:</p>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <p className="m-0">${formatNumber(getAvailableCashBalance(selectedAccount)?.toFixed(2))}</p>
                    </IonCol>
                  </IonRow>
                  <IonRow class="pl-1 mt-4">
                    {!selectedAccount?.partyType.includes('Fund Manager') &&
                      <>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                          <p className="text-left m-0"><b>Asset Titling</b>: </p>
                        </IonCol>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                          <p className="m-0">{selectedAccount.assetTitling}</p>
                        </IonCol>
                      </>
                    }
                    <IonCol>
                      <PartyTypeConditionalAccess featureDescription={'generateStatement'}>
                        <IonButton className="ion-float-right mt-2" type='button' onClick={() => history.push('/generate-statement')}>Generate Statement</IonButton>
                      </PartyTypeConditionalAccess>
                    </IonCol>
                  </IonRow>
                </>
              }
            </IonList>
          }/>
          
          <ToggleComponent isActive={false} title="Holdings" bodyData={
            <HoldingList />
          } />

          <ToggleComponent isActive={false} title="Related Parties" bodyData={
            selectedAccount && selectedAccount?.id && <DataLoader apiCall={() => calloutHelpers.getAccountDetails(selectedAccount?.id)} render={renderRelatedParties} dependencies={[selectedAccount]} />
          } />
          
        </IonCol>
        <Sidebar />
      </IonRow>
    </>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount
  }
}

export default connect(mapStateToProps)(AccountDetails);