import { isPlatform } from '@ionic/react';

import { Capacitor } from '@capacitor/core'
import logoLightMode from '../images/logo-dark.png';
import logoDarkMode from '../images/logo-light.png';

export const EMAIL_VALIDATION_PATTERN = "^([a-zA-Z0-9_\\-.+]+)@([a-zA-Z0-9_\\-.+]+)\\.([a-zA-Z]{2,5})$";

export const PHONE_VALIDATION_PATTERN = "^(\\+\\d{1,2}\\s?)?1?-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";

export const SSN_VALIDATION_PATTER = "^(\\d{3}\\-\\d{2}\\-\\d{4})$"

export const getAvailableCashBalance = (account?: RetirementAccount) => {
    
    const undirectedCashBalance = account?.undirectedCashBalance || 0
    const pendingCashCredits = account?.pendingCashCredits || 0
    const holdingsOnDeposits = account?.holdingsOnDeposits || 0
    const minimumCashBalance = account?.minimumCashBalance || 0
    const pendingCashDebits = account?.pendingCashDebits || 0
    const pendingFees = account?.pendingFees || 0

    return (+undirectedCashBalance) + (+pendingCashCredits) - (+holdingsOnDeposits) - (+minimumCashBalance) - (+pendingCashDebits) - (+pendingFees)
}

export const formatDateToUTCString = (date: Date, dateFormat: 'MM-DD-YYYY' | 'YYYY-MM-DD' | 'MM-DD-YY' | 'MM/DD/YYYY'): string => {
    const month = `${date.getUTCMonth() + 1}`.padStart(2, '0')
    const dayOfMonth = `${date.getUTCDate()}`.padStart(2, '0')
    const year = date.getUTCFullYear()
    const twoDigitYear = `${date.getUTCFullYear()}`.slice(-2)

    switch(dateFormat) {
        case 'MM-DD-YYYY':
            return `${month}-${dayOfMonth}-${year}` 
        case 'MM-DD-YY':
            return `${month}-${dayOfMonth}-${twoDigitYear}` 
        case 'YYYY-MM-DD':
            return `${year}-${month}-${dayOfMonth}`
        case 'MM/DD/YYYY':
            return `${month}/${dayOfMonth}/${year}`
    }
}

export const states = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];

export const formatNumberOfUnits = (n: any) => {
    return n.match(/([0-9]+,)*([0-9])*(.)([0-9])*/g)[0] + n.match(/([0-9]+,)*([0-9])*(.)([0-9])*/g)[1].replace(',', '');
}

export const formatUnitPrice = (n: any) => {
    let split = n.split('.');
    return (split[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + '.' + split[1]);
}

export const isMobile = () => {
    return (isPlatform('iphone') || isPlatform('android') || isPlatform('ipad'));
}

export const isPhone = () => {
  return (isPlatform('iphone') || isPlatform('android'));
}

export const isMobileWeb = () => (!Capacitor.isNative && isMobile())

export const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export const getLogo = () => {
    return isDarkMode() ? logoDarkMode : logoLightMode;
};

export const convertStringDateToDate = (dateString?: string | undefined | null) => {
    if (dateString)
    {
        return new Date(dateString.replace(/-/g, '/'))
    }
    return null
}

export const formatValueAsCurrency = (value: string | number) => {
    const currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
    return currencyFormatter.format(+value)
}

export const hasEnoughTimeElapsed = (date: string, numberOfMonths: number): boolean => {
    if(!Date.parse(date)) {
        return false
    }
    
    const targetDate = new Date(date)
    targetDate.setMonth(targetDate.getMonth() + numberOfMonths)
    const currentDate = new Date()
    const currentDateAfterTargetDate = currentDate > targetDate

    return currentDateAfterTargetDate
}

export const dateOneYearAgo = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 365);
    return currentDate;
}

export const deliveryMethodsFeeRemover = (deliveryMethod: {description: string, value: string}) => {
    let removingString = ''
    deliveryMethod.description.includes(' (No Fee)') ? removingString = ' (No Fee)'
    : deliveryMethod.description.includes('($5 Fee)') ? removingString = ' ($5 Fee)'
    : deliveryMethod.description.includes(' ($30 Fee)') ? removingString = ' ($30 Fee)'
    : removingString = '';

    const feeRemovedMethodDescription = deliveryMethod.description.replace(removingString, '');
    return {description: feeRemovedMethodDescription, value: deliveryMethod.value};
}

export const amountRegex = /^\d*(\.\d{1,2})?$/

export const isDocCustodyView = (selectedAccount: RetirementAccount) => selectedAccount.accountType === 'Document Custody';
export const isNotDocCustodyView = (selectedAccount: RetirementAccount) => selectedAccount.accountType !== 'Document Custody';