import React from 'react';
import { IonRow, IonCol, IonList } from '@ionic/react';
import { connect } from 'react-redux';
import DataLoader from '../DataLoader';
import ToggleComponent from '../ToggleComponent';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import helpCircleOutline from '../../images/help-circle-outline.svg';
import calloutHelper from '../../helpers/calloutHelpers';

const DocCustodyRelatedParties: React.FC<{selectedAccount?: RetirementAccount | undefined}> = ({selectedAccount}) => {
    const renderRelatedParties = (data: {relatedParties: RelatedParties[]}) => {
        const docCustodyRelatedParties = data?.relatedParties.filter(relatedParty => relatedParty.partyType !== 'Account Holder');
       
        if (!docCustodyRelatedParties || (docCustodyRelatedParties.length < 1)) {
            return <p>This account does not have related parties.</p>
        };

        return (
            <IonList data-testid='related-parties-list' class='pl-2 pr-2'>
                {docCustodyRelatedParties.map(relatedParty => {
                    return (
                        <IonRow key={relatedParty.id} class='divider'>
                            <IonCol class='m-0 p-0 mb-1' size='10'>
                                <IonRow class='mt-1'>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Name</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{relatedParty.name}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Address</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{relatedParty.address}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Type</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{relatedParty.partyType}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Email</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{relatedParty.PersonAccount?.Email}</p>
                                    </IonCol>
                                </IonRow>
                                {relatedParty.share &&
                                    <IonRow>
                                        <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                            <p data-testid={`related-party-share-${relatedParty.id}`} className='m-0'><b>Share</b>:</p>
                                        </IonCol>
                                        <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                            <p className="m-0">{relatedParty.share}%</p>
                                        </IonCol>
                                    </IonRow>
                                }
                                {relatedParty.onlineAccess && 
                                    <IonRow data-testid={`online-access-${relatedParty.id}`}>
                                        <TooltipComponent title={'Online Access '} titleColor='success' icon={helpCircleOutline} message={'This individual has online access to view your account'} />
                                    </IonRow>
                                }
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonList>
        )
    }

    return (
        <ToggleComponent isActive={false} title='Related Parties' bodyData={<DataLoader apiCall={() => calloutHelper.getAccountDetails(selectedAccount?.id)} render={renderRelatedParties} dependencies={[selectedAccount?.id]} />} />
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        selectedAccount: rootState.UserState.selectedAccount
    }
};

export default connect(mapStateToProps)(DocCustodyRelatedParties);