import React from 'react';
import { useForm, Controller, useWatch, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepThree from '../../../images/step-three.svg';
import { isPhone } from '../../../helpers/Utils';
import { InputComponents } from '../BillPayTypes';
export interface StepThree extends InputComponents {
  isFundManagerFull: Boolean
}

const BillPayStepThree: React.FC<StepThree> = ({ formRef, savedFields, submitFields, isFundManagerFull}) => {
  type BillPayTypeFields = BillPaymentWizard.BillPayTypeFields
  const getDefaultValues = (): BillPayTypeFields=>{
    return {
      taxYear: savedFields?.taxYear || '',
      fullOrPartial: savedFields?.fullOrPartial || '',
      paymentMemo: savedFields?.paymentMemo || '',
      expenseType: savedFields?.expenseType || '',
      billPaymentType: savedFields?.billPaymentType || ''
    }
  } 

  const {control, handleSubmit, formState: { errors }, getValues} = useForm<Partial<BillPayTypeFields>> ({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  const billPaymentType = useWatch({
    name: 'billPaymentType',
    control: control
  });

  const onValid: SubmitHandler<Partial<BillPayTypeFields>> = (formFields)=>{
    return submitFields({status: 'ok', formFields})
  }

  const onInvalid: SubmitErrorHandler<Partial<BillPayTypeFields>> = (errors) => {
    let formFields = getValues()
    submitFields({status: 'error', formFields: formFields, errors})
  }

  return (
    <div data-test="bill-pay-step-three">
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepThree} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.50}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <form data-test="bill-pay-step-three-form" ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}> 
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol>
                {!isFundManagerFull &&
                  <>
                    <IonRow class="mt-2 mb-1">
                      <IonLabel>What type of bill is this?</IonLabel>
                    </IonRow>
                    <IonRow class="w-100">
                      <Controller data-test="bill-payment-type" name='billPaymentType' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                      <IonSelect class="w-100 gr-border p-1" name={name} tabIndex={0} interface='action-sheet' onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                          <IonSelectOption>Bill Payment</IonSelectOption>
                          <IonSelectOption>Note Payable Payment</IonSelectOption>
                          {savedFields?.frequency !== 'Recurring' &&
                            <IonSelectOption>Real Estate Tax Payment</IonSelectOption>
                          }
                        </IonSelect>
                      } rules={{
                        required: true,
                        validate: (value) => { return value !== null }
                        }} />
                    </IonRow>
                    {errors['billPaymentType'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please add the type of bill you'd like to pay.</p></IonItem>}

                    {(billPaymentType === 'Bill Payment') &&
                      <>
                        <IonRow class="mt-2 mb-1">
                          <IonLabel>Expense Type</IonLabel>
                        </IonRow>
                        <IonRow class="w-100">
                        <Controller name='expenseType' data-test="expense-type" control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                          <IonSelect class="w-100 gr-border p-1" tabIndex={0} interface="action-sheet" mode="ios" name={name} onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                            <IonSelectOption value="Insurance">Insurance</IonSelectOption>
                            <IonSelectOption value="Homeowner Dues">Homeowner Dues</IonSelectOption>
                            <IonSelectOption value="Taxes">Taxes</IonSelectOption>
                            <IonSelectOption value="Utilities">Utilities</IonSelectOption>
                            <IonSelectOption value="Other">Other</IonSelectOption>
                          </IonSelect>
                        } rules={{
                          required: true,
                          validate: (value) => { return value !== null }
                          }} /> 
                        </IonRow>
                        {errors['expenseType'] && <IonItem class="mt-1" color="danger" data-test="expense-type-error"><p className="white-color">Please select the proper expense type.</p></IonItem>}
                      </>}

                    {(billPaymentType === 'Real Estate Tax Payment') &&
                      <>
                        <IonRow class="mt-2 mb-1">
                          <IonLabel>Tax Year</IonLabel>
                        </IonRow>
                        <IonRow>
                          <Controller name="taxYear" data-test="tax-year" control={control} render={({ field: { name, value, onChange }})=><InputMask mask='9999' className="input-mask mt-0 gr-border" type="text" name={name} value={value} onChange={onChange}/>}
                          rules={{
                            required: 'Please enter the tax year.',
                            pattern: {
                              value: /^(19|20)\d{2}$/,
                              message: 'Tax year incorrect.'
                            }
                          }} />
                        </IonRow>
                          {errors['taxYear'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['taxYear'].message}</p></IonItem>}
                        <IonRow class="mt-2 mb-1">
                          <IonLabel>Full/Partial Payment</IonLabel>
                        </IonRow>
                        <IonRow class="w-100">
                        <Controller name='fullOrPartial' data-test='full-partial' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                          <IonSelect class="w-100 gr-border p-1" name={name} tabIndex={0} interface="action-sheet" mode="ios" onIonChange={onChange} onIonBlur={onBlur} value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                            <IonSelectOption>Full Payment</IonSelectOption>
                            <IonSelectOption>Partial Payment</IonSelectOption>
                          </IonSelect>
                        } rules={{
                          required: true,
                          validate: (value) => { return value !== null }
                          }} />
                        </IonRow>
                        {errors['fullOrPartial'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please select whether this is a full or partial payment.</p></IonItem>}
                      </> 
                      }
                  </>
                }
                     
                <IonRow class="mt-2 mb-1">   
                  {(billPaymentType === 'Real Estate Tax Payment') ?
                    <IonLabel data-test="parcel-memo-label">Parcel Number/Memo (This memo will be provided to the payee to help recipient properly credit your payment. Examples include a parcel number, invoice number, account number or reference number. Maximum 30 characters.)</IonLabel>
                    : 
                    <IonLabel data-test='payment-memo-label'>Payment Memo (This memo will be provided to the payee to help recipient properly credit your payment. Examples include an invoice number, account number or reference number. Maximum 30 characters.)</IonLabel>
                  }
                </IonRow>
                <IonRow>
                  <Controller name="paymentMemo" control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                    <IonInput class="ion-text-left mt-0 gr-border pl-1 pr-1" type="text" name={name} maxlength={30} onIonBlur={onBlur} onIonChange={onChange} value={value} />
                  } rules={{
                    required: true,
                    validate: (value) => { return value !== null }
                    }} />
                </IonRow>
                {errors['paymentMemo'] && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter memo or parcel number to help the recipient properly credit your payment.</p></IonItem>}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </form>
    </div>
  );
};

export default BillPayStepThree;
