import React from 'react';
import { connect } from 'react-redux';
import ContainerComponent from '../ContainerComponent';
import { useHistory } from 'react-router-dom';
import { IonRow, IonCol, IonList, IonItem, IonIcon } from '@ionic/react';
import { logInOutline, logOutOutline } from 'ionicons/icons';
import { checkPartyTypeFeatureAndHomeQuickLinkAccess } from '../PartyTypeConditionalAccess';

const DocCustodySidebar: React.FC<{selectedAccount: RetirementAccount | undefined}> = ({selectedAccount}) => {
    const history = useHistory();
    const pathname = history?.location?.pathname;

    let menuItems: any[] = [];

    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createVaultIn')) {
        const vaultInButton = {
            text: 'Vault In Document',
            icon: logInOutline,
            handler: () => history.push('/vault-in')
        };

        menuItems.push(vaultInButton);
    }

    if (checkPartyTypeFeatureAndHomeQuickLinkAccess(selectedAccount, pathname, 'createVaultOut')) {
        const VaultOutButton = {
            text: 'Vault Out Document',
            icon: logOutOutline,
            handler: () => history.push('/vault-out')
        };

        menuItems.push(VaultOutButton);
    }

    return (
        <IonCol class='display-desktop' sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
            <IonRow>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                    <IonList class='p-0'>
                        <ContainerComponent className='p-1 light-gr-bg mb-0' title='I want to:' content={
                            menuItems.map(menuItem => {
                                return <IonItem key={menuItem.text} onClick={() => menuItem.handler()} class='cursor-pointer text-branded-orange'><IonIcon color='tertiary' slot='start' icon={menuItem?.icon} class='m-0 pr-1'></IonIcon>{menuItem.text}</IonItem>
                            })
                        } />
                    </IonList>
                </IonCol>
            </IonRow>
        </IonCol>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
      selectedAccount: rootState.UserState.selectedAccount,
    }
  }

export default connect(mapStateToProps)(DocCustodySidebar);