import React from 'react'
import { App } from '@capacitor/core'
import axios from 'axios'

const AutoLogout: React.FC<{logout: () => Promise<void>}> = ({logout, children}) => {

    let lastCallTime = React.useRef(Date.now())
    
    const checkActivity = React.useCallback(() => {
        const ONE_HOUR = 3600000
        //const TEST_TIME = 1000 * 20
        let currentTime = Date.now()
        let timeDiff = currentTime - lastCallTime.current
        
        if(timeDiff > ONE_HOUR){
            return false
        }else{
            lastCallTime.current = currentTime;
            return true
        }
    },[])

    React.useEffect(()=>{
        let reqSubNumber = axios.interceptors.request.use( async (req)=>{
            let isSessionActive = checkActivity()
            if(isSessionActive){
                return req
            }else{
                await logout()
                throw new axios.Cancel('Auth timed out')
            }
        })

        return ()=>{
            axios.interceptors.request.eject(reqSubNumber)
        }
    },[checkActivity, logout])

    React.useEffect(()=>{
        let handler = App.addListener('appStateChange', (state)=>{
            let isSessionActive = checkActivity()//by putting this outside, activity will be updated when the user moves out of context
            if(state.isActive){ //user has transitioned back onto the app
                if(!isSessionActive){
                    try{
                        logout();
                    }catch(err){
                        //noop
                    }
                }
            }
        })
        return ()=>{
            handler.remove()
        }
    },[logout])

    return <>
        {children}
    </>
}

export default AutoLogout