import React, { useRef, useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { IonRow, IonCol, IonPage, IonHeader, IonLabel, IonContent, IonRouterLink } from '@ionic/react';
import { AuthCheckContext } from '../../helpers/authHelpers/authService';
import { connect } from 'react-redux';
import HeaderTemplate from '../HeaderTemplate';
import NavBarTemplate from '../NavBarTemplate';
import DocCustodyHome from './DocCustodyHome';
import Billing from '../Billing';
import Footer from '../Footer';
import MyProfileContainer from '../MyProfileComponents/MyProfileContainer';
import CreditCardPayments from '../CreditCardComponents/CreditCardPayments';
import UpdatePaymentMethod from '../UpdatePaymentMethod';
import SelectAccountBar from '../SelectAccountBar';
import VaultInWizard from './VaultInWizard';
import VaultOutWizard from './VaultOutWizard';
import InvestorRoster from '../InvestorRoster';
import AddInvestorWizard from '../AddInvestor/AddInvestorWizard';
import ConfirmationPage from '../ConfirmationPage';
import EditInvestorParticipation from '../EditInvestorParticipation';
import { isNotDocCustodyView } from '../../helpers/Utils';

const HIDE_ACCOUNT_SELECTOR_LIST = [
    'investor-roster'
]

const DocCustodyPage: React.FC<{selectedAccount: RetirementAccount | undefined, setDefaultViewMode():void}> = ({selectedAccount, setDefaultViewMode}) => {
    const { comp } = useParams<{ comp: string; }>();
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop = () => {
        contentRef?.current && contentRef.current.scrollToTop();
    }
    const [ showFeedbackModal, setShowFeedbackModal ] = useState<Boolean>(false);

    const docCustodyPages = [
        'Home',
        'Investor Roster',
        'Billing'
    ];

    useEffect(() => {
        scrollToTop();
    }, [comp]);

    useEffect(() => {
        if (selectedAccount) {
            if (isNotDocCustodyView(selectedAccount)) {
                setDefaultViewMode();
            };
        };
    }, [selectedAccount]);

    const displayPage = (pageName:string) => {
        switch (pageName) {
            case 'home':
                return <DocCustodyHome />
            case 'billing':
                return <Billing />
            case 'my-profile':
                return <MyProfileContainer />
            case 'card-payment':
                return <CreditCardPayments />
            case 'update-payment-method':
                return <UpdatePaymentMethod />
            case 'vault-in':
                return <VaultInWizard />
            case 'vault-out':
                return <VaultOutWizard />
            case 'investor-roster':
                return <InvestorRoster />
            case 'add-investor':
                return <AddInvestorWizard scrollToTop={scrollToTop} />
            case 'confirmation':
                return <ConfirmationPage />
            case 'edit-investor-participation':
                return <EditInvestorParticipation />
        };
    };

    return (
        <IonPage data-testid='document-custody-page'>
            <IonHeader>
                <HeaderTemplate>
                    <IonCol size="8">
                        <NavBarTemplate pages={docCustodyPages}/>
                    </IonCol>
                    <IonCol size="3">
                        <DocCustodyRouterLinks />
                    </IonCol>
                </HeaderTemplate>
                {(!HIDE_ACCOUNT_SELECTOR_LIST.includes(comp)) &&
                    <IonRow class="container white-bg mr-auto ml-auto mt-0 mb-0 p-0">
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
                            <IonRow className='light-gr-bg'>
                                <IonCol sizeXs="5" sizeSm="5" sizeMd="4" sizeLg="4" sizeXl="3" className='p-0 m-0'>
                                    <IonLabel className='mt-2 d-flex justify-center align-items-center'>Select Account:</IonLabel>
                                </IonCol>
                                <IonCol sizeXs="7" sizeSm="7" sizeMd="8" sizeLg="8" sizeXl="9" className='p-0 m-0'>
                                    <SelectAccountBar />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                }
            </IonHeader>
            <IonContent fullscreen ref={contentRef}>
                <IonRow class='main-page'>
                    <IonCol>
                        {displayPage(comp?.toLowerCase())}
                    </IonCol>
                </IonRow>
                <Footer setShowFeedbackModal={setShowFeedbackModal} showFeedbackModal={showFeedbackModal}/>
            </IonContent>
        </IonPage>
    )
};

const DocCustodyRouterLinks: React.FC = () => {
    const authCheckContext = useContext(AuthCheckContext);
    const history = useHistory();
    
    return (
        <p className="text-right">
            <IonRouterLink class="hover-cursor nav-item" onClick={() => history.push('/my-profile')} color="light">My Profile</IonRouterLink> 
            | 
            <IonRouterLink class='hover-cursor nav-item' onClick={()=>{authCheckContext.current.logout()}} color="light">Logout</IonRouterLink>
        </p>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        selectedAccount: rootState.UserState.selectedAccount
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setDefaultViewMode: () => dispatch(
            {type: 'setViewMode', payload: {viewMode: 'DEFAULT_TYPE'}}
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DocCustodyPage);