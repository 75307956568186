import { AxiosError, AxiosResponse } from 'axios';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Spinner from './Spinner'

const DataLoader: React.FC<{apiCall():Promise<any>, render: Function, dependencies?: Array<any>}> = ({apiCall, render, dependencies}) => {
    if(!dependencies)
        dependencies = []
    const [loadedData, setLoadedData] = useState()
    const [loadingState, setLoadingState] = useState(true)
    const apiCallCancelled = useRef(false);

    /**
     * Added the useref as a half-hearted attempt at cancelling the setState callbacks
     * I'm not sure if the callBack closures will actually work, I'm curious if the ref would turn undefined
     * anyway, cancelling the api call would probably be a better solution but it wouldn't hurt to look into ways
     * of cancelling callbacks if the component is unmounted. I saw a few concepts on medium about ways to do this
     */
    useEffect(()=>{
        apiCallCancelled.current = false;
        //setLoadingState(true) leaving this here for now
        try{
            apiCall().then((result)=>{
                if(apiCallCancelled.current){
                    return;
                }
                setLoadedData(result.data)
                setLoadingState(false)
            }).catch((err: AxiosError)=>{
                if(apiCallCancelled.current){
                    return;
                }
                setLoadingState(false)
                setLoadedData(undefined)
            })
        }catch(err){
            if(apiCallCancelled.current){
                return;
            }
            setLoadingState(false)
            setLoadedData(undefined)
        }

        return ()=>{
            apiCallCancelled.current = true;
        }
    },[...dependencies, setLoadingState, setLoadedData, apiCallCancelled])

    return (loadingState ? <Spinner/> : <Fragment> {render(loadedData)} </Fragment>)
}

export default DataLoader