import React from 'react'
import { IonRow, IonCol, IonItem, IonIcon } from '@ionic/react'
import { checkbox, stopOutline } from 'ionicons/icons';
import { formatPhoneNumber } from 'shared-utils'
import { getPreferredContactMethodLabel } from './MyProfile'

const MyProfileReadOnly: React.FC<{personDetails: PersonAccountHelpers.PersonDetails}> = ({personDetails})=>{
    const firstLetterUppercase = (val: string) => `${val.slice(0,1).toUpperCase()}${val.slice(1)}`
    
    return <IonRow>
        <IonCol size="12">
          <IonItem color="tertiary" class="mt-3">
            Personal Contact Information
          </IonItem>
          <IonRow class="p-1">
            <IonCol size="12">
              <p className="p-0 m-0"><b>Name</b>: {`${personDetails?.firstName} ${personDetails?.lastName}`}</p>
                <p className="p-0 m-0"><b>Email</b>: {personDetails?.email} </p>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                <p className="p-0 m-0"><b>Primary Phone</b>:  {formatPhoneNumber(personDetails.phones.Primary.phoneNumber)}</p>
              {personDetails.phones?.Secondary?.phoneNumber &&  <p className="p-0 m-0"><b>Secondary Phone</b>:  {formatPhoneNumber(personDetails.phones.Secondary.phoneNumber)}</p>}
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                <p className="p-0 m-0"><b>Primary Phone Typ</b>:  {firstLetterUppercase(personDetails.phones.Primary.phoneType)}</p>
                {personDetails.phones?.Secondary?.phoneType && <p className="p-0 m-0"><b>Secondary Phone Type</b>:  {firstLetterUppercase(personDetails.phones.Secondary.phoneType)}</p>}
            </IonCol>
          </IonRow>
          <IonItem color="tertiary" class="mt-1">
            Address
          </IonItem>
          <IonRow class="p-1">
            {personDetails?.mailingShippingDiffer &&  <IonCol size="12">
                <p className="p-0 m-0"><b>My mailing address is different than my legal address</b>: <IonIcon icon={personDetails?.mailingShippingDiffer ? checkbox : stopOutline} class="lg-checkbox" color={personDetails?.mailingShippingDiffer ? "primary" : "dark"} ></IonIcon></p>
            </IonCol>}
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                <p className="p-0 m-0"><b>Mailing Street</b>: {personDetails?.mailingStreet}</p>
                <p className="p-0 m-0"><b>Mailing City</b>: {personDetails?.mailingCity}</p>
                <p className="p-0 m-0"><b>Mailing State</b>: {personDetails?.mailingState}</p>
                <p className="p-0 m-0"><b>Mailing Postal Code</b>: {personDetails?.mailingPostalCode}</p>
            </IonCol>
              {personDetails?.mailingShippingDiffer && <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                  <p className="p-0 m-0"><b>Legal Street</b>:  {personDetails?.legalStreet}</p>
                <p className="p-0 m-0"><b>Legal City</b>:  {personDetails?.legalCity}</p>
                  <p className="p-0 m-0"><b>Legal State</b>:  {personDetails?.legalState}</p>
                  <p className="p-0 m-0"><b>Legal Postal Code</b>:  {personDetails?.legalPostalCode}</p>
              </IonCol>}
            </IonRow>
            <IonItem color="tertiary" class="mt-1">
              Contact Preferences
            </IonItem>
            <IonRow class="p-1">
              <IonCol size="12">
                  <p className="p-0 m-0"><b>Preferred Contact Method</b>:  {getPreferredContactMethodLabel(personDetails?.preferredContactMethod)}</p>
                  <p className="p-0 m-0"><b>I do not want to receive monthly newsletter or other education-related emails</b>: <IonIcon icon={personDetails?.marketingOptOut ? checkbox : stopOutline} class="lg-checkbox" color={personDetails?.marketingOptOut ? "primary" : "dark"}></IonIcon></p>
              </IonCol>
            </IonRow>
            <IonItem color="tertiary" class="mt-1">
              Login Preferences
            </IonItem>
            <IonRow class="p-1">
              <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                  <p className="p-0 m-0"><b>Enable Multi Factor Authentication</b>: <IonIcon icon={personDetails?.multiFactorAuthEnabled ? checkbox : stopOutline} class="lg-checkbox" color={personDetails?.multiFactorAuthEnabled ? "primary" : "dark"}></IonIcon></p>
              </IonCol>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                <p className="p-0 m-0"><b>Enable Account Security Question/PIN</b>: <IonIcon icon={personDetails?.enableSecurityQuestion ? checkbox : stopOutline} class="lg-checkbox" color={personDetails?.enableSecurityQuestion ? "primary" : "dark"} ></IonIcon></p>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
}

export default MyProfileReadOnly