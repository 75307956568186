import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../reducers/store';

export const checkAccessToHomeQuickLinks = (pathname: string): boolean => {

    const hasAccessToHomeQuickLinks: boolean = store.getState().CachedObjects.accessDefinitions.hasAccessToHomeQuickLinks;

    if (hasAccessToHomeQuickLinks && pathname === '/home') {
        return true;
    }

    return false;
}

export const checkPartyTypeFeatureAccess = (account: RetirementAccount | undefined, featureDescription: String): boolean => {
    if(!account) {
        return false;
    }

    let relatedPartyHasAccess = false;


    const accessDefinitions: FeatureAccessDefinition[] = store.getState().CachedObjects.accessDefinitions.clientFeatureAccessDefinitions;

    const featureAccessDefinition = accessDefinitions?.find(definition => definition.clientFeature === featureDescription);

    if (featureAccessDefinition) {

        const relatedPartyAccess = featureAccessDefinition.partyTypeAccessList?.find(member => member.partyType === account.partyType);

        if (relatedPartyAccess) {
            relatedPartyHasAccess = relatedPartyAccess.hasAccess;
        }
    }
    return relatedPartyHasAccess;
}

export const checkPartyTypeFeatureAndHomeQuickLinkAccess = (selectedAccount, pathname, accessDefinition) => {
    if (checkAccessToHomeQuickLinks(pathname) || checkPartyTypeFeatureAccess(selectedAccount, accessDefinition)) {
        return true;
    }
}

export const checkPartyTypeAccessBuilder = (featureDescription: String) => {
    return (account: RetirementAccount): boolean => {
        return checkPartyTypeFeatureAccess(account, featureDescription);
    }
}

type PartyTypeConditionalAccess = {
    featureDescription: string,
    noAccessRender?: any,
    children?: any,
    selectedAccount: RetirementAccount | undefined,
}

const PartyTypeConditionalAccess: React.FC<PartyTypeConditionalAccess> = ({ featureDescription, noAccessRender, children, selectedAccount }) => {
    const [partyTypeHasAccess, setPartyTypeHasAccess] = useState<Boolean>(false);

    useEffect(() => {
        if(selectedAccount) {
            setPartyTypeHasAccess(checkPartyTypeFeatureAccess(selectedAccount, featureDescription));
        }
    }, [selectedAccount])

    return (<>
        {partyTypeHasAccess ? children : noAccessRender}
    </>)
}

const mapStateToProps = (rootState: RootState) => {
    return {
        selectedAccount: rootState.UserState.selectedAccount,
    }
}
export default connect(mapStateToProps)(PartyTypeConditionalAccess)