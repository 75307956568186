import axios from 'axios';

const tradingAccountCalloutHelper = {
    
    getTradingAccounts: (holdingId: string, token?: any) => {
        if (!holdingId) {
            throw Error('No holdingId provided');
        }
        
        return axios.get('/api/trading-account/trading-accounts', { cancelToken: token, params: { holdingId } });
    }

}

export default tradingAccountCalloutHelper;