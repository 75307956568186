import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { deliveryMethodType } from './distributionWizardTypes'
import {amountRegex} from '../../../helpers/Utils'

export type AmountFields = {
    fullOrPartial: fullOrPartialType,
    amount?: string,
}

export type fullOrPartialType = 'fullKeepOpen' | 'fullWithClose' | 'partial' | 'notSet'
export type fullOrPartialOption = {value: fullOrPartialType, description: string}

export const fullOrPartialOptions: fullOrPartialOption[] = [
    {value: 'partial', description: 'Partial Distribution'},
    {value: 'fullKeepOpen', description: 'All Available Cash: Keep Account Open'},
    {value: 'fullWithClose', description: 'Full Distribution: Close Account'}
]

const AmountFields: React.FC<{availableAccountBalance?: number, deliveryMethod: deliveryMethodType, isFundManagerFull: Boolean}> = ({availableAccountBalance, deliveryMethod, isFundManagerFull}) => {

    const [showAmountField, setShowAmountField] = useState<boolean>(false)

    const { control, formState: {errors}, getValues, setValue, clearErrors } = useFormContext<AmountFields>()

    const notifyFullOrPartialChange = (changeEvent: CustomEvent<any>) => {
        setValue('fullOrPartial', changeEvent?.detail?.value || 'notSet')
        setShowAmountField(getValues('fullOrPartial') === 'partial')
        if(changeEvent?.detail?.value !== 'notSet') {
            clearErrors('fullOrPartial')
        }
    }

    useEffect(() => {
        setShowAmountField(getValues('fullOrPartial') === 'partial')
    }, [])

    return (
        <>
            {!isFundManagerFull &&
                <>
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Full or Partial Distribution</IonLabel>
                    </IonRow>
                    <IonRow class="w-100">
                        <Controller data-test='full-or-partial-controller' name='fullOrPartial' control={control} render={({ field: { value, name } }) => {
                            return <IonSelect data-testid='full-or-partial-selection' class="w-100 gr-border p-1" interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" onIonChange={event => notifyFullOrPartialChange(event)} name={name} value={value}>
                                {fullOrPartialOptions?.map((option) => (<IonSelectOption key={option.value} value={option.value}>{option.description}</IonSelectOption>))}
                            </IonSelect>
                        }
                        } rules={{
                                required: true,
                                validate: {
                                    validOptionSelected: selected => {
                                        const fullDistributionTaken = selected === 'fullKeepOpen' || selected === 'fullWithClose'

                                        if(selected === 'notSet') {
                                            return 'Please select a partial or full distribution of the account.'
                                        }
                                        else if(fullDistributionTaken && availableAccountBalance && availableAccountBalance > 100000 && deliveryMethod !== 'wireTransfer') {
                                            return 'A distribution of over $100,000 is not possible unless doing a wire transfer.'
                                        }
                                    }
                                }
                            }} />
                    </IonRow>
                    {errors['fullOrPartial'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['fullOrPartial']?.message}</p></IonItem>}
                </>
            }

            {(showAmountField || isFundManagerFull) && (
                <>
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Amount (USD)</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                            <IonItem class="gr-border" lines="none">$
                                <Controller data-test='amount-controller' name='amount' control={control} render={({ field: { value, name, onChange, onBlur } }) => 
                                    (<IonInput data-testid='amount-input' class="ion-text-left ml-1" name={name} value={value} onIonChange={(event)=>onChange(event.detail.value)} onBlur={onBlur}/>)}
                                    rules={{
                                        required: "Please enter the distribution amount.",
                                        pattern: {
                                            value: amountRegex,
                                            message: 'Invalid Amount.'
                                        },
                                        validate: {
                                            amountGreaterThanZero: (value) => ((value && (+value > 0)) ? undefined : 'Amount value must be greater than 0.'),
                                            amountLessThanAccountBalance: (value) => {
                                                if (availableAccountBalance === 0) {
                                                    return `Amount may not be greater than the account's available cash.`
                                                }
                                                if(value && availableAccountBalance && +value > availableAccountBalance) {
                                                    let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
                                                    return `Amount may not be greater than the account's available cash: ${currencyFormatter.format(availableAccountBalance)}`
                                                }
                                            },
                                            amountValidForNonWire: (value) => {
                                                if(value && +value > 100000 && deliveryMethod !== 'wireTransfer') {
                                                    return 'A distribution of over $100,000 is not possible unless doing a wire transfer.'
                                                }
                                            }
                                        }
                                    }} /> 
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {errors['amount'] && <IonItem data-testid='amount-error' class="mt-1" color="danger"><p className="white-color">{errors['amount']?.message}</p></IonItem>}
                </>)}
        </>
    )
}

export default AmountFields