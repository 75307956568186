
import React, { useEffect, useReducer } from 'react';
import {
  IonRow,
  IonCol,
  IonButton
} from '@ionic/react';

const WizardButtons: React.FC<{steps: string[], activeStep: string, formRef: React.RefObject<HTMLFormElement>, handleSubmit: any, disableSubmit: boolean, submitText?: 'Submit' | string}> = ({steps, activeStep, handleSubmit, disableSubmit, formRef, submitText }) => {
    const showNextButton = () => (activeStep && (steps.indexOf(activeStep) !== (steps.length - 1))); 
    const showBackButton = () => (activeStep && (steps.indexOf(activeStep) !== 0));
    const handleNext = () => {
        if (formRef !== null && formRef.current !== null) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
        }
    }

    const handleBack = () => {
        let backButonClickEvent = new CustomEvent('onbackbuttonclick');
        window.dispatchEvent(backButonClickEvent);
    }

    return (
        <IonRow data-test="wizard-buttons">
          <IonCol>
              <IonRow>
                  <IonCol>
                            {(showNextButton()) ? 
                              (
                                <IonButton data-test='next-button' disabled={false}
                                onClick={handleNext} class="ion-float-right" size="large">Next</IonButton>
                              )
                              : 
                              (
                                <IonButton disabled={disableSubmit} data-test='submit-button' onClick={handleSubmit} class="ion-float-right" size="large">{submitText || 'Submit'}</IonButton>
                              )
                            }
                            {(showBackButton()) && (<IonButton data-test='back-button' onClick={handleBack} color="light" class="ion-float-right" size="large">Back</IonButton>)}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )
}

type ButtonState = 'visible' | 'hidden';
type SubmitTextState = 'Submit' | string;

const initialState = {
  backButtonVisible: false,
  nextButtonVisible: false,
  submitButtonVisible: false,
}

const reducer = (state, action) => {
  const onFirstStep = action.steps.indexOf(action.activeStep) === 0
  const onLastStep = action.steps.indexOf(action.activeStep) === (action.steps.length - 1)

  const backButtonVisible = action.backButtonState ? action.backButtonState === 'visible' : !onFirstStep 
  const nextButtonVisible = action.nextButtonState ? action.nextButtonState === 'visible' : !onLastStep
  const submitButtonVisible = action.submitButtonState ? action.submitButtonState === 'visible' : onLastStep

  return {backButtonVisible, nextButtonVisible, submitButtonVisible}
}

export const GenericWizardButtons: React.FC<{ steps: string[], submitText?: SubmitTextState, activeStep: string, handleStep(direction: 'next' | 'back'): () => void, handleSubmit: any, disableSubmit: boolean, backButtonState?: ButtonState, nextButtonState?: ButtonState, submitButtonState?: ButtonState }> = ({ steps, submitText, activeStep, handleSubmit, disableSubmit, handleStep, backButtonState, nextButtonState, submitButtonState }) => {
  const [buttonState, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({steps, activeStep, backButtonState, nextButtonState, submitButtonState})
  }, [steps, activeStep, backButtonState, nextButtonState, submitButtonState])

  return (
    <IonRow data-test="wizard-buttons">
      <IonCol>
        <IonRow>
          <IonCol>
            {buttonState.nextButtonVisible && (
              <IonButton data-test='next-button' disabled={false}
                onClick={handleStep('next')} class="ion-float-right" size="large">Next</IonButton>
            )}
            {buttonState.submitButtonVisible && (
              <IonButton disabled={disableSubmit} data-test='submit-button' onClick={handleSubmit} class="ion-float-right" size="large">{submitText || 'Submit'}</IonButton>
            )}
            {buttonState.backButtonVisible && (
              <IonButton data-test='back-button' onClick={handleStep('back')} color="light" class="ion-float-right" size="large">Back</IonButton>
            )}
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  )
}

export default WizardButtons