import { combineReducers } from 'redux'

import CachedObjectsReducer from './cachedObjects'
import SessionReducer from './session'
import UserStateReducer from './userState'

export const rootReducer = combineReducers({
  CachedObjects: CachedObjectsReducer,
  Session: SessionReducer,
  UserState: UserStateReducer
})

export type RootState = ReturnType<typeof rootReducer>