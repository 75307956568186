import React, { useState, useEffect, useCallback } from 'react';
import { IonRow, IonCol, IonLabel, IonInput, IonSelect, IonSelectOption, IonItem, IonButton, IonToast } from '@ionic/react';
import { useHistory } from 'react-router';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { connect } from 'react-redux';
import { AccountIonSelect } from '../SelectAccountBar';
import calloutHelper from '../../helpers/calloutHelpers';
import Spinner from '../Spinner';
import ConditionalSelect from '../ConditionalSelect';
import DocCustodySidebar from './DocCustodySidebar';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core';
import { POBoxRegex } from 'shared-utils';
import StateSelector from '../StateSelector';

const VaultOutWizard: React.FC<{availableAccounts?: RetirementAccount[]}> = ({availableAccounts}) => {
    const history = useHistory();
    const [ holdings, setHoldings ] = useState<DocumentHolding[]>([]);
    const [ holding, setHolding ] = useState<DocumentHolding>();
    const [ documentLink, setDocumentLink ] = useState<string>('');
    const [ documentsSpinner, setDocumentsSpinner ] = useState<boolean>(false);
    const [ deliveryMethodOptions, setDeliveryMethodOptions ] = useState<{value: string, description: string}[]>([]);
    const [ submittingSpinner, setSubmittingSpinner ] = useState<boolean>(false);
    const [ showConfirmation, setShowConfirmation ] = useState<boolean>(false);
    const [ toast, setToast ] = useState<{showToast: boolean, toastMessage: string}>({showToast: false, toastMessage: ''});

    const getDefaultValues = (): VaultOutForm => {
        return {
            accountId: '',
            documentName: '',
            cusipIds: [],
            deliveryMethod: '',
            careOf: '',
            mailingStreet: '',
            mailingCity: '',
            mailingState: '',
            mailingZip: ''
        }
    };

    const vaultOutForm = useForm<Partial<VaultOutForm>>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    const { control, formState: { errors }, handleSubmit, clearErrors, setValue, watch, setError } = vaultOutForm;

    const accountId = watch('accountId');
    const documentName = watch('documentName');
    const deliveryMethod = watch('deliveryMethod');
    const mailingStreet = watch('mailingStreet');

    useEffect(() => {
        setDocumentsSpinner(true);
        if (!accountId) {
            setHoldings([]);
            setDocumentsSpinner(false);
        }

        if (accountId) {
            calloutHelper.getDocumentHoldings(accountId || '').then(result => {
                setHoldings(result.data)
                setDocumentsSpinner(false);
                setValue('documentName', '');
                setValue('cusipIds', []);
            });
        }
    }, [accountId])

    useEffect(() => {
        if (holdings.some(holding => holding.AssetDescription === documentName)) {
            const selectedDocument = holdings.find(holding => holding.AssetDescription === documentName);
            setValue('cusipIds', [selectedDocument?.CusipId || '']);
            clearErrors('documentName');
            if (selectedDocument) {
                setHolding(selectedDocument);
            }

            if (selectedDocument?.DocumentLocation === 'Digital') {
                setDeliveryMethodOptions([{description: 'Download', value: 'Download'}]);
            } else {
                setDeliveryMethodOptions([{description: 'FedEx Overnight', value: 'FedEx Overnight'}, {description: 'Certified Mail', value: 'Certified Mail'}]);
            };
        } else {
            setValue('cusipIds', []);
            setHolding(undefined);
        }
    }, [documentName]);

    useEffect(() => {
        if (mailingStreet && deliveryMethod === 'Certified Mail' && !POBoxRegex.test(mailingStreet)) {
            clearErrors('mailingStreet');
        };
        if (mailingStreet && deliveryMethod === 'FedEx Overnight' && !POBoxRegex.test(mailingStreet)) {
            setError('mailingStreet', {type: 'validate', message: 'FedEx Overnight cannot be sent to a PO Box.'});
        };
    }, [deliveryMethod]);

    const renderVaultOutForm = () => {
        return (
            submittingSpinner ? <Spinner/> :
            <FormProvider {...vaultOutForm}>

                <form onSubmit={handleSubmit(onValid)}>
                    <IonRow>
                        <IonCol size='12'>
                            <IonRow class='mt-2 mb-1'>
                                <IonLabel>Select Account:</IonLabel>
                            </IonRow>
                            <IonRow class= 'w-100'>
                                <IonCol size='12' class='w-100 gr-border p-0 m-0'>
                                    <Controller name='accountId' control={control} render={({ field: { value, onChange, name} }) => (
                                        <AccountIonSelect accountID={value || ''} name={name} availableAccounts={availableAccounts || []} onChangeHandler={(event) => {
                                            setValue('accountId', event.detail.value);
                                        }} />
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please select an account'
                                        }
                                    }}/>
                                </IonCol>
                            </IonRow>
                            {errors['accountId'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['accountId'].message}</p></IonItem>}
                            
                            {accountId &&
                                <>
                                    {documentsSpinner ?
                                        <IonRow class='mt-2 mb-1'>
                                            <Spinner />
                                        </IonRow> :
                                        <>
                                            <IonRow class='mt-2 mb-1'>
                                                <IonLabel>Select Document:</IonLabel>
                                            </IonRow>
                                            <IonRow class='w-100'>
                                                <IonCol size='12' class='w-100 p-0 m-0'>
                                                    <Controller control={control} name='documentName' render={({ field: { name, value } }) => (
                                                        <Autocomplete data-testid='document-name-select' className='gr-border'
                                                            options={holdings.map(holding => holding.AssetDescription)}
                                                            value={value}
                                                            onChange={(event, value) => {
                                                                setValue(name, value || '')
                                                            }}
                                                            autoComplete={true}
                                                            autoSelect={true}
                                                            renderInput={params => {
                                                                let newInputProps = { ...params.InputProps, disableUnderline: true };
                                                                let newParams = { ...params, InputProps: newInputProps };
                                                                return (
                                                                    <TextField name={name} placeholder='Document' {...newParams} variant='outlined' />
                                                                )
                                                            }}
                                                        />
                                                        )}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please select a document.'
                                                            },
                                                            validate: (value) => holdings.some(holding => holding.AssetDescription === value) ? undefined : 'Please select a valid document.'
                                                        }}
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            {errors['documentName'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['documentName'].message}</p></IonItem>}
                                        </> }
                                        {documentName && !documentsSpinner &&
                                            <>
                                                <ConditionalSelect controllerName={'deliveryMethod'} title={'Delivery Method:'} errorMessage={'Please select a delivery method.'} dataList={deliveryMethodOptions} />
                                                {deliveryMethod && deliveryMethod !== 'Download' &&
                                                    <>  
                                                        <IonRow class='mt-2 mb-1'>
                                                            <IonLabel>Care Of:</IonLabel>
                                                        </IonRow>
                                                        <IonRow class="m-0 p-0">
                                                            <IonCol class="m-0 p-0">
                                                                <Controller name='careOf' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                                                                    <IonInput class="ion-text-left gr-border pl-1 pr-1" data-testid='care-of-input' name={name} type="text" maxlength={50} onIonInput={onChange} onIonBlur={onBlur} value={value} />
                                                                } rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Please input who the document will be in the Care of.'
                                                                    }
                                                                }} />
                                                            </IonCol>
                                                        </IonRow>
                                                        {errors['careOf'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['careOf'].message}</p></IonItem>}
                    
                                                        <IonRow class='mt-2 mb-1'>
                                                            <IonLabel>Mailing Street:</IonLabel>
                                                        </IonRow>
                                                        <IonRow class="m-0 p-0">
                                                            <IonCol class="m-0 p-0">
                                                                <Controller name='mailingStreet' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                                                                    <IonInput class="ion-text-left gr-border pl-1 pr-1" data-testid='mailing-street-input' name={name} type="text" maxlength={33} onIonInput={onChange} onIonBlur={onBlur} value={value} />
                                                                } rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Please input mailing street.'
                                                                    },
                                                                    validate: {
                                                                        POBoxCheckForFedExOvernight: (value) => (value && deliveryMethod === 'FedEx Overnight' && !POBoxRegex.test(value)) ? 'FedEx Overnight cannot be sent to a PO Box.' : undefined
                                                                    }
                                                                    
                                                                }} />
                                                                {errors['mailingStreet'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['mailingStreet'].message}</p></IonItem>}
                                                            </IonCol>
                                                        </IonRow>
                                                        
                                                        <IonRow class='mt-2 mb-1'>
                                                            <IonCol sizeXs='12' sizeSm='12' sizeMd='4' sizeLg='4' sizeXl='4'>
                                                                    <IonRow class='mb-1'>
                                                                        <IonLabel>Mailing City:</IonLabel>
                                                                    </IonRow>
                                                                    <IonRow class="m-0 p-0">
                                                                        <IonCol class="m-0 p-0">
                                                                            <Controller name='mailingCity' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                                                                                <IonInput class="ion-text-left gr-border pl-1 pr-1" data-testid='mailing-city-input' name={name} type="text" maxlength={33} onIonInput={onChange} onIonBlur={onBlur} value={value} />
                                                                            } rules={{
                                                                                required: {
                                                                                    value: true,
                                                                                    message: 'Please input mailing city.'
                                                                                }
                                                                            }} />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {errors['mailingCity'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['mailingCity'].message}</p></IonItem>}
                                                                </IonCol>
                                                                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                                                                    <IonRow class='mb-1'>
                                                                        <IonLabel>Mailing State:</IonLabel>
                                                                    </IonRow>
                                                                    <IonRow class='m-0 p-0'>
                                                                        <IonCol class='m-0 p-0'>
                                                                            <StateSelector name='mailingState' />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {errors.mailingState && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing state.</p></IonItem>}
                                                                </IonCol>
                                                                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                                                                    <IonRow class='mb-1'>
                                                                        <IonLabel>Mailing Zip:</IonLabel>
                                                                    </IonRow>
                                                                    <IonRow class="m-0 p-0">
                                                                        <IonCol class="m-0 p-0">
                                                                            <Controller name='mailingZip' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                                                                                <IonInput class="ion-text-left gr-border pl-1 pr-1" data-testid='mailing-zip-input' name={name} type="text"  onIonInput={onChange} onIonBlur={onBlur} value={value} maxlength={5}  />
                                                                            } rules={{ required: 'Please enter mailing zip.' }} />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {errors['mailingZip'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['mailingZip'].message}</p></IonItem>}
                                                                </IonCol>
                                                            </IonRow>
                                                    </>
                                                }
                                            </>
                                        }
                                </>
                            }
                            <IonRow class='mt-2 ml-1 mb-1'>
                                <IonLabel><b>Disclaimer:</b></IonLabel>
                            </IonRow>
                            <IonRow data-testid='disclaimer' class='ml-2 mb-1'>
                                Equity Trust will exercise reasonable care with respect to Property received by Equity Trust under this Agreement. Until actually received by Equity Trust, Equity Trust assumes no responsibility or liability for Property placed in transit by the Depositor, its agents or designees. Equity Trust will exercise reasonable care with respect to any Property actually placed in transit by Equity Trust. Equity Trust shall not be liable to the Depositor for any indirect, incidental, consequential or special damages suffered by the Depositor, including, but not limited to, lost revenues or lost profits, whether arising in contract, tort, negligence, strict liability, breach of statutory duty or otherwise, and regardless of any notice of the possibility of such damages. The liability of Equity Trust to the Depositor shall be limited to the actual replacement of Property identical to Property lost while in the possession of Equity Trust under this Agreement, due to theft, mysterious disappearance, or the negligence of Equity Trust, its employees, agents and designees.
                            </IonRow>

                            <IonButton class='mt-2 ion-float-right' data-testid='submit-button' onClick={() => handleSubmit(onValid, (errors) => {})()}>Submit</IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            </FormProvider>
        )
    }
    
    const renderConfirmationPage = () => {
        return (
            <>
                <IonRow data-testid='confirmation-page'>
                    <IonCol size='12'>
                        {deliveryMethod === 'Download' ?
                            <>
                                <IonRow>Thank you. The Vault Out request is now being processed. Once the request has been completed, we will send you a confirmation email. Download your document here:</IonRow>
                                <IonRow>
                                    <IonButton class='mt-2' href={documentLink} target='_blank'>Download</IonButton>
                                </IonRow>
                            </>
                            : <>
                                <IonRow>Thank you. The Vault Out request is now being processed. Once the request has been completed, we will send you a confirmation email.</IonRow>
                            </>
                        }
                        <IonButton class='mt-2 ion-float-right' onClick={() => history.push('/home')}>Return Home</IonButton>
                    </IonCol>
                </IonRow>
            </>
        );
    };

    const onValid: SubmitHandler<Partial<VaultOutForm>> = async (formFields) => {
        setSubmittingSpinner(true);
        try {
            const validFormFields: VaultOutFields = {
                accountId: formFields.accountId || '',
                cusipIds: formFields.cusipIds || [],
                deliveryMethod: formFields.deliveryMethod || '',
                careOf: formFields.careOf || '',
                mailingStreet: formFields.mailingStreet || '',
                mailingCity: formFields.mailingCity || '',
                mailingState: formFields.mailingState || '',
                mailingZip: formFields.mailingZip || ''
            };
            const postVaultOutResult = await calloutHelper.postVaultOut(validFormFields);

            if (postVaultOutResult.data.status === 'ok') {
                const contentDistributionData: ContentDistributionData = {
                    contentVersionId: holding?.ContentDocuments[0].ContentVersionId || '',
                    assetDescription: holding?.AssetDescription || ''
                };

                const createDocDownloadLinkResult = await calloutHelper.createDocDownloadLink(holding?.CusipId || '', contentDistributionData);
                if (createDocDownloadLinkResult.status === 200) {
                    setDocumentLink(createDocDownloadLinkResult.data[0].ContentDownloadUrl)
                }

                setShowConfirmation(true);
                setSubmittingSpinner(false);
            } else {
                setSubmittingSpinner(false);
                setToast({showToast: true, toastMessage: 'Vault Out Failed.'});
            }
        } catch (err) {
            setSubmittingSpinner(false);
            setToast({showToast: true, toastMessage: 'Vault Out Failed.'});
        };
    };

    return (
        <IonRow class='container'>
            {toast.showToast &&
                <IonToast isOpen={toast.showToast} color='danger' position='top' message={toast.toastMessage} onDidDismiss={() => setToast({showToast: false, toastMessage: ''})} buttons={[{icon: 'close-circle'}]} />
            }
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <IonRow>
                    <IonCol class='p-1 light-gr-bg'>
                        <IonRow>
                            <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                <IonRow>
                                    <h1>Vault Out</h1>
                                </IonRow>
                                {showConfirmation ? renderConfirmationPage() : renderVaultOutForm()}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
            <DocCustodySidebar />
        </IonRow>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(VaultOutWizard);