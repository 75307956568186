import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import AuthCheckMessageComponent from './AuthComponents/AuthCheckMessageComponent';
import { isPlatform } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { getLogo } from '../helpers/Utils';

type VersionCheckErrorType = 'InvalidVersion' | 'APIError' | 'None'

const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.midlandtrust.client.portal&hl=en_US&gl=US'
const appStoreUrl = 'https://apps.apple.com/us/app/midland-trust-company/id1554900445'

const VersionCheck: React.FC = props => {

    const [loading, setLoading] = useState<boolean>(true)
    const [errorState, setErrorState] = useState<VersionCheckErrorType>('APIError')

    useEffect(() => {
        if (Capacitor.isNative) {
            setLoading(true)
            validateClientVersion().finally(() => {
                setLoading(false)
            })
        }
        else {
            setErrorState('None')
            setLoading(false)
        }
    }, [])

    const navigateToAppPage = () => {
        if (isPlatform('android')) {
            window.location.href = playStoreUrl
        }
        else if (isPlatform('ios') || isPlatform('ipad') || isPlatform('iphone')) {
            window.location.href = appStoreUrl
        }
    }

    const renderChildren = () => {
        return React.Children.map(props.children, (child, index)=>{
            if(typeof child === 'object'){
                //lazy checking type. This should generally work
                return React.cloneElement(child as React.ReactElement<any>)
            }else{
                return child
            }
        })
    }

    const validateClientVersion = useCallback( async () => {
        try {
            const validationResponse = await axios.get('/app/validate-client-version', {params: {clientVersion: process.env.REACT_APP_VERSION}})     
            if(!validationResponse.data.isVersionValid) {
                setErrorState('InvalidVersion')
            }
            else {
                setErrorState('None')
            }
        }
        catch(err) {
            setErrorState('APIError')
        }
    }, [setErrorState])

    return (
        <>
            {loading ? (
                <div data-test='loading-animation' className='auth-container'>
                    <img className='auth-center' src={getLogo()} width="100%" alt="Equity Trust logo" />
                    <div className='auth-animation' />
                </div>) :
                <>
                    {errorState === 'None' && renderChildren()}
                    {errorState === 'InvalidVersion' &&
                        (<AuthCheckMessageComponent title="Your Equity Trust app is out of date." content="To ensure the best experience, you will need to install the latest version." buttonText="Update App" buttonOnClickEvent={navigateToAppPage} />)
                    }
                    {errorState === 'APIError' && (
                        <AuthCheckMessageComponent title="Unable to connect." content="Please check your network connection and try again." buttonText="Try Again" buttonOnClickEvent={validateClientVersion} />
                    )}
                </>}
        </>
    )
}

export default VersionCheck