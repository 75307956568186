import React from 'react';
import {
    IonItem,
    IonRow,
    IonCol,
  } from '@ionic/react';
import {formatNumber, formatDateString} from 'shared-utils';

const HoldingDetails: React.FC<{ holding: Holding }> = ({ holding }) => {
    
    return (
        <> 
            <IonRow>
                <IonCol>
                    <IonItem data-testid='holding-info-title' color='tertiary'>
                        Holding Information
                    </IonItem>
                    <IonRow class="p-1">
                        <IonCol>
                            <p data-testid='account' className="p-0 m-0"><b>Account</b>: {holding?.Account?.Name} - {holding?.Account?.ClientAccount?.FirstName} {holding?.Account?.ClientAccount?.LastName}</p>
                            <p data-testid='investment-name' className="p-0 m-0"><b>Investment Name</b>: {holding?.AssetDescription}</p>
                            {holding?.AssetType?.toLowerCase().includes('note') &&
                                <p data-testid='note-id' className="p-0 m-0"><b>Note ID</b>: {holding?.Cusip.Name}</p>
                            }
                        </IonCol>
                    </IonRow>
                    <IonItem data-testid='holding-value-title' color='tertiary'>
                        Holding Value Information
                    </IonItem>
                    <IonRow class="p-1">
                        <IonCol>
                            <p data-testid='market-value' className="p-0 m-0"><b>Market Value</b>: ${formatNumber(holding.MVFromATG?.toFixed(2))}</p>
                            <p data-testid='value-last-update' className="p-0 m-0"><b>Value Last Updated</b>: {holding.MarketValueDate && formatDateString(holding.MarketValueDate)}</p>
                            <p data-testid='units-held' className="p-0 m-0"><b>Units Held</b>: {holding.Units}</p>
                            <p data-testid='value-per-unit' className="p-0 m-0"><b>Value Per Unit</b>: {holding.MarketValuePerUnit}</p>
                        </IonCol>
                    </IonRow>
                    <IonItem data-testid='asset-contact-info-title' color='tertiary'>
                        Asset Contact Information
                    </IonItem>
                    <IonRow class="p-1">
                        <IonCol>
                            <p data-testid='contact-name' className="p-0 m-0"><b>Contact Name</b>: {holding.Cusip.ContactName}</p>
                            <p data-testid='contact-company' className="p-0 m-0"><b>Contact Company</b>: {holding.Cusip.ContactCompany}</p>
                            <p data-testid='contact-phone' className="p-0 m-0"><b>Contact Phone</b>: {holding.Cusip.ContactPhone}</p>
                            <p data-testid='contact-email' className="p-0 m-0"><b>Contact Email</b>: {holding.Cusip.ContactEmail}</p>
                            <p data-testid='contact-fax' className="p-0 m-0"><b>Contact Fax</b>: {holding.Cusip.ContactFax}</p>
                            <p data-testid='contact-street' className="p-0 m-0"><b>Contact Street</b>: {holding.Cusip.ContactStreet}</p>
                            <p data-testid='contact-city' className="p-0 m-0"><b>Contact City</b>: {holding.Cusip.ContactCity}</p>
                            <p data-testid='contact-state' className="p-0 m-0"><b>Contact State</b>: {holding.Cusip.ContactState}</p>
                            <p data-testid='contact-zip' className="p-0 m-0"><b>Contact Zip</b>: {holding.Cusip.ContactZip}</p>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    )
}

export default HoldingDetails;