import React, { useState, useEffect } from "react";
import { AddInvestorWizardStep } from "../AddInvestorTypes";
import { IonRow, IonItem } from "@ionic/react";
import WizardProgressBar from "../../WizardProgressBar";
import progressBarStepTwoImage from '../../../images/distribution-wizard-step-two.svg' 
import { AddFundParticipation } from "../../EditInvestorParticipation";
import { dateRegex } from "shared-utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

type AddedFundAccounts = {retirementAccountId: string, name: string, firstName: string, lastName: string, activeDate: string}

export interface AddInvestorWizardStepTwo extends AddInvestorWizardStep {
    availableFundAccounts: FundAccount[],
    setErrorMessage: Function,
    errorMessage: string,
    nextStepReady: boolean,
    setNextStepReady: Function
}

const AddInvestorStepTwo: React.FC<AddInvestorWizardStepTwo> = ({formRef, submitFields, wizardCompletionRatio, savedFields, availableFundAccounts, setErrorMessage, errorMessage, nextStepReady, setNextStepReady}) => {
    const [ fundAccountOptions, setFundAccountOptions ] = useState<FundAccount[]>([]);
    const [ addedFundAccounts, setAddedFundAccounts ] = useState<AddedFundAccounts[]>([]);
    // The constant variable initialAvailableAccounts is declared to work around bug -
    // availableFundAccounts prop would change when referenced.
    const initialAvailableAccounts = availableFundAccounts.filter(fundAccount => fundAccount.Active)

    useEffect(() => {
        setNextStepReady(false);
        let availableAccounts = initialAvailableAccounts;
        let addedAccounts: AddedFundAccounts[] = [];
        if (savedFields.accountRelationships) {
            const accountRelationships = savedFields.accountRelationships;
            accountRelationships.forEach(account => {
                const accountIndex = availableAccounts.findIndex(acc => acc.RetirementAccountId === account.accountId);
                availableAccounts.splice(accountIndex, 1);
                const addingAccount = availableFundAccounts.find(acc => acc.RetirementAccountId === account.accountId);
                addedAccounts.push({
                    retirementAccountId: addingAccount?.RetirementAccountId || '',
                    name: addingAccount?.RetirementAccount.Name || '',
                    firstName: addingAccount?.RetirementAccount.ClientFirstName || '',
                    lastName: addingAccount?.RetirementAccount.ClientLastName || '',
                    activeDate: account.activeDate || ''
                });
            });
        };
        setFundAccountOptions(availableAccounts);
        setAddedFundAccounts(addedAccounts);
    }, [])

    const transformFundAccounts = () => {
        const submittableFundAccounts = addedFundAccounts.map(fundAccount => {
            return {
                accountId: fundAccount.retirementAccountId,
                activeDate: fundAccount.activeDate
            }
        });

        const addInvestorStepTwoFields: AddInvestorWizard.AddInvestorStepTwoFields = {
            accountRelationships:submittableFundAccounts
        };

        return addInvestorStepTwoFields;
    };

    const handleFundAccount = (fundAccount: FundAccount, addOrRemove: string) => {
        setNextStepReady(false);
        let fundAccountsWithRemoved = fundAccountOptions;
        const fundAccountIndex = fundAccountsWithRemoved.findIndex(fAccount => fAccount.RetirementAccountId === fundAccount.RetirementAccountId);
        fundAccountsWithRemoved.splice(fundAccountIndex, 1);
        setFundAccountOptions(fundAccountsWithRemoved);
        setAddedFundAccounts(prevState => {
            return [ ...prevState, {retirementAccountId: fundAccount.RetirementAccountId, name: fundAccount.RetirementAccount.Name, firstName: fundAccount.RetirementAccount.ClientFirstName, lastName: fundAccount.RetirementAccount.ClientLastName, activeDate: new Date().toLocaleDateString()} ]
        });
    };

    const cancelEditParticipation = (retirementAccountId: string, addOrRemove: string) => {
        const addedFundAccountIndex = addedFundAccounts.findIndex(fundAccount => fundAccount.retirementAccountId === retirementAccountId);
        const returningIndex = initialAvailableAccounts.findIndex(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
        const returningNonParticipatingFundAccount = initialAvailableAccounts.find(fundAccount => fundAccount.RetirementAccountId === retirementAccountId);
        setAddedFundAccounts(prevState => {
            prevState.splice(addedFundAccountIndex, 1);
            return [...prevState]
        });
        setFundAccountOptions(prevState => {
            prevState.splice(returningIndex, 0, returningNonParticipatingFundAccount!);
            return [...prevState]
        });
    };

    const handleDateChange = (addOrRemove: string, id: string, date: any) => {
        let stringDate = '';
        if (date) {
            stringDate = date.toLocaleDateString();
        };

        const handleActiveDate = (date: string) => {
            const fundAccountIndex = addedFundAccounts.findIndex(fundAccount => fundAccount.retirementAccountId === id);
                setAddedFundAccounts(prevState => {
                    prevState[fundAccountIndex].activeDate = date;
                    return [...prevState]
                });
        };

        if (dateRegex.test(stringDate)) {
            handleActiveDate(stringDate);
        } else {
            handleActiveDate(stringDate);
        };
    };

    const handleSubmit = (formFields: AddInvestorWizard.AddInvestorStepTwoFields) => {
        if (nextStepReady) {
            if (addedFundAccounts.length < 1) {
                setErrorMessage('Please select at least one account to add.')
            } else if (addedFundAccounts.some(fundAccount => !fundAccount.activeDate)) {
                setErrorMessage('Please enter active date(s).')
            } else {
                submitFields({status: 'ok', formFields})
            }
        } else {
            setErrorMessage('');
        };
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(transformFundAccounts())!}>
            <WizardProgressBar progressBarImage={progressBarStepTwoImage} progressRatio={wizardCompletionRatio} />
            {(errorMessage !== '') &&
                <IonItem class='mt-1' color='danger'>
                    <p className='white-color'>{errorMessage}</p>
                </IonItem>
            }
            <IonRow class="ml-2">
                <h2>Please add at least one account the investor is related to.</h2>
            </IonRow>
            <IonRow>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AddFundParticipation fundAccounts={fundAccountOptions} addedFundAccounts={addedFundAccounts} handleFundAccount={handleFundAccount} cancelEditParticipation={cancelEditParticipation} handleDateChange={handleDateChange} />
                </MuiPickersUtilsProvider>
            </IonRow>
        </form>
    )
};

export default AddInvestorStepTwo;