import React, { useEffect, useState } from 'react';
import { IonCol, IonItem, IonRow, IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import calloutHelper from '../helpers/calloutHelpers';
import Spinner from '../components/Spinner'

const TransferDocuSignReturn: React.FC<{}> = () => {

    const history = useHistory()

    const [showSpinner, setShowSpinner] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState('');
    const [getSignatureUrlError, setGetSignatureUrlError] = useState('');

    const getTransferSignatureUrlFailed = () => {
        setShowSpinner(false);
        setGetSignatureUrlError('There was an error creating a link to retry the failed signature.');
    };

    useEffect(() => {

        setShowSpinner(true)
        
        const queryParams = new URLSearchParams(window.location.search)
        const docuSignEvent = queryParams.get("event")
        const transactionId = queryParams.get("transactionId")
        
        if (docuSignEvent === 'signing_complete')
        {
            history.push('/confirmation', {
                PendingOrPosted: 'pending',
                TransactionId: transactionId,
                transactionType: 'Transfer'
            });
        }   
        else {
            if (transactionId) {
                calloutHelper.getTransferSignatureUrl(transactionId, window.location.origin).then((response) => {
                    setSignatureUrl(response.data.signatureUrl)
                    setShowSpinner(false)
                }).catch(() => {
                    getTransferSignatureUrlFailed()
                })
            }
        } 
    }, [])
    
    return (
        <>
            <IonRow class='p-1 mt-5 mb-5 container'>
                <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeLg='12' sizeXl='12'>
                    <IonRow>
                        <IonCol class='p-1 light-gr-bg'>
                            <IonRow>
                                <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                                    <>
                                        <IonItem><h2>Transfer Cash into my Equity Trust Account</h2></IonItem>
                                        {getSignatureUrlError !== '' && (
                                            <IonItem class='mt-1' color='danger'>
                                                <p className='white-color'>{getSignatureUrlError}</p>
                                            </IonItem>
                                        )}
                                        {showSpinner ? <Spinner/> :
                                            <>
                                                {getSignatureUrlError === '' && 
                                                    <>
                                                        <IonRow class='pl-5 pt-3'>
                                                            There was an issue signing the transfer form. You can try again by clicking below. 
                                                        </IonRow>
                                                        <IonRow class='pl-5 pt-3'>
                                                            <IonButton href={signatureUrl}>Proceed to E-signature</IonButton>
                                                        </IonRow>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    )
}

export default TransferDocuSignReturn;