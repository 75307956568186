import { deliveryMethodType, DistributionFullForm, SubmittableDistributionForm } from "./DistributionFields/distributionWizardTypes";
import calloutHelper from '../../helpers/calloutHelpers';
import { getAvailableCashBalance } from '../../helpers/Utils';

export const getNextArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember)
    if(currentMemberIndex === array.length - 1 || currentMemberIndex === -1) {
        return array[currentMemberIndex]
    }
    return array[currentMemberIndex + 1]
}

export const getPreviousArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember)
    if(currentMemberIndex === 0 || currentMemberIndex === -1) {
        return array[currentMemberIndex]
    }
    return array[currentMemberIndex - 1]
}

export const getProgressRatio = <Type>(currentPosition: Type, allPositions: Array<Type>) => {
    if(!allPositions || allPositions.length === 0) {
        return 0
    }
    const currentPositionIndex = allPositions.findIndex((position) => position === currentPosition)
    const ratio = (currentPositionIndex + 1 * 1.0) / (allPositions.length)
    return ratio
}

export const getAvailableCash = async (account: RetirementAccount) => {
    let availableCashBalance: number = 0
    availableCashBalance = getAvailableCashBalance(account)
    
    let totalPendingCash: number = 0
    let pendingTradingAccoungWithdrawals = await getPendingTradingAccountWithdrawals(account?.id) 
    
    pendingTradingAccoungWithdrawals.forEach((tradingAccountWithdrawal: any) => {
        totalPendingCash += parseFloat(tradingAccountWithdrawal.amountCash) || 0
    })

    if (!totalPendingCash || totalPendingCash === 0) {
        return availableCashBalance
    }
    else {
        return availableCashBalance + totalPendingCash
    }
}

const getPendingTradingAccountWithdrawals = async (accountId: string) => {
    const pendingTradingAccountWithdrawalResult = await calloutHelper.getPendingTradingAccountWithdrawals(accountId)
    return pendingTradingAccountWithdrawalResult?.data
}

export const changeSavedFieldsToSubmittableForm = (savedFields: Partial<DistributionFullForm>, loggedInDetails?: PersonAccountHelpers.LoggedInDetails): SubmittableDistributionForm => {
    const amountAsNumber: number = +(savedFields.amount || 0)
    const taxWithholdingPercentageAsNumber: number = savedFields.electTaxWithholding === 'taxWithholding' ? +(savedFields.taxWithholdingPercentage || '0') : 0

    const submittableForm: SubmittableDistributionForm = {
        accountId: savedFields.accountId || '',
        distributionType: savedFields.distributionType || '',

        fullOrPartial: savedFields.fullOrPartial || '',
        amount: amountAsNumber,

        taxWithHoldingPercentage: taxWithholdingPercentageAsNumber,

        deliveryMethod: savedFields.deliveryMethod || '',

        mailingStreet: savedFields.mailingStreet || '',
        mailingCity: savedFields.mailingCity || '',
        mailingState: savedFields.mailingState || '',
        mailingZip: savedFields.mailingZip || '',
        CO: savedFields.CO || '',
        payableTo: savedFields.payableTo || '',

        bankId: savedFields.bankId || '',
        bankName: savedFields.bankName || '',
        routingNumber: savedFields.routingNumber || '',
        creditAccountNumber: savedFields.creditAccountNumber || '',
        creditAccountType: savedFields.creditAccountType || '',
        creditAccountName: savedFields.creditAccountName || '',
        saveBankInfo: savedFields.saveBankInfo || false,

        memo: savedFields.memo || '',

        frequency: savedFields?.frequency || 'One-Time',

        recurringTransactionData: {
            schedule: savedFields?.recurringTransactionData?.schedule || '',
            duration: savedFields?.recurringTransactionData?.duration || '',
            endDate: savedFields?.recurringTransactionData?.endDate || undefined,
            processDate: savedFields?.recurringTransactionData?.processDate || undefined
        }
    }

    return submittableForm
}

export const parseDeliveryMethod = (deliveryMethod: string): deliveryMethodType => {
    let parsedDeliveryMethod: deliveryMethodType = 'notSet'
    if (deliveryMethod.toLowerCase().includes('direct') || deliveryMethod.toLowerCase().includes('ach')) {
        parsedDeliveryMethod = 'directDeposit'
    }
    else if (deliveryMethod.toLowerCase().includes('wire')) {
        parsedDeliveryMethod = 'wireTransfer'
    }
    return parsedDeliveryMethod;
}