import axios from "axios";
import { validators } from 'shared-utils'

export const updateMyProfile =  async (personDetails: PersonAccountHelpers.PersonDetails, securityInputs: MyProfileFields.SecurityFields) => {
    const myProfileResults = await axios.post<PersonAccountHelpers.PersonDetails | string | undefined>('/api/update-my-profile', {personDetails, securityInputs})
    const personDetailsResp = myProfileResults.data as PersonAccountHelpers.PersonDetails
    //validate myProfileResults
    if(validators.isEmail(personDetails.email)){
        return personDetailsResp
    }

    throw new Error('Invalid Response')
}