import React from 'react';
import {IonRow, IonCol, IonItem, IonInput, IonProgressBar } from '@ionic/react';
import { useForm, Controller } from 'react-hook-form';
import progressBarStepTwo from '../../../images/update-asset-step-two.svg';
import { isPhone, convertStringDateToDate } from '../../../helpers/Utils';
import * as sharedUtils from 'shared-utils'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { UpdateAssetStep } from '../UpdateAssetWizard';

export interface StepTwo extends UpdateAssetStep {}

const UpdateAssetStepTwo: React.FC<StepTwo> = ({holding, goToNextStep, formRef, assetValuationForm}) => { 
  
  const {control, setValue, handleSubmit, formState: { errors }, clearErrors} = useForm<Partial<FairMarketValueCase>>({ 
    mode: "onChange",
    defaultValues: {...assetValuationForm}
  });

  const preloadLastDayOfPreviousYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getUTCMonth();
    if (currentMonth >= 0 && currentMonth < 5) {
      return ((currentDate.getUTCFullYear() - 1) + '-12-31');
    }
    return ''
  } 

  const getMinPriceDateInfo = () => {
    let oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    if (holding?.DateAcquired) {
      let dateAcquired = new Date(holding?.DateAcquired);
      if (dateAcquired > oneYearAgo) {
        return {'minDate' : dateAcquired, 'minDateMessage' : 'Date should not be before the asset purchase date'};
      }
    }
    return {'minDate' : oneYearAgo, 'minDateMessage' : 'Date should not be before one year ago'};
  }

  const onSubmit = (data: Partial<FairMarketValueCase>) => {
    clearErrors();

    const payload = {
      ...data
    }
    goToNextStep(payload);
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepTwo} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.33}></IonProgressBar>}
        </IonCol>
      </IonRow>
      {
          (<form data-test='update-asset-step-two' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IonRow>
              <IonCol>
                {holding &&
                  (<IonRow class="w-100">
                      {holding.AssetType === 'Property Owned' && holding.Units < 1 ? 
                        <h4>Current Market Value of Entire Property: ${sharedUtils.formatNumber(holding.MarketValuePerUnit?.toFixed(2))}</h4>
                        : <h4>Current Market Value: ${sharedUtils.formatNumber(holding.MVFromATG?.toFixed(2))}</h4>
                      }
                    </IonRow>
                  )}
                  <IonRow class="w-100 divider">
                    <h4>Current Price Date: {sharedUtils.formatDateString(holding?.Cusip.LastPriceUpdate)}</h4>
                  </IonRow>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                      {holding && holding.AssetType === 'Property Owned' && holding.Units < 1 ? 
                        <h4>New Market Value of Entire Property:</h4>
                        : <h4>New Market Value:</h4>
                      }
                    </IonCol>
                  </IonRow>
                  {holding &&
                    (<IonRow>
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <IonRow class="mb-1 p-0">
                          <IonCol class="m-0 p-0" size="12">
                          <Controller name='newMarketValue' control={control} render={({ field: { value, onChange } }) =>
                          <IonItem class="gr-border" lines="none">
                            $<IonInput data-testid="new-market-value-input" className="ion-text-left ml-1" name='newMarketValue' type="number" value={value} onIonChange={(event)=>onChange(event.detail.value)}/></IonItem>
                            } rules={{
                              required: "Please provide a new market value.",
                              validate: {
                                amountIsNotZero: (value) => ((value && (+value === 0)) ? 'Please notify our office at (239) 333-4826 if this asset is no longer held.' : undefined),
                                amountIsNotNegative: (value) => ((value && (+value < 0)) ? 'Please provide a new market value greater than 0.' : undefined)
                              }
                            }
                          }/>
                          </IonCol>
                        </IonRow>
                        {errors['newMarketValue'] && <IonItem data-testid='new-market-value-error' class="mt-1" color="danger"><p className="white-color">{errors['newMarketValue']?.message}</p></IonItem>}
                      </IonCol>
                    </IonRow>
                    )
                  }
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="4" sizeXl="4">
                      <h4>New Price Date:</h4>
                      <IonItem className="ion-text-left gr-border white-bg mb-1" lines="none">
                        <Controller name='valuationDate' defaultValue={preloadLastDayOfPreviousYear()} control={control} render={({ field: { value } }) =>
                        {
                          return <KeyboardDatePicker
                            className="date-width" name="valuationDate" disablePast={false} disableFuture={true} margin='normal' value={convertStringDateToDate(value)} minDate={getMinPriceDateInfo().minDate} minDateMessage={getMinPriceDateInfo().minDateMessage} maxDateMessage='Date should not be in the future' format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true}  KeyboardButtonProps={{ 'aria-label': 'change-date' }} onChange={(date) => {
                              if (date) {
                                setValue('valuationDate', sharedUtils.formatDateToString(date));
                              } 
                            }}/>
                          }} rules={{
                              required: true
                          }} />
                      </IonItem>
                      {errors['valuationDate'] && <IonItem class="mt-1" color="danger">
                        <p className="white-color">Invalid Date. {errors['valuationDate'].message}</p>
                      </IonItem>}
                    </IonCol>
                  </IonRow>
              </IonCol>
            </IonRow>
          </MuiPickersUtilsProvider>
        </form>)
      }
    </>
  );
};

export default UpdateAssetStepTwo;