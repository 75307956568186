import React, { useEffect } from 'react'
import { IonRow, IonLabel, IonSelect, IonSelectOption, IonItem } from '@ionic/react'
import { Controller, useFormContext } from 'react-hook-form'
import { throwError } from 'shared-utils'
import { SelectChangeEventDetail } from '@ionic/core'

export type option = {
    value: string,
    description: string,
}

const ConditionalSelect: React.FC<{ controllerName: string, title?: string, errorMessage?: string, dataList: option[], notifySelectChanged?: Function }> = ({ controllerName, title, errorMessage, dataList, notifySelectChanged }) => {

    const { control, formState: { errors }, setValue } = useFormContext()
    
    useEffect(() => {
        const preselectData = async () => {
            try {
                if (dataList.length === 1) {
                    setValue(controllerName, dataList[0].value)
                }
            } catch (err) {
                throwError(err)
            }
        }
        preselectData();
    }, [controllerName, dataList, setValue])

    const ionSelectChanged = (onChange, event: CustomEvent<SelectChangeEventDetail<string>>) => {
        onChange(event.detail.value)
        if(notifySelectChanged) {
            notifySelectChanged()
        }
    }

    return (
        <>
            {title && (
                <IonRow class="mt-2 mb-1">
                    <IonLabel>{title}</IonLabel>
                </IonRow>)
            }
            <IonRow class="w-100">
                <Controller name={controllerName} control={control} rules={{required: true}} render={({ field: { value, name, onChange } }) => {
                    return <IonSelect id='conditional-select' data-testid={`conditional-select-${name}`} class={"w-100 gr-border p-1"} interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" disabled={dataList.length === 1} onIonChange={event => ionSelectChanged(onChange, event)} name={name} value={value}>
                        {dataList.length === 1 ? <IonSelectOption key={value} value={value}>{dataList[0]?.description}</IonSelectOption> :
                            dataList.map((option, index) => {
                                return <IonSelectOption key={index} value={option.value}>{option.description}</IonSelectOption>
                            })
                        }
                    </IonSelect>
                }}/>
            </IonRow>
            {errors[controllerName] && <IonItem class="mt-1 w-100" color="danger"><p className="white-color">{errorMessage || `Please select a valid ${title || 'option'}.`}</p></IonItem>}
        </>
    )
}

export default ConditionalSelect