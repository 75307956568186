import { IonButton, IonCol, IonRow } from '@ionic/react'
import React from 'react'

interface ICardStatus{
  isCardAdded: boolean,
  isEditing: boolean,
} 

interface IProps{
  handleRemove: () => void,
  state: CreditCardPayment.CardFormInputs,
  setCardStatus: (value: ICardStatus) => void
  cardStatus: ICardStatus
}

export const CreditCardHeader: React.FC<IProps> = ({ handleRemove, state, setCardStatus, cardStatus }) => {
    return (
      <IonRow class="container">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
          <p>
            <b>Card Number</b>:{" "}
            {`**********${state.number.slice(14)}`}
            <br />
          </p>
          <p>
            <b>Expiration Date</b>: {state.expiry}
            <br />
          </p>
          <p>
            <b>Billing ZIP Code</b>: {state.zipCode}
            <br />
          </p>
          <IonButton
            onClick={() =>
              setCardStatus({
                ...cardStatus,
                isEditing: true,
              })
            }
            size="small"
          >
            Edit
          </IonButton>
          <IonButton onClick={handleRemove} size="small">
            Remove
          </IonButton>
        </IonCol>
    </IonRow>
    )
}