import React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { states } from '../helpers/Utils';
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from 'react-hook-form';
import { IonInput } from '@ionic/react';


const StateSelector: React.FC<{name: string, disableField?: boolean, defaultValue?: any, validationDisabled?: boolean, editingPayee?: boolean}> = ({name = 'mailingState', disableField, defaultValue, validationDisabled, editingPayee}) => {
  const {control, setValue} = useFormContext()
  const useStyles = makeStyles(() => ({
    inputRoot: {
      "& .MuiOutlinedInput-notchedOutline": {
        color: "red",
        padding: "0px",
        margin: "0px",
        border: "solid #e4e4e4 3px",
        borderRadius: "0px"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        color: "red",
        borderColor: "#e4e4e4"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        color: "red",
        borderColor: "#e4e4e4"
      }
    }
  }));

  const classes = useStyles();

  return (
    <Controller name={name} control={control} defaultValue={defaultValue} render={({ field: { value, name }}) =>
    (disableField ?
      <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" value={value} readonly={true} /> :
      <Autocomplete data-testid='state-select' freeSolo={true} value={value}
        id="legal-states"
        className={editingPayee ? "pl-1 pr-1 pb-1" : ""}
        classes={classes}
        autoSelect={true}
        options={states}
        getOptionLabel={option => option}
        onChange={(event, value) => {
          setValue(name, value, { shouldValidate: true })
        }}
        renderOption={option => (
          <span> {option}</span>
        )} renderInput={params => {
          let newInputProps = { ...params.InputProps, placeholder: 'State' };
          let innerInputProps = { ...params.inputProps, maxLength: "2" }
          let newParams = {
            ...params, InputProps: newInputProps,
            inputProps: innerInputProps
          };
          return (
            <TextField type="text" {...newParams} variant="outlined" />
          )
        }} />
    )
    
    } rules={{
          required: !validationDisabled, 
          validate: (val) => (!validationDisabled ? states.findIndex((state) => /[A-Za-z]{2}/.test(val) && state === val?.toUpperCase()) !== -1 : true)
      }} />
  );
};

export const StateSelectorWithErrorMessage: React.FC<{name: string, disableField?: boolean, defaultValue?: any, requiredDisabled?: boolean, editingInvestor?: boolean}> = ({name = 'mailingState', disableField, defaultValue, requiredDisabled, editingInvestor}) => {
  const {control} = useFormContext()
  const useStyles = makeStyles(() => ({
    inputRoot: {
      "& .MuiOutlinedInput-notchedOutline": {
        color: "red",
        padding: "0px",
        margin: "0px",
        border: "solid #e4e4e4 3px",
        borderRadius: "0px"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        color: "red",
        borderColor: "#e4e4e4"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        color: "red",
        borderColor: "#e4e4e4"
      }
    }
  }));

  const classes = useStyles();

  return (
    <Controller name={name} control={control} render={({ field: { value, name, onChange, onBlur }}) =>
    (disableField ?
      <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" value={value} readonly={true} /> :
      <Autocomplete freeSolo={true} value={value}
        className={editingInvestor ? "pl-1 pr-1 pb-1" : "pr-1 pb-1"}
        classes={classes}
        autoSelect={true}
        options={states}
        getOptionLabel={option => option}
        onChange={(event, value) => {
          if(typeof value === 'string'){
            onChange(value.toUpperCase())
          }else{
            onChange('')
          }
        }}
        onBlur={onBlur}
        renderOption={option => (
          <span> {option}</span>
        )} renderInput={params => {
          let newInputProps = { ...params.InputProps, placeholder: 'State' };
          let innerInputProps = { ...params.inputProps, maxLength: "2" }
          let newParams = {
            ...params, InputProps: newInputProps,
            inputProps: innerInputProps
          };
          return (
            <TextField type="text" {...newParams} variant="outlined" />
          )
        }} />
    )
    
    } rules={{
          required: {
            value: !requiredDisabled,
            message: 'Please select a state.'
          },
          validate: (val) => {
            if((typeof val === 'string' && /[A-Za-z]{2}/.test(val) && states.findIndex((state) => state === val?.toUpperCase()) !== -1) || !val){
              return
            }
            return 'Please enter a valid state.'
          },
          value: (cb)=>(cb)
      }} />
  );
};

export default StateSelector;
