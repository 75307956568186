import React from 'react';
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Main from './pages/Main';
import MobileMenu from './components/MobileMenu';
import MobileSidebar from './components/MobileSidebar';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import {store} from './reducers/store'

const App: React.FC = () => {
  return (
  <Provider store={store}>
    <IonApp>
      <IonReactRouter>
        <MobileMenu />
        <MobileSidebar />
        <Switch>
          <Route path="/:comp" render={()=> <Main/>}/>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </Switch>
      </IonReactRouter>
    </IonApp>
  </Provider>
)};

export default App;
