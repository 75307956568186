import { IonRow, IonCol, IonButton, IonToast } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import BeneficiariesFields from './BeneficiariesFields'
import { useHistory } from 'react-router'
import DividedContainer from './DividedContainer'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import calloutHelper from '../helpers/calloutHelpers'
import { buildActionableBeneficiaryList, configureBeneficiaries, EditableBeneficiary, getNewBeneficiary, BeneficiaryType, validateBeneficiaryNameLengths } from '../helpers/beneficiaryHelper'
import Spinner from './Spinner'
import calloutHelpers from '../helpers/calloutHelpers'

export type BeneficiaryForm = {
    primary: EditableBeneficiary[],
    contingent: EditableBeneficiary[],
}

const SHOW_ERROR_TIME = 5000

const Beneficiaries: React.FC<{ scrollToTop: Function }> = ({ scrollToTop }) => {
    const history: any = useHistory()
    const accountId = history?.location?.state?.accountId || ''
    const [relatedParties, setRelatedParties] = useState<RelatedParties[]>([])
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [totalSharePercentage, setTotalSharePercentage] = useState<{ primary: number, contingent: number }>({ primary: 100, contingent: 100 })

    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>

    const form = useForm<BeneficiaryForm>({
        mode: 'onSubmit',
        defaultValues: {
            primary: [],
            contingent: [],
        }
    })

    const primaryBeneficiaries = useFieldArray<BeneficiaryForm>({
        control: form.control,
        name: 'primary'
    })

    const contingentBeneficiaries = useFieldArray<BeneficiaryForm>({
        control: form.control,
        name: 'contingent'
    })

    useEffect(() => {
        if (accountId !== '') {
            setShowSpinner(true)
            calloutHelpers.getAccountDetails(accountId).then((result) => {
                if (result?.data?.relatedParties) {
                    const accountRelatedParties = result.data.relatedParties as RelatedParties[]
                    setRelatedParties(accountRelatedParties)

                    const primaryBenes = configureBeneficiaries(accountRelatedParties, 'Beneficiary (Primary)')
                    const contingentBenes = configureBeneficiaries(accountRelatedParties, 'Beneficiary (Contingent)')

                    primaryBeneficiaries.replace(primaryBenes)
                    contingentBeneficiaries.replace(contingentBenes)
                }
            }).finally(() => {
                setShowSpinner(false)
            })
        }
    }, [])

    useEffect(() => {
        calculateSharePercentages()
    }, [primaryBeneficiaries.fields, contingentBeneficiaries.fields])

    const showErrorMessage = (message: string) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, SHOW_ERROR_TIME)
    }

    const addEmptyBeneficiary = (beneficiaryType: BeneficiaryType) => () => {
        const typeSpecificBeneficiaries = beneficiaryType === 'primary' ? primaryBeneficiaries : contingentBeneficiaries
        typeSpecificBeneficiaries.prepend(getNewBeneficiary(beneficiaryType))
    }

    const calculateSharePercentages = () => {
        const beneficiaryReducer = (value: number, beneficiary: EditableBeneficiary) => {
            const beneficiaryShare = +beneficiary.share || 0
            return value + beneficiaryShare
        }

        const primaries = form.getValues('primary')
        const contingents = form.getValues('contingent')
        const applicablePrimaryBeneficiaries = primaries.filter(beneficiary => !beneficiary.deleteBeneficiary)
        const applicableContingentBeneficiaries = contingents.filter(beneficiary => !beneficiary.deleteBeneficiary)
        const primaryShareTotal = (applicablePrimaryBeneficiaries.length > 0) ? applicablePrimaryBeneficiaries.reduce((previousValue, beneficiary) => beneficiaryReducer(previousValue, beneficiary), 0) : 100
        const contingentShareTotal = (applicableContingentBeneficiaries.length > 0) ? applicableContingentBeneficiaries.reduce((previousValue, beneficiary) => beneficiaryReducer(previousValue, beneficiary), 0) : 100

        setTotalSharePercentage({ primary: primaryShareTotal, contingent: contingentShareTotal })
    }

    const swapBeneficiaryType = (index: number, sourceType: BeneficiaryType) => {
        const sourceArray = sourceType === 'primary' ? primaryBeneficiaries : contingentBeneficiaries
        const destinationArray = sourceType === 'primary' ? contingentBeneficiaries : primaryBeneficiaries
        const beneficiary = form.getValues(sourceType)[index]
        sourceArray.remove(index)
        destinationArray.append(beneficiary)
    }

    const deleteBeneficiary = (index: number, type: BeneficiaryType) => {
        const beneficiaryArray = type === 'primary' ? primaryBeneficiaries : contingentBeneficiaries
        beneficiaryArray.remove(index)
    }

    const renderBeneficiaries = () => {
        const beneficiaryMap = ((beneficiary, index) => {
            return {
                listKey: beneficiary.id,
                componentRender: (<BeneficiariesFields key={beneficiary.relatedPartyId} index={index} beneficiaryType={beneficiary.type} totalSharePercentage={totalSharePercentage} calculateSharePercentages={calculateSharePercentages} swapBeneficiaryType={swapBeneficiaryType} deleteNewBeneficiary={deleteBeneficiary} />)
            }
        })
        const renderBeneficiary = (beneficiaryItems, title: string, buttonText: String, beneficiaryType: BeneficiaryType) => {
            return (<DividedContainer title={title} items={beneficiaryItems}
                headerContents={<IonButton className="ion-float-left" type='button' onClick={addEmptyBeneficiary(beneficiaryType)}>{buttonText}</IonButton>}
            />)
        }

        const primaryBeneficiariesItems = primaryBeneficiaries.fields.map(beneficiaryMap)
        const contingentBeneficiariesItems = contingentBeneficiaries.fields.map(beneficiaryMap)

        return (<>
            {renderBeneficiary(primaryBeneficiariesItems, 'Primary Beneficiaries', 'Add Primary', 'primary')}
            {renderBeneficiary(contingentBeneficiariesItems, 'Contingent Beneficiaries', 'Add Contingent', 'contingent')}
        </>
        )
    }

    const onValidSubmit = () => {
        setErrorMessage('')
        if (!(totalSharePercentage.primary === 100 && totalSharePercentage.contingent === 100)) {
            showErrorMessage('Share percentage for each beneficiary type must add up to 100.')
            return
        }

        const primaryBeneficiaries = form.getValues('primary') || []
        const contingentBeneficiaries = form.getValues('contingent') || []
        const beneficiaries = [...primaryBeneficiaries, ...contingentBeneficiaries]
        const actionableBeneficiaries = buildActionableBeneficiaryList(beneficiaries, relatedParties)

        if (actionableBeneficiaries.length === 0) {
            showErrorMessage('No beneficiary data was changed.')
            return
        }

        if (!validateBeneficiaryNameLengths(actionableBeneficiaries)) {
            showErrorMessage('The full name of beneficiaries cannot exceed 32 characters.')
            return
        }

        scrollToTop()
        setShowSpinner(true)
        calloutHelper.updateBeneficiaries(accountId, actionableBeneficiaries).then(() => {
            history.push('/account-details', {
                Toast: { message: 'Success! The information you entered has been saved.', color: 'success' }
            })
        }).catch(() => {
            showErrorMessage('There was a problem updating the beneficiaries')
        }).finally(() => {
            setShowSpinner(false)
        })
    }

    const onInvalidSubmit = () => {
        showErrorMessage('Errors were found with the beneficiary information provided.')
    }

    return (
        <>
            <IonToast isOpen={errorMessage !== ''} color={'danger'} position='top' message={errorMessage} duration={SHOW_ERROR_TIME} buttons={[{ icon: 'close-circle' }]} />
            <IonRow className="container">
                <IonCol className='p-1' sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8">
                    {showSpinner ? <Spinner /> : (
                        <FormProvider {...form}>
                            <form ref={formRef} onSubmit={form.handleSubmit(onValidSubmit, onInvalidSubmit)}>
                                {renderBeneficiaries()}
                                <IonRow className='p-0 m-0'>
                                    <IonCol className='p-0 mr-0' sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8" />
                                    <IonCol className='p-0 ml-0' sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                                        <IonButton className="ion-float-right m-0 p-0" size="large" type='submit'>Update</IonButton>
                                    </IonCol>
                                </IonRow>
                            </form>
                        </FormProvider>
                    )}
                </IonCol>
                <Sidebar />
            </IonRow>
        </>
    )
}

export default Beneficiaries