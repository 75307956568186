import { rootReducer } from './rootReducer'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import {throttle} from 'lodash'

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
}

const saveState = (state: RootState) => {
    try {
      const serializedState = JSON.stringify({UserState: state.UserState});
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
}

export const store = createStore(rootReducer, loadState(), applyMiddleware(thunk));

store.subscribe(throttle(()=>{
    saveState({
      ...store.getState()
    })
}, 1000))


export type AppDispatch = typeof store.dispatch

