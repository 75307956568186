import React, { useEffect, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import WizardProgressBar from '../WizardProgressBar';
import { TransferStepTwoFields, TransferWizardStep, CustodiansList } from '../TransferFields/TransferWizardTypes';
import progressBarStepTwoImage from '../../images/transfer-step-two.svg'
import { IonCol, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonInput } from '@ionic/react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import FileUpload, { uploadCallBackInput } from '../helperComponents/FileUpload';

interface TransferWizardStepTwo extends TransferWizardStep {
    availableAccounts: RetirementAccount[],
    savedFiles: SFFile[],
    custodiansList: CustodiansList
}

type AccountType = 'Traditional IRA' | 'Roth IRA' | 'SEP IRA'

const accountTypeOptions: Array<AccountType> = [
    'Traditional IRA',
    'Roth IRA',
    'SEP IRA'
];

const TransferStepTwo: React.FC<TransferWizardStepTwo> = ({availableAccounts, formRef, submitFields, wizardCompletionRatio, savedFields, savedFiles, custodiansList}) => {
    
    const getDefaultValues = ():TransferStepTwoFields => {
        return {
            custodianName: savedFields?.custodianName || '',
            custodianId: savedFields?.custodianId || '',
            fromAccountNumber: savedFields?.fromAccountNumber || '',
            fromAccountType: savedFields?.fromAccountType || '' 
        };
    };
    
    const { control, watch, formState: {errors}, handleSubmit, setValue, getValues, clearErrors } = useForm<TransferStepTwoFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });
    
    const [ files, setFiles ] = useState<SFFile[]>(savedFiles);
    const [ fileError, setFileError ] = useState<string>('');
    const selectedAccountType = availableAccounts.find(account => account.id === savedFields?.accountId)?.accountType;

    const custodian = watch('custodianName')
    const accountType = watch('fromAccountType')

    useEffect(() => {
        if (custodiansList.some(checkingCustodian => checkingCustodian.name === custodian)) {
            const selectedCustodian = custodiansList.find(checkingCustodian => checkingCustodian.name === custodian);
            const selectedCustodianId = selectedCustodian?.salesforce_id;
            const selectedCustodianMedallionRequirement = selectedCustodian?.medallion_required;
            setValue('custodianId', selectedCustodianId || '');
            clearErrors('custodianName');
            if ((selectedCustodianMedallionRequirement === 'Yes' || selectedCustodianMedallionRequirement === 'See Notes') && (!files.length)) {
                setFileError('To complete your transfer, we require a statement from your current custodian.');
            } else {
                setFileError('');
            };
        } else {
            setValue('custodianId', '');
        };
    }, [custodian, files]);

    useEffect(() => {
        if ((accountType === 'Roth IRA' && selectedAccountType === 'Roth IRA') || (accountType !== 'Roth IRA' && selectedAccountType !== 'Roth IRA')) {
            clearErrors('fromAccountType');
        };
    }, [accountType]);

    const deleteFile = (index: number) => {
        files.splice(index, 1);
        setFiles([...files]);
    };

    const onFileChange = async (fileUploadResponse: uploadCallBackInput) => {
        if (fileUploadResponse.status === 'success') {
            setFiles(fileUploadResponse.files);
        } else {
            setFiles([]);
        };
    };

    const onValid = (formFields: TransferStepTwoFields) => {
        if (!fileError) {
            submitFields({status: 'ok', formFields, altPayload: {type: 'files', files}});
        }
    };

    const onInvalid = (errors: FieldErrors) => {
        submitFields({status: 'error', formFields: getValues(), errors, altPayload: {type: 'files', files}});
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
            <WizardProgressBar progressBarImage={progressBarStepTwoImage} progressRatio={wizardCompletionRatio} />

            <IonRow class='mt-2 mb-1'>
                <IonLabel>Current Custodian/Financial Institution</IonLabel>
            </IonRow>
            <IonRow class='w-100'>
                <IonCol class='w-100 p-0 m-0'>
                    <Controller control={control} name='custodianName' render={({ field: { name, value } }) => (
                        <>
                            <Autocomplete className='gr-border' 
                                options={custodiansList.map(custodian => custodian.name)}
                                value={value}
                                onChange={(event, value) => {
                                    setValue(name, value || '')
                                }}
                                autoComplete={true}
                                autoSelect={true}
                                renderInput={params => {
                                    let newInputProps = { ...params.InputProps, disableUnderline: true };
                                    let newParams = { ...params, InputProps: newInputProps };
                                    return (
                                        <TextField name={name} placeholder='Custodian/Financial Institution' {...newParams} variant='outlined' />
                                    )
                                }} />
                        </>
                    )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please select a custodian or financial institution.'
                            },
                            validate: (value) => custodiansList.some(custodian => custodian.name === value) ? undefined : 'Please choose a valid custodian or financial institution.'
                        }}
                    />
                </IonCol>
            </IonRow>
            {errors['custodianName'] && <IonItem className='mt-1' color='danger'><p className='white-color'>{errors['custodianName']?.message}</p></IonItem>}

            <IonRow class='mt-2 mb-1'>
                <IonLabel>Account Number</IonLabel>
            </IonRow>
            <IonRow class='w-100'>
                <IonCol class='w-100 p-0 m-0'>
                    <Controller name='fromAccountNumber' control={control} render={({ field: { value, name, onChange, onBlur }}) => (
                        <IonInput className='gr-border' name={name} value={value} onIonChange={onChange} onBlur={onBlur} />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Please enter account number.'
                        },

                    }}
                    />
                </IonCol>
            </IonRow>
            {errors['fromAccountNumber'] && <IonItem className='mt-1' color='danger'><p className='white-color'>{errors['fromAccountNumber']?.message}</p></IonItem>}

            <IonRow class='mt-2 mb-1'>
                <IonLabel>Account Type</IonLabel>
            </IonRow>
            <IonRow class='w-100'>
                <IonCol class='w-100 p-0 m-0'>
                    <Controller name='fromAccountType' control={control} render={({ field: { value, name, onBlur, onChange }}) => (
                        <IonSelect className='w-100 gr-border p-1' interface='action-sheet' placeholder='Please Select' tabIndex={0} mode='ios' name={name} value={value} onIonChange={onChange} onBlur={onBlur}>
                            {accountTypeOptions.map(option => <IonSelectOption key={option} value={option}>{option}</IonSelectOption> )}
                        </IonSelect>
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Please select account type.'
                        },
                        validate: {
                            validateAccountType: value => 
                                (selectedAccountType === 'Roth IRA' && value !== 'Roth IRA') || (selectedAccountType !== 'Roth IRA' && value === 'Roth IRA')
                                    ? 'These account types are not available for a transfer. Please contact Equity Trust with any questions.' : undefined,
                        }
                    }}
                    />
                </IonCol>
            </IonRow>
            {errors['fromAccountType'] && <IonItem className='mt-1' color='danger'><p className='white-color'>{errors['fromAccountType']?.message}</p></IonItem>}

            <IonRow class='mt-2 mb-1'>
                <IonLabel>Upload Document(s)</IonLabel>
            </IonRow>
            <IonRow class='m-0 p-0 w-100 d-block'>
                <IonCol class='m-0 p-0' sizeXs='12' sizeSm='10' sizeMd='8' sizeLg='6' sizeXl='4'>
                    <FileUpload files={files} filesUploadedCallback={onFileChange} deleteFile={deleteFile} />
                </IonCol>
            </IonRow>
            {fileError && <IonItem className='mt-1' color='danger'><p className='white-color'>{fileError}</p></IonItem>}
        </form>
    )
}

export default TransferStepTwo;