import React from 'react';
import { useHistory } from 'react-router'
import {
    IonRow,
    IonCol,
    IonButton,
} from '@ionic/react';
import { isMobile, formatDateToUTCString } from '../helpers/Utils';
import { formatNumber } from 'shared-utils';

export interface Transaction {
    Id: string,
    Cusip: {
        AssetName1?: string,
        Name?: string
    },
    Category?: string,
    TaxYear?: string,
    Units?: number,
    NetProceeds?: number,
    Check?: string,
    PendingOrPosted: 'pending' | 'posted',
    TransactionDate?: string,
    TransactionType?: string,
    Comment1?: string, 
    Comment2?: string, 
    Comment3?: string, 
    Comment4?: string, 
    Comment5?: string, 
    Comment6?: string, 
    Amount?: number,
    ToFrom?: string,
    ClientApprovalPending?: boolean,
    Name?: string,
    Status?: string,
    CurrentNote?: string,
}

const TransactionItem: React.FC<{transaction: Transaction}> = ({transaction}) => {
    const history = useHistory()
    const goToTransaction = () => {
        history.push({
            pathname: '/transaction',
            state: {PendingOrPosted: transaction.PendingOrPosted, TransactionId: transaction.Id}
        })
    }

    const transactionDateAsDate = new Date(transaction.TransactionDate || '');
    const dateFormat = isMobile() ? 'MM-DD-YYYY' : 'MM-DD-YY'
    const formattedDate = formatDateToUTCString(transactionDateAsDate, dateFormat);
    
    return (
        <span data-test="transaction-item">
            <IonRow class="pl-1 pr-1">
                {transaction.PendingOrPosted === 'posted' &&
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="1" sizeLg="1" sizeXl="1">
                        <span data-test="transaction-date">
                            <p className="m-0">
                                {formattedDate}
                            </p>
                        </span>
                    </IonCol>
                }
                <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <span data-test="transaction-type">
                        <p className="m-0">
                            {transaction.TransactionType}
                        </p>
                    </span>
                </IonCol>
                {
                    transaction.PendingOrPosted === 'pending' && <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                        <span data-test="to-from">
                            <p className="m-0">
                                {transaction?.ToFrom}
                            </p>
                        </span>
                    </IonCol>
                }
                <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <span data-test="asset-name">
                        {(transaction.Cusip?.AssetName1) &&
                            <p className="m-0">
                                {transaction.Cusip?.AssetName1}
                            </p>
                        }
                    </span>
                </IonCol>
                {transaction.PendingOrPosted === 'posted' && <IonCol sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <span data-test="comment">
                        <p className="m-0">
                            {transaction.Comment1}
                        </p>
                    </span>
                </IonCol>}
                <IonCol sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <span data-test="amount">
                        <p className="m-0">
                            ${(formatNumber(transaction?.Amount?.toFixed(2)))}
                        </p>
                    </span>
                </IonCol>
                <IonCol class={isMobile() ? "divider" : ""} sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                    <IonButton class={isMobile() ? "mt-0 mb-1" : "cursor-pointer d-block m-0 p-0"} onClick={goToTransaction} size="small">More Details</IonButton>
                </IonCol>
            </IonRow>
        </span>
    )
}
export default TransactionItem;