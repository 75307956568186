import React, { useEffect, useCallback, useRef, useContext } from 'react'
import {
  IonRow,
  IonCol,
  IonButton,
  useIonToast
} from '@ionic/react'
import { convertMyProfileToPersonAccountDetails } from './SubComponents/MyProfileUtils'
import calloutHelpers, {isCalloutError} from '../../helpers/calloutHelpers'
import MyProfile from './MyProfile'
import MyProfileReadOnly from './MyProfileReadOnly'
import { AuthCheckContext } from '../../helpers/authHelpers/authService'
import { IonSpinner } from '@ionic/react'
import { SubmitHandler } from 'react-hook-form'
import { updateMyProfile } from '../../helpers/calloutHelpers/myProfileCalloutHelpers'

import myProfileReducer, {MyProfileReducer, MyProfileReducerModel} from './myProfileReducer'
const MyProfileContainer: React.FC = () => {
  /**
   * Putting this placeholder in for now to placate the tsc check since the code was setup for this value to always be defined.
   * We can keep the assertion that this value is always defined but it would probably be wise to do some kind of check to check 
   * that it's true at run-time
   * Otherwise we could keep it as possibly undefined and modify code in MyProfile to handle that.
   */

  const authCheckContext = useContext(AuthCheckContext)
  const formRef = useRef<HTMLFormElement>(document.createElement('form'))
  const [myProfileState, dispatchMyProfile] = React.useReducer<MyProfileReducer, MyProfileReducerModel>(myProfileReducer, {
    isEditing: false,
    isLoading: true,
    personDetails: undefined,
    toast: useIonToast(), 
    formRef
  }, (val)=>{
    return val
  })

  const { personDetails, isEditing, isLoading } = myProfileState

  const usersFirstName = personDetails?.firstName || ''

  const loadAccountData = useCallback(async () => {
    let personDetailsResponse = await calloutHelpers.getLoggedInPersonAccount()
    dispatchMyProfile({type: 'set-person-details', personDetails: personDetailsResponse.data.myProfile})
  }, [])

  useEffect(() => {
    loadAccountData()
  }, [loadAccountData])

  const submitForm = () => formRef?.current?.dispatchEvent(new Event('submit', {cancelable: true}))

  const submitSuccessful: SubmitHandler<MyProfileFields.MyProfileFormInputs> = async (fields) =>{
    dispatchMyProfile({type: 'submit'})
    try{
      const convertedValues = convertMyProfileToPersonAccountDetails(fields)
      const updatedFields = await updateMyProfile(convertedValues.personDetails, convertedValues.secFields)
      dispatchMyProfile({type: 'submit-success', personDetails: updatedFields})
    }catch(err){
      if(isCalloutError(err) && typeof err.response?.data === 'string'){
        dispatchMyProfile({type: 'submit-error', errorMessage: err.response.data})
        return
      }
      dispatchMyProfile({type: 'submit-error'})
      return
    }
  }
  
  const RenderForms: React.FC = () => {
    if(isLoading){
      return <IonRow style={{'padding-top': '20px', 'padding-bottom': '30px', 'padding-left': '45%', 'padding-right': '45%'}}>
        <IonSpinner/>
      </IonRow> 
    }

    if(isEditing && personDetails){
      return <MyProfile personDetails={personDetails} formRef={formRef} submitSuccessful={submitSuccessful}/> 
    }

    if(personDetails){
      return <MyProfileReadOnly  personDetails={personDetails}/>
    }

    return <> Could not load MyProfile </>
  }

  const MyProfileButtons: React.FC = () => { 
    if(isEditing){
      // Save Cancel
      return <>
        <IonButton disabled={Boolean(myProfileState.isLoading)} onClick={() => dispatchMyProfile({type: 'cancel-edit'})} color="light" class="ion-float-right">Cancel</IonButton>
        <IonButton disabled={Boolean(myProfileState.isLoading)} onClick={submitForm} type="submit" color="light" class="ion-float-right">Save</IonButton>
      </>
    }

    // Edit Change-Password
    return <>
      <IonButton disabled={personDetails?.email === undefined || Boolean(myProfileState.isLoading)} onClick={() => personDetails?.email && authCheckContext?.current?.resetPassword(personDetails.email, usersFirstName)} color="light" class="ion-float-right">Change Password</IonButton>
      <IonButton disabled={Boolean(myProfileState.isLoading)} onClick={() => dispatchMyProfile({type: 'start-edit'})} color="light" class="ion-float-right">Edit</IonButton>
    </>
  }

  return <>
    <IonRow class="p-1 mb-1 container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
        <IonRow>
          <IonCol class="p-1 light-gr-bg">
            <IonRow>
              <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                <RenderForms />
                <IonCol size="12">
                  <MyProfileButtons/>
                </IonCol>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  </>
}

export default MyProfileContainer