import React from 'react';
import {
  IonRow,
  IonCol,
} from '@ionic/react';
import { useHistory } from 'react-router';
import calloutHelper from '../helpers/calloutHelpers';
import ContainerComponent from '../components/ContainerComponent';
import PostedTransaction from './PostedTransaction';
import PendingTransaction from './PendingTransaction/PendingTransaction';
import Sidebar from '../components/Sidebar';
import DataLoader from '../components/DataLoader';

const TransactionDetails: React.FC = () => {
  const history = useHistory<{PendingOrPosted?: string, TransactionId?: string}>()
  const stateDetail = history.location.state

  const renderTransactionDetails = (transaction : any) => {
    if (!stateDetail || !transaction) return;
    let title = stateDetail.PendingOrPosted === 'posted' ? 'Posted Transaction' : 'Pending Transaction';
    let content = stateDetail.PendingOrPosted === 'posted' ? <PostedTransaction transaction={transaction} /> : <PendingTransaction transaction={transaction}/>
    let props = {title, content}
    return <ContainerComponent {...props}/>
  }

  return (
    <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        {stateDetail && stateDetail.TransactionId && stateDetail.PendingOrPosted &&
          <DataLoader apiCall={() => calloutHelper.getTransaction(stateDetail.TransactionId as string, stateDetail.PendingOrPosted as 'pending' |'posted')} render={renderTransactionDetails} dependencies={[stateDetail]} />
        }
      </IonCol>
      {stateDetail?.PendingOrPosted !== 'pending' && <Sidebar />}
    </IonRow>
  );
};

export default TransactionDetails;