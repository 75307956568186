import React from 'react'
import {IonItem} from '@ionic/react'
import {FieldError, ControllerRenderProps} from 'react-hook-form'
import { validators } from 'shared-utils'
import {ErrorNames, MyProfileFormInputs} from '../myProfileTypes'

export const ShowFieldError: React.FC<ErrorNames> = (props) => {
    var fieldError: FieldError | undefined;
    if(props.name === 'Legal'  || props.name === 'Mailing'){
      const addressErrors = props.errors && props.errors[props.name]
      fieldError = addressErrors && addressErrors[props.subName]
    } else if(props.name === 'Primary' || props.name === 'Secondary') {
      const phoneErrors = props.errors && props.errors[props.name]
      fieldError = phoneErrors && phoneErrors[props.subName]
    } else {
      fieldError = props.errors[props.name]
    }

  return fieldError ? <IonItem class="mt-1" color="danger">{fieldError?.message}<p className="white-color"></p></IonItem> : null
}

export const getDefaultValues = (personDetails: PersonAccountHelpers.PersonDetails): MyProfileFields.MyProfileFormInputs => {
  return {
    Mailing: {
      street: personDetails.mailingStreet,
      city: personDetails.mailingCity,
      state: personDetails.mailingState,
      postalCode: personDetails.mailingPostalCode
    },
    Legal: {
      street: personDetails.legalStreet,
      city: personDetails.legalCity,
      state: personDetails.legalState,
      postalCode: personDetails.legalPostalCode
    },
    Primary: personDetails.phones.Primary,
    Secondary: personDetails.phones.Secondary,
    email: personDetails?.email || '',
    mailingShippingDiffer: personDetails?.mailingShippingDiffer || false,
    preferredContactMethod: personDetails.preferredContactMethod,
    marketingOptOut: personDetails?.marketingOptOut || false,
    mfa: personDetails?.multiFactorAuthEnabled || false,
    enableSecurityQuestion: personDetails?.enableSecurityQuestion || false,
    pin: '',
    questionOrPin: '',
    securityAnswer: '',
    securityQuestion: ''
  }
}

type PatchPersonAccountRequest = {
  personDetails: PersonAccountHelpers.PersonDetails,
  secFields: MyProfileFields.SecurityFields 
}
export const convertMyProfileToPersonAccountDetails = (myProfileForm: Partial<MyProfileFields.MyProfileFormInputs>):PatchPersonAccountRequest  => {
    const defaultInvalidContactMethod = (val: unknown) => validators.MyProfileFieldValidators.isPreferredContactMethod(val) ? val : '' 
    const defaultInvalidString = (val: unknown) => validators.isString(val) ? val : '' 
    
    const resolveBillingAddress = (myProfileForm: Partial<MyProfileFields.MyProfileFormInputs>) => {
        if(myProfileForm.mailingShippingDiffer){
            return {
                legalStreet: defaultInvalidString(myProfileForm?.Legal?.street),
                legalCity: defaultInvalidString(myProfileForm?.Legal?.city),
                legalState: defaultInvalidString(myProfileForm?.Legal?.state),
                legalPostalCode: defaultInvalidString(myProfileForm?.Legal?.postalCode)
            }
        }
        return {
            legalStreet: defaultInvalidString(myProfileForm.Mailing?.street),
            legalCity: defaultInvalidString(myProfileForm.Mailing?.city),
            legalState: defaultInvalidString(myProfileForm.Mailing?.state),
            legalPostalCode: defaultInvalidString(myProfileForm.Mailing?.postalCode)
        } 
    }

    const email = validators.isEmail(myProfileForm.email) ? myProfileForm.email : undefined 

    if(!email || !myProfileForm.Primary)
    {
      throw new Error('Input invalid')
    }

    if(!myProfileForm) {
      throw new Error('no form given')
    }

    // should live in a global context with other sequelize model types
    const secFields: MyProfileFields.SecurityFields = {
      pin: myProfileForm.pin || '',
      questionOrPin: myProfileForm.questionOrPin || '',
      securityAnswer: myProfileForm.securityAnswer || '',
      securityQuestion: myProfileForm.securityQuestion || '' 
    }

    const personDetails: PersonAccountHelpers.PersonDetails = {
        email,
        phones: {
          Primary: myProfileForm.Primary,
          Secondary: myProfileForm.Secondary
        },
        
        mailingStreet: defaultInvalidString(myProfileForm?.Mailing?.street),
        mailingCity: defaultInvalidString(myProfileForm?.Mailing?.city),
        mailingState: defaultInvalidString(myProfileForm?.Mailing?.state),
        mailingPostalCode: defaultInvalidString(myProfileForm?.Mailing?.postalCode),
        
        ...resolveBillingAddress(myProfileForm),
        
        mailingShippingDiffer: Boolean(myProfileForm.mailingShippingDiffer),
        marketingOptOut: Boolean(myProfileForm.marketingOptOut),
        multiFactorAuthEnabled: Boolean(myProfileForm.mfa),
        enableSecurityQuestion: Boolean(myProfileForm.enableSecurityQuestion),

        preferredContactMethod: defaultInvalidContactMethod(myProfileForm.preferredContactMethod) //could type check specific values
    }
    return {personDetails, secFields}
}

export const mapFieldPropsToIonProps = (props: ControllerRenderProps<MyProfileFormInputs, any>) => {
  const refOverride = (ref: any)=>{
    const refTranslator = {
      focus: ()=>{
        typeof ref?.setFocus === 'function' && ref.setFocus()
      }
    }
    props.ref(refTranslator)
  }
  return {
    value: props.value,
    onIonBlur: props.onBlur,
    onIonChange: props.onChange,
    ref: refOverride,
    name: props.name,
  }
}