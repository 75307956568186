import { GenericHistory, GenericSubmitFieldsCallback } from "../../../helpers/wizardHelpers"
import { CheckFormFields } from "../../ConditionalFieldComponents/CheckFields"
import { AmountFields } from "./AmountFields"
import { SaveBankField } from "./SaveBankCheckbox"
import { TaxWithholdingFields } from "./TaxWithholdingFields"

export type distributionStep = 'step-one' | 'step-two' | 'review'

export type deliveryMethodType = 'directDeposit' | 'wireTransfer' |'checkStandard' | 'checkOvernight' | 'checkHoldForPickup' | 'notSet'

export const deliveryMethodOptions: Array<{ value: deliveryMethodType, description: string }> = [
    { value: 'directDeposit', description: 'Direct Deposit (No Fee)' },
    { value: 'wireTransfer', description: 'Wire Transfer ($30 Fee)' },
    { value: 'checkStandard', description: 'Check via Regular Mail ($5 Fee)' },
    { value: 'checkOvernight', description: 'Check via Fedex Overnight ($30 Fee)' },
    { value: 'checkHoldForPickup', description: 'Check Hold for Pickup - Ft Myers ($5 Fee)' }
]

export type DistributionHistory = GenericHistory<distributionStep, Partial<DistributionFullForm>> & (TransactionHistoryType | RecurringTransactionHistoryType)

export type DistributionFullForm = DistributionStepOneFields & DistributionStepTwoFields

export type DistributionStepOneFields = {
    accountId: string,
    retirementAccountLabel: string,
    retirementAccountBalance?: number,
    deliveryDestination: string,
    bankId?: string,
    deliveryMethod: deliveryMethodType,
    accountType: string
} & Partial<BankFields>

export type DistributionStepTwoFields = {
    distributionType: string,
    deliveryMethod: deliveryMethodType,
    frequency: string,
    recurringTransactionData?: RecurringTransactionData
} & AmountFields & TaxWithholdingFields & Partial<CheckFormFields> & Partial<BankFields> & SaveBankField

// This type represents the data that will be submitted by the distribution wizard to the back end.
export type SubmittableDistributionForm = {
    accountId: string,
    distributionType: string,

    fullOrPartial: string,
    amount: number,

    taxWithHoldingPercentage: number,

    deliveryMethod: string,

    mailingStreet: string,
    mailingCity: string,
    mailingState: string,
    mailingZip: string,
    CO: string,
    payableTo: string,
    
    bankId: string,
    bankName: string,
    routingNumber: string,
    creditAccountNumber: string,
    creditAccountType: string,
    creditAccountName: string, 
    saveBankInfo: boolean,

    memo: string,

    frequency: string,

    recurringTransactionData?: RecurringTransactionData
}

export type SubmitDistributionFieldsCallback = GenericSubmitFieldsCallback<DistributionFullForm>

export interface DistributionWizardStep {
    formRef: React.MutableRefObject<HTMLFormElement>,
    submitFields: SubmitDistributionFieldsCallback,
    wizardCompletionRatio: number,
    savedFields: Partial<DistributionFullForm>,
    loggedInDetails?: PersonAccountHelpers.LoggedInDetails 
}