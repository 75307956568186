const initialState: UserState = {
    personAccount: undefined,
    selectedAccount: undefined
}
      
    export function UserStateReducer(
        state = initialState,
        action: UserStateActions
    ): UserState {
    switch (action.type) {
        case "setPersonAccount":
            return {...state, ...action.payload}
        case "setSelectedAccount": {
            if (action.payload.selectedAccount !== null && action.payload.selectedAccount !== undefined) {
                return {...state, ...action.payload}
            }
            return state
        }
        default:
            return state
    }
}

export default UserStateReducer