import './Documents.css'
import React, { Fragment, useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core'
import { openFileNatively, openFileInWeb, openFileInWebMobile } from '../helpers/documentHelper'
import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
  IonModal,
  IonPage
} from '@ionic/react';
import Sidebar from './Sidebar';
import CalloutHelpers from '../helpers/calloutHelpers'
import ToggleComponent from './ToggleComponent';
import { connect } from 'react-redux'
import DataLoader from '../components/DataLoader'
import { formatDateString } from 'shared-utils'
import { isMobileWeb } from '../helpers/Utils'

const Documents: React.FC<{selectedAccountId?: string, viewMode: ViewMode}> = ({selectedAccountId, viewMode}) => {
  const [docFilter, setDocFilter] = useState<string>('All')
  const [ downloading, setDownloading ] = useState<boolean>(false)

  useEffect(()=>{
    setDocFilter('All')
  },[selectedAccountId])

  const renderDocumentList = (docs: Array<Documents>) => {
    if(!docs || (docs.length < 1)) {
      return  <p>This account does not have any documents.</p> 
    }
    let newFilterOptions = new Set<string>()
      docs.forEach((value)=>{
        newFilterOptions.add(value.Type)
      })
    let FilterOptions = Array.from(newFilterOptions)
    
    return <Fragment>

      <IonModal backdropDismiss={false} swipeToClose={false} keyboardClose={false} isOpen={downloading} cssClass={"document-modal"}><IonPage><div className="loading">Downloading</div></IonPage></IonModal>
      <IonList class="pl-1 pr-1">
        <IonLabel>Filter by:</IonLabel>
          <IonSelect name='docFilter' placeholder="All" class="w-100 gr-border p-1 mt-1" tabIndex={0} interface='action-sheet' onIonChange={(event)=>{
            setDocFilter(event.detail.value)
          }} interfaceOptions={{ animated: true, mode: 'ios', cssClass: 'documents-action-sheet' }}>
            <IonSelectOption>All</IonSelectOption>
          {FilterOptions && (FilterOptions).map((docFilter) => (
            <IonSelectOption key={docFilter}>
              {docFilter}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonList>
      <IonList data-testid='documents-list' class="pl-1">
        {docs.filter(value=>{
          if(docFilter === 'All'){
            return true
          }

          return docFilter === value.Type
        }).map(value => {
          return (<IonItem key={value.Id}>{
            renderDocElement(value)
          }</IonItem>)
        })}
      </IonList>
    </Fragment>
  }

  const resolveFile =  (attachmentId: string, fileName: string, mimeType:string) => async () => {
    let fileNameCleaned = fileName.replace(/\//g,'_')

    if(Capacitor.isNative){
      setDownloading(true)
      await openFileNatively(attachmentId, fileNameCleaned, mimeType)
      setTimeout(()=>{
        setDownloading(false)
      }, 100)
      return
    }

    if(isMobileWeb()){
      setDownloading(true)
      await openFileInWebMobile(attachmentId, fileNameCleaned)
      setTimeout(()=>{
      }, 100)
      return
    }

    //web => open special link in new tab
    setDownloading(true)
    await openFileInWeb(attachmentId, mimeType)
    setTimeout(()=>{
      setDownloading(false)
    }, 100)
  }

  const renderDocElement = (value: Documents) => {
    return (
      <IonCol class='m-0 p-0' size='10'>
        <IonRow class='mt-1'>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <p className='m-0'><b>Document Name</b>:</p>
            </IonCol>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <p className="m-0">{value.Name}</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <p className='m-0'><b>Document File Name</b>:</p>
            </IonCol>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <p className="m-0">{value.FileName}</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <p className='m-0'><b>Document Type</b>:</p>
            </IonCol>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <p data-testid={`document-type-${value.Id}`} className="m-0">{value.Type}</p>
            </IonCol>
        </IonRow>
        {value.Description &&
          <IonRow>
              <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                  <p className='m-0'><b>Description</b>:</p>
              </IonCol>
              <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                  <p className="m-0">{value.Description}</p>
              </IonCol>
          </IonRow>
        }
        <IonRow class='mb-1'>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                <p className='m-0'><b>Document Date</b>:</p>
            </IonCol>
            <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                <p data-testid={`document-date-${value.Id}`} className="m-0">{formatDateString(value.DocDate)}</p>
            </IonCol>
        </IonRow>
        <IonRow class='mb-1'>
          <IonButton data-testid={`open-file-button-${value.Id}`} onClick={resolveFile(value.AttachmentId, value.FileName, value.MimeType)}>Open File</IonButton>
        </IonRow>
    </IonCol>
  )}

  const renderDocumentsWithDataLoaded = () => {
    return <DataLoader apiCall={()=>CalloutHelpers.getDocuments(selectedAccountId)} render={renderDocumentList} dependencies={[selectedAccountId]}/>
  }



  return (
    viewMode === 'DOC_CUSTODY' ? <ToggleComponent isActive={false} title='Statements' bodyData={renderDocumentsWithDataLoaded()}/>
    : <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
          <IonRow>
            <IonCol class="mb-2 p-1 light-gr-bg">
              <IonRow>
                <IonCol class="pt-1 pb-2 gr-border white-bg">
                  {renderDocumentsWithDataLoaded()}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
        <Sidebar />
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccountId: rootState.UserState.selectedAccount?.id,
    viewMode: rootState.CachedObjects.viewMode
  }
}

export default connect(mapStateToProps)(Documents);
