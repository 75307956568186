import React from 'react';
import '../pages/Style.css';
import logo from '../images/logo-white.png';
import { 
  IonToolbar,
  IonRow,
  IonCol,
  IonButtons,
  IonMenuButton,
  IonButton,
} from '@ionic/react';

const HeaderTemplate: React.FC = ({children}) => {
  return <>
    <IonRow class="display-mobile mobile-header">
      <IonToolbar class="noshadow mobile-header-toolbar" color="secondary">
        <IonButtons slot="start">
          <img className='logo-mobile ml-0-5' src={logo} width="270" alt="Equity Logo" />
        </IonButtons>
        <IonButtons slot="end">
          <IonButton>
            <IonMenuButton class="hamburger" menu="mobile-menu" color="light" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonRow>
    <IonRow class="display-desktop">
      <IonToolbar mode="md" color="secondary" className='global-header'>
        <IonRow class="header-row h-100 align-items-center">
          <IonCol size="1">
            <img className='logo' src={logo} alt="Equity Logo"/>
          </IonCol>
          {children}
        </IonRow>
      </IonToolbar>
    </IonRow>
  </>
};

export default HeaderTemplate;