import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  IonItem,
  IonList,
  IonMenu,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonRouterOutlet,
  IonRouterLink,
} from '@ionic/react';
import {
  homeSharp,
  documentTextSharp,
  documentAttachSharp,
  createSharp,
  logOutSharp,
  listSharp,
  cardSharp,
  serverSharp,
  peopleSharp,
  folderOpenSharp,
  personCircleSharp,
  listOutline,
  peopleOutline
} from 'ionicons/icons';
import { menuController } from '@ionic/core';
import { AuthCheckContext } from '../helpers/authHelpers/authService';
import { useHistory } from 'react-router-dom';

const MobileMenu: React.FC<{viewMode: ViewMode, availableAccounts: RetirementAccount[], selectedAccount: RetirementAccount | undefined}> = ({viewMode, availableAccounts, selectedAccount}) => {
  const authCheckContext =  useContext(AuthCheckContext)
  const history = useHistory();
  const [ isFundManagerFull, setIsFundManagerFull ] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAccount?.partyType === 'Fund Manager - Full') {
      setIsFundManagerFull(true);
    } else {
      setIsFundManagerFull(false);
    }
  }, [selectedAccount]);

  const signout = ()=>{
    authCheckContext.current.logout()
  }

  const investorRosterTab = () => <IonItem>
    <IonIcon icon={listOutline} slot="start"></IonIcon>
    <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/investor-roster")}}>Investor Roster</IonRouterLink>
  </IonItem>

  return (
    <div>
      <IonMenu side="start" menuId="mobile-menu" contentId="main" >
        <IonHeader className='global-header'>
          <IonToolbar mode="md">
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {viewMode === 'DEFAULT_TYPE' ?
              <>
                <IonItem>
                  <IonIcon icon={homeSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/")}}>Home</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={documentTextSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/account-details")}}>Account Details</IonRouterLink>
                </IonItem>
                {isFundManagerFull &&
                  investorRosterTab()
                }
                <IonItem>
                  <IonIcon icon={listSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/transactions")}}>Transactions</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={serverSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/holdings")}}>Holdings</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={documentAttachSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/documents")}}>Documents</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={folderOpenSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/cases")}}>Cases</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={cardSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/billing")}}>Billing</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={peopleSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/saved-payees")}}>Saved Payees</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={personCircleSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/my-profile")}}>My Profile</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={createSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/forms")}}>Forms</IonRouterLink>
                </IonItem>
              </> :
              <>
                <IonItem>
                  <IonIcon icon={homeSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/")}}>Home</IonRouterLink>
                </IonItem>
                {investorRosterTab()}
                <IonItem>
                  <IonIcon icon={cardSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/billing")}}>Billing</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonIcon icon={personCircleSharp} slot="start"></IonIcon>
                  <IonRouterLink color="dark" onClick={() => {menuController.close(); history.push("/my-profile")}}>My Profile</IonRouterLink>
                </IonItem>
              </>
            }
            <IonItem>
              <IonIcon icon={logOutSharp} slot="start"></IonIcon>
              <IonRouterLink color="dark" onClick={() => signout()}>Logout</IonRouterLink>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonRouterOutlet id="main"></IonRouterOutlet>
    </div>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    viewMode: rootState.CachedObjects.viewMode,
    availableAccounts: rootState.CachedObjects.accounts,
    selectedAccount: rootState.UserState.selectedAccount
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      setViewMode: (viewMode: ViewMode) => dispatch(
        {type: 'setViewMode', payload: {viewMode}}
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);