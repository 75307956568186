/**
 * auth helpers typically used by AuthService.ts, moved these out to de-clutter that service code
 */
import { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2'
import { bytesToBase64 } from '../base64';
import * as AuthConst from './authConstants'

type codeFlowParams = {
    state: string,
    access_token: string,
    token_type: string,
    expires_in: string,
    code: string,
    id_token: string
}

export const randomStringGen = () => {
  const byteArray = new Uint16Array(8)
  crypto.getRandomValues(byteArray)
  return window.btoa(byteArray.toString()).replace(/-/g, '+').replace(/_/g, '/').replace(/=/g,'');
}

const addToAdditionalParams = (baseParams: OAuth2AuthenticateOptions, additionalParams) => ( 
  {
    ...baseParams,
    additionalParameters:{
      ...baseParams.additionalParameters,
      ...additionalParams
    }
  }
)

export const addCodeChallengeToAdditionalParameters = (baseParams: OAuth2AuthenticateOptions, codeChallenge: string):OAuth2AuthenticateOptions => ( 
  {
    ...addToAdditionalParams(baseParams, {code_challenge: codeChallenge, code_challenge_method: 'S256'})
  }
)

export const addLoginHintToAdditionalParameters = (baseParams: OAuth2AuthenticateOptions, loginHint: string, firstName: string) => ( 
  {
    ...addToAdditionalParams(baseParams, {login_hint: loginHint, Intro_Name: firstName})
  }
)

  export const generateCodeVerifier = () => {
    let codeVerifier = randomStringGen()
    window.sessionStorage.setItem('cv',codeVerifier)
    return codeVerifier
  }

  export const generateCodeChallenge = async () => {
    const codeVerifier = generateCodeVerifier()
    //convert codeVerifier into arrayBuffer
    const encoder = new TextEncoder()
    let codeVerifierAsAB = encoder.encode(codeVerifier)
  
    //encode with SHA-256
    let hashedCode = await crypto.subtle.digest('SHA-256', codeVerifierAsAB)
    /*
      convert buffer to URL safe base64 (may want to look into a another package to do both)
      using source for a robust base64 encoder below. the fix to make it urlsafe is somewhat hamstrung
      I would prefer something more reliable.
    */
    let codeChallenge = bytesToBase64(new Uint8Array(hashedCode)).replace(/\//g,'_').replace(/\+/g,'-').replace(/=+$/,'')
    return codeChallenge
  }

  export const getCodeVerifier = () => {
    let cv = window.sessionStorage.getItem('cv')
    return cv ? cv : ''
  }

  export const validateCodeFlowParamKeys = (parsedParamObject: any) => {
    //perform light validation that all params are present
    //if code is fake, the refresh token request will fail
    if(!validateKeys(Object.keys(parsedParamObject))){
      return null
    }
    return parsedParamObject as codeFlowParams 
  }
  
  export const validateKeys = (keyList: Array<string>) => {
    /*if(keyList.length !== 6){
      return false
    }
  
    if(keyList[0] !== "state"){
      return false
    }
  
    if(keyList[1] !== "access_token"){
      return false
    }
  
    if(keyList[2] !== "token_type"){
      return false
    }
  
    if(keyList[3] !== "expires_in"){
      return false
    }
  
    if(keyList[4] !== "code"){
      return false
    }
  
    if(keyList[5] !== "id_token"){
      return false
    }*/
    return keyList.includes('code') && keyList.includes('id_token')
  
  }


  export const azureLoginParams = (overrideFlow: Auth.LoginPolicies = AuthConst.POLICIES.signIn ): OAuth2AuthenticateOptions=> ({
  appId: AuthConst.ENVIRONMENT.appId,
  authorizationBaseUrl: `${AuthConst.resolveSignInAuthority(overrideFlow)}/oauth2/v2.0/authorize`,
  accessTokenEndpoint: "",
  scope: `openid offline_access https://${AuthConst.ENVIRONMENT.tennent}/${AuthConst.ENVIRONMENT.appId}/user_impersonation`,
  responseType: "code id_token token",
  additionalParameters:{
    prompt:'login',
    exp: (Date.now()/1000 + 60 * 5).toFixed()

  },
  web: {
    redirectUrl: AuthConst.REDIRECT,
    windowTarget: "_self",
    pkceEnabled: false, //pkce is handled manually
  },
  android: {
      pkceEnabled: true,
      responseType: "code",
      redirectUrl: "com.midlandtrust.client.portal://oauth/auth",
      accessTokenEndpoint: AuthConst.resolveSignInAuthority(overrideFlow),
      handleResultOnNewIntent: true,
      handleResultOnActivityResult: true
  },
  ios: {
      pkceEnabled: true,
      responseType: "code",
      redirectUrl: "msauth.com.tenant://oauth",
      accessTokenEndpoint: AuthConst.resolveSignInAuthority(overrideFlow),
  }
})