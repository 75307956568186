import DateFnsUtils from '@date-io/date-fns'
import { IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { EMAIL_VALIDATION_PATTERN, formatDateToUTCString, PHONE_VALIDATION_PATTERN, SSN_VALIDATION_PATTER } from '../helpers/Utils'
import InputMask from 'react-input-mask'
import StateSelector from './StateSelector'
import { BeneficiaryType, uppercaseFirstCharacter } from '../helpers/beneficiaryHelper'
import { BeneficiaryForm } from './Beneficiaries'
import { convertStringDateToDate } from '../helpers/Utils'

const ANY_STRING_VALIDATION_PATTERN = '[^]*'
const MINIMUM_DATE_OF_BIRTH = '01/01/1900'

type BeneficiariesFieldsProps = {
    beneficiaryType: BeneficiaryType,
    index: number,
    totalSharePercentage: { primary: number, contingent: number },
    calculateSharePercentages: Function,
    swapBeneficiaryType: Function,
    deleteNewBeneficiary: Function
}

const BeneficiariesFields: React.FC<BeneficiariesFieldsProps> = ({ beneficiaryType, index, totalSharePercentage, calculateSharePercentages, swapBeneficiaryType, deleteNewBeneficiary }) => {
    const { control, setValue, getValues, clearErrors, register, formState: { errors } } = useFormContext<BeneficiaryForm>()

    const retrieveFieldError = (field: string): string | undefined => {
        const typeSpecificErrors = errors[beneficiaryType]
        if (typeSpecificErrors) {
            const beneficiaryErrors = typeSpecificErrors[index]
            if (beneficiaryErrors) {
                return beneficiaryErrors[field]?.message
            }
        }
    }

    const type = useWatch({ name: `${beneficiaryType}.${index}.type`, control })
    const firstName = useWatch({ name: `${beneficiaryType}.${index}.firstName`, control })
    const lastName = useWatch({ name: `${beneficiaryType}.${index}.lastName`, control })
    const email = useWatch({ name: `${beneficiaryType}.${index}.email`, control })
    const mailingStreet = useWatch({ name: `${beneficiaryType}.${index}.mailingStreet`, control })
    const mailingCity = useWatch({ name: `${beneficiaryType}.${index}.mailingCity`, control })
    const mailingState = useWatch({ name: `${beneficiaryType}.${index}.mailingState`, control })
    const mailingZip = useWatch({ name: `${beneficiaryType}.${index}.mailingZip`, control })
    const phone = useWatch({ name: `${beneficiaryType}.${index}.phone`, control })
    const dateOfBirth = useWatch({ name: `${beneficiaryType}.${index}.dateOfBirth`, control })
    const ssn = useWatch({ name: `${beneficiaryType}.${index}.ssn`, control })
    const share = useWatch({ name: `${beneficiaryType}.${index}.share`, control })
    const deleteBeneficiary = useWatch({ name: `${beneficiaryType}.${index}.deleteBeneficiary`, control })
    const disableFullEdit = useWatch({ name: `${beneficiaryType}.${index}.disableFullEdit`, control })

    useEffect(() => {
        if (beneficiaryType !== type) {
            swapBeneficiaryType(index, beneficiaryType)
        }
    }, [type])

    useEffect(() => {
        if (getValues(`${beneficiaryType}.${index}.relatedPartyId`) === '') {
            if (deleteBeneficiary) {
                deleteNewBeneficiary(index, beneficiaryType)
            }
        }
        else {
            if (deleteBeneficiary) {
                setValue(`${beneficiaryType}.${index}.share`, '0')
                setValue(`${beneficiaryType}.${index}.action`, 'delete')
                clearErrors(`${beneficiaryType}.${index}`)
            }
            else {
                calculateSharePercentages()
                setValue(`${beneficiaryType}.${index}.action`, disableFullEdit ? 'limitedEdit' : 'edit')
            }
        }
    }, [deleteBeneficiary])
 
    const registerFields = (inp: any, rules: any = {})=>{
        const { name, onBlur,onChange,ref } = register(inp, rules)
        return {
            name: name,
            onIonBlur: onBlur,
            onIonChange:onChange,
            ref: ref
        }
    }

    const deleteBeneficiaryValue = Boolean(deleteBeneficiary)

    const getInputMaskClass = () => {
        return deleteBeneficiaryValue || disableFullEdit ? "input-mask remove-focus p-1 grey-color" : "input-mask remove-focus p-1"
    }
   
    return (
        <>
            <IonRow className="align-items-center m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonLabel>{index + 1}. {uppercaseFirstCharacter(beneficiaryType)} Beneficiary</IonLabel>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8">
                    <IonSelect class="w-100 gr-border p-1" tabIndex={0} interface="action-sheet" mode="ios" interfaceOptions={{ animated: true, mode: 'ios' }} disabled={deleteBeneficiaryValue}  {...registerFields(`${beneficiaryType}.${index}.type`)} defaultValue={type} >
                        <IonSelectOption value='primary'>Beneficiary (Primary)</IonSelectOption>
                        <IonSelectOption value='contingent'>Beneficiary (Contingent)</IonSelectOption>
                    </IonSelect>
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> First Name</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                                <IonInput class="ion-text-left mr-1 pt-0" defaultValue={firstName} { ...registerFields(`${beneficiaryType}.${index}.firstName`,{ 
                                    required: {
                                        value: !deleteBeneficiaryValue,
                                        message: 'A first name is required.'
                                    }
                                })} maxlength={32} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('firstName') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('firstName')}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> Last Name</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                                <IonInput class="ion-text-left mr-1 pt-0" defaultValue={lastName} { ...registerFields(`${beneficiaryType}.${index}.lastName`, {
                                    required: {
                                        value: !deleteBeneficiaryValue,
                                        message: 'A last name is required.'
                                    }
                                    })} maxlength={32} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('lastName') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('lastName')}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Email</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                            <IonInput class="ion-text-left mr-1 pt-0" defaultValue={email} { ...registerFields(`${beneficiaryType}.${index}.email`, {
                                pattern: {
                                    value: (!deleteBeneficiaryValue) ? new RegExp(EMAIL_VALIDATION_PATTERN) : new RegExp(ANY_STRING_VALIDATION_PATTERN),
                                    message: 'Please provide a valid email address.'
                                }
                             })} maxlength={50} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('email') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('email')}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> Street</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                            <IonInput class="ion-text-left mr-1 pt-0" defaultValue={mailingStreet} { ...registerFields(`${beneficiaryType}.${index}.mailingStreet`, {
                                required: {
                                    value: !deleteBeneficiaryValue,
                                    message: 'Please provide a mailing street address.'
                                }
                            })} maxlength={33} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('mailingStreet') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('mailingStreet')}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> City</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                                <IonInput class="ion-text-left mr-1 pt-0" defaultValue={mailingCity} { ...registerFields(`${beneficiaryType}.${index}.mailingCity`, {
                                    required: {
                                        value: !deleteBeneficiaryValue,
                                        message: 'Please provide a mailing city.'
                                    }
                                })} maxlength={17} disabled={deleteBeneficiaryValue || disableFullEdit}  />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('mailingCity') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('mailingCity')}</p></IonItem>}
                </IonCol>
                 <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> State</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="m-0 p-0">
                                <IonCol class="m-0 p-0">
                                    <StateSelector name={`${beneficiaryType}.${index}.mailingState`} defaultValue={mailingState} disableField={deleteBeneficiaryValue || disableFullEdit} validationDisabled={deleteBeneficiaryValue} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('mailingState') === '' && <IonItem class="mt-1" color="danger"><p className="white-color">Please provide a valid mailing state.</p></IonItem>}
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> Zip</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border">
                                <InputMask className={getInputMaskClass()} mask='9999999999' defaultValue={mailingZip} maskChar='' { ...register(`${beneficiaryType}.${index}.mailingZip`, {
                                    required: {
                                        value: !deleteBeneficiaryValue,
                                        message: 'Please provide a mailing zip code.'
                                    }
                                })} disabled={deleteBeneficiaryValue || disableFullEdit}
                                />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('mailingZip') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('mailingZip')}</p></IonItem>}
                </IonCol>
            </IonRow>
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> Date of Birth</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow className="gr-border p-1">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Controller name={`${beneficiaryType}.${index}.dateOfBirth`} control={control} defaultValue={dateOfBirth} render={({ field: { name, value, onChange } }) => {
                                    return <KeyboardDatePicker name={name} minDate={MINIMUM_DATE_OF_BIRTH} disablePast={false} disableFuture={true} value={convertStringDateToDate(value)} format="MM/dd/yyyy" InputProps={{ disableUnderline: true }} animateYearScrolling={true} KeyboardButtonProps={{ 'aria-label': 'change-date' }} error={false} helperText={undefined} disabled={deleteBeneficiaryValue || disableFullEdit}
                                        onChange={(date) => {
                                            if (date) {
                                                const changedDate = formatDateToUTCString(date, 'MM-DD-YYYY')     
                                                onChange(changedDate)
                                            }
                                        }}
                                    />
                                    }}
                                    rules={{
                                        required: {
                                            value: !deleteBeneficiary,
                                            message: 'Please provide a date of birth.'
                                        },
                                        validate: value => {
                                            if (!deleteBeneficiary) {
                                                const formattedValue = () => {
                                                    const splitValue = value?.split('-');
                                                    if (splitValue) return `${splitValue[2]}-${splitValue[0]}-${splitValue[1]}`
                                                    return
                                                }
                                                if (!value || !Date.parse(formattedValue() || '')) {
                                                    return 'Please provide a valid date of birth.'
                                                }
                                                const fieldDate = new Date(value)

                                                const currentDate = new Date()
                                                if (fieldDate > currentDate) {
                                                    return 'Date of birth should not be in the future.'
                                                }

                                                const minDate = new Date(MINIMUM_DATE_OF_BIRTH)
                                                if (fieldDate < minDate) {
                                                    return 'Date of birth is before the minimum allowed date.'
                                                }
                                            }
                                        }
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('dateOfBirth') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('dateOfBirth')}</p></IonItem>}
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Phone</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow className="gr-border">
                                <InputMask className={getInputMaskClass()} defaultValue={phone} mask='(999) 999-9999' maskChar='' {...register(`${beneficiaryType}.${index}.phone`, {
                                    pattern: {
                                        value: (!deleteBeneficiaryValue) ? new RegExp(PHONE_VALIDATION_PATTERN) : new RegExp(ANY_STRING_VALIDATION_PATTERN),
                                        message: 'Please provide a valid phone number.'
                                    }
                                })} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('phone') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('phone')}</p></IonItem>}
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>SSN</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow className="gr-border">
                            <InputMask className={getInputMaskClass()} mask='999-99-9999' defaultValue={ssn} { ...register(`${beneficiaryType}.${index}.ssn`, {
                                 pattern: {
                                    value: (!deleteBeneficiaryValue) ? new RegExp(SSN_VALIDATION_PATTER) : new RegExp(ANY_STRING_VALIDATION_PATTERN),
                                    message: "Please provide a valid social security number"
                                }
                            })} maskChar='' alwaysShowMask={false} disabled={deleteBeneficiaryValue || disableFullEdit} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('ssn') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('ssn')}</p></IonItem>}
                </IonCol>
            </IonRow> 
            <IonRow className="m-0 p-0">
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel><span className="text-danger">*</span> Share %</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border p-0">
                            <Controller name={`${beneficiaryType}.${index}.share`} control={control} defaultValue={share} render={({ field: { value, onBlur, onChange, name } }) =>
                                    <IonInput class="ion-text-left" name={name} value={value} type='number' disabled={deleteBeneficiaryValue || disableFullEdit} onIonBlur={onBlur} onIonChange={(event) => {
                                        onChange(event.detail.value)
                                        calculateSharePercentages()
                                    }} />}
                                    rules={{
                                        required: {
                                            value: !deleteBeneficiary,
                                            message: 'Please provide a valid share percentage.'
                                        },
                                        validate: value => {
                                            if (!deleteBeneficiary) {
                                                if(isNaN(+value)) {
                                                    return 'Please provide a valid share percentage.'
                                                }
                                                
                                                const share = +value
                                                
                                                if (share <= 0) {
                                                    return 'Share percentage must be positive.'
                                                }
                                                else if (share > 100) {
                                                    return 'Share percentage cannot be greater than 100.'
                                                }
                                            }
                                        }
                                    }}
                                />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {retrieveFieldError('share') && <IonItem class="mt-1" color="danger"><p className="white-color">{retrieveFieldError('share')}</p></IonItem>}
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Total {uppercaseFirstCharacter(beneficiaryType)} %</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="gr-border p-0">
                                <IonInput class="ion-text-left" value={totalSharePercentage[beneficiaryType]} disabled={true} />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                    <IonRow class="mt-2 mb-1">
                        <IonLabel>Delete</IonLabel>
                    </IonRow>
                    <IonRow class="m-0 p-0">
                        <IonCol class="m-0 p-0">
                            <IonRow class="mt-1">
                                <Controller name={`${beneficiaryType}.${index}.deleteBeneficiary`} control={control} render={({ field: {value, onChange, name}}) => (
                                    <IonCheckbox color="primary" name={name} checked={value} onIonChange={(event) => {onChange(event.detail.checked)}}/>)}
                                    />
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    )
}

export default BeneficiariesFields