import React, { FC } from 'react';
import {
    IonRow,
    IonCol,
    IonButton,
    IonSpinner
} from '@ionic/react';
import { useForm, FormProvider, } from 'react-hook-form';
import { Link } from 'react-router-dom'
import Spinner from './../Spinner'
import CreditCardFields from './CreditCardFields';

export type CreditCardFormInfo = {
    creditCardNumber: string,
    expiry: string,
    zipCode: string,
}

interface IProps {
    onCardSubmit: (value: object) => void
    isLoading: boolean
    creditCardInfo: CreditCardFormInfo
    title?: string,
    validateFields?: boolean,
    maskValue?: string,
    showUpdateButton?: boolean
}

const CreditCardInfo: FC<IProps> = ({ onCardSubmit, isLoading, creditCardInfo, title, validateFields, maskValue, showUpdateButton=true}) => {
    const defaultValues: CreditCardFormInfo = creditCardInfo || {
        creditCardNumber: "",
        expiry: "",
        zipCode: "",
    }

    const myCardInfo = useForm<CreditCardFormInfo>({
        mode: 'onChange',
        defaultValues: defaultValues
    });

    const { control, formState: { errors }, handleSubmit } = myCardInfo;

    const onSubmit = handleSubmit((data) => {
        onCardSubmit(data)
    });

    return (
        <>
            <IonRow>
                <IonCol class="pl-1"><h4>{title}</h4></IonCol>
            </IonRow>
            <IonRow className="container w-100">
                <IonCol>
                    <FormProvider {...myCardInfo}>
                        <form className="w-100" onSubmit={onSubmit}>
                            {
                                isLoading ? (
                                    <Spinner />
                                ) : (
                                    <CreditCardFields 
                                        creditCardNumberFieldName="creditCardNumber"
                                        errors={errors}
                                        control={control}
                                        validateFields={validateFields}
                                        maskValue={maskValue}
                                        defaultValues={{
                                            creditCardNumber: defaultValues.creditCardNumber,
                                            expiry: defaultValues.expiry,
                                            zipCode: defaultValues.zipCode
                                        }} />
                                )}
                            <IonRow className="form-actions d-block mt-1 text-end">
                                {showUpdateButton && 
                                    <IonCol>
                                        <button disabled={isLoading} type='submit' className='p-0'>
                                            <IonButton className='m-0'> {isLoading ? <IonSpinner name="bubbles" /> : ""}Update</IonButton>
                                        </button>
                                    </IonCol>
                                }
                                <IonCol>
                                    <Link to="/billing">
                                        <button className=' white-bg'>
                                            <IonButton color="light" className='w-100'> Cancel</IonButton>
                                        </button>
                                    </Link>
                                </IonCol>
                            </IonRow>
                        </form>
                    </FormProvider>
                </IonCol>
            </IonRow>
        </>
    );
};

export default CreditCardInfo
