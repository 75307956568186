import React from 'react';
import {
  IonRow,
  IonCol,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { connect } from 'react-redux';
import calloutHelpers from '../../helpers/calloutHelpers'
import DataLoader from '../DataLoader';
import { isDarkMode } from '../../helpers/Utils'
import { formatNumber, formatDateString } from 'shared-utils';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import PartyTypeConditionalAccess from '../PartyTypeConditionalAccess';
import realEstateIcon from '../../images/real-estate-icon.svg';
import noteMortgage from '../../images/notes-mortgage-icon.svg';
import tradingBankAccount from '../../images/trading-bank-account-icon.svg';
import generalAsset from '../../images/general-asset-icon.svg';
import realEstateIconDark from '../../images/real-estate-icon-dk.svg';
import noteMortgageDark from '../../images/notes-mortgage-icon-dk.svg';
import tradingBankAccountDark from '../../images/trading-bank-account-icon-dk.svg';
import generalAssetDark from '../../images/general-asset-icon-dk.svg';


const HoldingList: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {
  
  const history = useHistory()

  interface formType {
    action: string;
  }

  const getDefaultValues = ()=>{
    let defaultForm: formType = {
      action: '',
    }
    return defaultForm
  } 

  const {control} = useForm<formType> ({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });
  
  const goToHoldingDetails = (holding : Holding) => {
    history.push({
        pathname: '/holding',
        state: holding
    })
  }

  const goToUpdateValue = (holding : Holding) => {
    history.push({
        pathname: '/update-asset',
        state: {holding}
    })
  }

  const goToSendFundsToExistingInvestment = (holding: Holding) => {
    history.replace('/additional-buy', {
      fromHolding: true,
      state: { holding }
    });
  }

  const goToInvitePayer = (holding: Holding) => {
    history.replace('/invite-payers', {
      state: { holding }
    });
  }

  const handleActionChange = (holding: any)=>{
    return (event) => {
      switch (event.detail.value) {
        case 'More Details':
          goToHoldingDetails(holding);
          break;
        case 'Update Value':
          goToUpdateValue(holding);
          break;
        case 'Send Funds to an Existing Investment':
          goToSendFundsToExistingInvestment(holding);
          break;
        case 'Invite Payer':
          goToInvitePayer(holding);
          break;
      }
    }
  }
  
  const getHoldingIcon = (assetType: string) => {
    if (assetType === 'Property Owned') {
      return isDarkMode() ? realEstateIconDark : realEstateIcon;
    };

    if (assetType.includes('Notes')) {
      return isDarkMode() ? noteMortgageDark : noteMortgage;
    };

    if (assetType === 'Futures/Forex Acct' || assetType === 'Brokerage Acct') {
      return isDarkMode() ? tradingBankAccountDark : tradingBankAccount;
    };

    return isDarkMode() ? generalAssetDark : generalAsset;
  }

 const includeSendFundsToInvestment = (holding: Holding) => {
    let cusip = holding.Cusip
    if (cusip.FuturesForexCompanyId) {
      if (cusip.FuturesForexCompany?.BankAccountName && cusip.FuturesForexCompany?.BankAccountNumber && cusip.FuturesForexCompany?.ReceivingABA) {
        return true
      }
    }
    else if (cusip.DeliveryMethod && cusip.ABA && cusip.CreditAccount && cusip.CreditName) {
      return true
    }
    return false
  }

  const renderAssetDetails = (holdings: Array<Holding>) => {
    
    if (!holdings || (holdings.length < 1)) return <p>This account does not have any holdings.</p>
    
    return (
      <IonList class="pl-2 pr-2">
        {holdings.map(holding => {
          return (
            <IonRow key={holding.Id} class="divider">
              <IonCol class="m-0 p-0" size="10">
                <IonRow class="mt-1">
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <p className="m-0"><b>Investment Name</b>:</p>
                  </IonCol>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <p className="m-0">{holding.AssetDescription}</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <p className="m-0"><b>Market Value Date</b>:</p>
                  </IonCol>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <p className="m-0">{holding.MarketValueDate && formatDateString(holding.MarketValueDate)}</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <p className="m-0"><b>Market Value</b>:</p>
                  </IonCol>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <p className="m-0">${formatNumber(holding.MVFromATG?.toFixed(2))}</p>
                  </IonCol>
                </IonRow>
                <IonRow class="pb-2">
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <p className="m-0"><b>Actions</b>:</p>
                  </IonCol>
                  <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                    <Controller name="action" control={control} render={({ field: { value, onBlur } }) => <IonSelect class="w-100 gr-border pt-0 pb-0 pl-1 pr-1" tabIndex={0} interface='action-sheet' onIonChange={handleActionChange(holding)} onIonBlur={onBlur} placeholder="Select Action" value={value} interfaceOptions={{ animated: true, mode: 'ios' }}>
                      <IonSelectOption value="More Details">More Details</IonSelectOption>
                      <PartyTypeConditionalAccess featureDescription='updateAssetValue'>
                        <IonSelectOption value="Update Value">Update Value</IonSelectOption>
                      </PartyTypeConditionalAccess>
                      {includeSendFundsToInvestment(holding) &&
                        <PartyTypeConditionalAccess featureDescription='createAdditionalBuy'>
                          <IonSelectOption value="Send Funds to an Existing Investment">Send Funds to an Existing Investment</IonSelectOption>
                        </PartyTypeConditionalAccess>                    
                      }
                      <PartyTypeConditionalAccess featureDescription='invitePayer'>
                        {(holding.AssetType === "Property Owned" || holding.AssetType?.includes("Note")) && <IonSelectOption value="Invite Payer">Invite Payer</IonSelectOption>}
                      </PartyTypeConditionalAccess>
                    </IonSelect>} />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol class="p-0 d-flex justify-right" size="2">
                <img src={getHoldingIcon(holding.AssetType)} width="40px" className="align-items-center pt-1 pb-1" alt="Real estate icon" />
              </IonCol>
            </IonRow>
          )
        })}
      </IonList>
    )
  }

  return (
   <DataLoader apiCall={() => calloutHelpers.getNonCashHoldings(selectedAccount?.id)} render={renderAssetDetails} dependencies={[selectedAccount]} /> 
  );
}; 

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
  }
}

export default connect(mapStateToProps)(HoldingList);