import React from 'react';
import {
    IonRow,
    IonCol
  } from '@ionic/react';
import { useHistory } from 'react-router';
import CaseDetails from './CaseDetails';
import ContainerComponent from './ContainerComponent';
import Sidebar from './Sidebar';

//NOTE: This component only really exists to help with testing. There didn't seem to be a good way to mock useHistory.
//This also pulls the ContainerComponent out so shallow rendering could be used to test CaseDetails. Past those two
//points, we should be able to combine this component with CaseDetails.

const HoldingContainer: React.FC = () => {
    const history = useHistory()
    const caseItem = history.location.state as Case

    return (
        <>
            <IonRow class="container">
                <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <ContainerComponent title = 'Case' content = {<CaseDetails caseItem ={caseItem}/>}/>
                </IonCol>
                <Sidebar />
            </IonRow>
        </>
    )
}

export default HoldingContainer;