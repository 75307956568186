import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller, SubmitHandler, FieldError } from 'react-hook-form';
import { IonModal, IonItem, IonRow, IonButton, IonLabel, IonCol, IonInput, IonIcon, IonTitle } from '@ionic/react';
import calloutHelper from '../../helpers/calloutHelpers';
import FileSelectButton from './FileSelectButton';

type FileUploadForm = {
    description: string,
    files: FileAndUploadStatus[]
}

const ShowFieldError: React.FC<{error: FieldError | undefined}> = ({error}) => {
    return error ? <IonItem lines='none' class='FieldError'>{error?.message}</IonItem> : null
}

type FileUpload ={
    setShowFileUploadModal: Function,
    showFileUploadModal: boolean,
    finishUpload: () => void,
    pendingTransactionName?: string
}

const FileUpload: React.FC<FileUpload> = ({setShowFileUploadModal, showFileUploadModal, finishUpload, pendingTransactionName}) => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ personDetails, setPersonDetails ] = useState<{name: string, email: string}>({name: '', email: ''});

    const defaultValues: FileUploadForm = {
        description: '',
        files: []
    }

    const { handleSubmit, control, watch, setValue, setError, formState, reset } = useForm<FileUploadForm>({defaultValues});
    const files = watch('files');

    const loadAccountData = useCallback(async () => {
        setLoading(true);
        const personAccountResponse = await calloutHelper.getLoggedInPersonAccount();
        const personDetail = personAccountResponse.data.myProfile;
        setPersonDetails({name: `${personDetail.firstName} ${personDetail.lastName}`, email: personDetail.email})
        setLoading(false);
    }, [setPersonDetails])

    useEffect(() => {
        loadAccountData();
    }, [showFileUploadModal])

    const uploadFiles = async (formFields: FileUploadForm) => {
        setLoading(true);
        let errorOccurred = false;
        const filesToProcess = files.filter(val => val.status !== 'uploaded')
        for (const fileToUpload of filesToProcess) {
            const metaData: ClientFileUploadRequest = {
                file: fileToUpload.file,
                metaData: {
                    name: personDetails.name,
                    email: personDetails.email,
                    description: formFields.description,
                    pendingTransactionName: pendingTransactionName
                }
            }
            try {
                fileToUpload.status = 'added';
                setValue('files', [...files]);
                const result = await calloutHelper.uploadFile(metaData);
                if (result.status === 200) {
                    fileToUpload.status = 'uploaded';
                } else {
                    errorOccurred = true;
                    fileToUpload.status = 'error';
                };
            } catch (err) {
                errorOccurred = true;
                console.error(err);
                fileToUpload.status = 'error';
            }
            setValue('files', [...files]);
        }

        if (!errorOccurred) {
            setTimeout(() => finishUpload(), 500);
            return;
        } else {
            setError('files', {
                message: 'One or more files failed to upload. Try again by pressing Upload.',
                type: 'deps'
            });
        };
        setLoading(false);
    };

    const onValid: SubmitHandler<FileUploadForm> = (formFields) => {
        !loading && uploadFiles(formFields);
    };

    const showFileUploadFailed = () => {
        const fieldError: FieldError | undefined = (formState?.errors?.files as any);
        if (fieldError?.type && fieldError.type === 'deps') {
            return <ShowFieldError error={fieldError} />
        };
    };

    return (
        <IonModal cssClass={'file-upload-modal'} mode='ios' isOpen={showFileUploadModal} onDidDismiss={() => {
            setShowFileUploadModal(false);
            reset();
        }}>
            <form onSubmit={(event) => {event.preventDefault(); handleSubmit(onValid)(event)}}>
                <IonCol>
                    <IonRow>
                        <div className='ModalHeader'>Secure Upload</div>
                        <IonIcon class='ModalHeader' icon='close' onClick={() => {setShowFileUploadModal(false); reset()}}/>
                    </IonRow>
                    <div className='p-1 light-gr-bg'>
                        <IonRow class='gr-border file-upload-file-form'>
                            <IonCol class='pl-3 pr-3 pb-1 white-bg ModalHeader'>
                                {showFileUploadFailed()}
                                <IonRow class='mt-1 mb-1'>
                                    Use this form to securely upload files to Equity Trust. Please provide what the document is, who it is for, and what you would like Equity Trust to do with the file. We will process your uploaded file within two business days.
                                </IonRow>
                                <Controller
                                    control={control}
                                    name='description'
                                    render={({ field, fieldState: {error} }) =>
                                        <IonCol>
                                            <IonRow class='mt-1 mb-1'>
                                                <IonLabel>Please describe what you are uploading:</IonLabel>
                                            </IonRow>
                                            <IonInput class='FileUpload' name={field.name} type='text' onIonChange={field.onChange} onIonBlur={field.onBlur} value={field.value} />
                                            <IonRow>
                                                <ShowFieldError error={error} />
                                            </IonRow>
                                        </IonCol>
                                    }
                                    rules={
                                        { required: 'Please enter a description.' }
                                    }
                                />
                                <Controller
                                    control={control}
                                    name='files'
                                    rules={
                                        { required: 'Please select one or more files' }
                                    }
                                    render={({field: props, fieldState: {error}}) => (
                                        <IonCol>
                                            <FileSelectButton files={props.value} uploading={loading} filesUploadedCallback={val => {
                                                if (val.status === 'success') {
                                                    props.onChange(val.files)
                                                }
                                            }}
                                            deleteFile={(indexToDelete: number) => {
                                                setValue('files',
                                                    props.value.filter((val, index) => indexToDelete != index)
                                                )
                                            }}
                                            />
                                            <IonRow>
                                                <ShowFieldError error={error} />
                                            </IonRow>
                                        </IonCol>
                                    )}
                                />
                            </IonCol>
                        </IonRow>
                    </div>
                </IonCol>
            </form>
            <IonButton onClick={handleSubmit(onValid)} disabled={loading}>Upload</IonButton>
        </IonModal>
    )
}

export default FileUpload