import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import '../pages/Style.css';
import { 
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
  IonTextarea,
  IonInput,
  IonToast
} from '@ionic/react';
import { useHistory } from 'react-router';
import calloutHelpers from '../helpers/calloutHelpers';
import { throwError } from 'shared-utils'

type InvitePayer = {
  firstName: string,
  lastName: string,
  email: string,
  personalNote: string,
  label: string,
  description: string,
  clientName: string,
  assetType: string
}

const InvitePayers: React.FC = () => {
  const history: any = useHistory();
  const holding = history?.location?.state?.state?.holding;
  const label = holding?.Cusip?.AssetName1;
  const description = holding?.Cusip?.AssetDescription;
  const [savedFields, setSavedFields] = useState<InvitePayer>();
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [ unsuccessfulToast, setUnsuccessfulToast ] = useState<{message: 'An error has occurred. Email was not sent.' | '', color: 'danger' | ''}>();

  const { control, handleSubmit, formState: { errors }, reset } = useForm<InvitePayer>();

  const renderPayerInvitationForm = () => {
    return <form data-testid='payer-form' onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol class="pr-2 pl-2" size="12">
          <IonRow>
            <IonCol>
              <p>Send an email invitation to a Third Party which allows the party to submit payments directly to your IRA.</p>

              <p>The Equity Trust Payment site allows payers to schedule both recurring and one time outgoing payments from their bank. Please complete the fields below, click "Send Invitation Email", and a link to establish login credentials will be sent to the payer.</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem color="tertiary">
                Payer Information
              </IonItem>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow className="mt-2 mb-1">
                <IonLabel>First Name</IonLabel>
              </IonRow>
              <IonRow className="m-0 p-0">
                <IonCol className="m-0 p-0 ">
                  <Controller name='firstName' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonInput data-testid='payer-first-name' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} value={value} />
                  } rules={{ required: {
                    message: 'Please enter payer first name.',
                    value: true
                  }}} />
                </IonCol>
              </IonRow>
              {errors['firstName'] && <IonItem className="mt-1" color="danger"><p className="white-color">{errors['firstName']?.message}</p></IonItem>}
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow className="mt-2 mb-1">
                <IonLabel>Last Name</IonLabel>
              </IonRow>
              <IonRow className="m-0 p-0">
                <IonCol className="m-0 p-0 ">
                  <Controller name='lastName' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonInput data-testid='payer-last-name' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} value={value} />
                  } rules={{ required: {
                    message: 'Please enter payer last name.',
                    value: true
                  }}} />
                </IonCol>
              </IonRow>
              {errors['lastName'] && <IonItem className="mt-1" color="danger"><p className="white-color">{errors['lastName']?.message}</p></IonItem>}
            </IonCol>
          </IonRow>
          <IonRow class="mb-3">
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow className="mt-2 mb-1">
                <IonLabel>Email</IonLabel>
              </IonRow>
              <IonRow className="m-0 p-0">
                <IonCol className="m-0 p-0 ">
                  <Controller name='email' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonInput data-testid='payer-email' class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} value={value} />
                  } rules={{ required: {
                    message: 'Please enter payer email address.',
                    value: true
                  }}} />
                </IonCol>
              </IonRow>
              {errors['email'] && <IonItem className="mt-1" color="danger"><p className="white-color">{errors['email']?.message}</p></IonItem>}
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow className="mt-2 mb-1">
                <IonLabel>Personal Note</IonLabel>
              </IonRow>
              <IonRow className="m-0 p-0">
                <IonCol className="m-0 p-0 ">
                  <Controller name='personalNote' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonTextarea data-testid='payer-personal-note' class="ion-text-left gr-border pl-1 pr-1 mt-0" name={name} autocapitalize="sentences" inputmode="text" onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} value={value} />
                  } />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol class="mt-2" size="12">
              <IonItem color="tertiary">
                CUSIP Information
              </IonItem>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow>
                <p><b>Name</b>: {label}</p>
              </IonRow>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonRow>
                <p><b>Description</b>: {description}</p>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonButton type="submit" class="ion-float-right" size="large">Send Invitation Email</IonButton>
        </IonCol>
      </IonRow>
    </form>
  }

  const renderSuccessPage = () => {
    return <IonRow data-testid='success-page' class="w-100">
      <IonCol size="12">
        <IonItem color="tertiary" class="mt-2">
          Payer Successfully Invited
        </IonItem>
        <p className='ml-1'>The email invitation was successfully sent using the Payer Details below.</p>
        <IonItem color="tertiary">
          Payer Details
        </IonItem>
        <p data-testid='success-first-name' className='ml-1 mb-0'><b>First Name</b>: {savedFields?.firstName}</p>
        <p data-testid='success-last-name' className='ml-1 mt-0 mb-0'><b>Last Name</b>: {savedFields?.lastName}</p>
        <p data-testid='success-email' className='ml-1 mt-0 mb-0'><b>Email</b>: {savedFields?.email}</p>
        <p data-testid='success-personal-note' className='ml-1 mt-0'><b>Personal Note</b>: {savedFields?.personalNote}</p>
        <IonButton onClick={() => history.replace('/account-details')} class="ion-float-right" size="large">Finished</IonButton>
        <IonButton onClick={() => setEmailSuccess(false)} color="light" class="ion-float-right" size="large">Invite Another Payer</IonButton>
      </IonCol>
    </IonRow>
  }

  const onSubmit = async (formFields: InvitePayer) => {
    try {
      const sendPayerInvitationsResult = await calloutHelpers.sendPayerInvitation({
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        personalNote: formFields.personalNote,
        label: holding.Cusip.Name,
        description: holding.AssetDescription,
        clientName: `${holding.Account.ClientFirstName} ${holding.Account.ClientLastName}`,
        assetType: holding.AssetType
      });
      console.log(sendPayerInvitationsResult);

      const resultStatus = sendPayerInvitationsResult?.data;

      if (resultStatus.successResults.length > 0) {
        setUnsuccessfulToast(undefined);
        setSavedFields(formFields);
        setEmailSuccess(true);
        reset();
        return;
      };

      if (resultStatus.unsuccessfulResults.length > 0) {
        setUnsuccessfulToast({message: 'An error has occurred. Email was not sent.', color: 'danger'});
        return;
      };

    } catch (err) {
      throwError(err);
    }
  }

  return (
    <IonRow class="container" data-test="component-transactions-list-container">
        {unsuccessfulToast !== undefined &&
          <IonToast data-testid='unsuccessful-toast' isOpen={unsuccessfulToast !== undefined} color={unsuccessfulToast?.color} position='top' message={unsuccessfulToast?.message} onDidDismiss={() => setUnsuccessfulToast(undefined)} buttons={[{ icon: 'close-circle' }]} />
        }
        <IonCol class="p-1" size="12">
          <IonRow>
            <IonCol class="mb-2 p-1 light-gr-bg">
              <IonRow>
                <IonCol class="pt-1 pb-2 gr-border white-bg">
                  <IonRow>
                    <h1 className="page-title">Invite Payers</h1>
                  </IonRow>
                  <IonRow>
                    {(emailSuccess ? renderSuccessPage() : (label && description) ? renderPayerInvitationForm() : <p>No holding selected. Please go to the Holdings page and select Invite Payer.</p>)}
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
  );
};

export default InvitePayers;