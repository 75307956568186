import React, { useState } from 'react';
import {IonRow, IonCol, IonItem, IonProgressBar, IonAlert, IonRouterLink, IonList, IonLoading
} from '@ionic/react';
import progressBarStepOne from '../../../images/update-asset-step-one.svg'
import { isPhone } from '../../../helpers/Utils';
import { formatNumber, formatDateString } from 'shared-utils';
import calloutHelper from '../../../helpers/calloutHelpers/caseCalloutHelper';

const UpdateAssetStepOne: React.FC<{pendingValuationCases: Array<any>, setPendingValuationCases: Function, scrollToTop: Function, holding: Holding, formRef: React.RefObject<HTMLFormElement>, goToNextStep}> = ({pendingValuationCases, setPendingValuationCases, formRef, scrollToTop, holding, goToNextStep}) => {
  
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [showCancelCaseLoading, setShowCancelCaseLoading] = useState<boolean>(false);
  const [cancelCaseError, setCancelCaseError] = useState<string>('');
  
  const handleCancel = async(caseId : string) => {

    setShowCancelCaseLoading(true);
    
    try {
      await calloutHelper.cancelFairMarketValueCase(caseId);

      setShowCancelAlert(false);
      setShowCancelCaseLoading(false);
      scrollToTop();
      setPendingValuationCases([]);
    }
    catch (e) {
      setCancelCaseError('ERROR: Failed cancelling case.');
      setShowCancelAlert(true);
    } 
    
    setShowCancelCaseLoading(false);  
  }

  const handleCancelCaseClick = (caseId : string) => {
    setShowCancelAlert(true);
    setSelectedCaseId(caseId);
  }

  const handleNext = (e) => {
    e.preventDefault()
    goToNextStep();
  }

  return (
    <form ref={formRef} onSubmit={handleNext}>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <IonRow class="divider">
        <h1 className="ion-text-left">Update Asset Valuation Instructions:</h1>
        <ul>
          <li>As a Self‐Directed account holder, you are responsible for ensuring this form is completed</li>
          <li>The asset value may not be determined by you or any disqualified parties</li>
          <li>The asset value must be determined by an independent, neutral, qualified third party</li>
          <li>Supporting documentation MUST be attached</li>
          <li>For correct tax reporting, if you are supplying year-end value, please input 12/31 for the "New Price Date" and NOT today's date</li>
        </ul>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonRow>
            <IonCol>
              <IonRow class="w-100">
                <h2>Holding Information</h2>
              </IonRow>
              <IonRow class="divider">
                <IonItem lines="none">
                  <p>
                    <b>Account</b>: {holding?.Account?.Name} - {holding?.Account?.ClientAccount?.FirstName} {holding?.Account?.ClientAccount?.LastName}<br />
                    <b>Asset Description</b>: {holding.AssetDescription}<br/>
                    <b>CUSIP</b>: {holding.Cusip.Name}<br/>
                    <b>Asset Type</b>: {holding.AssetType}<br/>
                    <b>Price Date</b>: {formatDateString(holding.MarketValueDate)}<br/>
                    <b>Market Value</b>: ${formatNumber(holding.MVFromATG?.toFixed(2))}
                  </p>
                </IonItem>
              </IonRow>
                {(pendingValuationCases.length > 0) && 
                  <>
                    <IonRow>
                      <h2>Pending Valuation Case</h2>
                    </IonRow>
                    <IonRow class="divider">
                      <IonList>
                        {pendingValuationCases && pendingValuationCases.map((caseToRender : Case) => {
                          return (
                            <IonItem class="w-100" lines="none" key={caseToRender.Id}>
                              <p>
                                <b>Asset Description</b>: {caseToRender.AssetDescription}<br/>
                                <b>New Market Value</b>: ${formatNumber(caseToRender.NewMarketValue)}<br/>
                                <b>Market Value Change</b>: ${formatNumber(caseToRender.MarketValueChange)}<br/>
                                <IonRouterLink onClick={() => handleCancelCaseClick(caseToRender.Id)} class="cursor-pointer">Cancel Case</IonRouterLink>
                              </p>
                            </IonItem>
                          )
                        })}
                      </IonList>
                    </IonRow>
                    <p>In order to create a new case, you must cancel the pending case.</p>
                  </>
                }
                <IonAlert
                  isOpen={showCancelAlert}
                  onDidDismiss={() => setShowCancelAlert(false)}
                  header={'Are you sure you wish to cancel the existing valuation case?'}
                  subHeader={cancelCaseError}
                  buttons={[
                    {
                      text: 'Go Back',
                      role: 'cancel',
                      handler: () => {
                        setShowCancelAlert(false);
                      }
                    },
                    {
                      text: 'Cancel Existing Case',
                      handler: () => {
                        handleCancel(selectedCaseId);
                      }
                    }
                  ]}
                />
                <IonLoading
                  isOpen={showCancelCaseLoading}
                  message={'Cancelling case...'}
                />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </form>
  );
};


export default UpdateAssetStepOne;
