import React, { Fragment, useEffect, useState } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonToolbar,
  IonButton,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonAlert,
  IonToast
} from '@ionic/react';
import Spinner from './Spinner';
import ContainerComponent from './ContainerComponent';
import EditBankPayeeModal from './EditBankPayeeModal';
import calloutHelper from '../helpers/calloutHelpers';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PartyTypeConditionalAccess from './PartyTypeConditionalAccess';
import Sidebar from './Sidebar';
import { isDarkMode } from '../helpers/Utils';

const SavedPayees: React.FC = () => {
  const history = useHistory();

  const [savedPayeeLists, setSavedPayeeLists] = useState<{savedPayeesResult: SavedPayee[], savedBanksResult: SavedBankFields[]}>({savedPayeesResult: [], savedBanksResult:[]})
  const [showSpinner, setShowSpinner] = useState<Boolean>(false)
  const [segmentType, setSegmentType] = useState<'edit payee' | 'edit bank'>('edit payee');
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [payeeOrBank, setPayeeOrBank] = useState<{savedType: 'payee' | 'bank' | '', payeeOrBankId: string}>({savedType: '', payeeOrBankId: ''});
  const [toastMessage, setToastMessage] = useState<{message: string, color: 'success' | 'danger' | ''}>({message: '', color: ''})
  const [showToast, setShowToast] = useState<boolean>(false);

  const getButtonColor = () => isDarkMode() ? 'primary' : 'secondary'

  const updateSelectedSegment = (e: CustomEvent) => {
    let selectedSegment: 'edit payee' | 'edit bank' = e.detail.value;
    setSegmentType(selectedSegment);
  }

  const goToBillPay = (payeeItem: any) => {
    history.push('/pay-a-bill',{
      PreselectedFields: {payeeId: payeeItem.payeeId}
    })
  }

  const goToContribution = (bankItem: Bank) => {
    history.push('/make-a-contribution',{
      PreselectedFields: {selectedBankId: bankItem.bankId}
    })
  }

  const deletePayee = async () => {
    try {
      if (payeeOrBank.savedType && payeeOrBank.payeeOrBankId) {
        setShowSpinner(true);
        const deleteSavedPayeeOrBankResult = await calloutHelper.deletePayee(payeeOrBank.payeeOrBankId);
        if (deleteSavedPayeeOrBankResult.data === 'Success' || deleteSavedPayeeOrBankResult.data === 'Deleted payee from postgres') {
          if (payeeOrBank.savedType === 'payee') {
            const savedPayeeListsWithRemovedPayee = savedPayeeLists.savedPayeesResult.filter(payees => payees.payeeId !== payeeOrBank.payeeOrBankId);
            setSavedPayeeLists(prevState => { return {savedPayeesResult: savedPayeeListsWithRemovedPayee, savedBanksResult: prevState.savedBanksResult}});
            setToastMessage({message: 'Payee deleted', color: 'success'});
          };
          if (payeeOrBank.savedType === 'bank') {
            const savedPayeeListsWithRemovedBank = savedPayeeLists.savedBanksResult.filter(banks => banks.bankId !== payeeOrBank.payeeOrBankId);
            setSavedPayeeLists(prevState => { return {savedBanksResult: savedPayeeListsWithRemovedBank, savedPayeesResult: prevState.savedPayeesResult}});
            setToastMessage({message: 'Bank deleted', color: 'success'});
          };
        };
        setShowSpinner(false);
        setShowToast(true);
      };
    } catch {
      if (payeeOrBank.savedType === 'payee') {
        setToastMessage({message: 'Payee deleting error', color: 'danger'});
      }
      if (payeeOrBank.savedType === 'bank') {
        setToastMessage({message: 'Bank deleting error', color: 'danger'});
      };
      setShowSpinner(false);
      setShowToast(true);
    };
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const getSavedPayeeItemsList = async (token: any) => {
      
      setShowSpinner(true);

      try {
        let savedPayeeAndBankItemResult = await calloutHelper.getSavedPayeesAndBanks(token);
        
        if (savedPayeeAndBankItemResult && savedPayeeAndBankItemResult.data) {
          setSavedPayeeLists(savedPayeeAndBankItemResult.data);
        }

        setShowSpinner(false);
        
      } catch (err) {
        setShowSpinner(false);
      }
    }

    getSavedPayeeItemsList(source.token);
    
  }, [])

  const renderPayeeList = (savedPayeeList: SavedPayee[]) => {
    if (showSpinner) {
      return (
       <Spinner />
      )
    }

    if (savedPayeeList?.length <= 0) {
      return <p className='pl-1 pr-1'>We could not find any payees for your account.</p>
    }

    if (savedPayeeList?.length > 0) {
      return <IonList class="w-100 mb-1">
        {savedPayeeList.map(payeeItem => {
          return <Fragment key={payeeItem.payeeId}>
          <IonItem>
            <p>
              <span>
                <b>Payable To</b>: {payeeItem.payableTo}<br />
              </span>
              <span data-test='account-info'>
                <b>Delivery Method</b>: {payeeItem.deliveryMethod === 'ACH' ? 'Direct Deposit' : payeeItem.deliveryMethod}<br />
              </span>
              <span data-test='asset-info'>
                <b>Bill Payment Type</b>: {payeeItem.billPaymentType}<br />
              </span>
              {payeeItem.expenseType &&
                <span data-test='asset-info'>
                  <b>Expense Type</b>: {payeeItem.expenseType}<br />
                </span>
              }
              <span data-test='asset-info'>
                <b>Payment Memo</b>: {payeeItem.paymentMemo}
              </span>
              <IonRow class='pt-0-5'>
                <PartyTypeConditionalAccess featureDescription='createBillPayment'>
                  <IonButton size='small' color={getButtonColor()} onClick={() => goToBillPay(payeeItem)}>Pay</IonButton>
                </PartyTypeConditionalAccess>
                <IonButton size='small' color={getButtonColor()} onClick={() => {
                  setPayeeOrBank({savedType: 'payee', payeeOrBankId: payeeItem.payeeId});
                  setShowEditModal(true);
                }}>Edit</IonButton>
                <IonButton size='small' color={getButtonColor()} onClick={() => (setPayeeOrBank({savedType: 'payee', payeeOrBankId: payeeItem.payeeId}), setShowConfirmDeleteAlert(true))}>Delete</IonButton>
              </IonRow>
            </p>
          </IonItem>
        </Fragment>
        })}
      </IonList>
    }
  }

  const renderBankList = (savedBankList: SavedBankFields[]) => {
    if (showSpinner) {
      return (
       <Spinner />
      )
    }

    if (savedBankList?.length <= 0) {
      return <p className='pl-1 pr-1'>We could not find any banks for your account.</p>
    }

    if (savedBankList?.length > 0) {
      return <IonList class="w-100 mb-1">
        {savedBankList.map(bankItem => {
          let maskedCreditAccountNumber = bankItem.creditAccountNumber.slice(-4);
          maskedCreditAccountNumber = `XXXXXXXX${maskedCreditAccountNumber}`
          return <Fragment key={bankItem.bankId}>
            <IonItem>
              <p>
                <span data-test='account-info'>
                  <b>Name on Bank Account</b>: {bankItem.creditAccountName}<br />
                </span>
                <span>
                  <b>Bank Name</b>: {bankItem.bankName}<br />
                </span>
                <span data-test='asset-info'>
                  <b>Bank Routing Number</b>: {bankItem.routingNumber}<br />
                </span>
                <span data-test='asset-info'>
                  <b>Bank Account Number</b>: {maskedCreditAccountNumber}<br />
                </span>
                <span data-test='asset-info'>
                  <b>Bank Account Type</b>: {bankItem.creditAccountType}<br />
                </span>
                <IonRow class='pt-0-5'>
                  <PartyTypeConditionalAccess featureDescription={'createContribution'}>
                    <span data-test='asset-info'>
                      <IonButton color={getButtonColor()} onClick={() => goToContribution(bankItem)} size="small">Make Contribution</IonButton>
                    </span>
                  </PartyTypeConditionalAccess>
                  <IonButton size='small' color={getButtonColor()} onClick={() => {
                    setPayeeOrBank({savedType: 'bank', payeeOrBankId: bankItem.bankId});
                    setShowEditModal(true);
                  }}>Edit</IonButton>
                  <IonButton size='small' color={getButtonColor()} onClick={() => (setPayeeOrBank({savedType: 'bank', payeeOrBankId: bankItem.bankId}), setShowConfirmDeleteAlert(true))}>Delete</IonButton>
                </IonRow>
              </p>
            </IonItem>
          </Fragment>
        })}
      </IonList>
    }
  }
  
  return (
    <IonRow class="container">
      {showToast &&
        <IonToast isOpen={showToast} color={toastMessage?.color} position='top' message={toastMessage?.message} onDidDismiss={() => setShowToast(false)} buttons={[{icon: 'close-circle'}]} />
      }
      <IonAlert
        isOpen={showConfirmDeleteAlert}
        onDidDismiss={() => (setShowConfirmDeleteAlert(false), setPayeeOrBank({savedType: '', payeeOrBankId: ''}))}
        header={
          payeeOrBank.savedType === 'payee' ? 'Delete saved payee?'
          : payeeOrBank.savedType === 'bank' ? 'Delete saved bank?' : undefined
        }
        buttons={[
          {
            text: 'Delete',
            handler: () => deletePayee()
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setPayeeOrBank({savedType: '', payeeOrBankId: ''})
          }
        ]}
      />
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        <ContainerComponent content={
          <>
            <IonRow>
              <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                <IonToolbar class="nozindex">
                  <IonSegment data-test="segment" value={segmentType} onIonChange={updateSelectedSegment}>
                    <IonSegmentButton  value="edit payee">Saved Payees</IonSegmentButton>
                    <IonSegmentButton value="edit bank">Saved Banks</IonSegmentButton>
                  </IonSegment>
                </IonToolbar>
              </IonCol>
            </IonRow>
            {segmentType === 'edit payee' ? renderPayeeList(savedPayeeLists.savedPayeesResult) : renderBankList(savedPayeeLists.savedBanksResult)}
          </>
        } />
      </IonCol>
      <Sidebar />
      <EditBankPayeeModal setShowEditModal={setShowEditModal} showEditModal={showEditModal} bankOrPayee={payeeOrBank} savedPayee={
        payeeOrBank.savedType === 'payee' ? savedPayeeLists.savedPayeesResult.find(payee => payee.payeeId === payeeOrBank.payeeOrBankId)
        : payeeOrBank.savedType === 'bank' ? savedPayeeLists.savedBanksResult.find(bank => bank.bankId === payeeOrBank.payeeOrBankId) : null
      } setSavedPayeeLists={setSavedPayeeLists} savedPayeeLists={savedPayeeLists} setToastMessage={setToastMessage} setShowToast={setShowToast}/>
    </IonRow>
  );
};

export default SavedPayees;
