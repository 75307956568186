import React, {useState} from 'react'
import { useHistory } from 'react-router';
import {IonRow, IonCol, IonButton, IonModal, IonToast} from '@ionic/react';
import {PendingTrans} from './PendingTransaction'
import calloutHelpers from '../../helpers/calloutHelpers';
import Spinner from '../Spinner';
import PartyTypeConditionalAccess from '../PartyTypeConditionalAccess';

const CancelTransactionButton: React.FC<{transaction: Partial<PendingTrans>}> = ({transaction}) => {
    const transactionId = transaction.Id
    const [isCancellable, setIsCancellable] = useState(transaction.IsCancellable);
    const [errorToast, setErrorToast] = useState<Toast|undefined>() 
    const [showCancelPrompt, setShowCancelPrompt] = useState(false);
    const history = useHistory()

    const cancelTransactionCallout = async () => {
        try{
            transactionId && await calloutHelpers.cancelTransaction(transactionId)
            history.replace('/transactions', {
                Toast: {message:'Transaction canceled', color:'success'}
            })
        } catch(err) {
            setErrorToast({
                color: 'warning',
                message: 'Failed to cancel the transaction. This transaction likely cannot be canceled.'
            })
        }
        setShowCancelPrompt(false)
        setIsCancellable(false)
    }

    return  <PartyTypeConditionalAccess featureDescription='cancelTransaction'>
        <IonToast isOpen={(errorToast?.message !== undefined && errorToast?.color !== undefined)} color={errorToast?.color} position='top'  message={errorToast?.message} duration={3 * 1000} buttons={[{icon:'close-circle'}]} />
        <CancelModel isCancelPromptVisible={showCancelPrompt} hideCancelPrompt={()=>setShowCancelPrompt(false)} cancelTransactionCallout={cancelTransactionCallout}/>
        {isCancellable &&
          <IonRow>
            <IonCol>
              <IonButton onClick={()=>setShowCancelPrompt(true)}>Cancel Transaction</IonButton>
            </IonCol>
          </IonRow>
        }
    </PartyTypeConditionalAccess>
}

const CancelModel: React.FC<{isCancelPromptVisible: boolean, hideCancelPrompt: ()=>void, cancelTransactionCallout: ()=>void}> = ({isCancelPromptVisible, hideCancelPrompt, cancelTransactionCallout}) => {
        const [loading, setLoading] = useState<boolean>(false)
        const cancelTransaction = () => {
            setLoading(true)
            cancelTransactionCallout()
        }

        return <IonModal cssClass={'cancel-modal'} isOpen={isCancelPromptVisible} backdropDismiss={false} onDidDismiss={() => console.log('dismissing')} mode="ios">
            <CancelMessage loading={loading}/>
            <IonRow style={{container: 'flex', 'justifyContent': 'space-between', 'paddingLeft': '10px', 'paddingRight': '10px'}}>
                <IonButton disabled={loading} onClick={cancelTransaction}>Confirm</IonButton>
                <IonButton disabled={loading} onClick={hideCancelPrompt}>Close</IonButton>
            </IonRow>
        </IonModal>
}

const CancelMessage: React.FC<{loading: boolean}> = ({loading}) =>{
    return <>
    <IonRow style={{'justifyContent': 'center', 'paddingTop': '10px', 'fontWeight': 'bold'}}>Cancel Transaction</IonRow>
    { loading ? <Spinner/> : <CancelText/> }
    </>
}

const CancelText: React.FC = () => ( 
    <>
        <IonRow style={{'justifyContent': 'center'}}>
            Once canceled, this cannot be undone.
        </IonRow>
        <IonRow style={{'justifyContent': 'center'}}>
            Press confirm to cancel this transaction.
        </IonRow>
    </>
)


export default CancelTransactionButton