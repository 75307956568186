export const SFChat = (personDetails: PersonAccountHelpers.LoggedInDetails) =>{
    var initESW = async function(gslbBaseURL, embedded_svc?: any) {
      if(!embedded_svc)
      {
        return
      } 
      embedded_svc.settings.displayHelpButton = true; //Or false
      embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
      embedded_svc.settings.extraPrechatInfo = [{
      //CAN WE PREFILL THE BELOW INFORMATION?
      "entityName": "Contact",
      "linkToEntityName": "Contact",
      "linkToEntityField": "ContactId",
      "saveToTranscript": "ContactId",
      "showOnCreate": false,
      "entityFieldMaps": [{
      "isExactMatch": false,
      "fieldName": "FirstName",
      "doCreate": false,
      "doFind": false,
      "label": "First Name"
      },
      {
      "isExactMatch": false,
      "fieldName": "LastName",
      "doCreate": false,
      "doFind": false,
      "label": "Last Name"
      },{
      "isExactMatch": true,
      "fieldName": "Email",
      "doCreate": false,
      "doFind": true,
      "label": "Email"
      }]
      },
      {
      "entityName": "Account",
      "showOnCreate": false,
      "linkToEntityName": "Account",
      "linkToEntityField": "AccountId",
      "saveToTranscript": "AccountId",
      "entityFieldMaps": [
      {
      "isExactMatch": false,
      "fieldName": "FirstName",
      "doCreate": false,
      "doFind": false,
      "label": "First Name"
      },
      {
        "isExactMatch": false,
        "fieldName": "LastName",
        "doCreate": false,
        "doFind": false,
        "label": "Last Name"
      },
      {
        "isExactMatch": true,
        "fieldName": "PersonEmail",
        "doCreate": false,
        "doFind": true,
        "label": "Email"
      }]
      },
      {
        "entityName": "Case",
        "showOnCreate": true,
        "entityFieldMaps": [
      {
        "doCreate": false,
        "doFind": false,
        "fieldName": "Subject",
        "isExactMatch": false,
        "label": "Subject"
      }]
      }];
      
      embedded_svc.settings.prepopulatedPrechatFields = {
        "FirstName": personDetails?.myProfile.firstName,
        "LastName": personDetails?.myProfile.lastName,
        "Email": personDetails?.myProfile.email
      }
  
      if(embedded_svc?.settings?.enabledFeatures)
      {
        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      }
      if(embedded_svc?.settings?.entryFeature)
      {
        embedded_svc.settings.entryFeature = 'LiveAgent';
      }
      await embedded_svc.init(
        'https://entrust.my.salesforce.com',
        'https://entrust.my.site.com/CSchat',
        gslbBaseURL,
        '00D30000000Yy42',
        'Client_Services_Chat_Deployment',
        {
          baseLiveAgentContentURL: 'https://c.la2-c2-ia4.salesforceliveagent.com/content',
          deploymentId: '5724u000000blM8',
          buttonId: '5734u000000blNL',
          baseLiveAgentURL: 'https://d.la2-c2-ia4.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I4u0000004CBYEA2_1865ab99963',
          isOfflineSupportEnabled: false
        }
      );
    };
    if (!(window as any).embedded_svc) {
      var s = document.createElement('script');
      s.id = 'chat-script'
      s.setAttribute('src', 'https://entrust.my.salesforce.com/embeddedservice/5.0/esw.min.js');
      s.onload = function() {
        const inter = setInterval(()=>{
              if((window as any).embedded_svc)
              {
                initESW('https://service.force.com', (window as any).embedded_svc);
                clearInterval(inter)
              }
          }, 1000)
        };
      document.body.appendChild(s);
    }
    /**
     * This can cause the attached chat to fail
     * 
     *  else {
      initESW('https://service.force.com', (window as any).embedded_svc);
    }
     */
  }