import React from 'react';
import { IonButton, IonRow, IonCol, IonItem, IonPage, IonContent } from '@ionic/react';
import LoginHeader from '../../components/LoginHeader';
import Footer from '../../components/Footer';
export interface AuthCheckMessageComponentProps {
  title: string;
  content: string;
  buttonText?: string;
  buttonOnClickEvent?: ()=>void;
}

const AuthCheckMessageComponent: React.FC<AuthCheckMessageComponentProps> = ({ title, content, buttonText, buttonOnClickEvent }) => {
  return (
    <IonPage>
      <LoginHeader />
      <IonContent>
        <IonRow class=" main-page mt-5 mb-5">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="3"></IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow class="gr-border">
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pl-2 pr-2 white-bg">
                    <IonRow>
                      <IonCol size="12">
                        {(title) && <h1 className="ion-text-left">{title}</h1>}
                          <p className="mt-0 w-100">{content}</p>
                        {(buttonText) && <IonItem lines="none" class="mt-1"><IonButton size="default" onClick={buttonOnClickEvent}>{buttonText}</IonButton></IonItem>}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="3"></IonCol>
        </IonRow>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default AuthCheckMessageComponent;