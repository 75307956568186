import React, { useState } from 'react';
import {
    IonRow,
    IonCol,
    IonItem,
    IonToast,
    IonButton,
    IonAlert
} from '@ionic/react';
import calloutHelpers from '../helpers/calloutHelpers';
import { formatDateString } from 'shared-utils';
import ToolTipComponent from './TooltipComponent/TooltipComponent';
import helpCircleOutline from '../images/help-circle-outline.svg';
import { formatValueAsCurrency } from '../helpers/Utils';
import Spinner from './Spinner';

interface RecurringTrans {
    Id?: string,
    Account?: {Name?: string, ClientFirstName?: string, ClientLastName?: string},
    Cusip?: {Name?: string, AssetName1?: string},
    AmountCash: number,
    AmountCashTotal?: number,
    AmountPortal?: string,
    CO?: string,
    CreditAccount?: string,
    CreditName?: string,
    CheckMemo?: string,
    DeliveryMethod?: string,
    EndDate?: string,
    FfcAccount?: string,
    FfcDetail?: string,
    Frequency?: string,
    FrequencyPretty?: string,
    Name?: string,
    MailingCity?: string,
    MailingStreet?: string,
    MailingState?: string,
    MailingZip?: string,
    NextRunDate?: string,
    ProcessDay?: string,
    ReceivingABA?: string,
    ReceivingBank?: string,
    Status?: string,
    ToFrom?: string,
    TransactionType?: string
}

const RecurringTransaction: React.FC<{ recurringTransaction: RecurringTrans, toast?: {message: string, color: 'success' | 'warning' | 'danger'} }> = ({ recurringTransaction, toast}) => {
    const [ editedRecurringTransactionFields, setEditedRecurringTransactionFields ] = 
        useState<{status?: string, endDate?: string, nextRunDate?: string}>({
            status: recurringTransaction.Status,
            endDate: recurringTransaction.EndDate,
            nextRunDate: recurringTransaction.NextRunDate
        });
    const [ confirmingStatus, setConfirmingStatus ] = useState('');
    const [ showConfirmStatusAlert, setShowConfirmStatusAlert ] = useState(false);
    const [ statusToastMessage, setStatusToastMessage ] = useState('');
    const [ showSpinner, setShowSpinner ] = useState(false)

    const confirmStatusChange = async (newStatus: string) => {   
        try {
            if (recurringTransaction?.Id) {
                setShowSpinner(true);
                const updatedRecurringTransaction = await calloutHelpers.updateRecurringTransactionStatus(recurringTransaction?.Id, newStatus);
                const updatedStatusRecurring = updatedRecurringTransaction.data;
                setStatusToastMessage('Status updated');
                setEditedRecurringTransactionFields({
                    status: updatedStatusRecurring.Status__c,
                    endDate: updatedStatusRecurring.End_Date__c,
                    nextRunDate: updatedStatusRecurring.Next_Run_Date__c
                });
                setShowSpinner(false);
            };
        } catch (err) {
            setStatusToastMessage('Error updating status');
            setShowSpinner(false);
        };
    };

    const renderButtonWithTextHelp = (newStatus: string) => {
        let message: string = '';
        let title: string = '';
        
        if (newStatus === 'Suspended') {
            message = 'This will change the recurring transaction status from "Active" to "Suspended". This means the transaction will not generate until you log back in and resume the recurring transaction.';
            title = 'Temporarily Suspend';
        } else if (newStatus === 'Ended') {
            message = 'This will change the recurring transaction from "Active" (or "Suspended") to "Ended". This means the transaction will no longer generate and is permanently cancelled.';
            title = 'Permanently Stop';
        } else if (newStatus === 'Active') {
            message = 'This will change the recurring transaction from "Suspended" to "Active". This means the transaction will resume until you log back in and suspend the recurring transaction.';
            title = 'Resume';
        };
        
        return <IonButton onClick={() => (setConfirmingStatus(newStatus), setShowConfirmStatusAlert(true))}><ToolTipComponent title={title} icon={helpCircleOutline} message={message} /></IonButton>

        
    }

    return (
        <>
            {showSpinner 
                ? <Spinner />
                : <>
                    <IonToast isOpen={(toast?.message !== undefined && toast?.color !== undefined)} color={toast?.color} position='top' message={toast?.message} duration={3 * 1000} buttons={[{icon: 'close-circle'}]}/>
                    
                    {statusToastMessage &&
                        <IonToast isOpen={(statusToastMessage !== undefined)} color={statusToastMessage === 'Status updated' ? 'success' : 'danger'} position='top' message={statusToastMessage} duration={3 * 1000} buttons={[{icon: 'close-circle'}]} />
                    }

                    <IonAlert 
                        isOpen={showConfirmStatusAlert}
                        onDidDismiss={() => (setShowConfirmStatusAlert(false), setConfirmingStatus(''))}
                        header={
                            confirmingStatus === 'Suspended' ? 'Temporarily suspend recurring transaction?'
                            : confirmingStatus === 'Ended' ? 'Permanently stop recurring transaction?'
                            : confirmingStatus === 'Active' ? 'Resume recurring transaction?' : undefined
                        }
                        buttons={[
                            {
                                text: 'Confirm',
                                handler: () => confirmStatusChange(confirmingStatus)
                            },
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                handler: () => setConfirmingStatus('')
                            }
                        ]}
                    />

                    <IonItem color='tertiary'>
                        Recurring Transaction Details
                    </IonItem>
                    <IonRow class='p-1'>
                        <IonCol>
                            <p className='p-0 m-0'><b>Status</b>: {editedRecurringTransactionFields.status}</p>
                            <p className='p-0 m-0'><b>Account</b>: {recurringTransaction?.Account?.Name} - {recurringTransaction?.Account?.ClientFirstName} {recurringTransaction?.Account?.ClientLastName}</p>
                            <p className='p-0 m-0'><b>Transaction Type</b>: {recurringTransaction?.TransactionType}</p>
                            
                            {!recurringTransaction?.DeliveryMethod?.includes('Check') &&
                                <p className='p-0 m-0'><b>{recurringTransaction?.TransactionType?.includes('Distribution') ? 'From' : 'To'}</b>: {recurringTransaction?.ToFrom}</p>
                            }
                            
                            <p className='p-0 m-0'><b>Amount</b>: {!recurringTransaction?.TransactionType?.includes('Distribution')
                                    ? formatValueAsCurrency(recurringTransaction?.AmountCash)
                                    : recurringTransaction?.AmountPortal
                                }
                            </p>
                            
                            {recurringTransaction?.Cusip &&
                                <p className='p-0 m-0'><b>Related Asset</b>: {recurringTransaction?.Cusip?.Name} - {recurringTransaction?.Cusip?.AssetName1}</p>
                            }
                            <IonRow className='p-1'>
                                {editedRecurringTransactionFields?.status === 'Active' &&
                                    <>
                                        {renderButtonWithTextHelp('Suspended')}
                                        {renderButtonWithTextHelp('Ended')}
                                    </>
                                }
                                {editedRecurringTransactionFields?.status === 'Suspended' &&
                                    <>
                                        {renderButtonWithTextHelp('Active')}
                                        {renderButtonWithTextHelp('Ended')}
                                    </>
                                }
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    <IonItem color='tertiary'>
                        Schedule Details
                    </IonItem>
                    <IonRow class='p-1'>
                        <IonCol>
                            <p className='p-0 m-0'><b>Frequency</b>: {recurringTransaction?.FrequencyPretty}</p>

                            {editedRecurringTransactionFields.nextRunDate &&
                                <p className='p-0 m-0'><b>Next Process Date</b>: {formatDateString(editedRecurringTransactionFields?.nextRunDate)}</p>
                            }
                            
                            {recurringTransaction?.EndDate &&
                                <p className='p-0 m-0'><b>End Date</b>: {formatDateString(editedRecurringTransactionFields?.endDate)}</p>
                            }
                        </IonCol>
                    </IonRow>

                    <IonItem color='tertiary'>
                        Delivery Instructions
                    </IonItem>
                    <IonRow class='p-1'>
                            <IonCol>
                                <p className='p-0 m-0'><b>Delivery Method</b>: {recurringTransaction?.DeliveryMethod}</p>
                                {recurringTransaction?.CreditAccount &&
                                <>
                                    <p className='p-0 m-0'><b>Name on Bank Account</b>: {recurringTransaction?.CreditName}</p>
                                    <p className='p-0 m-0'><b>Bank Account Number</b>: {recurringTransaction?.CreditAccount}</p>
                                </>
                                }

                                {recurringTransaction?.ReceivingBank &&
                                    <>
                                        <p className='p-0 m-0'><b>Receiving Bank</b>: {recurringTransaction?.ReceivingBank}</p>
                                        <p className='p-0 m-0'><b>Receiving ABA</b>: {recurringTransaction?.ReceivingABA}</p>
                                    </>
                                }

                                {recurringTransaction?.DeliveryMethod?.includes('Check') &&
                                    <>
                                        <p className='p-0 m-0'><b>Payable To</b>: {recurringTransaction?.ToFrom}</p>
                                        {!recurringTransaction?.DeliveryMethod?.includes('Hold') &&
                                            <>    
                                                <p className='p-0 m-0'><b>CO</b>: {recurringTransaction?.CO}</p>
                                                <p className='p-0 m-0'><b>Mailing Street</b>: {recurringTransaction?.MailingStreet}</p>
                                                <p className='p-0 m-0'><b>Mailing City</b>: {recurringTransaction?.MailingCity}, {recurringTransaction?.MailingState}</p>
                                                <p className='p-0 m-0'><b>Mailing Zip</b>: {recurringTransaction?.MailingZip}</p>
                                                {recurringTransaction?.CheckMemo &&
                                                    <p className='p-0 m-0'><b>Check Memo</b>: {recurringTransaction?.CheckMemo}</p>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </IonCol>
                    </IonRow>
                </>
            }
        </>
    )
};

export default RecurringTransaction;