import { UseIonToastResult } from '@ionic/react'
import React, { Reducer } from 'react'

type MyProfileReducerActions = {
    type: 'submit'
  } | {
    type: 'start-edit'
  } | {
    type: 'cancel-edit'
  } | {
    type: 'form-change',
    hasChange: Boolean
  } | {
    type: 'set-person-details'
    personDetails: PersonAccountHelpers.PersonDetails
  } | {
    type: 'submit-success', personDetails: PersonAccountHelpers.PersonDetails
  } | {
    type: 'submit-error', errorMessage?: string
  }

export type MyProfileReducerModel = {
    personDetails: PersonAccountHelpers.PersonDetails | undefined,
    isEditing: Boolean,
    isLoading: Boolean,
    toast: UseIonToastResult,
    formRef: React.MutableRefObject<HTMLFormElement>
  }
  
type MyProfileUpdateToast = {
  message: string,
  type: 'error'|'success'
}

export type MyProfileReducer = Reducer<MyProfileReducerModel, MyProfileReducerActions>

const myProfileReducer: MyProfileReducer = (prevState, action) => {
    const presentToast = (options: MyProfileUpdateToast)=>prevState.toast[0](
      {
        message: options.message,
        color: options.type === 'success' ? 'success' : 'danger',
        position: 'top',
        duration: options.type === 'success' ? 3 * 1000 : 15 * 1000,
        buttons: [{ icon: 'close-circle' }],
      }
    )
    const presentSuccess = () =>{
      presentToast({
        message: 'Successfully updates profile.',
        type: 'success' 
      })
    }
    const dismissToast = ()=>prevState.toast[1]()

    switch(action.type){
      case 'set-person-details': {
        return {...prevState, personDetails: action.personDetails, isLoading: false}
      }

      case 'submit' : {
        dismissToast()
        return {...prevState, isLoading: true}
      }

      case 'submit-success' : {
        presentSuccess()
        return {...prevState, isLoading: false, isEditing: false,
            personDetails: action.personDetails,
        }
      }

      case 'submit-error' : {
        const default_message = 'There was an issue submitting your profile updates, please try again later.'
        const sanitizeMessage = action.errorMessage?.replace(/[^\s.\w]/g,'')
        const message = sanitizeMessage || default_message 
        presentToast({
            type: 'error',
            message,
          })
          return { ...prevState, isLoading: false, isEditing: false}
      }

      case 'cancel-edit' : {
        return {...prevState, isEditing: false}
      }

      case 'start-edit' : {
        return { ...prevState, 
          isEditing: true
        }
      }

      default:
        return prevState
    }
  }

  export default myProfileReducer