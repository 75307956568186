import React from 'react';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonItem,
} from '@ionic/react';
import StateSelector from '../../components/StateSelector';
import { Controller, useFormContext } from 'react-hook-form';
import { POBoxRegex } from 'shared-utils';

export type CheckFormFields = {
  mailingStreet: string,
  mailingCity: string,
  mailingState: string,
  mailingZip: string,
  CO: string,
  payableTo?: string,
  memo?: string
}

export const defaultValues: CheckFormFields ={
  mailingStreet: '',
  mailingCity: '',
  mailingState: '',
  mailingZip: '',
  CO: '',
  payableTo: '',
}

const CheckFields: React.FC<{ deliveryMethod: string, disableFields?: boolean, isFundManagerFull?: Boolean }> = ({ deliveryMethod, disableFields, isFundManagerFull }) => {
  const {formState: { errors }, control} = useFormContext<CheckFormFields>()
  return (
    <>
      <IonRow class="m-0 p-0">
            {isFundManagerFull &&
              <>
                <IonRow class='mt-2 mb-1'>
                  <IonLabel>Payable To</IonLabel>
                </IonRow>
                <IonRow class='w-100'>
                  <Controller name='payableTo' control={control} render={({ field: { value, onBlur, onChange, name } }) =>
                    <IonInput class='gr-border' name={name} value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={26}/>
                  }
                  rules ={{
                    required: 'Please add the name of who you are paying.'
                  }} 
                />
                </IonRow>
                {errors['payableTo'] && <IonItem class='mt-1 w-100' color='danger'><p className='white-color'>{errors['payableTo']?.message}</p></IonItem>}
              </>
            }
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing Street</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
              <Controller name='mailingStreet' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={33} onIonChange={onChange} onIonBlur={onBlur} value={value} readonly={disableFields}/>
              } rules={{
                  required: true,
                  validate: (value) => {
                    if (deliveryMethod === 'Check FedEx Overnight Delivery ($30 Fee)') {
                      return POBoxRegex.test(value);
                    }
                  },
                }} />
              </IonCol>
            </IonRow>
             {errors.mailingStreet &&
              <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing street {deliveryMethod ==='Check FedEx Overnight Delivery ($30 Fee)' && ('(PO Boxes not available with the selected delivery method)')}. </p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing City</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='mailingCity' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={30} onIonChange={onChange} onIonBlur={onBlur} value={value} readonly={disableFields}/>
                } rules={{ required: true }} />
              </IonCol>
            </IonRow>
              {errors.mailingCity && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing city.</p></IonItem>}
          </IonCol>
        </IonRow>
        <IonRow class="m-0 p-0">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing State</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0 ">
                <StateSelector name='mailingState' disableField={disableFields} />
              </IonCol>
            </IonRow>
            {errors.mailingState && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing state.</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing Zip</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='mailingZip' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text"  onIonChange={onChange} onIonBlur={onBlur} value={value} maxlength={5} readonly={disableFields}/>
                } rules={{ required: 'Please enter mailing zip.' }} />
              </IonCol>
            </IonRow>
            {errors.mailingZip?.message && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['mailingZip']?.message}</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <IonRow class="mt-2 mb-1">
              <IonLabel>C/O</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='CO' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={50} onIonChange={onChange} onIonBlur={onBlur} value={value} readonly={disableFields}/>
                } rules={{ required: false }} />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        {isFundManagerFull &&
          <>
            <IonRow class='mt-2 mb-1'>
              <IonLabel>Memo</IonLabel>
            </IonRow>
            <IonRow class='m-0 p-0'>
              <Controller name="memo" control={control} render={({ field: { value, name, onBlur, onChange } }) => 
                <IonInput class='ion-text-left mt-0 gr-border pl-1 pr-1' type='text' name={name} maxlength={30} onIonBlur={onBlur} onIonChange={onChange} value={value} />
              } rules = {{
                required: true,
                validate: (value) => { return value !== null }
              }} />
            </IonRow>
          </>
        }
    </>
  );
};

export default CheckFields;
