import React from 'react';
import {
    IonRow,
    IonCol
  } from '@ionic/react';
import { useHistory } from 'react-router';
import HoldingDetails from './HoldingDetails';
import ContainerComponent from '../ContainerComponent';
import Sidebar from '../Sidebar';

//NOTE: This component only really exists to help with testing. There didn't seem to be a good way to mock useHistory.
//This also pulls the ContainerComponent out so shallow rendering could be used to test HoldingDetails. Past those two
//points, we should be able to combine this component with HoldingDetails.

const HoldingContainer: React.FC = () => {
    const history = useHistory()
    const holding = history.location.state as Holding

    return (
        <>
            <IonRow class="container">
                <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <ContainerComponent title = 'Holding' content = {<HoldingDetails holding ={holding}/>}/>
                </IonCol>
                <Sidebar />
            </IonRow>
        </>
    )
}

export default HoldingContainer;