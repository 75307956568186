import React, { useCallback, useEffect, useState } from "react"
import calloutHelpers from "../helpers/calloutHelpers";
import { store } from "../reducers/store";
import { getLogo } from "../helpers/Utils";

const ReduxInitializer: React.FC = (props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const loadData = useCallback(async () => {
        setLoading(true)

        const accessDefinitionsResult = await calloutHelpers.getAccessDefinitions()
        if (accessDefinitionsResult?.data) {
            store.dispatch({
                type: 'setAccessDefinitions',
                payload: {
                    accessDefinitions: accessDefinitionsResult.data
                }
            })
        }

        const availableAccounts = await calloutHelpers.getAvailableAccounts()
        if (availableAccounts.data) {
            store.dispatch({
                type: 'setAccounts',
                payload: {
                    accounts: availableAccounts.data
                }
            })

            if (availableAccounts.data.some(account => account.accountType.toLowerCase().includes('document custody'))) {
                store.dispatch({
                    type: 'setViewMode',
                    payload: {
                        viewMode: 'DOC_CUSTODY'
                    }
                })
            }

            let selectedAccount = store.getState().UserState.selectedAccount
            if (!selectedAccount || !availableAccounts.data.includes(selectedAccount)) {
                store.dispatch({
                    type: 'setSelectedAccount',
                    payload: {
                        selectedAccount: availableAccounts.data[0]
                    }
                })
            }
        }

        setLoading(false)

    }, [])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <>
            {loading ?
                <>
                    <div data-test='loading-animation' className='auth-container'>
                        <img className='auth-center' src={getLogo()} width="100%" alt="Equity Trust logo" />
                        <div className='auth-animation' />
                    </div>
                </> :
                <>
                    {props.children}
                </>
            }
        </>
    )
}

export default ReduxInitializer