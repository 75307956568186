import React from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonRouterLink,
  IonButton,
} from '@ionic/react';
import ToggleComponent from './ToggleComponent';
import caseCalloutHelper from '../helpers/calloutHelpers/caseCalloutHelper';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import DataLoader from './DataLoader';
import { useHistory } from 'react-router';

const CasesList: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {

  let closedStatusList: Array<string> = ['Closed', 'Cancelled'];

  let history = useHistory();
  
  const goToCaseDetails = (caseItem: Case) => {
    history.push({
        pathname: '/case',
        state: caseItem
    })
  }

  const filterOpenCases = (caseItems: Array<Case>) => {
    const filteredOpenCases = caseItems?.filter(openCases => {
      return (openCases.AvailableInPortal && !closedStatusList.includes(openCases.Status));
    })
    if (filteredOpenCases && filteredOpenCases.length > 0) {
      return filteredOpenCases.map(caseItem => {
        return <IonItem key={caseItem.Id}>
          <p><b>Case #</b>: {caseItem.CaseNumber}<br /><b>Case Reason</b>: {caseItem.Reason}<br /><b>Subject</b>: {caseItem.Subject}<br /><b>Status</b>: {caseItem.Status}<br /><IonButton onClick={() => goToCaseDetails(caseItem)} size="small">More Details</IonButton></p>
        </IonItem>
      })
    } return <p>There are no open cases for this account</p>
  }

  const filterClosedCases = (caseItems: Array<Case>) => {
    const filteredClosedCases = caseItems?.filter(openCases => {
      return (openCases.AvailableInPortal && closedStatusList.includes(openCases.Status));
    })

    if (filteredClosedCases && filteredClosedCases.length > 0) {
      return filteredClosedCases.map(caseItem => {
        return <IonItem key={caseItem.Id}>
          <p><b>Case #</b>: {caseItem.CaseNumber}<br /><b>Case Reason</b>: {caseItem.Reason}<br /><b>Subject</b>: {caseItem.Subject}<br /><b>Status</b>: {caseItem.Status}<br /><IonButton onClick={() => goToCaseDetails(caseItem)} size="small">More Details</IonButton></p>
      </IonItem>
      })
    } return <p>There are no closed cases for this account</p>
  }
  
  const renderCases = (caseItems: Array<Case>) => {
    return <>
      <ToggleComponent isActive={true} title="Open Cases" bodyData={filterOpenCases(caseItems)} />
      <ToggleComponent isActive={false} title="Closed Cases" bodyData={filterClosedCases(caseItems)} />
    </>
  }
  
  return (
    <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        <DataLoader apiCall={() => caseCalloutHelper.getCases(selectedAccount?.id)} render={renderCases} dependencies={[selectedAccount]} />
      </IonCol>
      <Sidebar />
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
  }
}

export default connect(mapStateToProps)(CasesList);
