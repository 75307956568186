import React, { useState } from 'react';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonItem
} from '@ionic/react';
import StateSelector from '../../components/StateSelector';
import { Controller, useFormContext } from 'react-hook-form';
import calloutHelper from '../../helpers/calloutHelpers';
import { POBoxRegex } from 'shared-utils'

export type DomestWireFormFields = {
  mailingStreet: string,
  mailingCity: string,
  mailingState: string,
  mailingZip: string,
  routingNumber: string,
  bankName: string,
  creditAccountNumber: string,
  creditAccountName: string
}

export const defaultValues:DomestWireFormFields = {
  mailingStreet: '',
  mailingCity: '',
  mailingState: '',
  mailingZip: '',
  routingNumber: '',
  bankName: '',
  creditAccountNumber: '',
  creditAccountName: ''
}


const DomesticWireFields: React.FC<{control: any, setValue: Function, errors: any}> = () => {
  const [routingNumberErrorState, setRoutingNumberErrorState] = useState<'none' | 'invalidDeliveryMethod' | 'error'>('none');
  const { control, setValue, formState: { errors } } = useFormContext<DomestWireFormFields>()

  const validateRoutingNumber = (event: any) => {
    let routingNumber = event.target.value; 
    setValue('bankName', '')
    if (routingNumber && routingNumber.length === 9) {
      calloutHelper.checkRoutingNumber(routingNumber, "Wire").then(result => {
        if (result.data.status === 'Valid') {
          setValue('bankName', result.data.bankName);
          setRoutingNumberErrorState('none')
        } 
        else if(result.data.status === 'Invalid') {
          setValue('routingNumber', '');
          setValue('bankName', '');
          setRoutingNumberErrorState('invalidDeliveryMethod')
        }
        else {
          setValue('routingNumber', '');
          setValue('bankName', '');
          setRoutingNumberErrorState('error')
        }
      }).catch(err => {
        setValue('routingNumber', '');
        setValue('bankName', '');
        setRoutingNumberErrorState('error')
      })
    }
  }

  const getRoutingNumberErrorMessage = () => {
    let errorMessage = 'Please provide a routing number.'
    switch(routingNumberErrorState) {
      case 'error':
        errorMessage = 'Failed to lookup routing number.'
        break
      case 'invalidDeliveryMethod':
        errorMessage = 'The provided routing number cannot be used for wire transfers.'
        break
    }
    return errorMessage
  }

  return (
    <>
      <IonRow class="m-0 p-0">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing Street</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='mailingStreet' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={33} onIonChange={onChange} onIonBlur={onBlur} value={value} />
                } rules={{
                  required: true,
                  pattern: POBoxRegex
                }} />
              </IonCol>
            </IonRow>
              {errors.mailingStreet && <IonItem class="mt-1" color="danger"><p className="white-color">PO Boxes not available with the selected delivery method.</p></IonItem>}
          </IonCol>
      </IonRow>
      <IonRow class="m-0 p-0">
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
          <IonRow class="mt-2 mb-1">
            <IonLabel>Mailing City</IonLabel>
          </IonRow>
          <IonRow class="m-0 p-0">
            <IonCol class="m-0 p-0">
              <Controller name='mailingCity' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" maxlength={26} onIonChange={onChange} onIonBlur={onBlur} value={value} />
              } rules={{ required: true }} />
            </IonCol>
          </IonRow>
          {errors.mailingCity && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing city.</p></IonItem>}
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
          <IonRow class="mt-2 mb-1">
            <IonLabel>Mailing State</IonLabel>
          </IonRow>
          <IonRow class="m-0 p-0">
            <IonCol class="m-0 p-0 ">
             <StateSelector name='mailingState'/>
            </IonCol>
          </IonRow>
          {errors.mailingState && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing state.</p></IonItem>}
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Mailing Zip</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='mailingZip' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={onChange} onIonBlur={onBlur} value={value} maxlength={5} />
                } rules={{ required: true }} />
              </IonCol>
            </IonRow>
              {errors.mailingZip && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter mailing zip.</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Bank Routing Number</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0">
                <Controller name='routingNumber' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={(e) => {
                    onChange(e);
                    validateRoutingNumber(e);
                  }} onIonBlur={onBlur} value={value} maxlength={9} />
                } rules={{ required: true }} />
              </IonCol>
            </IonRow>
              {errors.routingNumber && <IonItem class="mt-1" color="danger"><p className="white-color">{getRoutingNumberErrorMessage()}</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Bank Name</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-1 lt-gr-bg">
                <Controller name='bankName' control={control} render={({ field: { value, name }}) =>
                  <IonInput className="ion-text-left gr-border" type="text" name={name} id="bank_name" maxlength={100} disabled value={value} />
                } rules={{required: true}}/>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Bank Account Number</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0 ">
                <Controller name='creditAccountNumber' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="number" onIonChange={onChange} onIonBlur={onBlur} value={value} />
                } rules={{ required: {
                    message: 'Please enter bank account number',
                    value: true },
                  maxLength: {
                    message: 'Bank account number cannot exceed 17 characters',
                    value: 17
                  }
                }} />
              </IonCol>
            </IonRow>
              {errors['creditAccountNumber'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['creditAccountNumber']?.message}</p></IonItem>}
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow class="mt-2 mb-1">
              <IonLabel>Name on Bank Account</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <IonCol class="m-0 p-0 ">
                <Controller name='creditAccountName' control={control} render={({ field: { value, name, onBlur, onChange } }) =>
                  <IonInput class="ion-text-left gr-border pl-1 pr-1" name={name} type="text" onIonChange={onChange} onIonBlur={onBlur} value={value} />
                } rules={{ required: {
                    message: 'Please enter name on bank account',
                    value: true },
                  maxLength: {
                    message: 'Name on bank account cannot exceed 18 characters',
                    value: 18
                  }
                }} />
              </IonCol>
            </IonRow>
              {errors['creditAccountName'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['creditAccountName']?.message}</p></IonItem>}
          </IonCol>
        </IonRow>
    </>
  );
};

export default DomesticWireFields;
