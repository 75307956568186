import React, { Fragment, useState, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonModal,
  IonContent,
  IonRouterLink,
  IonButton,
  IonIcon,
} from '@ionic/react';
import ToggleComponent from '../components/ToggleComponent';
import TooltipComponent from './TooltipComponent/TooltipComponent';
import Sidebar from '../components/Sidebar';
import TransactionsListLoader from './TransactionsListLoader';
import { formatNumber, throwError } from 'shared-utils';
import { Transaction } from './TransactionItem';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import helpCircleOutline from '../images/help-circle-outline.svg';
import calloutHelper from '../helpers/calloutHelpers';

const showPendingTransactionMessage = (transactionInfoType: string) => {
  if (transactionInfoType.toLowerCase().includes('transfer in (in cash)')) {
    return <>
      <h3 className="pl-3 pr-3">Transfer In (In Cash)</h3>
      <p className="text-left pl-3 pr-3">A cash transfer is a movement of funds between two IRA accounts.  This process is initiated through the receiving custodian and typically takes between 5-10 business days.</p>
    </>
  } else if (transactionInfoType.toLowerCase().includes('transfer in (in kind)')) {
    return <>
      <h3 className="pl-3 pr-3">Transfer In (In Kind)</h3>
      <p className="text-left pl-3 pr-3">An "In-Kind" transfer is a movement of any NON-CASH asset between two IRA accounts.  Because asset re-registration is involved, an "in-kind" transfer can take weeks rather than days.</p>
    </>
  } else if (transactionInfoType.toLowerCase().includes('contribution')) {
    return <>
      <h3 className="pl-3 pr-3">Contribution</h3>
      <p className="text-left pl-3 pr-3">A contribution is non-qualified (personal) cash that an individual is adding to their account.  Please note that eligibility requirements (i.e. earned income) and annual contribution limits (based on the account type) do apply.</p>
    </>
  } else if (transactionInfoType.toLowerCase().includes('rollover in (cash)')) {
    return <>
      <h3 className="pl-3 pr-3">Rollover</h3>
      <p className="text-left pl-3 pr-3">A rollover is a movement of funds or assets from a qualified plan (i.e. 401k, 403b, 457), to an IRA.  In order to roll funds from a qualified plan to an IRA, you must have a triggering event.  The most common triggering events are termination of employment or termination of the plan.  Rollovers are initiated through your current administrator.  To avoid required federal withholding, it is best to complete your administrator's "Direct Rollover" form where you will instruct them to send the funds directly to Equity Trust Company.</p>
    </>
  }
}

export const TransactionAlerts:React.FC<{
  alertItems: Transaction[], goToTransactionDetail: Function, showTransactionInfo: boolean, setShowTransactionInfo: Function, setTransactionInfoType: Function, transactionInfoType: string
}> = ({
  alertItems, goToTransactionDetail, showTransactionInfo, setShowTransactionInfo, setTransactionInfoType, transactionInfoType
}) => {

  return (
    <>
      {alertItems && alertItems.length > 0 &&
        <ToggleComponent isActive title="What's Happening Now" bodyData={alertItems.map((trans: Transaction, index) => {
          return <Fragment key={trans.Id}>
            <IonItem class="mt-1 mb-1" color="danger">
              <p data-test='transaction-approval' className="white-color">
                {trans.TransactionType} needs your approval. &nbsp;
                <IonRouterLink class="white-color underline cursor-pointer" onClick={() => goToTransactionDetail(trans.Id)}>Click here to review</IonRouterLink>.
              </p>
            </IonItem>
            {/* We may want to add these back for transactions that dont need approval */}
            {/* {(trans.Status !== 'Complete' && trans.Status !== 'Cancelled') ? <IonItem class="mt-1" color="danger">
              <p className="white-color">Your {trans.TransactionType}{(trans.TransactionType?.toLowerCase().includes('transfer in')) || (trans.TransactionType?.toLowerCase().includes('contribution')) || (trans.TransactionType?.toLowerCase().includes('rollover in (cash)')) ? <IonIcon onClick={() => (setTransactionInfoType(trans.TransactionType || ''), setShowTransactionInfo(true))} icon={helpCircleOutline} class="tooltip-danger cursor-pointer"></IonIcon> : ''} ({trans.Name}) to {trans.ToFrom} is currently in {trans.Status} status. The last update was {trans.CurrentNote}. <IonRouterLink class="white-color cursor-pointer" onClick={() => goToTransactionDetail(trans.Id)}><u>Details</u></IonRouterLink></p>
            </IonItem> : ''} */}
            <IonModal cssClass="transaction-info-modal" isOpen={showTransactionInfo} onDidDismiss={() => setShowTransactionInfo(false)} mode="ios">
              <IonContent class="tos">
                {showPendingTransactionMessage(transactionInfoType)}
              </IonContent>
              <IonButton onClick={() => setShowTransactionInfo(false)}>Close</IonButton>
            </IonModal>
          </Fragment>
        })} />
      }
    </>
  )
}

const homeAlerts = (showHomeAlerts: boolean, homeAlertsData: any[]) => {
  return showHomeAlerts && <ToggleComponent isActive title="Alerts" bodyData={
    homeAlertsData.map(alert => {
      const splitHomeAlertBody = alert.body.split('\n');
      
      let alertBody = splitHomeAlertBody.map((alertBody: string, index: number) => {
        return <Fragment key={index}>{alertBody}<br/></Fragment>;
      })

      return <IonItem key={alert.id} class="mt-1 mb-1" color="danger">
        <p className="white-color">
          <strong>{alert.title}</strong><br />
          {alertBody}
        </p>
      </IonItem>
    })
  } />
}

const Home: React.FC<{ availableAccounts: Array<RetirementAccount>, setSelectedAccount: Function}> = ({ availableAccounts, setSelectedAccount }) => {
  const history = useHistory();
  const [pendingApprovalTransactions, setPendingApprovalTransactions] = useState<Transaction[]>([]);
  const [showTransactionInfo, setShowTransactionInfo] = useState(false);
  const [transactionInfoType, setTransactionInfoType] = useState<string>('');
  const [showHomeAlerts, setShowHomeAlerts] = useState<boolean>(false);
  const [homeAlertsData, setHomeAlertsData] = useState<any[]>([]);

  const goToTransactionDetail = (transId: string) => {
    history.push('/transaction', {
      PendingOrPosted: 'pending',
      TransactionId: transId
    });
  }

  const goToAccountDetails = (account: any) => {
    const chosenAccountId = account.id;
    let selectedAccount: RetirementAccount = availableAccounts.filter(account => (account.id === chosenAccountId))[0];
    setSelectedAccount(selectedAccount);
    history.push({ pathname: '/account-details' });
  }

  useEffect(() => {
    const getHomeAlertOptions = async () => {
      try {
        const homeAlertResult = await calloutHelper.getHomeAlerts();
        if (homeAlertResult.data.length > 0) {
          setHomeAlertsData(homeAlertResult.data);
          setShowHomeAlerts(true);
        } else {
          setShowHomeAlerts(false);
        }
      } catch (err) {
        throwError(err)
      }
    };
    getHomeAlertOptions();
  }, []);



  return (
    <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        {homeAlerts(showHomeAlerts, homeAlertsData)}
        <TransactionAlerts alertItems={pendingApprovalTransactions} goToTransactionDetail={goToTransactionDetail} showTransactionInfo={showTransactionInfo} setShowTransactionInfo={setShowTransactionInfo} setTransactionInfoType={setTransactionInfoType} transactionInfoType={transactionInfoType} />
        <ToggleComponent isActive={true} title="Accounts" bodyData={
          <IonList>
            {availableAccounts && availableAccounts.map((account)=>
              <Fragment key={account.id}>
                <IonItem>
                  <p>
                    <b>Account</b>: {`${account.accountNumber} - ${account.firstName} ${account.lastName}`}<br />
                    <b>Account Type</b>: {account.accountType}<br />
                    <b>Party Type</b>: {account.partyType}<br />
                    <TooltipComponent title={'Available Cash '} icon={helpCircleOutline} body={': $' + formatNumber((+account.undirectedCashBalance + +account.pendingCashCredits - +account.holdingsOnDeposits - +account.minimumCashBalance - +account.pendingCashDebits - +account.pendingFees)?.toFixed(2))} message={'This value does not include any pending deposits, fees due from the account, or the minimum cash balance.'} /><br />
                    <TooltipComponent title={'Total Account Value '} icon={helpCircleOutline} body={': $' + formatNumber(account.totalAccountValue?.toFixed(2))} message={'If this value appears incorrect, a Fair Market Value update may be required on one or more of your holdings to bring the value of the account to an accurate total.'} /><br />
                    <IonButton onClick={() => goToAccountDetails(account)} size="small">Account Details</IonButton>
                  </p>
                </IonItem>
              </Fragment>)}
          </IonList>
        }/>
        {availableAccounts && (
          <ToggleComponent isActive={false} title="Pending Transactions" bodyData={
            <TransactionsListLoader pendingOrPosted='pending' queryPendingTransactionsBy='allAccounts' setPendingApprovalTransactions={setPendingApprovalTransactions}/>
          } />
        )}
      </IonCol>
      <Sidebar />
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSelectedAccount: (selectedAccount: RetirementAccount) => dispatch(
      {type: 'setSelectedAccount', payload: {selectedAccount}}
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);