import React, { useState } from 'react';
import {
    IonItem,
    IonButton,
    IonList,
    IonIcon
  } from '@ionic/react';
import { close } from 'ionicons/icons'
import { isPlatform } from '@ionic/react';
export type uploadCallBackInput = {status: 'success', files: Array<SFFile>} | {status: 'error', errorMessage: string}
const FileUpload: React.FC<{filesUploadedCallback:(fileUploadResponse: uploadCallBackInput)=>void, files:Array<SFFile>, deleteFile?:(fileIndex: number)=>void, fileParams?: {acceptedFiles?:string, maxSize?: number, maxSizeError?: string}}> = ({filesUploadedCallback, deleteFile, files, fileParams}) => {
    const FILE_TOO_LARGE = 'File size is too large. Please limit your document to a total of 3 MB.';
    const MAX_FILE_SIZE = 3145355;

    const [errorMessage, setErrorMessage] = useState<string|undefined>('');

    const isUploadedDocsSizeBelowMaxSize = (uploadedFilesList : Array<any>) => {
        const maxAcceptedSize = fileParams?.maxSize ? fileParams?.maxSize : MAX_FILE_SIZE;
        let fileSizeArr : Array<any> = []; 
        for (let i = 0; i < uploadedFilesList.length; i++) {
            fileSizeArr.push(uploadedFilesList[i].size);
        }
        let docsSizeInBytes = fileSizeArr.reduce((size1, size2) => size1 + size2, 0);
        return docsSizeInBytes <= maxAcceptedSize;
    }

    const onFileChange = async(e : any) => {
        setErrorMessage(undefined)
        const base64 = 'base64,';
        let files = e.target.files;
        let loadedFiles: Array<SFFile> = []
        
        if (!isUploadedDocsSizeBelowMaxSize(files)) {
            const errorMessage = fileParams?.maxSizeError ? fileParams?.maxSizeError : FILE_TOO_LARGE;

            (document.getElementById('upload_files') as any).value = '';

            setErrorMessage(errorMessage)
            return filesUploadedCallback({status: 'error', errorMessage: errorMessage});
        }
        
        for (let i = 0; i < files.length; i++) {  
            let readerListener = new Promise((resolve, reject)=>{
                let reader = new FileReader();
                reader?.addEventListener('load',()=> {
                    
                    let fileContents = typeof reader.result === 'string' ? reader.result : '';
                    let dataStart = fileContents?.indexOf(base64) + base64.length;
                    let blob = fileContents?.substring(dataStart);
                    
                    let newFile = {
                        'name': files[i].name, 
                        'base64_string': blob
                    };
                    resolve(newFile)
                    
                });
                
                reader.addEventListener('error',()=>{
                    reject()
                })
                
                reader.addEventListener('abort',()=>{
                    reject()
                })
                
                reader.readAsDataURL(files[i]);
            })
            try{
                loadedFiles.push((await readerListener) as SFFile)
            }catch(err){
                //noop
            }
        }
        (document.getElementById('upload_files') as any).value = '';
        filesUploadedCallback({status: 'success', files: loadedFiles})
    }
    
    const fileInputAcceptParams = () => {
        if(fileParams?.acceptedFiles){
            return fileParams?.acceptedFiles
        }
        return isPlatform('android') ? 'image/*, .doc' : '.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, xlsx';
    }
    
    return (
        <>
            <IonButton data-testid='file-upload-button' data-test="file-upload-button" onClick={()=>{document.getElementById('upload_files')?.click()}}>Upload Files</IonButton>
            <input data-testid='file-upload-input' id='upload_files' type='file' style={{'display': 'none'}} name='upload_files' onChange={onFileChange} accept={fileInputAcceptParams()} multiple/>
            {(errorMessage && errorMessage !== '') &&
                (<IonItem class="mt-1 w-100" color="danger">
                  <p className="white-color">{errorMessage}</p>
                  </IonItem>)}
            <IonList>
                {files?.map((sfFile,index)=>{
                    return <IonItem id='test-fild-id' data-test="test-file"  style={{'paddingBottom': '5px'}} key={index}>{sfFile.name} {(typeof deleteFile === 'function') && <IonIcon data-test="file-upload-delete" style={{cursor: 'pointer'}} onClick={()=>deleteFile(index)} icon={close} slot="end" />}</IonItem>
                })}
            </IonList>
        </>)
}

export default FileUpload