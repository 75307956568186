import React from 'react';
import {IonRow, IonCol, IonList, IonItem, IonProgressBar} from '@ionic/react';
import progressBarStepThree from '../../../images/update-asset-step-three.svg';
import { isPhone } from '../../../helpers/Utils';
import { formatNumber, formatDateString } from 'shared-utils';
import { UpdateAssetStep } from '../UpdateAssetWizard';

const UpdateAssetStepThree: React.FC<UpdateAssetStep> = ({holding, formRef, goToNextStep, assetValuationForm}) => {
  const handleNext = (e) => {
    e.preventDefault();
    goToNextStep();
  }

  const getValueDifference = () => {
    if (assetValuationForm?.newMarketValue && holding?.MVFromATG) {
      if (isMultiHeldRealEstateAsset(holding)) { 
        return Math.abs(+assetValuationForm.newMarketValue - +holding.MarketValuePerUnit);
      }
      else {
        return Math.abs(+assetValuationForm.newMarketValue - +holding.MVFromATG);
      }
    }
  }

  const getPartiallyOwnedRealEstateDifference = () => {
    if (assetValuationForm?.newMarketValue && holding?.Units && holding?.MVFromATG) {
      return Math.abs(+(assetValuationForm.newMarketValue * holding.Units) - +holding.MVFromATG);
    }
  }
  
  const getValuePercentChange = () => {
    if (holding?.MVFromATG) {
      if (isMultiHeldRealEstateAsset(holding)) { 
        return Math.floor(((getPartiallyOwnedRealEstateDifference() as number) / (+holding.MVFromATG)) * 100); 
      }
      else {
        return Math.floor(((getValueDifference() as number) / (+holding.MVFromATG)) * 100); 
      }
    }
  }

  const isMultiHeldRealEstateAsset = (holding : Holding | undefined) => {
    return (holding && holding.AssetType === 'Property Owned' && holding.Units < 1);
  }

  const getCurrentMarketValue = (holding : Holding | undefined) => {
    return isMultiHeldRealEstateAsset(holding) ? holding?.MarketValuePerUnit : holding?.MVFromATG;
  }

  const getValueDescription = (holding : Holding | undefined) => {
    return isMultiHeldRealEstateAsset(holding) ? 'Entire Property Value' : 'Value';
  }

  const getDifferenceOfText = (holding : Holding | undefined) => {
    return isMultiHeldRealEstateAsset(holding) ? 'Difference for your IRA share:' : 'Difference Of:';
  }
  
  const getDifferenceOfPercent = () => {
    const valuePercentChange = getValuePercentChange();

    return (valuePercentChange !== undefined && valuePercentChange < 1) ? 'Less than 1%' :  getValuePercentChange() + '%';
  }

  return (
    <form data-test='update-asset-step-three' ref={formRef} onSubmit={handleNext}> 
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepThree} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.66}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonRow>
            <IonCol>
              <IonRow>
                <h2>Market Value Changed</h2>
              </IonRow>
              <IonRow>
                <IonList class="w-100">
                  <IonItem><p>
                    {getValueDescription(holding)} as of {formatDateString(holding?.MarketValueDate)}: <br/><b>${formatNumber(getCurrentMarketValue(holding)?.toFixed(2))}</b><br/><br/>
                    {getValueDescription(holding)} as of {formatDateString(assetValuationForm?.valuationDate)}: <br/><b>${formatNumber(Number(assetValuationForm?.newMarketValue).toFixed(2))}</b>
                  </p></IonItem>
                  {holding && holding.AssetType === 'Property Owned' && holding.Units < 1 && assetValuationForm?.newMarketValue &&
                    <>
                      <IonItem><p>
                        IRA Share Value as of {formatDateString(holding?.MarketValueDate)}: <br/><b>${formatNumber((holding?.MarketValuePerUnit * holding?.Units).toFixed(2))}</b><br/><br/>
                        IRA Share Value as of {formatDateString(assetValuationForm?.valuationDate)}: <br/><b>${formatNumber((assetValuationForm?.newMarketValue * holding?.Units).toFixed(2))}</b>  
                      </p></IonItem>
                    </>
                  }
                  <IonItem><p>
                    <b>{getDifferenceOfText(holding)}</b><br/>
                    Dollar Amount: <b>${formatNumber(getValueDifference()?.toFixed(2))}</b><br/>
                    Percent: <b>{getDifferenceOfPercent()}</b>
                  </p></IonItem>
                  {getValuePercentChange() !== undefined && (getValuePercentChange() as number) >= 20 && 
                    <IonItem class="mt-1" color="danger" data-test="valuation-warning"><p className="white-color"> The valuation of your asset will change by {getValuePercentChange()} percent. If this is correct, please click Next, otherwise click Back and adjust your New Market Value.</p></IonItem>
                  }
                </IonList>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </form>
  );
};


export default UpdateAssetStepThree;