import React, { useState, useEffect } from 'react';
import { IonRow, IonCol, IonLabel, IonInput, IonSelect, IonSelectOption, IonItem, IonButton, IonToast } from '@ionic/react';
import { useHistory } from 'react-router';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { AccountIonSelect } from '../SelectAccountBar';
import FileUpload, {uploadCallBackInput} from '../helperComponents/FileUpload';
import calloutHelper from '../../helpers/calloutHelpers';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import DocCustodySidebar from './DocCustodySidebar';

const VaultInWizard: React.FC<{availableAccounts?: RetirementAccount[]}> = ({availableAccounts}) => {
    const history = useHistory();
    const [ submittingSpinner, setSubmittingSpinner ] = useState<boolean>(false);
    const [ showConfirmation, setShowConfirmation ] = useState<boolean>(false);
    const [ files, setFiles ] = useState<SFFile[]>([]);
    const [ toast, setToast ] = useState<{showToast: boolean, toastMessage: string}>({showToast: false, toastMessage: ''})

    const getDefaultValues = (): VaultInForm => {
        return {
            accountId: '',
            documentDescription: '',
            vaultType: '',
            trackingNumber: ''
        }
    }

    const { control, formState: { errors }, handleSubmit, clearErrors, setValue, watch} = useForm<Partial<VaultInForm>>({
        mode: 'onSubmit',
        defaultValues: getDefaultValues()
    });

    const vaultType = watch('vaultType');

    useEffect(() => {
        if (vaultType === 'Digital') {
            setValue('trackingNumber', '');
        }
    }, [vaultType])

    const onFileChange = async (fileUploadResponse: uploadCallBackInput) => {
        clearErrors('vaultType');
        if(fileUploadResponse.status === 'success') {
            setFiles(fileUploadResponse.files);
        } else {
            setFiles([]);
        };
    };

    const deleteFile = (index: number) => {
        files.splice(index, 1);
        setFiles([...files]);
    };

    const renderVaultInForm = () => {
        return (
            submittingSpinner ? <Spinner/> :
            <form onSubmit={handleSubmit(onValid)}>
                <IonRow>
                    <IonCol size='12'>
                        <IonRow class='mt-2 mb-1'>
                            <IonLabel>Select Account:</IonLabel>
                        </IonRow>
                        <IonRow class='w-100'>
                            <IonCol size='12' class='w-100 gr-border p-0 m-0'>
                                <Controller name='accountId' control={control} render={({ field: { value, onChange, name } }) => (
                                    <AccountIonSelect accountID={value || ''} name={name} availableAccounts={availableAccounts || []} onChangeHandler={(event) => {
                                        setValue('accountId', event.detail.value);
                                    }} />
                                )} 
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please select an account.'
                                    }
                                }}/>
                            </IonCol>
                        </IonRow>
                        {errors['accountId'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['accountId'].message}</p></IonItem>}
                        <IonRow class='mt-2 mb-1'>
                            <IonLabel>Document Description:</IonLabel>
                        </IonRow>
                        <IonRow class='w-100'>
                            <Controller name='documentDescription' control={control} render={({ field: {value, onChange, onBlur, name}}) => (
                                <IonInput data-testid='document-description-input' class='ion-text-left gr-border' name={name} value={value} onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} maxlength={255}/>
                            )} 
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please add a description for the document.'
                                }
                            }}/>
                        </IonRow>
                        {errors['documentDescription'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['documentDescription'].message}</p></IonItem>}
                        <IonRow class='mt-2 mb-1'>
                            <IonLabel>Vault Type:</IonLabel>
                        </IonRow>
                        <IonRow class='w-100'>
                            <Controller name='vaultType' control={control} render={({ field: {value, onChange, name} }) => (
                                <IonSelect data-testid='vault-type-select' class='w-100 gr-border p-1' name={name} interface='action-sheet' tabIndex={0} mode='ios' onIonChange={(event) => onChange(event.detail.value)} value={value} placeholder='Select Vault Type'>
                                    <IonSelectOption value='Digital'>Digital</IonSelectOption>
                                    <IonSelectOption value='Physical'>Physical</IonSelectOption>
                                </IonSelect>
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please select a vault type.'
                                },
                                validate: {
                                    fileUploadRequired: (value) => ((value === 'Digital' && files.length < 1) ? 'Please upload a document.' : undefined)
                                }
                            }}/>
                        </IonRow>
                        {errors['vaultType'] && errors['vaultType'].type === 'required' && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['vaultType'].message}</p></IonItem>}
                        
                        {vaultType === 'Physical' &&
                            <>
                                <IonRow class='mt-2 mb-1'>
                                    <IonLabel>Tracking Number:</IonLabel>
                                </IonRow>
                                <IonRow class='w-100'>
                                    <Controller name='trackingNumber' control={control} render={({ field: {value, onChange, onBlur, name}}) => (
                                        <IonInput data-testid='tracking-number-input' class='ion-text-left gr-border' name={name} value={value} onIonChange={(event) => onChange(event.detail.value)} onIonBlur={onBlur} maxlength={36}/>
                                    )} 
                                    rules={{required: false}}/>
                                </IonRow>
                                {errors['trackingNumber'] && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['trackingNumber'].message}</p></IonItem>}
                            </>
                        }

                        <IonRow class='mt-2 mb-1 align-items-center'>
                            <FileUpload files={files} filesUploadedCallback={onFileChange} deleteFile={deleteFile} />
                        </IonRow>
                        {errors['vaultType'] && errors['vaultType'].message === 'Please upload a document.' && <IonItem class='mt-1' color='danger'><p className='white-color'>{errors['vaultType'].message}</p></IonItem>}
                        <IonRow class='mt-2 ml-1 mb-1'>
                            <IonLabel><b>Disclaimer:</b></IonLabel>
                        </IonRow>
                        <IonRow data-testid='disclaimer' class='ml-2 mb-1'>
                            Equity Trust will exercise reasonable care with respect to Property received by Equity Trust under this Agreement. Until actually received by Equity Trust, Equity Trust assumes no responsibility or liability for Property placed in transit by the Depositor, its agents or designees. Equity Trust will exercise reasonable care with respect to any Property actually placed in transit by Equity Trust. Equity Trust shall not be liable to the Depositor for any indirect, incidental, consequential or special damages suffered by the Depositor, including, but not limited to, lost revenues or lost profits, whether arising in contract, tort, negligence, strict liability, breach of statutory duty or otherwise, and regardless of any notice of the possibility of such damages. The liability of Equity Trust to the Depositor shall be limited to the actual replacement of Property identical to Property lost while in the possession of Equity Trust under this Agreement, due to theft, mysterious disappearance, or the negligence of Equity Trust, its employees, agents and designees.
                        </IonRow>

                        <IonButton data-testid='submit-button' class='mt-2 ion-float-right' onClick={() => handleSubmit(onValid, (errors) => {})()}>Submit</IonButton>
                    </IonCol>
                </IonRow>
            </form>
        )
    };

    const renderConfirmationPage = (vaultType: string) => {
        
        return (
            <>
                <IonRow>
                    <IonCol data-testid='confirmation-page' size='12'>
                        {vaultType === 'Digital' ? 
                            <IonRow>Thank you. The Vault In Document has been uploaded.</IonRow>
                            : <>
                                <IonRow>Thank you. Please proceed with sending physical documents to:</IonRow>
                                <IonRow class='mt-2 ml-1'><b>15671 San Carlos Blvd, Fort Myers, FL 33908 (Attn: Document Custody).</b></IonRow>
                            </>
                        }
                        <IonButton class='mt-2 ion-float-right' onClick={() => history.push('/home')}>Return Home</IonButton>
                    </IonCol>
                </IonRow>
            </>
        )
    };

    const onValid: SubmitHandler<Partial<VaultInForm>> = async (formFields) => {
        setSubmittingSpinner(true);
        try {
            const validFormFields: VaultInFields = {
                files: files,
                accountId: formFields.accountId || '',
                documentDescription: formFields.documentDescription || '',
                vaultType: formFields.vaultType || '',
                trackingNumber: formFields.trackingNumber || ''
            }
            const postVaultInResult = await calloutHelper.postVaultIn(validFormFields);
            if (postVaultInResult.data.status === 'ok') {
                setShowConfirmation(true);
                setSubmittingSpinner(false);
            } else {
                setSubmittingSpinner(false);
                setToast({showToast: true, toastMessage: 'Vault In Failed.'})
            }
        } catch (err) {
            setSubmittingSpinner(false);
            setToast({showToast: true, toastMessage: 'Vault In Failed.'})
        }
    };

    return (
            <IonRow class="container">
                {toast.showToast &&
                    <IonToast isOpen={toast.showToast} color='danger' position='top' message={toast.toastMessage} onDidDismiss={() => setToast({showToast: false, toastMessage: ''})} buttons={[{icon: 'close-circle'}]} />
                }
                <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <IonRow>
                        <IonCol class="p-1 light-gr-bg">
                            <IonRow>
                                <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                    <IonRow>
                                        <h1>Vault In</h1>
                                    </IonRow>
                                    {showConfirmation ? renderConfirmationPage(vaultType || '') : renderVaultInForm()}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCol>
                <DocCustodySidebar />
            </IonRow>
    )
};

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(VaultInWizard);