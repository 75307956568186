import React from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from '@ionic/react';
import progressBarStepOne from '../../../images/contribution-wizard-step-one.svg';
import { isPhone } from '../../../helpers/Utils';
import { useForm, Controller, FieldErrors } from 'react-hook-form';
import { AccountIonSelect } from '../../SelectAccountBar';
import { InputComponents } from '../ContributionTypes'
import { checkPartyTypeAccessBuilder } from '../../PartyTypeConditionalAccess';

export const NEW_BANK: Partial<Bank> = {bankId: 'Add Payee', bankName: 'Add New Bank'}

type ContributionStepOneType = InputComponents & {
  availableAccounts: Array<RetirementAccount>, 
  bankOptions: Array<Bank>, 
}

type StepOneFields = ContributionWizard.StepOneFields

const ContributionStepOne: React.FC<ContributionStepOneType> = ({availableAccounts, bankOptions, formRef, submitFields, savedFields, preselectedFields}) => {
  const getDefaultValues = ():StepOneFields => {
    return {
      retirementAccount: savedFields?.retirementAccount || '',
      selectedBankId: savedFields?.selectedBankId || preselectedFields?.selectedBankId || ''
    }
  }

  const { control, formState: { errors }, handleSubmit, getValues } = useForm<StepOneFields>({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
    
  });
  const onValid = (formFields: StepOneFields)=>{
    submitFields({status: 'ok', formFields})
  }
  
  const onInvalid = (errors: FieldErrors) => {
    submitFields({status: 'error', formFields: getValues(), errors})
  }  
  
  const BankSelectOptions:React.FC<{banks : Partial<Bank>[]}> = ({banks}) => {
    
    const getBankDisplayName = (bank: Partial<Bank>) => {
      return bank.creditAccountName + ' - ' + bank.bankName
    }
    
    banks.sort((a, b) => (getBankDisplayName(a).toLowerCase().localeCompare(getBankDisplayName(b).toLowerCase())))
   
    return (
      <>
        {banks?.map((bank) => (
          <IonSelectOption data-testid={'bank-select-option-' + bank.bankId} key={bank.bankId} value={bank.bankId}>{getBankDisplayName(bank)}</IonSelectOption>)
        )}
        <IonSelectOption data-testid={'bank-select-option-' + NEW_BANK.bankId} key={NEW_BANK.bankId} value={NEW_BANK.bankId}>{NEW_BANK.bankName}</IonSelectOption>
      </>  
    )
  }
  
  return (<form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <IonRow class="divider">
        <h1 className="ion-text-left">Cash Contribution Instructions:</h1>
        <ul>
          <li>You can make a cash contribution to your account directly from your bank account.</li>
          <li>It is your responsibility to make sure you are eligible to make the contribution.</li>
          <li>Requests submitted will be reviewed within one business day. We will contact you if we need further information. You will receive an email confirmation when your contribution request has been successfully processed.</li>
          <li>By providing your banking details, you authorize Equity Trust to request a withdrawal for the indicated contribution amount from your bank. Equity Trust will submit your request within one business day. Funds will be subject to a five business day hold once deposited to your Equity Trust account.</li>
          <li className="text-danger">Once your contribution has been processed, there will be a 5 day hold on the funds for verification purposes.</li>
        </ul>
      </IonRow>
      <IonRow class="mt-2 mb-1">
        <IonLabel>Select Account</IonLabel>
      </IonRow>
      <IonRow class="w-100 gr-border p-0 m-0">
        <IonCol size="12" class="w-100 p-0 m-0">
          <Controller control={control} name='retirementAccount' render={({ field: { name, value, onChange }})=>(
            <AccountIonSelect accountID={value} availableAccounts={availableAccounts}  onChangeHandler={onChange} name={name} accountFilter={checkPartyTypeAccessBuilder('createContribution')}/>
          )}
          rules={{ required: {
            value: true,
            message: 'Please select an account'
          } }}/>
        </IonCol>
      </IonRow>
      {errors['retirementAccount'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['retirementAccount'].message}</p></IonItem>}
      <IonRow class="mt-2 mb-1">
        <IonLabel>From Account</IonLabel>
      </IonRow>
      <IonRow class="w-100">
        <Controller data-test='select-bank-account-controller' name='selectedBankId' control={control} render={({ field: { value, name, onChange } }) => {
          return (
            <IonSelect class="w-100 gr-border p-1" interface="action-sheet" placeholder='Please Select' tabIndex={0} mode="ios" name={name} onIonChange={onChange} value={value}>
              <BankSelectOptions banks={bankOptions}/>
            </IonSelect>
          )
        }
        } rules={{ required: {
          value: true,
          message: 'Please select a payment option'
        } }} />
      </IonRow>
      {errors['selectedBankId'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['selectedBankId'].message}</p></IonItem>}
      </form> );
};
export default ContributionStepOne;