
//these types should defined in a global types (possibly) or somewhere shared

const initialState: SessionState = {
    token: undefined,
    meta: undefined,
    isAuthenticated: false
}
  
export function SessionReducer(
    state = initialState,
    action: SessionActions
): SessionState {
    switch (action.type) {
        case "setIsAuthenticated":
            return {...state, ...action.payload}
        case "setSession":
            return {...state, ...action.payload}
        case "setTimeout":
            return {...state, ...action.payload}
        default:
            return state
        }
}

export default SessionReducer