import React from 'react'
import { useForm, 
  Controller, 
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler, 
} from 'react-hook-form'
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonLabel,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { EMAIL_VALIDATION_PATTERN } from '../../helpers/Utils'
import { MyProfileFormInputs, MyProfileTypes } from './myProfileTypes'
import { ShowFieldError, mapFieldPropsToIonProps, getDefaultValues } from './SubComponents/MyProfileUtils'
import SecurityQuestions from './SubComponents/SecurityQuestions'
import AddressFields from './SubComponents/MyProfileAddressFields'
import PhoneFields from './SubComponents/PhoneFields'

export const getPreferredContactMethodLabel = (val: MyProfileFields.PreferredContactMethod) => {
  if(val === 'Home' || val === 'Mobile' || val === 'Work'){
    return `Phone (${val})`
  }
  return val
}

const MyProfile: React.FC<MyProfileTypes> = ({ personDetails, formRef, submitSuccessful, validationError }) => {

    const myProfileForm = useForm<MyProfileFormInputs>({
        mode: 'onSubmit',
        defaultValues: getDefaultValues(personDetails),
        shouldFocusError: true,
        shouldUnregister: true,
    })

    const { control, formState: { errors }, watch, handleSubmit } = myProfileForm
    
    const enableSecurityQuestion = watch('enableSecurityQuestion') 

    const mailingShippingDiffer = watch('mailingShippingDiffer')

    const onError: SubmitErrorHandler<MyProfileFormInputs> = (errors) => {
        if(validationError){
            validationError(errors)
        }
    }

    const onSuccess: SubmitHandler<MyProfileFormInputs> = (fields) => {
        fields.Primary.phoneNumber = fields.Primary.phoneNumber.replace(/[^\d]/g, '')
        if(fields.Secondary?.phoneNumber){
            fields.Secondary.phoneNumber = fields.Secondary.phoneNumber.replace(/[^\d]/g, '')
        }
        submitSuccessful(fields)
    }

    const PreferredContactMethodOption:React.FC<{option: MyProfileFields.PreferredContactMethod}> = ({option}) =>{
        return <IonSelectOption value={option}>{getPreferredContactMethodLabel(option)}</IonSelectOption>
    }

    return (
        <FormProvider {...myProfileForm}>
            <form ref={formRef} onSubmit={handleSubmit(onSuccess, onError)}>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="tertiary" class="mt-3">
                            Personal Contact Information
                        </IonItem>
                        <IonRow class="p-1">
                            <IonCol size="12">
                                <p className="p-0 m-0"><b>Name</b>: {`${personDetails?.firstName} ${personDetails?.lastName}`}</p>
                                <>
                                    <IonCol size="12" class="d-block mt-1">
                                        <IonLabel className="p-0 m-0">Email:</IonLabel>
                                    </IonCol>
                                    <Controller name='email' control={control} render={(props) =>
                                        <IonInput autocomplete='email' class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} /> 
                                    } rules={{
                                        pattern: {
                                            value: new RegExp(EMAIL_VALIDATION_PATTERN),
                                            message: 'Please provide a valid email address.'
                                        },
                                        required: "Please enter email address."
                                    }} />
                                    <ShowFieldError name='email' errors={errors}/>
                                </>
                            </IonCol>
                            <PhoneFields/>
                        </IonRow>
                        <IonItem color="tertiary" class="mt-1">
                            Address
                        </IonItem>
                        <IonRow class="p-1">
                            <IonCol size="12">
                                <>
                                    <IonCol size="12">
                                        <IonLabel className="p-0 m-0">My mailing address is different than my legal address:</IonLabel>
                                    </IonCol>
                                    <Controller name='mailingShippingDiffer' control={control} render={({field:{ name, value, onChange }}) =>
                                        <IonCheckbox name={name} checked={value} class="ion-text-left gr-border lg-checkbox" onIonChange={(event)=>onChange(event.detail.checked)}/>
                                    }/>
                                    <br/>
                                </>
                            </IonCol>
                            <AddressFields addressType='Mailing'/>
                            {mailingShippingDiffer && <AddressFields addressType='Legal'/>}
                        </IonRow>

                        <IonItem color="tertiary" class="mt-1">
                            Contact Preferences
                        </IonItem>
                        <IonRow class="p-1">
                            <IonCol size="12">
                                <IonCol size="12" class="d-block">
                                    <IonLabel className="p-0 m-0">Preferred Contact Method:</IonLabel>
                                </IonCol>
                                <Controller name='preferredContactMethod' control={control} 
                                    rules={{
                                        required: 'Please select a contact method.'
                                    }}
                                    render={(props) =>
                                        <IonSelect class={"w-100 gr-border p-1 mb-2"} interface="action-sheet" mode="ios" {...mapFieldPropsToIonProps(props.field)}>
                                            <PreferredContactMethodOption option='Email' />
                                            <PreferredContactMethodOption option='Mail' />
                                            <PreferredContactMethodOption option='Home' />
                                            <PreferredContactMethodOption option='Mobile' />
                                            <PreferredContactMethodOption option='Work' />
                                        </IonSelect>
                                    }
                                />
                                <ShowFieldError name='preferredContactMethod' errors={errors}/>
                                <IonCol size="12">
                                    <IonLabel className="p-0 m-0">I do not want to receive monthly newsletter or other education-related emails:</IonLabel>
                                </IonCol>
                                <Controller name='marketingOptOut' control={control} render={({ field: { value, onChange, name } }) =>
                                    <IonCheckbox class="ion-text-left gr-border lg-checkbox" checked={value} name={name} onIonChange={event=>onChange(event.detail.checked)} />
                                } />
                            </IonCol>
                        </IonRow>
                        <IonItem color="tertiary" class="mt-1">
                            Login Preferences
                        </IonItem>
                        <IonRow class="p-1">
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonCol size="12">
                                    <IonLabel className="p-0 m-0">Enable Multi Factor Authentication:</IonLabel>
                                </IonCol>
                                <Controller name='mfa' control={control} render={({ field: { value, onBlur, name, onChange } }) =>
                                    <IonCheckbox class="ion-text-left gr-border lg-checkbox" checked={value} name={name} onIonChange={(event)=>onChange(event.detail.checked)}/>
                                    }
                                />
                                <br/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <>
                                    <IonCol size="12">
                                        <IonLabel className="p-0 m-0">Enable Account Security Question/PIN:</IonLabel>
                                    </IonCol>
                                    <Controller name='enableSecurityQuestion' control={control} render={({ field: { value, onBlur, name, onChange}}) =>
                                        <IonCheckbox class="ion-text-left gr-border lg-checkbox" name={name} checked={value} onIonChange={event => onChange(event.detail.checked)}/>}
                                    />
                                    {enableSecurityQuestion && <SecurityQuestions/>}
                                </>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </form>
        </FormProvider>
    )
}

export default MyProfile