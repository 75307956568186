import React, {useEffect, useState} from 'react';
import '../pages/Style.css';
import { 
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonItem,
} from '@ionic/react';
import { connect } from 'react-redux';
import { isMobile } from '../helpers/Utils';
import Spinner from './Spinner';

const SelectAccountBar: React.FC<{ availableAccounts: Array<RetirementAccount>, setSelectedAccount(account:RetirementAccount):void, selectedAccountId?: string, onChange?: (retirementAccount: RetirementAccount)=>void }> = ({ availableAccounts, setSelectedAccount, selectedAccountId}) => {
  const [showEmptyIonSelect, setShowEmptyIonSelect] = useState<boolean>(true);

  useEffect(() => {
    if(availableAccounts && availableAccounts.length > 0){
      setShowEmptyIonSelect(false)
    }

    if (selectedAccountId === undefined && availableAccounts.length > 0) {
      setSelectedAccount(availableAccounts[0])
    }
  }, [availableAccounts, setSelectedAccount, selectedAccountId])

  const handleSelectedAccountChange = (event: CustomEvent) => {
    const chosenAccountId = event.detail.value;
    let selectedAccount: RetirementAccount = availableAccounts.filter(account => (account.id === chosenAccountId))[0];
    setSelectedAccount(selectedAccount);
  }

  const displayAccountSelectOptions = () => {
    let ionRowClassName = 'container mr-auto ml-auto mt-0 mb-0 p-0';
    let ionColStyles : {[key: string]:string} = {size: '12', className: 'p-0'};
    let ionItemClassName = 'm-0 p-0';
    let ionSelectClassName = ''; 
    let ionSelectOptionClassName = '';
    if (!isMobile()) {
      ionColStyles = {
        size: '12'
      };
      ionSelectClassName = 'w-100';
    } else {
      ionRowClassName += ' p-0';
      ionItemClassName += ' p-0';
      ionSelectClassName += ' p-0';
      ionSelectOptionClassName += ' p-0';
    }
    let markup = {
      ionRowClassName,
      ionColStyles,
      ionItemClassName,
      ionSelectClassName,
      ionSelectOptionClassName
    }

    const emptyIonSelect = <>
      <IonCol {...markup.ionColStyles}>
        <IonItem className={markup.ionItemClassName} lines="none">
          <IonSelect className={markup.ionSelectClassName} interface="action-sheet" mode="ios" placeholder="Select Account" />
        </IonItem>
      </IonCol>
    </>

    return <AccountIonSelect onChangeHandler={handleSelectedAccountChange} accountID={selectedAccountId||''} availableAccounts={availableAccounts} markup={markup} showPlaceholder={showEmptyIonSelect} placeholderElement={emptyIonSelect} withClosedAccounts={true}/>
  }

  return (
    displayAccountSelectOptions()
  );
};

type AccountIonSelectType = {
  onChangeHandler(event:CustomEvent<{value: string}>):void, accountID:string, availableAccounts:Array<RetirementAccount>, showPlaceholder?:boolean, placeholderElement?:JSX.Element, name?:string, 
  accountFilter?(account: RetirementAccount): boolean,
  markup?:{
    ionRowClassName: string
    ionColStyles : {[key: string]:string}
    ionItemClassName: string
    ionSelectClassName: string
    ionSelectOptionClassName: string
  },
  withClosedAccounts?: boolean
}

export const AccountIonSelect: React.FC<AccountIonSelectType> = ({accountID, availableAccounts, onChangeHandler, accountFilter, markup, showPlaceholder, placeholderElement, name='accountSelectBar', withClosedAccounts}) => {

  if(!markup){
    markup={
      ionRowClassName: 'container mr-auto ml-auto mt-0 mb-0 p-0',
      ionItemClassName: 'm-0  p-0',
      ionSelectClassName: ' p-0',
      ionSelectOptionClassName: ' p-0',
      ionColStyles : {size: '12', className: 'p-0'}
    }
  }

  const selectableAvailableAccounts = withClosedAccounts ? availableAccounts : availableAccounts.filter(account => account.status !== 'Closed');

  const placeholder = placeholderElement ? placeholderElement : <Spinner/>

  return (<IonRow className={markup.ionRowClassName}>
      {(showPlaceholder) ? (placeholder) : (<IonCol {...markup.ionColStyles}>
        <IonItem className={markup.ionItemClassName} lines="none">
          <IonSelect data-testid='account-select' name={name} className={markup.ionSelectClassName} interface="action-sheet" mode="ios" placeholder="Select Account" onIonChange={onChangeHandler} value={accountID}>
            {selectableAvailableAccounts?.map && selectableAvailableAccounts?.filter(value => accountFilter ? accountFilter(value) : true).map((value) =>
            (<IonSelectOption className={markup?.ionSelectOptionClassName} key={value.id} value={value.id}>
              {`${value.accountNumber} - ${value.firstName} ${value.lastName} - ${value.accountType}`}
            </IonSelectOption>)
            )}
          </IonSelect>
        </IonItem>
      </IonCol>)}
  </IonRow>)
}

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts,
    selectedAccountId: rootState.UserState.selectedAccount?.id,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSelectedAccount: (selectedAccount: RetirementAccount) => dispatch(
      {type: 'setSelectedAccount', payload: {selectedAccount}}
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccountBar);