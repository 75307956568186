import React, { useState, useEffect, useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepOne from '../../images/contribution-wizard-step-one.svg';
import { isPhone } from '../../helpers/Utils';
import { useForm, FieldErrors, useWatch, FormProvider } from 'react-hook-form';
import { InputComponents } from './TradingAccountWithdrawalTypes';
import calloutHelper from '../../helpers/calloutHelpers';
import ConditionalSelect, { option } from '../ConditionalSelect';
import axios from 'axios';
import Spinner from '../Spinner';
import { checkPartyTypeAccessBuilder } from '../PartyTypeConditionalAccess';

type UpdateAssetStepOneType = InputComponents & {
  availableAccounts: Array<RetirementAccount>, 
}

interface RelatedAssetOption {
  value: string,
  description: string,
  cusipId: string,
}

type StepOneFields = TradingAccountWithdrawalWizard.StepOneFields

const TradingAccountWithdrawalStepOne: React.FC<UpdateAssetStepOneType> = ({availableAccounts, formRef, submitFields, savedFields, setErrorMessage, clearErrorMessage}) => {
  const [assetOptions, setAssetOptions] = useState<RelatedAssetOption[]>([]);
  const [accountsWithTradingBankHoldings, setAccountsWithTradingBankHoldings] = useState<option[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const getDefaultValues = ():StepOneFields => {
    return { 
      accountId: savedFields?.accountId || '',
      holdingId: savedFields?.holdingId || '',
      assetLabel: savedFields?.assetLabel || '',
      cusipId: savedFields?.cusipId || ''
    }
  }

  const stepOneForm = useForm<StepOneFields>({
    mode: 'onChange',
    defaultValues: getDefaultValues()
  })

  const { formState: { errors }, handleSubmit, getValues, setValue, watch } = stepOneForm;
  
  const selectedAccountId = watch('accountId')

  useEffect(() => {
    const getAccountsWithTradingAccountHolding = async () => {
      setShowSpinner(true);
      try
      {
        const accountsWithTradingBankHoldingResultResponse = await calloutHelper.getAccountsWithTradingBankHoldings();

        if(typeof accountsWithTradingBankHoldingResultResponse?.data === 'object' && accountsWithTradingBankHoldingResultResponse.data.length > 0){ 
          const data: RetirementAccount[] = accountsWithTradingBankHoldingResultResponse?.data
          const accountFilter = checkPartyTypeAccessBuilder('createTradingAccountWithdrawal')
          const options =  data.reduce<option[]>((collection, account)=>{
            if(accountFilter(account)){
              const description = `${account.accountNumber} - ${account.firstName} ${account.lastName} - ${account.accountType}`
              const allowedOption = {value: account.id, description}
              return [...collection, allowedOption]
            }
            return collection
          }, [])
          setAccountsWithTradingBankHoldings(options);
        }
        
        setShowSpinner(false);
      } catch (err) {
        if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
          setAssetOptions([]);
          setErrorMessage('Error retrieving accounts with a trading bank holding');
          clearErrorMessage();
        }
        setShowSpinner(false);
      }
    };
    getAccountsWithTradingAccountHolding();
    
  }, [clearErrorMessage, setErrorMessage]);

  useEffect(() => {
    if (!selectedAccountId) return; 
    
    let source = axios.CancelToken.source();
    const getAssetOptions = async (token: any) => {
      setShowSpinner(true);
      try
      {
        if (!selectedAccountId) return;
        const assetOptionsResult = await calloutHelper.getBankAndTradingHoldings(selectedAccountId, token);
        setAssetOptions([...assetOptionsResult.data.relatedAssetOptions]);
        setShowSpinner(false);
      } catch (err) {
        if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
          setAssetOptions([]);
          setErrorMessage('Error retrieving asset options');
          clearErrorMessage();
        }
        setShowSpinner(false);
      }
    };
    getAssetOptions(source.token);
    return(() => {
      source.cancel('Cancelling in cleanup');
    });
  }, [accountsWithTradingBankHoldings, selectedAccountId, clearErrorMessage, setErrorMessage]);
  
  const setFieldsIfOnlyOneItemExists = useCallback(async () => {
    try {
      if (accountsWithTradingBankHoldings.length === 1) {
        setValue('accountId', accountsWithTradingBankHoldings[0].value)
        if (assetOptions.length === 1) {
          setValue('holdingId', assetOptions[0].value);
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
        setAssetOptions([]);
        setErrorMessage('Error setting accountId and/or holdingId');
        clearErrorMessage();
      }
    }
  }, [accountsWithTradingBankHoldings, assetOptions, setValue, setErrorMessage, clearErrorMessage])

  useEffect(() => {
    setFieldsIfOnlyOneItemExists();
  }, [setFieldsIfOnlyOneItemExists])

  const setAsset = (event: any) => {
    setValue('accountId', event.detail.value, {shouldValidate: true});
  }

  const onValid = (formFields: StepOneFields)=>{
    if(!formFields.holdingId){
      return; //hack to deal with hitting next while asset options are loading
    }
    
    let selectedAsset = assetOptions.find(asset => asset.value === formFields.holdingId);

    if (selectedAsset) {
      formFields.assetLabel = selectedAsset?.description;
      formFields.cusipId = selectedAsset.cusipId;
    }
    
    submitFields({status: 'ok', formFields})
  }
  
  const onInvalid = (errors: FieldErrors) => {
    submitFields({status: 'error', formFields: getValues(), errors})
  }
  
  return <FormProvider {...stepOneForm}>
    <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      <IonRow class="divider">
        <h1 className="ion-text-left">Trading Account Withdrawal Instructions:</h1>
        <ul>
          <li>Using this system, you can request a withdrawal from your trading account. Moving funds from your trading account to your IRA cash balance is a non-taxable event.</li>
          <li>Before you submit this request, it is your responsibility to ensure funds are liquid and available for withdrawal.</li>
          <li>Most trading companies will deliver the funds to your IRA by wire transfer within 2-5 business days after this request has been submitted.</li>
          <li>If you plan to use these funds to take an IRA distribution or to transfer to another IRA custodian, please indicate that in the "Expected Use of Funds" so that we may best assist you with next steps.</li>
          <li>We will contact you if we need further information. You will receive an email confirmation when funds have been deposited to your IRA cash account.</li>
        </ul>
        <p><b>Note</b>: By submitting this withdrawal request electronically, your processing fee is reduced based on your fee schedule. However, your trading company may charge a fee to send the funds to your IRA.</p>
      </IonRow>
      {showSpinner ? <Spinner/> : <ConditionalSelect controllerName='accountId' dataList={accountsWithTradingBankHoldings} errorMessage="Please select an account." title='Select Account' notifySelectChanged={setAsset}/>}
      {errors['accountId'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['accountId'].message}</p></IonItem>}
      <IonRow class="w-100">
        {selectedAccountId && <ConditionalSelect controllerName="holdingId" title="Related Asset" errorMessage="Please select a related asset." dataList={assetOptions} />}
      </IonRow>
    </form>
    </FormProvider>;
};
export default TradingAccountWithdrawalStepOne;