import React from 'react'
import {useFormContext, Controller, ValidationRule} from 'react-hook-form'
import {MyProfileFormInputs} from '../myProfileTypes'
import {IonCol, IonLabel, IonInput, IonSelect, IonSelectOption} from '@ionic/react'
import {mapFieldPropsToIonProps, ShowFieldError} from './MyProfileUtils'

const SecurityQuestions: React.FC = () => {
  const { control, watch, formState:{ errors}, getFieldState } = useFormContext<MyProfileFormInputs>()
  const enabledFieldState = getFieldState('enableSecurityQuestion')
  const secQuestion = watch('securityQuestion')
  const questionOrPin = watch('questionOrPin')
  const maxLength: ValidationRule<number> = {
    value: 25,
    message: 'Too many characters, this cannot exceed 25 characters.'
  }
    /**
     * Pin and Question
     * I originally set them up to render a FC's but when errors changed focus would be forced to the label
     * Security Question:
     * I believe this was due to ShowFieldError, I think it needs to be changed to only cause a re-render when
     * dependent fields are updated (if that's possible)
     * 
     * They need to be rendered in the same root as this FC
     * They can be conditional but apparently they cannot be in a nested FC
     * 
     * Assigning them to these consts is similar to pushing them inline where the variables are referenced
     * 
     * Maybe Key would help?
     */
    const Pin = (<>
        <IonCol size="12" class="d-block mt-1">
            <IonLabel className="p-0 m-0">PIN:</IonLabel>
        </IonCol>
        <Controller name='pin' control={control}
            rules={{required: 'Please enter a PIN.',  maxLength}}
            render={(props) =>
                <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)}/>
            }
        />
        <ShowFieldError name='pin' errors={errors}/>           
    </>)

    const Question = (<>
        <IonCol size="12" class="d-block mt-1">
            <IonLabel className="p-0 m-0">Security Question:</IonLabel>
        </IonCol>
        <Controller name='securityQuestion' control={control}
            rules={{ required: 'Please enter a security question.', maxLength}}
            render={(props) =>
                <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} maxlength={250}/>
            }
        />
        <ShowFieldError name='securityQuestion' errors={errors}/>

        <IonCol size="12" class="d-block mt-1">
            <IonLabel className="p-0 m-0">Security Answer:</IonLabel>
        </IonCol>
        <Controller name='securityAnswer' control={control}
            rules={
                {
                    required: 'Please enter an answer for your secret question.',
                    validate: val=>{
                        if(val === secQuestion){
                            return 'Security answer cannot match security question.'
                        }
                    },
                    maxLength 
                }
            }
            render={(props) =>
                <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} maxlength={250}/>
            }
        />
        <ShowFieldError name='securityAnswer' errors={errors}/>
    </>)
  return <>
        <Controller name='questionOrPin' control={control}
            rules={{required: enabledFieldState.isDirty ? 'Please select Pin or Security Question.' : false }}
            render={(props) =>{
                    const questionOrPinPlaceHolder = enabledFieldState.isDirty ? 'Select' : 'Update Question/Pin'
                    return <IonSelect class={"w-100 gr-border p-1 mt-1"}  placeholder={questionOrPinPlaceHolder} interface="action-sheet" mode="ios" {...mapFieldPropsToIonProps(props.field)}>
                        <IonSelectOption value="question">Security Question</IonSelectOption>
                        <IonSelectOption value="pin">PIN</IonSelectOption>
                    </IonSelect>
                }
            }
        />
        <ShowFieldError name='questionOrPin' errors={errors}/>
        {questionOrPin === 'pin' && Pin}
        {questionOrPin === 'question' && Question}
    </>
}

export default SecurityQuestions