import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IonCol, IonItem, IonRow, IonToast, IonButton } from '@ionic/react'
import DistributionStepOne, { deliveryDestinationOption } from './DistributionSteps/DistributionStepOne'
import DistributionStepTwo from './DistributionSteps/DistributionStepTwo'
import Spinner from '../Spinner'
import { distributionStep, DistributionHistory, SubmitDistributionFieldsCallback, DistributionFullForm, SubmittableDistributionForm } from './DistributionFields/distributionWizardTypes'
import { GenericWizardButtons } from '../WizardButtons'
import { useHistory } from 'react-router'
import { getPreviousArrayMember, getNextArrayMember, changeSavedFieldsToSubmittableForm } from './distributionWizardHelpers'
import DistributionStepReview from './DistributionSteps/DistributionReviewStep'
import { TokenServices, getProgressRatio } from '../../helpers/wizardHelpers'
import calloutHelper from '../../helpers/calloutHelpers'
import { connect } from 'react-redux'

const DistributionWizard: React.FC<{ scrollToTop : Function, availableAccounts: RetirementAccount[], selectedAccount: RetirementAccount | undefined }> = ({scrollToTop, availableAccounts, selectedAccount}) => {

    const [loggedInDetails, setLoggedInDetails] = useState<PersonAccountHelpers.LoggedInDetails>()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [tosConfirmed, setTOSConfirmed] = useState<boolean>(false)
    const [availableBanks, setAvailableBanks] = useState<Bank[]>([])
    const [distributionTypes, setDistributionTypes] = useState<Array<{value: string, description: string}>>([])
    const [deliveryDestinationOptions, setDeliveryDestinationOptions] = useState<Array<deliveryDestinationOption>>([])
    const [availableDeliveryMethods, setAvailableDeliveryMethods] = useState<Array<{value: string, description: string}>>([])
    const [is401kAccount, setIs401kAccount] = useState<boolean>(false)

    const history = useHistory<DistributionHistory>()
    const contributionTokenFromLocation = history?.location?.state?.wizardToken || null
    const savedFields = history?.location?.state?.formFields || {}
    const toast = history?.location?.state?.Toast

    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>
    const direction = useRef<'next' | 'back'>()
    const stepsArray: Array<distributionStep> = ['step-one', 'step-two', 'review']
    const currentStep: distributionStep = history?.location?.state?.step || 'step-one'
    const isFundManagerFull = selectedAccount?.partyType === 'Fund Manager - Full';

    const loadAccountData = useCallback(async () => {
        let personDetailsResponse = await calloutHelper.getLoggedInPersonAccount()
        setLoggedInDetails(personDetailsResponse?.data)
        
        let banksResponse = await calloutHelper.getSavedBanks()
        let banksWithDeliveryMethod = banksResponse.data
        setAvailableBanks(banksWithDeliveryMethod)
        configureDeliveryDestinationOptions(banksWithDeliveryMethod)

    }, [setAvailableBanks, setLoggedInDetails])

    const configureDeliveryDestinationOptions = (banks: Bank[]) => {
        const newDestinationOption:deliveryDestinationOption = {value: 'newDestination', description: 'New Delivery Method'}
        const bankOptions: deliveryDestinationOption[] = banks.map(bank => ({ value: bank.bankId || '', description: isFundManagerFull ? `${bank.creditAccountName} - ${bank.bankName}` : bank.bankName }))

        const optionsArray: deliveryDestinationOption[] = [...bankOptions, newDestinationOption]
        setDeliveryDestinationOptions(optionsArray)
    }

    useEffect(() => {
        setShowSpinner(true)
        loadAccountData().finally(() => {
            setShowSpinner(false)
        })
    }, [])

    const mergeSavedFieldsAndFormFields = useCallback<(formFields:Partial<DistributionFullForm>)=>Partial<DistributionFullForm>>((formFields)=>{
        if(formFields.accountId && formFields.accountId !== savedFields.accountId){
            delete savedFields.taxWithholdingPercentage
            delete savedFields.electTaxWithholding
        }
        let mergedFields: Partial<DistributionFullForm> = {...savedFields, ...formFields }
        return mergedFields
    }, [savedFields])

    const submitStep = (navigtionDirection: 'next' | 'back') => () => {
        direction.current = navigtionDirection;
        formRef?.current?.dispatchEvent(new Event('submit', {cancelable: true}))
    }

    const submitFields = useCallback<SubmitDistributionFieldsCallback>((submitState) => {
        if(submitState.status === 'error' && direction.current === 'next') {
            return
        }

        let nextStep: distributionStep = direction?.current === 'back' ? getPreviousArrayMember<distributionStep>(currentStep, stepsArray) : getNextArrayMember<distributionStep>(currentStep, stepsArray)
        if(nextStep === currentStep) {
            return
        }

        let historyContributionToken = currentStep === 'step-one' ? TokenServices.updateTokenFromSession() : contributionTokenFromLocation
        let mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields)

        history.push('/request-distribution', {
            step: nextStep,
            formFields: mergedFields,
            wizardToken: historyContributionToken
        })

    }, [history, direction, currentStep, stepsArray, contributionTokenFromLocation])

    const clearErrorMessage = () => {
        setTimeout(() => {
            setErrorMessage('');
        }, 3000)
    }

    const submitFailed = () => {
        setShowSpinner(false)
        setErrorMessage('There was an issue while trying to create your distribution.');
        clearErrorMessage();
    }

    const submitDistribution = () => {
        scrollToTop();
        setShowSpinner(true);
        
        const submittableForm: SubmittableDistributionForm = changeSavedFieldsToSubmittableForm(savedFields, loggedInDetails); 
        calloutHelper.postDistribution(submittableForm).then((result) => {
            TokenServices.clearTokenFromSession()
            if (result.data.status === 'ok') {
                history.replace('/confirmation', {
                    PendingOrPosted: 'pending',
                    TransactionId: result.data.transactionId,
                    transactionType: isFundManagerFull ? 'Disbursement' : 'Distribution',
                    customTextArray: [
                        'Equity Trust reserves the right to require verbal verification before processing distribution requests. We will contact you if we need verbal verification or further information.',
                        'Requests submitted will be reviewed and processed within 3 business days. You will receive an email confirmation when your distribution request has been successfully processed.'
                    ]
                })
            }
            else if (result.data.status === 'recurring-ok' && result.data.recurringTransactionId) {
                history.push({
                    pathname: '/confirmation',
                    state: {
                        recurringTransactionId: result.data.recurringTransactionId,
                        transactionType: isFundManagerFull ? 'Disbursement' : 'Distribution',
                        customTextArray: [
                            'Equity Trust reserves the right to require verbal verification before processing distribution requests. We will contact you if we need verbal verification or further information.',
                            'Requests submitted will be reviewed and processed within 3 business days. You will receive an email confirmation when your distribution request has been successfully processed.'
                        ]
                    }
                });
            }
            else {
                submitFailed()
            }
        }).catch(err => {
            submitFailed()
        })
    }

    const displayStep = useCallback((step: distributionStep) => {
        let contributionTokenSession = TokenServices.getTokenFromSession()
        if(step !== 'step-one' && contributionTokenSession !== contributionTokenFromLocation) {
            history.replace('/home')
        }

        const componentProps = {formRef, submitFields, savedFields, loggedInDetails, availableBanks}

        const renderStepOne = () => {
            const stepOneProgressRatio = getProgressRatio<distributionStep>('step-one', stepsArray)
            return <DistributionStepOne {...componentProps} wizardCompletionRatio={stepOneProgressRatio} availableAccounts={availableAccounts} setDistributionTypes={setDistributionTypes} deliveryDestinationOptions={deliveryDestinationOptions} setAvailableDeliveryMethods={setAvailableDeliveryMethods} isFundManagerFull={isFundManagerFull} setIs401kAccount={setIs401kAccount} />
        }
        const renderStepTwo = () => {
            const stepTwoProgressRatio = getProgressRatio<distributionStep>('step-two', stepsArray)
            return <DistributionStepTwo {...componentProps} wizardCompletionRatio={stepTwoProgressRatio} distributionTypes={distributionTypes} availableDeliveryMethods={availableDeliveryMethods} isFundManagerFull={isFundManagerFull} is401kAccount={is401kAccount} />
        }
        const renderReview = () => {
            const rewivewStepProgressRatio = getProgressRatio<distributionStep>('review', stepsArray)
            return <DistributionStepReview {...componentProps} wizardCompletionRatio={rewivewStepProgressRatio} tosConfirmed={tosConfirmed} setTOSConfirmed={setTOSConfirmed} isFundManagerFull={isFundManagerFull}/>
        }

        switch(step) {
            case 'step-two':
                return renderStepTwo()
            case 'review':
                return renderReview()
            default: 
                return renderStepOne()
        }
    }, [history, stepsArray, contributionTokenFromLocation, submitFields, availableAccounts])

    useEffect(() => {
        // This is responsible for rerendering the disaplyed step after currentStep has changed.
        scrollToTop()
    }, [currentStep, scrollToTop]) 

    return(
        <IonRow class='container' data-test='dstribution-wizard'>
            {toast !== undefined &&
                <IonToast isOpen={(toast?.message !== undefined && toast?.color !== undefined)} position='top' message={toast?.message} color={toast?.color} duration={1000 * 10} buttons={[{icon: 'close-circle'}]} />
            }
            <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='12'>
                <IonRow>
                    <IonCol class='p-1 light-gr-bg'>
                        <IonRow>
                            <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                                {(errorMessage !== '') &&
                                    (<IonItem class='mt-1' color='danger'>
                                        <p className='white-color'>{errorMessage}</p>
                                    </IonItem>)}
                                {showSpinner ? <Spinner /> : <>
                                    {displayStep(currentStep)}
                                    <IonRow>
                                        <IonCol>
                                            <IonRow>
                                                <IonCol>
                                                    {(currentStep !== 'step-two' || (currentStep === 'step-two' && !is401kAccount)) &&
                                                        <GenericWizardButtons steps={stepsArray} activeStep={currentStep} disableSubmit={!tosConfirmed} handleStep={submitStep} handleSubmit={submitDistribution} />
                                                    }
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </>}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts,
        selectedAccount: rootState.UserState.selectedAccount
    }
}
export default connect(mapStateToProps)(DistributionWizard);