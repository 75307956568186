import React from 'react';
import {
  IonRow,
  IonCol,
} from '@ionic/react';
import Sidebar from './../Sidebar';
import ContainerComponent from './../ContainerComponent';
import { connect } from 'react-redux';
import HoldingList from './HoldingList';

const Holdings: React.FC = () => {
  return (
    <IonRow class="container">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
        <ContainerComponent title="Holdings" content={
          <>
            <HoldingList/>
          </>
        } />
      </IonCol>
      <Sidebar />
    </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
  }
}

export default connect(mapStateToProps)(Holdings);