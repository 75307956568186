import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonItem,
  IonRow,
  IonCol,
} from '@ionic/react';
import {formatNumber, formatDateString} from 'shared-utils';

export interface PostedTrans {
  Account? : {Name?: string, ClientAccount? : {FirstName: string, LastName: string}},
  Cusip? : {Name?: string, AssetName1: string},
  TransactionDate?: string,
  Type?: string,
  NetProceeds?: number,
  MarketValue?: number,
  Units?: number,
  Check?: string,
  CheckStatus?: string,
  Cleared?: string,
  DateStopped?: string,
  DateVoided?: string,
  TaxYear?: string,
  Comment1?: string, 
  Comment2?: string, 
  Comment3?: string, 
  Comment4?: string, 
  Comment5?: string, 
  Comment6?: string, 
}

const PostedTransaction: React.FC<{transaction: PostedTrans}> = ({transaction}) => {
  const [comments, setComments] = useState<any>([]);
  
  useEffect(() => {
    setComments([transaction.Comment1, transaction.Comment2, transaction.Comment3, transaction.Comment4, transaction.Comment5, transaction.Comment6]);
  }, [transaction]);
  
  const checkDetails = () => {
    
    let checkDate;
    if (transaction.DateVoided) {
      checkDate = transaction.DateVoided;
    }
    else if (transaction.DateStopped) {
      checkDate = transaction.DateStopped;
    }
    else if (transaction.Cleared) {
      checkDate = transaction.Cleared;
    } 
    
    let checkDetails;
    if (transaction.CheckStatus) {
      checkDetails = transaction.Check + ' ' + transaction.CheckStatus;  
    }

    if (checkDate) {
      checkDetails += ' ' + formatDateString(checkDate);
    }

    return checkDetails;
  }

  return (  
    <>
      <IonItem data-test='posted-transaction-title' color="tertiary">
        Posted Transaction Details
      </IonItem>
      <IonRow class="p-1">
        <IonCol>
          <p data-test='account-name' className="p-0 m-0"><b>Account</b>: {transaction.Account?.Name} - {transaction.Account?.ClientAccount?.FirstName} {transaction.Account?.ClientAccount?.LastName}</p>
          <p data-test='transaction-date' className="p-0 m-0"><b>Transaction Date</b>: {formatDateString(transaction?.TransactionDate)}</p>
          <p data-test='transaction-type' className="p-0 m-0"><b>Transaction Type</b>: {transaction.Type}</p>
          
          {transaction.MarketValue && 
            <p data-test='transaction-amount' className="p-0 m-0"><b>Transaction Amount</b>: ${formatNumber(transaction.MarketValue?.toFixed(2))}</p>
          }
          {!transaction.MarketValue && 
            <p data-test='transaction-amount' className="p-0 m-0"><b>Transaction Amount</b>: ${formatNumber(transaction.NetProceeds?.toFixed(2))}</p>
          }

          <p data-test='related-asset' className="p-0 m-0"><b>Related Asset</b>: {transaction.Cusip?.Name} - {transaction.Cusip?.AssetName1}</p>
          <p data-test='unit-change' className="p-0 m-0"><b>Unit Change</b>: {transaction.Units?.toFixed(6)}</p>
          <p data-test='check-details' className="p-0 m-0"><b>Check #</b>: {checkDetails()}</p>
          <p data-test='tax-year' className="p-0 m-0"><b>Tax Year</b>: {transaction.TaxYear}</p>
          <p data-test='comments-label' className="p-0 m-0"><b>Comments</b>:</p>
 
            {comments.map((comment:string, index: number) => {
              if (comment && !comment.includes('^TRANS-')) {
                return <p key={index} data-test='comments-1' className="p-1 m-0 light-gr-bg">{comment}</p> 
              }
              return null;
            })}         
        </IonCol>
      </IonRow>
    </>
  );
};

export default PostedTransaction