import React from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
} from '@ionic/react';
import { useForm, Controller } from 'react-hook-form';
import progressBarStepThree from '../../../images/update-asset-step-four.svg';
import { isPhone } from '../../../helpers/Utils';
import { UpdateAssetReviewStep } from '../UpdateAssetWizard';
import FileUpload, { uploadCallBackInput } from '../../helperComponents/FileUpload';

const UpdateAssetStepFour: React.FC<UpdateAssetReviewStep> = ({formRef,handleFinalSubmit, assetValuationForm, files, setFiles}) => {
  const { control, formState: { errors }, handleSubmit, setError, clearErrors } = useForm<Partial<FairMarketValueCase>>({
    mode: 'onChange',
    defaultValues: {...assetValuationForm}
  });

  const onFileChange = async(fileUploadResponse : uploadCallBackInput) => {
    clearErrors('files');
    if(fileUploadResponse.status === 'success'){
      setFiles(fileUploadResponse.files)
    }else{
     setFiles([]);
    }
   }

   const deleteFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files])
  }

  const onSubmit = (data: Partial<FairMarketValueCase>) => {
    clearErrors();
    if (files.length === 0) {
      setError('files', {
        type: 'filesRequired',
        message: 'Please upload a Supporting Document to verify the Market Value Update.'
      })
      return;
    }
    handleFinalSubmit(data);
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepThree} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={1.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol size="12">
              <IonRow>
                <IonCol>
                  <IonRow class="w-100">
                    <h2>Supporting Documentation</h2>
                  </IonRow>
                  <IonRow>
                    <Controller name='valuationSource' control={control} defaultValue={'Appraiser'} render={({ field: { value, onBlur, onChange }}) => {
                        return <IonSelect class="w-100 gr-border p-1" interface="action-sheet" tabIndex={0} mode="ios" name='valuationSource' onIonChange={onChange} onIonBlur={onBlur} value={value}>
                          <IonSelectOption value='Appraiser'>Appraiser</IonSelectOption>
                          <IonSelectOption value='Fund Manager'>Fund Manager</IonSelectOption>
                          <IonSelectOption value='Account Statement'>Account Statement</IonSelectOption>
                          <IonSelectOption value='Public Information'>Public Information</IonSelectOption>
                          <IonSelectOption value='Other'>Other</IonSelectOption>
                        </IonSelect>
                      }
                    } rules={{required: true}} />
                  </IonRow>
                  <IonRow class="mt-2 mb-1">
                    <IonLabel>Valuation Source Details</IonLabel>
                  </IonRow>
                  <IonRow>
                    <Controller name='valuationSourceDetails' control={control} render={({ field: { value, onBlur, onChange } }) =>
                      <IonInput class="ion-text-left gr-border pl-1" name='valuationSourceDetails' value={value} onIonBlur={onBlur} onIonChange={onChange} maxlength={255}/>
                    }/>
                  </IonRow>
                  <IonRow class="mt-2 mb-1">
                    <IonLabel>Supporting Document(s)</IonLabel>
                  </IonRow>
                  <IonRow class="m-0 p-0">
                    <IonCol class="mt-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
                      <FileUpload files={files} filesUploadedCallback={onFileChange} deleteFile={deleteFile} />
                    </IonCol>
                  </IonRow>
                  {errors['files'] &&  (
                    <IonItem class="mt-1" color="danger"><p className="white-color">{(errors['files'] as any).message}</p></IonItem>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </form>
    </>
  );
};

export default UpdateAssetStepFour;