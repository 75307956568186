import React, { useState, useEffect, useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
  IonLabel,
  IonInput,
} from '@ionic/react';
import progressBarThreeStepStepOne from '../../../images/three-steps-step-one.svg';
import { isPhone } from '../../../helpers/Utils';
import { useForm, Controller, FieldErrors, useWatch, FormProvider } from 'react-hook-form';
import { AccountIonSelect } from '../../SelectAccountBar';
import { InputComponents } from '../AdditionalBuyTypes';
import calloutHelper from '../../../helpers/calloutHelpers';
import ConditionalSelect from '../../ConditionalSelect';
import Spinner from '../../Spinner';
import { checkPartyTypeAccessBuilder } from '../../PartyTypeConditionalAccess';
import axios from 'axios';

type AdditionalBuyStepOneType = InputComponents & {
  holding: any,
  fromHolding: boolean
}

interface RelatedAssetOption {
  value: string,
  description: string,
  cusipId: string,
}

type StepOneFields = AdditionalBuyWizard.StepOneFields

const AdditionalBuyStepOne: React.FC<AdditionalBuyStepOneType> = ({holding, fromHolding, formRef, submitFields, savedFields, setErrorMessage, clearErrorMessage}) => {
  const [assetOptions, setAssetOptions] = useState<RelatedAssetOption[]>([]);
  const [additionalBuyEligibleAccounts, setAdditionalBuyEligibleAccounts] = useState<any[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const getDefaultValues = ():StepOneFields => {
    return { 
      accountId: savedFields?.accountId || '',
      holdingId: savedFields?.holdingId || '',
      assetLabel: savedFields?.assetLabel || '',
      cusipId: savedFields?.cusipId || ''
    }
  }
  
  const stepOneForm = useForm<StepOneFields>({
    mode: 'onChange',
    defaultValues: getDefaultValues()
  })

  const { control, formState: { errors }, handleSubmit, getValues, setValue } = stepOneForm;

  const selectedAccountId = useWatch({
    name: 'accountId', 
    control: control, 
    defaultValue: savedFields?.accountId || ''
  })

  useEffect(() => {
    //We clear the holdingId here to make sure it isn't "cached" after switching from an account with available holdings to an account without any
    setValue('holdingId', '')
    
    setAssetOptions(additionalBuyEligibleAccounts.reduce((prevVal, val) => {
      if (val.id === selectedAccountId) {
        val.holdings.forEach((holding: any) => {
          prevVal.push({value: holding.id, cusipId: holding.cusip.id, description: holding.assetDescription})
        })
      }
      return prevVal;
    }, []))
  }, [additionalBuyEligibleAccounts, selectedAccountId])

  useEffect(() => {
    const getAdditionalBuyEligibleAccounts = async () => {
      setShowSpinner(true);
      try
      {
        const additionalBuyEligibleAccountsResult = await calloutHelper.getAccountsWithAdditionalBuyHoldings();
        setAdditionalBuyEligibleAccounts([...additionalBuyEligibleAccountsResult.data]);
        setShowSpinner(false);
      } catch (err) {
        if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
          setAssetOptions([]);
          setErrorMessage('Error retrieving eligible accounts');
          clearErrorMessage();
        }
        setShowSpinner(false);
      }
    };
    getAdditionalBuyEligibleAccounts();
    
  }, [clearErrorMessage, setErrorMessage]);
  
  const setFieldsIfOnlyOneItemExists = useCallback(async () => {
    try {
      if (additionalBuyEligibleAccounts.length === 1) {
        setValue('accountId', additionalBuyEligibleAccounts[0].id)
        if (assetOptions.length === 1) {
          setValue('holdingId', assetOptions[0].value);
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.message !== 'Cancelling in cleanup') {
        setAssetOptions([]);
        setErrorMessage('Error setting accountId and/or holdingId');
        clearErrorMessage();
      }
    }
  }, [additionalBuyEligibleAccounts, assetOptions, setValue, setErrorMessage, clearErrorMessage])

  useEffect(() => {
    setFieldsIfOnlyOneItemExists();
  }, [setFieldsIfOnlyOneItemExists])

  const setAsset = (event: any) => {
    setValue('accountId', event.detail.value, { shouldValidate: true });
  }

  const onValid = (formFields: StepOneFields)=>{
    let selectedAsset = assetOptions.find(asset => asset.value === formFields.holdingId);

    if (selectedAsset) {
      formFields.assetLabel = selectedAsset?.description;
      formFields.cusipId = selectedAsset.cusipId;
    }
    
    submitFields({status: 'ok', formFields})
  }
  
  const onInvalid = (errors: FieldErrors) => {
    submitFields({status: 'error', formFields: getValues(), errors})
  }

  return <FormProvider {...stepOneForm}>
      <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
        <IonRow class={(!isPhone()) ? "divider" : ''}>
          <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
            {(!isPhone()) ? <img src={progressBarThreeStepStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>}
          </IonCol>
        </IonRow>
        <IonRow class="divider">
          <h1 className="ion-text-left">Send funds to {(fromHolding) ? holding.AssetType : 'existing investment'}</h1>
        </IonRow>
        <IonRow class="mt-2 mb-1">
          <IonLabel>Select Account</IonLabel>
        </IonRow>
        <IonRow class="w-100 gr-border p-0 m-0">
          <IonCol size="12" class="w-100 p-0 m-0">
            <Controller name={'accountId'} control={control} render={({ field: { value, name } }) => {
              return showSpinner ? (<Spinner />) : (additionalBuyEligibleAccounts.length === 1) ?
                <IonInput className="ion-text-left pl-1 pr-1" type="text" value={additionalBuyEligibleAccounts[0].accountNumber + ' - ' + additionalBuyEligibleAccounts[0].firstName + ' ' + additionalBuyEligibleAccounts[0].lastName + ' - ' + additionalBuyEligibleAccounts[0].accountType} readonly={true} /> :
                <AccountIonSelect accountID={value} name={name} availableAccounts={additionalBuyEligibleAccounts || []} accountFilter={checkPartyTypeAccessBuilder('createAdditionalBuy')} onChangeHandler={setAsset}/> 
            }} rules={{required: {
              value: true,
              message:'Please select an account.'
            }}} />
          </IonCol>
        </IonRow>
        {errors['accountId'] && <IonItem class="mt-1" color="danger"><p className="white-color">{errors['accountId'].message}</p></IonItem>}
        {assetOptions?.length > 0 && selectedAccountId &&  
          <IonRow class="w-100">
            {selectedAccountId && <ConditionalSelect controllerName="holdingId" title="Related Asset" errorMessage="Please select a related asset." dataList={assetOptions} />}
          </IonRow>
        }
      </form>
    </FormProvider>;
};
export default AdditionalBuyStepOne;