import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AuthCheck from './components/AuthComponents/AuthCheck'
import App from './App';
import { registerWebPlugin, Capacitor } from "@capacitor/core";
import { OAuth2Client } from '@byteowls/capacitor-oauth2'
import axios from 'axios'
import { API_SERVER } from './helpers/authHelpers/authConstants'
import VersionCheck from './components/VersionCheck';
import ReduxInitializer from './components/ReduxInitializer';

if(Capacitor.isNative){
    let apiServer = API_SERVER 
    axios.defaults.baseURL = apiServer
}
registerWebPlugin(OAuth2Client)



ReactDOM.render(<VersionCheck><AuthCheck><ReduxInitializer><App/></ReduxInitializer></AuthCheck></VersionCheck>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
