import { Plugins, FilesystemDirectory, Browser } from '@capacitor/core'
import {FileOpener} from '@ionic-native/file-opener'
import CalloutHelpers from '../helpers/calloutHelpers'
const Filesystem = Plugins.Filesystem

const DEFAULT_TYPE = 'application/octet-stream'

export const openFileInWebMobile = async (attachmentId: string, fileName: string) =>{ 
        try{
            let response = await CalloutHelpers.getFileDirectly(attachmentId)
            let mimetype = response.headers['content-type'];
            let blob = new Blob([response.data], {type: mimetype})
            let fileSource = window.URL.createObjectURL(blob)
            
            const anchor = document.createElement('a');
            anchor.href = fileSource
            anchor.download = fileName
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        }catch(err){
            //should prompt the user that this has happened
        }
}

export const openFileInWeb =  async (attachmentId:string, mimetype: string) =>{
    try{
        let response =  await CalloutHelpers.getFileLink(attachmentId)
        let anchor = document.createElement('a')
        anchor.href = response.data.url
        anchor.target = '_blank'
        if(mimetype === DEFAULT_TYPE){
            anchor.download = ''
            anchor.target = ''
        }
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }catch(err){
      //noop
    }
}

export const openSnapshotInWeb = async (accountId, snapshotLengthInDays) => {
  try {
    const response = await CalloutHelpers.getPdfSnapshotLink(accountId, snapshotLengthInDays);
    const anchor = document.createElement('a');
    anchor.href = response.data.url;
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (err) {
    //noop
  }
}

export const openFileNatively = async (attachmentId: string, fileName: string, mimetype: string) => {
    //showPrompt()
    if(mimetype === DEFAULT_TYPE){
      let response = await CalloutHelpers.getFileLink(attachmentId)
      Browser.open(response.data)
      return
    }
    let response = await CalloutHelpers.getFileDirectly(attachmentId)
    //need to check mimetype and do something different
    toBase64(response.data, writeFileAndOpenOnMobileCallback(fileName, mimetype, attachmentId), (error)=>{})
}

const toBase64 = (file, callback, error) => {
  const reader = new FileReader();
  reader.readAsDataURL(file)
  reader.onload = ()=>callback(reader.result)
  reader.onerror = ()=>error(reader.error)
}

const writeFileAndOpenOnMobileCallback = (fileName, mimetype, attachmentId) => async (data) => {
  let file;
  try{
    file = await Filesystem.writeFile({data, path: fileName, directory: FilesystemDirectory.Cache});
    let openContext = await openFileWithMobile(file.uri, mimetype);
  }catch(err){
    //fallback, download file in browser
    if(file.uri){
      try{
        await promptOpenFileWithMobile(file.uri, mimetype);

      }catch(err){
        let response = await CalloutHelpers.getFileLink(attachmentId)
        Browser.open(response.data)
      }
    }else{
      let response = await CalloutHelpers.getFileLink(attachmentId)
      Browser.open(response.data)
    }
    //Browser.open(file.uri)
  }
 
}

const openFileWithMobile = (uri: string, mimetype) => {
  return FileOpener.open(uri, mimetype)
}

const promptOpenFileWithMobile = (uri: string, mimetype) => {
  return FileOpener.showOpenWithDialog(uri, mimetype)
}
