import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router'
import {
  IonRow,
  IonCol,
  IonItem,
  IonAlert,
  IonModal,
  IonContent,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import TradingAccountWithdrawalStepOne from './TradingAccountWithdrawalStepComponents/TradingAccountWithdrawalStepOne';
import TradingAccountWithdrawalStepTwo from './TradingAccountWithdrawalStepComponents/TradingAccountWithdrawalStepTwo';
import TradingAccountWithdrawalReview from './TradingAccountWithdrawalStepComponents/TradingAccountWithdrawalReview';
import { SubmitFieldsCallback, steps } from './TradingAccountWithdrawalStepComponents/TradingAccountWithdrawalTypes';
import calloutHelper from '../helpers/calloutHelpers';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import { GenericWizardButtons } from './WizardButtons';
import { TokenServices } from '../helpers/wizardHelpers';
import axios from 'axios';

type FullForm = TradingAccountWithdrawalWizard.FullForm

const TradingAccountWithdrawalWizard: React.FC<{availableAccounts: Array<RetirementAccount>, scrollToTop: Function}> = ({scrollToTop, availableAccounts}) => {
  /**
   * Initialized
   * If wizzard is not initialized, no matter what step you're on you get redirected to step one which flips the init state
   * Then you can travel to whatever step you wish
   * Initialize must be reset on 
   */

  const direction = useRef<'next'|'back'>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [TOSConfirmed, setTOSConfirmed] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showOptionsAlert, setShowOptionsAlert] = useState(false);
  const [showTransferInstructions, setShowTransferInstructions] = useState(false);
  const [transId, setTransId] = useState('');

  const history: any = useHistory()
  const stepsArray: Array<steps> = ['step-one',  'step-two' , 'review'];
  const step: steps = history?.location?.state?.step || 'step-one';
  const savedFields = history?.location?.state?.formFields || {};
  const tradingAccountWithdrawalTokenFromLocation = history?.location?.state?.wizardToken || null
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const getForwardStep = useCallback(() => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === stepsArray.length - 1){
      return stepsArray[stepPosition];
    }

    let nextStep = stepPosition + 1;
    return stepsArray[nextStep];
  }, [step, stepsArray])

  const getPrevStep = useCallback( () => {
    let stepPosition = stepsArray.indexOf(step);
    if(stepPosition === 0){
      return stepsArray[stepPosition];
    }

    let prevStep = stepPosition - 1;
    return stepsArray[prevStep];
  }, [step, stepsArray])

  useEffect(() => {
    scrollToTop();
  }, [step, scrollToTop]);

  const mergeSavedFieldsAndFormFields = useCallback<(formFields:Partial<FullForm>)=>Partial<FullForm>>((formFields)=>{
    const stepOneFieldMerge = () => {
      let mergedFields: Partial<FullForm> = {...savedFields}
      
      mergedFields = {...mergedFields, ...formFields}
      return mergedFields
    }

    switch(step){
      case 'step-one':
        return stepOneFieldMerge()
      default:
        return {...savedFields, ...formFields}
    }
  },[step, savedFields])
  
  const incrementStep = useCallback<SubmitFieldsCallback>((submitState) => {
    if(submitState.status === 'error' && direction.current === 'next'){
      return;
    }
    
    let newStep = direction.current === 'next' ? (getForwardStep()) : (getPrevStep())

    if(step === newStep){
      return;
    }

    let mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields)
    let historyTradingAccountWithdrawalToken = step === 'step-one' ? TokenServices.updateTokenFromSession() : tradingAccountWithdrawalTokenFromLocation

    history.push('/trading-account-withdrawal',{
      step: newStep,
      formFields: mergedFields,
      wizardToken: historyTradingAccountWithdrawalToken,
    })
  }, [step, history, getPrevStep, getForwardStep, mergeSavedFieldsAndFormFields, tradingAccountWithdrawalTokenFromLocation]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000)
  }

  const submitStep = (go: 'next'|'back') => () => {
    direction.current = go
    if (step === 'review') {
      return incrementStep({ 'status': 'ok', formFields: savedFields })
    }

    if (formRef && formRef.current) {
      
      formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
    }
  }

  const displayStep = useCallback((currentStep: steps) => {
    let step = currentStep;
    let tradingAccountWithdrawalTokenSession = TokenServices.getTokenFromSession()
    const componentProps = {formRef, submitFields: incrementStep, savedFields}

    if(step !==  'step-one' && tradingAccountWithdrawalTokenSession !== tradingAccountWithdrawalTokenFromLocation){
      history.replace('/home')
    }

    const renderStepTwo = () => {
      return <TradingAccountWithdrawalStepTwo setErrorMessage={() => {}} clearErrorMessage={() => {}} {...componentProps}/>
    }

    const renderReview = () => { 
      let accountId = availableAccounts.find((account)=>{
        return account.id === savedFields?.accountId
      })
      let retirementAccountLabel = `${accountId?.accountNumber} - ${accountId?.firstName} ${accountId?.lastName} - ${accountId?.accountType}`
      
      return <TradingAccountWithdrawalReview retirementAccountLabel={retirementAccountLabel} setTOSState={setTOSConfirmed} tosState={TOSConfirmed} savedFields={savedFields}/>
    }
    //return components[step]
    switch(step){
      case 'step-two':
        return renderStepTwo();
      case 'review':
        return renderReview();
      default:
        return <TradingAccountWithdrawalStepOne setErrorMessage={() => {}} clearErrorMessage={() => {}} availableAccounts={availableAccounts} {...componentProps}/>
    }
  }, [availableAccounts, savedFields, incrementStep, TOSConfirmed, tradingAccountWithdrawalTokenFromLocation, history])
  
  const submitFailed = () => {
    setShowSpinner(false)
    setErrorMessage('There was an issue while trying to create your trading account withdrawal.');
  }

  const goToTransactionDetails = (transactionId: string) => {
    scrollToTop();
    history.replace('/confirmation', {
      PendingOrPosted: 'pending',
      TransactionId: transactionId,
      transactionType: 'Trading Account Withdrawal Request',
      customTextArray: [
        'Most trading companies will deliver the funds to your IRA by wire transfer within 2-5 business days after this request has been submitted.',
        'We will contact you if we need further information. You will receive an email confirmation when funds have been deposited to your IRA cash account.'
      ]
    });
  }

  const goToDistributionRequest = () => {
    scrollToTop();
    history.replace('/request-distribution', {
      formFields: {accountId: savedFields?.accountId},
      Toast: {message: 'Your trading account withdrawal was successfully submitted.', color: 'success'}
    });
  }

  const submitTradingAccountWithdrawal = async () => {
    clearErrorMessage();
    scrollToTop();
    setShowSpinner(true);
    const account = availableAccounts.find(account => account.id === savedFields.accountId)
    try {
      await calloutHelper.postTradingAccountWithdrawal(savedFields, (account?.pendingTAWs || 0) + 1).then((result) => {
        if (result.data) {
          setTransId(result.data);
          updateAvailableAccountPendingTAWs(account)
          setShowSpinner(false);
          if (savedFields.distributionRequest === 'Yes') {
            return goToDistributionRequest();
          } else {
            return goToTransactionDetails(result.data);
          }
        } else {
          submitFailed();
        }
      })
    }
    catch (err) {
      if(axios.isAxiosError(err) && err.response?.data){
        setErrorMessage(err.response?.data);
      } else {
        setErrorMessage('Error submitting trading account.')
      }
      setShowSpinner(false);
    }
  }

  const updateAvailableAccountPendingTAWs = (account: RetirementAccount | undefined) => {
    if(account) {
      account.pendingTAWs = account.pendingTAWs + 1
    }
  }

  return (
      <IonRow class="container" data-test="bill-pay">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
          <IonRow>
            <IonCol class="p-1 light-gr-bg">
              <IonRow>
                <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                  {(errorMessage !== '') &&
                  (<IonItem class="mt-1" color="danger">
                    <p className="white-color">{errorMessage}</p>
                    </IonItem>)}
                    {showSpinner ? <Spinner/> :<>
                      {displayStep(step)}
                      <IonRow>
                        <IonCol>
                          <IonRow>
                            <IonCol>
                              <GenericWizardButtons steps={stepsArray} activeStep={step} disableSubmit={!TOSConfirmed} handleStep={submitStep} handleSubmit={submitTradingAccountWithdrawal}/>
                            </IonCol>
                          </IonRow>
                          <IonModal cssClass={'user-agreement-modal'} backdropDismiss={false} isOpen={showTransferInstructions} onDidDismiss={() => setShowTransferInstructions(false)} mode="ios">
                            <IonContent>
                              <p className="text-left pl-3 pr-3">In order to transfer funds to another IRA custodian, you will need to contact the receiving custodian and complete their required transfer paperwork. You will need to return the transfer paperwork to the custodian for them to sign. They will forward the completed paperwork to us for processing. The receiving custodian may require a recent statement of your Equity Trust account. You can pull this from your Equity Trust client portal under the documents tab.</p>

                              <p className="text-left pl-3 pr-3">The information they will likely ask for on the transfer form:<br/>
                              Current Custodian: Equity Trust<br/>
                              Account Number:  Please put your 7 digit Equity Trust account number<br/>
                              Overnight Address: 15671 San Carlos Blvd #101, Fort Myers, FL 33908<br/>
                              Mailing Address: PO Box 07520, Fort Myers, FL 33919<br/>
                              Phone Number: 239-333-4863<br/>
                              Fax Number: 239-466-5496<br/>
                              Email: transferservices@midlandtrust.com</p>

                              <p className="text-left pl-3 pr-3">You can let the receiving custodian know they can fax the transfer form over to us as we do not need originals and do not require a medallion signature guarantee.</p>

                              <p className="text-left pl-3 pr-3">See Equity Trust <IonRouterLink href="https://www.midlandtrust.com/forms/open-an-account/Fee-Schedule-1.pdf" target="_blank">fee schedule</IonRouterLink> for fees associated with transfers/distributions and closing your account.</p>
                            </IonContent>
                            <IonButton onClick={() => goToTransactionDetails(transId || '')}>Continue</IonButton>
                          </IonModal>
                        </IonCol>
                      </IonRow>
                    </>}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts //could either apply filter here, in a const up top or in the selection box. I'm leaning toward the front two
  }
}

export default connect(mapStateToProps)(TradingAccountWithdrawalWizard);