import React from 'react'
import { IonCol, IonProgressBar, IonRow } from '@ionic/react'
import { isPhone } from '../helpers/Utils'

interface ProgressBar {
    progressBarImage: string,
    progressRatio: number
}

const WizardProgressBar: React.FC<ProgressBar> = ({progressBarImage, progressRatio}) => {
    return (
        <IonRow class={(!isPhone()) ? "divider" : ''}>
            <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
                {(!isPhone()) ? <img src={progressBarImage} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={progressRatio}></IonProgressBar>}
            </IonCol>
        </IonRow>
    )
}

export default WizardProgressBar