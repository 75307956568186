import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonCol, IonItem, IonRow } from '@ionic/react';
import TransferStepOne from './TransferSteps/TransferStepOne';
import TransferStepTwo from './TransferSteps/TransferStepTwo';
import TransferStepThree from './TransferSteps/TransferStepThree';
import TransferReview from './TransferSteps/TransferReview';
import TransferSign from './TransferSteps/TransferSign';
import Spinner from './Spinner';
import { TransferHistory, TransferStep, TransferFullForm, SubmitTransferFieldsCallback, SubmittableTransferForm, AltPayloads, CustodiansList } from './TransferFields/TransferWizardTypes';
import { GenericWizardButtons } from './WizardButtons';
import { useHistory } from 'react-router';
import { TokenServices } from '../helpers/wizardHelpers'
import calloutHelper from '../helpers/calloutHelpers';
import { connect } from 'react-redux';
import { changeSavedFieldsToSubmittableForm } from '../helpers/transferWizardHelpers';
import { getPreviousArrayMember, getNextArrayMember, getProgressRatio } from '../helpers/wizardHelpers';

const TransferWizard: React.FC<{ scrollToTop: Function, availableAccounts: RetirementAccount[] }> = ({scrollToTop, availableAccounts}) => {
    const [ loggedInDetails, setLoggedInDetails ] = useState<PersonAccountHelpers.LoggedInDetails>();
    const [ custodiansList, setCustodianList ] = useState<CustodiansList>([]);
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
    const [ showSubmitLodingText, setShowSubmitLodingText ] = useState<boolean>(false);
    const [ files, setFiles ] = useState<Array<SFFile>>([]);
    const [ signStep, setSignStep ] = useState<{signRequired: boolean, signatureUrl: string, signatureType: string}>({signRequired: false, signatureUrl: '', signatureType: ''});

    const history = useHistory<TransferHistory>();
    const transferTokenFromLocation = history?.location?.state?.wizardToken || null;
    const savedFields = history?.location?.state?.formFields || {};

    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
    const direction = useRef<'next' | 'back'>();
    const stepsArray : Array<TransferStep> = ['step-one', 'step-two', 'step-three', 'review'];
    const currentStep : TransferStep = history?.location?.state?.step || 'step-one';

    const loadAccountData = useCallback(async () => {
        let personDetailsResponse = await calloutHelper.getLoggedInPersonAccount();
        setLoggedInDetails(personDetailsResponse?.data);
    }, [setLoggedInDetails]);

    const loadCustodiansData = useCallback(async () => {
        let custodianResponse = await calloutHelper.getAllCustodians();
        setCustodianList(custodianResponse.data[0]);
    }, [setCustodianList]);

    useEffect(() => {
        setShowSpinner(true);
        loadAccountData()
        .finally(() => {
            loadCustodiansData();
        })
        .finally(() => {
            setShowSpinner(false);
        });
    }, []);

    const mergeSavedFieldsAndFormFields = useCallback<(formFields: Partial<TransferFullForm>, altPayload?: AltPayloads) => Partial<TransferFullForm>>((formFields, altPayload) => {
        const persistFiles = () => {
            if (altPayload?.type === 'files') {
                setFiles(altPayload.files || []);
            };
        };
        persistFiles();
        let mergedFields: Partial<TransferFullForm> = {...savedFields, ...formFields};
        return mergedFields;
    }, [savedFields, setFiles]);

    const submitStep = (navigationDirection: 'next' | 'back') => () => {
        direction.current = navigationDirection;
        formRef?.current?.dispatchEvent(new Event('submit', {cancelable: true}));
    };

    const submitFields = useCallback<SubmitTransferFieldsCallback>((submitState) => {
        if (submitState.status === 'error' && direction.current === 'next') {
            return;
        };

        let nextStep: TransferStep = direction?.current === 'back' ? getPreviousArrayMember<TransferStep>(currentStep, stepsArray) : getNextArrayMember<TransferStep>(currentStep, stepsArray);
        if (nextStep === currentStep) {
            return;
        };

        let historyTransferToken = currentStep === 'step-one' ? TokenServices.updateTokenFromSession() : transferTokenFromLocation;
        let mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields, submitState.altPayload);

        history.push('/request-transfer', {
            step: nextStep,
            formFields: mergedFields,
            wizardToken: historyTransferToken
        });
    }, [history, direction, currentStep, stepsArray, transferTokenFromLocation]);

    const clearErrorMessage = () => {
        setTimeout(() => {
            setErrorMessage('');
        }, 3000);
    };

    const submitFailed = () => {
        setShowSpinner(false);
        setErrorMessage('There was an issue while trying to create your transfer.');
        clearErrorMessage();
    };

    const submitTransfer = async () => {
        scrollToTop();
        setShowSpinner(true);
        setShowSubmitLodingText(true);

        try {
            const submittableForm: SubmittableTransferForm = changeSavedFieldsToSubmittableForm(savedFields, loggedInDetails, files);
            const response = await calloutHelper.postTransfer(submittableForm, window.location.origin);
    
            setShowSpinner(false);
            setShowSubmitLodingText(false);
            TokenServices.clearTokenFromSession();
            if (response.data.status === 'ok' && response.data.signatureUrl) {
                setSignStep({signRequired: true, signatureUrl: response.data.signatureUrl, signatureType: response.data.signatureType});
            } else {
                submitFailed();
            };
        } catch (err) {
            submitFailed();
        };
    };

    const displayStep = useCallback((step: TransferStep) => {
        let transferTokenSession = TokenServices.getTokenFromSession();
        if (step !== 'step-one' && transferTokenSession !== transferTokenFromLocation) {
            history.replace('/home');
        };

        const componentProps = {formRef, submitFields, savedFields, loggedInDetails};

        const renderStepOne = () => {
            const stepOneProgressRatio = getProgressRatio<TransferStep>('step-one', stepsArray);
            return <TransferStepOne {...componentProps} wizardCompletionRatio={stepOneProgressRatio} availableAccounts={availableAccounts} />
        };

        const renderStepTwo = () => {
            const stepTwoProgressRatio = getProgressRatio<TransferStep>('step-two', stepsArray);
            return <TransferStepTwo {...componentProps} wizardCompletionRatio={stepTwoProgressRatio} availableAccounts={availableAccounts} savedFiles={files} custodiansList={custodiansList}/>
        };

        const renderStepThree = () => {
            const stepThreeProgressRatio = getProgressRatio<TransferStep>('step-three', stepsArray);
            return <TransferStepThree {...componentProps} wizardCompletionRatio={stepThreeProgressRatio} />
        };

        const renderReview = () => {
            const stepReviewProgressRatio = getProgressRatio<TransferStep>('review', stepsArray);
            return <TransferReview {...componentProps} wizardCompletionRatio={stepReviewProgressRatio} availableAccounts={availableAccounts} />
        };

        switch(step) {
            case 'step-two':
                return renderStepTwo()
            case 'step-three':
                return renderStepThree()
            case 'review':
                return renderReview()
            default:
                return renderStepOne()
        };

    }, [history, stepsArray, transferTokenFromLocation, submitFields, availableAccounts, custodiansList, files, loggedInDetails, savedFields])

    useEffect(() => {
        scrollToTop();
    }, [currentStep, scrollToTop]);

    return (
        <IonRow class='container'>
            <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeLg='12' sizeXl='12'>
                <IonRow>
                    <IonCol class='p-1 light-gr-bg'>
                        <IonRow>
                            <IonCol class='pl-3 pr-3 pt-1 pb-3 gr-border white-bg'>
                                {(errorMessage !== '') &&
                                    (<IonItem class='mt-1' color='danger'>
                                        <p className='white-color'>{errorMessage}</p>
                                    </IonItem>)}
                                {showSpinner ? 
                                    <>
                                        {showSubmitLodingText && (
                                            <IonRow class='w-100'>
                                                <h1>Please wait while the final step is prepared...</h1>
                                            </IonRow>
                                        )}
                                        <Spinner />
                                    </>
                                     : <>
                                    {signStep.signRequired ? <TransferSign signatureUrl={signStep.signatureUrl} signatureType={signStep.signatureType}/> :
                                        <>
                                            {displayStep(currentStep)}
                                            <IonRow>
                                                <IonCol>
                                                    <IonRow>
                                                        <IonCol>
                                                            <GenericWizardButtons steps={stepsArray} activeStep={currentStep} disableSubmit={false} handleStep={submitStep} handleSubmit={submitTransfer} submitText={'Next'}/>                                                </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }
                                </>}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )
}

const mapStateToProps = (rootState: RootState)  => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
}

export default connect(mapStateToProps)(TransferWizard);