import React from 'react'
import '../pages/Style.css'
import { IonRow, IonCol } from '@ionic/react'

interface DividedContainer {
    title: string,
    items: Array<{ listKey: string, componentRender: any }>,
    headerContents?: any,
}

const DividedContainer: React.FC<DividedContainer> = ({ title, items, headerContents }) => {
    return (
        <IonRow>
            <IonCol class="mb-2 p-1 light-gr-bg">
                <IonRow class="gr-border">
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pl-3 pb-1 white-bg">
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                                <h2 className="ion-text-left">{title}</h2>
                            </IonCol>
                            <IonCol class="pr-3">
                                {headerContents}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>

                {items?.map((item) =>
                    <IonRow class="gr-border mt-1" key={item.listKey}>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pl-3 pb-1 white-bg">
                            <IonRow>
                                <IonCol class="pr-3">
                                    {item.componentRender}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                )}
            </IonCol>
        </IonRow>
    )
}

export default DividedContainer
