import React from 'react';
import { IonRow, IonCol } from '@ionic/react';
import Documents from '../Documents';
import DocumentsHeld from './DocumentsHeld';
import VaultMovements from './VaultMovements';
import DocCustodyRelatedParties from './DocCustodyRelatedParties';
import DocCustodySidebar from './DocCustodySidebar';

const DocCustodyHome: React.FC = () => {

    return (
        <IonRow class='container'>
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                    <DocumentsHeld />
                    <Documents />
                    <VaultMovements />
                    <DocCustodyRelatedParties />
            </IonCol>
            <DocCustodySidebar />
        </IonRow>
    )
};

export default DocCustodyHome;