import axios from 'axios';

export interface PostedTransactionFilter {
    fromDate?: string,
    toDate?: string,
    Type?: string,
    AssetName1?: string
}

export interface SavedPayee {
    deliveryMethod?: string,
    billPaymentType?: string,
    expenseType?: string,
    payableTo?: string,
    paymentMemo?: string
}

export interface UpdatePaymentMethod {
    accountId: string | undefined,
    paymentMethod: string | undefined,
    creditCardData:{
        creditCardNumber:number,
        expiry:number,
        zipCode:number
    }
}

export const isCalloutError = axios.isAxiosError

const calloutHelper = {
    getFileDirectly: (fileId: string) => axios.get('/api/document/file', {params:{fileId}, responseType: 'blob'}),

    getFileLink: (fileId: string) => axios.get('/api/document/file-link', {params:{fileId}}),

    getDocuments: (accountId?: string) => {
        if(!accountId){
            throw Error('No Id Set')
        }
        return axios.get('/api/document/documents', {params:{accountId}})
    },

    getCaseDocs: (caseId: string) => axios.get('/api/document/case-documents', { params: { caseId } }),

    getPostedTransactions: (accountId: string, filters?: PostedTransactionFilter) => axios.get('/api/transaction/posted-transactions', { params: { accountId, filters } }),

    getPostedTransFilterFormOptions : (accountId: string) => axios.get('/api/transaction/posted-transaction-filter-form-options', {params:{accountId}}),

    getPendingTransactions : (accountIds: string[]) => axios.get('/api/transaction/pending-transactions', {params:{accountIds}}),

    getTransaction: (transactionId: string, type: 'posted'|'pending') => axios.get('/api/transaction', {params:{transactionId, type}}),

    postBillPayment: (billPayment: BillPaymentWizard.ClientBillPaymentBody) => axios.post<CreateTransactionResponse>('/api/transaction/bill-payment', {accountId : billPayment.retirementAccount, billPayment}),

    getSavedPayees: (token?: any) => axios.get<Partial<SavedPayee>[]>('/api/saved-payees', { cancelToken: token }),

    getSavedBanks: () => axios.get<Array<Bank>>('/api/banks'),

    getSavedPayeesAndBanks: (token?: any) => axios.get('/api/payees', { cancelToken: token }),

    deletePayee: (payeeId: string) => axios.delete('/api/payee', {params: {payeeId}}),

    updatePayee: (payeeId: string, payeeData: SavedPayeeFields) => axios.patch('/api/payee', {payeeId, payeeData}),

    getContributionOptions: (accountIds?: Array<string>, taxYear?: string) => {
        if(!accountIds){
            throw Error('No accountId provided')
        }
        return axios.get<{contributionOptions?: Array<ContributionOptions>}>('/api/contribution-options', {params: {accountIds}})
    },

    postTransactionApprovalStatus: (transactionId: string, approvalStatus : TransactionApprovalStatus) => axios.post('/api/transaction/approval-status', {transactionId: transactionId, type: 'pending', approvalStatus: approvalStatus}),

    getTransactionBillingDetails: (transactionId?: string) => axios.get('/api/transaction/billing-details', {params: {transactionId, type: 'pending'}}),

    getBuyTransactionBillingDetails: (holdingId? : string) => axios.get('/api/transaction/buy-billing-details', {params: {holdingId}}),

    getAvailableAccounts: () => axios.get('/api/available-accounts'),

    getRetirementAccountWithCreditCard:(accountId?: number | string) =>axios.get('/api/account/retirement-account-with-credit-card', { params:{ accountId }}),

    getAccessDefinitions: () => axios.get('/api/client-access-definitions'),

    getAccountsWithTradingBankHoldings: () => axios.get('/api/accounts-with-trading-bank-holdings'),

    getAccountsWithAdditionalBuyHoldings: () => axios.get('/api/holding/accounts-with-additional-buy-holdings'),

    getAccountHoldingsForBillPay : (accountId: string, token?: any) => axios.get('/api/holding/account-holdings-for-bill-pay', {cancelToken: token, params:{accountId}}),

    checkRoutingNumber : (routingNumber: string, deliveryMethod: string) => axios.get<BankQueryResponse>('/api/validate-bank-route', { params:{routingNumber, deliveryMethod} }),

    getAccountDetails: (accountId?: string) => {
        if (!accountId) {
            throw Error('No accountId provided');
        }
        return axios.get('/api/account/account-details',{params:{accountId}})
    },

    getFundParticipantDetails: (accountIds?: string[]) => {
        if (!accountIds) {
            throw Error('No accountIds provided');
        }
        return axios.get('/api/account/fund-participant-details', {params: {accountIds}});
    },

    getNonCashHoldings : (accountId? : string) => {
        if (!accountId) {
            throw Error('No accountId provided');
        }
        return axios.get('/api/holding/all-non-cash-holdings', {params: {accountId}});
    },

    getRecurringTransactions : (accountId? : string) => {
        if(!accountId) {
            throw Error('No accountId provided');
        }
        return axios.get('/api/recurring-transaction/account-recurring-transactions', {params: {accountId}});
    },

    getRecurringTransaction : (recurringTransactionId? : string) => {
        if(!recurringTransactionId) {
            throw Error('No recurringTransactionId provided');
        }
        return axios.get('/api/recurring-transaction', {params: {recurringTransactionId}});
    },

    getAllCustodians : () => axios.get('/api/all-custodians'),

    updateRelatedPartyPersonAccount : (personAccountId: string, contactInfo: Partial<RelatedPartyContactInfo>) => axios.post('/api/account/update-related-person-account-info', {personAccountId, contactInfo}),

    updateBeneficiaries : (accountId: string, beneficiaries: EditBeneficiary.ActionableBeneficiary[]) => axios.post('api/account/update-beneficiaries', {accountId, beneficiaries}),

    updateInvestor : (accountIds: string[], personAccountId: string, investorFields: InvestorFields) => axios.patch('/api/account/update-investor', {accountIds, personAccountId, investorFields}),

    updateInvestorParticipation :  (accountIds, personAccountId, accountRelationships) => axios.patch('/api/account/update-investor-participation', {accountIds, personAccountId, accountRelationships}),

    addInvestor : (accountIds: string[], investor: AddInvestorWizard.AddInvestorStepOneFields, accountRelationships: AddInvestorWizard.AccountRelationships) => axios.post('/api/account/add-investor', {accountIds, investor, accountRelationships}),

    updateRecurringTransactionStatus : (recurringTransactionId: string, status: string) => {
        if (!recurringTransactionId) {
            throw Error('No recurringTransactionId provided')
        }
        return axios.patch('api/recurring-transaction/status', {recurringTransactionId, status});
    },

    postContribution: (formData: Partial<ContributionWizard.FullForm>) => axios.post<CreateTransactionResponse>('/api/transaction/contribution', { accountId: formData.retirementAccount, formData }),
    
    postTradingAccountWithdrawal: (formData: Partial<TradingAccountWithdrawalWizard.FullForm>, pendingTAWs: number) => axios.post('/api/transaction/trading-account-withdrawal', { accountId: formData.accountId, formData, pendingTAWs }),

    postAdditionalBuy: (formData: Partial<AdditionalBuyWizard.ClientAdditionalBuyBody>) => {
        return axios.post('/api/transaction/additional-buy', { holdingId: formData.holdingId, formData })
    },

    postDistribution: (formData) => axios.post<CreateTransactionResponse>('/api/transaction/distribution', {accountId: formData.accountId, formData}),

    postTransfer: (formData, urlOrigin: string) => axios.post('/api/transaction/transfer', {accountId: formData.accountId, urlOrigin: urlOrigin, formData}),

    postVaultIn: (formData: VaultInFields) => axios.post('/api/transaction/vault-in', formData),

    postVaultOut: (formData: VaultOutFields) => axios.post('/api/transaction/vault-out', formData),

    //NOTE: Need to pass the type as pending to pass ACL's. We might be able to rework the ACL's to handle this better?
    getTransferSignatureUrl: (transactionId : string, urlOrigin: string) => axios.get('/api/transaction/transfer-signature-url', {params: {transactionId: transactionId, type: 'pending', urlOrigin: urlOrigin}}),

    getBankAndTradingHoldings : (accountId? : string, token?: any) => {
        if (!accountId) {
            throw Error('No accountId provided');
        }
        return axios.get('/api/holding/bank-and-trading-holdings', {cancelToken: token, params:{accountId}});
    },

    getAdditionalBuyFundingInstructions: (holdingId: string, token?: any) => {
        if (!holdingId) {
            throw Error('No holdingId provided');
        }
        return axios.get('/api/holding/get-funding-instructions', { cancelToken: token, params: { holdingId } });
    },

    getLoggedInPersonAccount() {
        return axios.get<PersonAccountHelpers.LoggedInDetails>('/api/person-account/logged-in-person-details')
    },

    updatePersonAccFeedbackOptions: (feedbackOptions: FeedbackOptions) => axios.patch('/api/person-account/feedback-options', { feedbackOptions }),

    getHomeAlerts: () => axios.get<Array<any>>('/api/alert/home-alerts'),

    getPdfSnapshotLink: (accountId? : string, filter?: string ) => {
        if (!accountId) {
            throw Error('No accountId provided');
        }
        return axios.get('/api/pdf/snapshot', {params: {accountId, filter}});
    },

    getPendingAdditionalBuyTransactions: (holdingId: string) => axios.get('/api/transaction/pending-additional-buys', { params: { holdingId } }),

    getPendingTradingAccountWithdrawals: (accountId: string) => axios.get('/api/transaction/pending-trading-account-withdrawals', { params: { accountId } }),
    
    cancelTransaction: (transactionId: string) => axios.patch(`/api/transaction/cancel`, {transactionId}),
    
    sendFeedback: (formFields: Object) => axios.post('/api/email/send-feedback', { formFields: formFields }),

    sendPayerInvitation: (formFields: Object) => axios.post('/api/email/send-payer-invitation', {formFields: formFields}),

    // Should be condensed into one call to update profile.
    updateEmailAddress: (emailAddress: string) => axios.post('/api/updateEmailAddress', { emailAddress }),
    updatePhoneNumber: (phoneNumber: string) => axios.post('/api/updatePhoneNumber', { phoneNumber }),
    updatePassword: (currentPassword: string, newPassword: string) => axios.post('/api/updatePassword', { currentPassword, newPassword }),

    updatePaymentMethod:(payload:UpdatePaymentMethod) =>axios.patch('/api/account/payment-method', payload),
    
    postCreditCardPayment: (payload: CreditCardPayment.CardFormInputs) => axios.post("api/account/credit-card-payment", payload),

    uploadFile: (data: ClientFileUploadRequest) => axios.post('/api/file-upload', data),

    getDocumentHoldings: (accountId: string) => axios.get('/api/holding/document-holdings', { params: { accountId } }),

    createContentDistribution: (cusipId: string, contentDistributionData: ContentDistributionData) => axios.post('/api/holding/content-distribution', {cusipId: cusipId, contentDistributionData: contentDistributionData}),

    createDocDownloadLink: (cusipId: string, contentDistributionData: ContentDistributionData) => axios.post(`/api/holding/document-download-link`, {cusipId: cusipId, contentDistributionData: contentDistributionData})
};

export default calloutHelper;
