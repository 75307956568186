import React, { useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
} from '@ionic/react';
import progressBarStepFour from '../../../images/contribution-wizard-step-review.svg';
import { isPhone } from '../../../helpers/Utils';
import { formatDateString } from 'shared-utils';
import TermsOfServiceComponent, {standardTOS} from '../../TermsOfServiceComponent';

const ContirbutionReview: React.FC<{savedFields?:Partial<ContributionWizard.FullForm>, retirementAccountLabel: string, tosState: boolean, setTOSState(state:boolean):void}> = ({ savedFields, retirementAccountLabel, tosState, setTOSState}) => {
  const amountFormatted = useCallback( () => {
    let currencyFormatter = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});
    return currencyFormatter.format(savedFields?.amount || 0)
  },[savedFields?.amount])

  const formatDate = () => {
    return formatDateString(savedFields?.processDate);  
  }

  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {(!isPhone()) ? <img src={progressBarStepFour} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={1.0}></IonProgressBar>}
        </IonCol>
      </IonRow>
      {
      (<>
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol>
                <IonRow class="w-100">
                  <h1>Review details below.</h1>
                </IonRow>
                <IonRow class="divider">
                  <IonCol size="12">
                    <IonItem color="tertiary">
                      Contribution Details
                    </IonItem>
                  </IonCol>
                  <IonCol size="12">
                    <IonItem lines="none">
                      <p>
                        <b>Account</b>: {retirementAccountLabel}<br />
                        <b>Amount</b>:  {amountFormatted()}<br />
                        <b>Frequency</b>: {savedFields?.frequency}<br />
                        {savedFields?.frequency === 'Recurring' && savedFields?.recurringTransactionData?.duration === 'Until I Cancel' &&
                          <>
                            <b>Duration</b>: {savedFields?.recurringTransactionData?.duration} <br />
                          </>
                        }

                        <b>{(savedFields?.frequency === 'One-Time' || savedFields?.recurringTransactionData?.duration === 'Until I Cancel') ? 'Process Date' : 'First Process Date'}</b>: {formatDate()}<br />
                        {savedFields?.frequency === 'Recurring' && savedFields?.recurringTransactionData?.duration === 'Specified Date' &&
                          <>
                            <b>End Date</b>: {formatDateString(savedFields?.recurringTransactionData?.endDate)}
                          </>
                        }
                      </p>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12">
                    <IonItem color="tertiary">
                      Funding Details
                    </IonItem>
                  </IonCol>
                  <IonCol size="12">
                    <IonItem lines="none">
                      <p>
                        {savedFields?.taxYear &&
                          <>
                            <b>Tax Year</b>: {savedFields?.taxYear}<br />
                          </>
                        }
                        <b>Bank Routing Number</b>:  {savedFields?.routingNumber}<br />
                        <b>Bank Name</b>:  {savedFields?.bankName}<br />
                        <b>Name on Bank Account</b>: {savedFields?.creditAccountName}<br />
                        <b>Bank Account Number</b>:  {savedFields?.creditAccountNumber}<br />
                        <b>Bank Account Type</b>:  {savedFields?.creditAccountType}<br />
                      </p>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <TermsOfServiceComponent modalBodyText={ standardTOS() } checked={ tosState } checkboxEvent={(event)=>{ setTOSState( event.detail.checked) }} modalButtonText="Close" ionFloat="ion-float-right" />
        </>)
      }
    </>
  );
};

export default ContirbutionReview;
