import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
} from '@ionic/react';
import { useHistory } from 'react-router';
import UpdateAssetStepOne from './UpdateAssetSteps/UpdateAssetStepOne';
import UpdateAssetStepTwo from './UpdateAssetSteps/UpdateAssetStepTwo';
import UpdateAssetStepThree from './UpdateAssetSteps/UpdateAssetStepThree';
import UpdateAssetStepFour from './UpdateAssetSteps/UpdateAssetStepFour';
import Spinner from '../Spinner';
import caseCalloutHelper from '../../helpers/calloutHelpers/caseCalloutHelper';
import WizardButtons from '../WizardButtons'
import axios from 'axios';
import { TokenServices } from '../../helpers/wizardHelpers';

export interface UpdateAssetStep {
  formRef: React.RefObject<HTMLFormElement>,
  goToNextStep?: any,
  holding?: Holding,
  assetValuationForm?: Partial<FairMarketValueCase>
}

export interface UpdateAssetReviewStep extends UpdateAssetStep {
  handleFinalSubmit? :any,
  files: Array<SFFile>,
  setFiles: any
}

type fmvStepString = 'holding-info' | 'new-valuation' | 'mv-change-summary' | 'supporting-docs'

const fmvSteps: Array<fmvStepString> = ["holding-info", "new-valuation", "mv-change-summary", "supporting-docs"]; 

const UpdateAssetWizard: React.FC<{selectedAccountId?: string, personAccount?: PersonAccount, scrollToTop: Function}> = ({scrollToTop}) => {
  
  const [step, setStep] = useState<fmvStepString>(fmvSteps[0]); 
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<SFFile>>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [pendingValuationCases, setPendingValuationCases] = useState<Array<any>>([]);

  const history: any = useHistory()
  const holding = history?.location?.state?.holding as Holding;
  const tokenFromLocation = history?.location?.state?.wizardToken || null
  
  const [assetValuationForm, setAssetValuationForm] = useState<Partial<FairMarketValueCase>>({
    holdingId: holding.Id
  })

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    scrollToTop();
  }, [step, scrollToTop]); 

  useEffect(() => {
    if ((history?.location?.state as any).step) {
      setStep((history.location.state as any).step);
    } else {
      setStep(fmvSteps[0])
    }

    if (history?.location?.state?.payload) {
      let payload = history?.location?.state?.payload;
      setAssetValuationForm(prevState => ({
        ...prevState, 
        ...payload
      }))
    }
  }, [history?.location?.state?.step, history])

  useEffect(() => {
    setShowSpinner(true);
    caseCalloutHelper.getCasesForUpdatingAssetValue(holding).then((result) => {
      setPendingValuationCases(result.data);
      setShowSpinner(false);
    }).catch(() => {
      setErrorMessage('Failed retrieving pending valuation cases');
    })
  }, [holding])

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000)
  }

  const goToNextStep = (payload?: Partial<FairMarketValueCase>) => {
    let currentStepIndex = fmvSteps.indexOf(step);
    goToStep(fmvSteps[currentStepIndex + 1], payload);
  } 

  const goToStep = useCallback((newStep: fmvStepString, payload?: Partial<FairMarketValueCase>) => {
    const historyToken = step === 'holding-info' ? TokenServices.updateTokenFromSession() : tokenFromLocation;
    
    let historyState: {
      holding: Holding, 
      step: fmvStepString,
      wizardToken: string,
      payload?: Partial<FairMarketValueCase>,
    } = {
      holding,
      step: newStep,
      wizardToken: historyToken,
      payload: history.location.state.payload
    }

    if (payload) {
      historyState = {...historyState, payload:{...assetValuationForm, ...payload}}
    }

    history.push({
      pathname: '/update-asset',
      state: {...historyState}
    })
  }, [history, holding])

  useEffect(() => {
    const updateFormOnBack = () => {
      let currentStepIndex = fmvSteps.indexOf(step);
      goToStep(fmvSteps[currentStepIndex - 1], assetValuationForm);
    }
    window.addEventListener('onbackbuttonclick', updateFormOnBack);

    return () => {
      window.removeEventListener('onbackbuttonclick', updateFormOnBack);
    }
  }, [goToStep, step])

  const submitForm = () => {
    if (formRef !== null && formRef.current !== null) {
      formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
   }
  }

  const handleSubmit = async (dataFromFinalStep: Partial<FairMarketValueCase>) => {
    setErrorMessage('');

    let fairMarketValueCase: Partial<FairMarketValueCase> = {
      ...assetValuationForm, 
      ...dataFromFinalStep, 
      files
    }

    try {
      scrollToTop(); 
      setShowSpinner(true);
      await caseCalloutHelper.createFairMarketValueCase(fairMarketValueCase, holding.Account.Id); 
      setShowSpinner(false);
      TokenServices.clearTokenFromSession();
      setSuccessMessage('Success! Your valuation update was submitted.');
    }
    catch (err) {
      if(axios.isAxiosError(err) && err.response?.data){
        setErrorMessage(err.response?.data);
      } else {
        setErrorMessage('Error creating case.')
      }
      setShowSpinner(false);
    }
  }

  const props = { formRef, setErrorMessage, clearErrorMessage, scrollToTop, holding, goToNextStep, assetValuationForm, pendingValuationCases }

  const displayStep = () => {
    const tokenSession = TokenServices.getTokenFromSession();
    if (step !== 'holding-info' && tokenSession !== tokenFromLocation) {
      history.replace('/home');
    };

    switch (step) {
      case "holding-info":
        return <UpdateAssetStepOne {...props} setPendingValuationCases={setPendingValuationCases}/>
      case "new-valuation":
        return <UpdateAssetStepTwo {...props}/>
      case "mv-change-summary":
        return <UpdateAssetStepThree {...props}/>
      case "supporting-docs":
        return <UpdateAssetStepFour {...props} handleFinalSubmit={handleSubmit} files={files} setFiles={setFiles}/>
      default:
    }
  }

  return (
    <IonRow class="container" data-test="bill-pay">
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
        <IonRow>
          <IonCol class="p-1 light-gr-bg">
            <IonRow>
              <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                {(errorMessage !== '') &&
                (<IonItem class="mt-1" color="danger">
                  <p className="white-color">{errorMessage}</p>
                  </IonItem>)}
                {(successMessage !== '') && (
                  <IonItem class="mt-1" color="success">
                    <p className="white-color">{successMessage}</p>
                    </IonItem>
                )}
                {(successMessage && holding.Cusip.HoldingsWithUnitsCount === 1) && (
                  <p>Please allow up to five business days for us to process your changes. During this time, the asset will show the old valuation.</p>
                )}
                {(successMessage && holding.Cusip.HoldingsWithUnitsCount && holding.Cusip.HoldingsWithUnitsCount > 1) && (
                  <p>We have received your valuation. As this asset is held by multiple accounts, your valuation will not change until we have a consensus on the value of the asset.</p>
                )}
                {showSpinner ? <Spinner /> : 
                  <>
                    {successMessage === '' && 
                      displayStep()
                    }
                    <IonRow>
                      <IonCol>
                        <IonRow>
                          <IonCol>
                            {(pendingValuationCases.length === 0 && successMessage === '') && (
                              <WizardButtons steps={fmvSteps} activeStep={step} handleSubmit={submitForm} disableSubmit={false} formRef={formRef} />
                            )}
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </>
                }
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};


export default UpdateAssetWizard;
