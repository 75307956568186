import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { IonRow, IonCol, IonList, IonGrid, IonInput, IonSelect, IonSelectOption, IonButton, IonToast } from '@ionic/react';
import { connect } from 'react-redux';
import calloutHelper from '../helpers/calloutHelpers';
import Spinner from './Spinner';
import { isMobile } from '../helpers/Utils';
import EditInvestorInformationModal from './EditInvestorInformationModal';
import { convertStringDateToDate, formatDateToUTCString } from '../helpers/Utils';

type FundParticipantFilters = {
    name?: string,
    fundAccountName?: string,
    active: boolean
}

const formatInactiveDate = (dateToFormat: string) => {
    const date = convertStringDateToDate(dateToFormat);
    let dateString = ''
    if (date) {
        dateString = formatDateToUTCString(date, 'MM-DD-YYYY')
    };
    return dateString;
}

const InvestorRoster: React.FC<{availableAccounts: RetirementAccount[]}> = ({availableAccounts}) => {
    const history = useHistory<{personAccountId?: string, fundAccountsToEdit?: FundAccount[], investorName?: string, toast?: string}>();
    const stateDetail = history.location.state;
    const participationToast = stateDetail?.toast;
    const isFundManagerFull = availableAccounts.some(account => account.partyType === 'Fund Manager - Full');
    const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
    const [ fundParticipants, setFundParticipants ] = useState<FundParticipant[]>([]);
    const [ fundParticipantFilters, setFundParticipantFilters ] = useState<FundParticipantFilters>({ name: '', fundAccountName: '', active: true });
    const [ filteredFundParticipants, setFilteredFundParticipants ] = useState<FundParticipant[]>([]);
    const [ editFundParicipant, setEditFundParicipant ] = useState<{
        id?: string,
        personAccountId?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        mailingStreet?: string,
        mailingCity?: string,
        mailingState?: string,
        mailingPostalCode?: string
    }>({});
    const [ showEditInformationModal, setShowEditInformationModal ] = useState<boolean>(false);
    const [ toast, setToast ] = useState<{showToast: boolean, message: string, color: 'success' | 'danger' | ''}>({showToast: false, message: '', color: ''});

    useEffect(() => {
        if (participationToast === 'success') {
            setToast({showToast: true, message: 'Participation updated.', color: 'success'});
        };

        try {
            setShowSpinner(true);
            const accountIds = availableAccounts.map(account => account.id)
            calloutHelper.getFundParticipantDetails(accountIds).then((result) => {
                setFundParticipants(result.data.fundParticipants);
                setFilteredFundParticipants(result.data.fundParticipants)
                setShowSpinner(false)
            });
        } catch {
            setFundParticipants([]);
            setFilteredFundParticipants([]);
            setShowSpinner(false);
        };
    }, []);

    useEffect(() => {
        let filteredFundParticipants = fundParticipants;

        if (fundParticipantFilters.name) {
            filteredFundParticipants = filteredFundParticipants.filter(fundParticipant => `${fundParticipant.firstName} ${fundParticipant.lastName}`.toLowerCase().includes(fundParticipantFilters.name?.toLowerCase() || ''));
        };

        if (fundParticipantFilters.fundAccountName) {
            filteredFundParticipants = filteredFundParticipants.filter(fundParticipant => fundParticipant.relatedParties.some(relatedParty => {
                return `${relatedParty.RetirementAccount.Name} - ${relatedParty.RetirementAccount.ClientFirstName} ${relatedParty.RetirementAccount.ClientLastName}`.toLowerCase().includes(fundParticipantFilters.fundAccountName?.toLowerCase() || '');
            }));
        }
        
        if (fundParticipantFilters.active) {
            filteredFundParticipants = filteredFundParticipants.filter(fundParticipant => fundParticipant.active);
        } else {
            filteredFundParticipants = filteredFundParticipants.filter(fundParticipant => !fundParticipant.active);
        };
       
        setFilteredFundParticipants(filteredFundParticipants);
    }, [fundParticipantFilters, fundParticipants]);

    const renderFilterInput = (filter: 'name' | 'fundAccountName') => {
        const handleFilterChange = (filterType: 'name' | 'fundAccountName', filterValue: string) => {
            if (filterType === 'name') {
                setFundParticipantFilters(prevState => {
                    return { ...prevState, name: filterValue };
                });
            } else {
                setFundParticipantFilters(prevState => {
                    return { ...prevState, fundAccountName: filterValue }
                });
            };
        };

        return (
            <IonInput class='gr-border p-1' placeholder={filter === 'name' ? 'Search Fund Participants' : 'Search Fund Accounts'} onIonChange={event => handleFilterChange(filter, event.detail.value || '')} />
        )
    };

    const renderActiveSelect = () => {
        return (
            <IonSelect data-testid='active-select' class='gr-border p-1' interface='action-sheet' tabIndex={0} mode="ios" value={fundParticipantFilters.active} onIonChange={event => setFundParticipantFilters(prevState => { return {...prevState, active: event.detail.value} })}>
                <IonSelectOption value={true}>Active</IonSelectOption>
                <IonSelectOption value={false}>Inactive</IonSelectOption>
            </IonSelect>
        )
    }

    const informationModalFields = (fundParticipant: FundParticipant) => {
        return {
            id: fundParticipant.id,
            personAccountId: fundParticipant.personAccountId,
            firstName: fundParticipant.firstName,
            lastName: fundParticipant.lastName,
            email: fundParticipant.email,
            mailingStreet: fundParticipant.mailingStreet,
            mailingCity: fundParticipant.mailingCity,
            mailingState: fundParticipant.mailingState,
            mailingPostalCode: fundParticipant.mailingPostalCode
        }
    };

    const editInformationButton = (editModalFields) => {
        return <IonButton data-testid={`edit-information-button-${editModalFields.id}`} size='small' color='primary' onClick={() => {
            setEditFundParicipant(editModalFields);
            setShowEditInformationModal(true);
        }}>{isMobile() ? 'Edit Contact Info' : 'Edit'}</IonButton>
    };

    const editParticipationButton = (fundParticipant: FundParticipant) => {
        return <IonButton data-testid={`edit-participation-button-${fundParticipant.id}`} size='small' color='primary' onClick={() => {
            const activeRelatedParties = fundParticipant.relatedParties.filter(fundAccount => fundAccount.Active);
            history.push('edit-investor-participation', {
                personAccountId: fundParticipant.personAccountId,
                fundAccountsToEdit: activeRelatedParties,
                investorName: `${fundParticipant.firstName} ${fundParticipant.lastName}`
            });
        }}>{isMobile() ? 'Edit Participation' : 'Edit'}</IonButton>
    };

    const renderInvesterRoster = () => {
        if (!fundParticipants || (fundParticipants.length < 1)) {
            return <p>This account does not have investors.</p>
        };

        return (
            isMobile() ? <IonList>
                <IonRow class='mb-1'>{renderFilterInput('name')}</IonRow>
                <IonRow class='mb-1'>{renderFilterInput('fundAccountName')}</IonRow>
                <IonRow class='mb-1'>{renderActiveSelect()}</IonRow>
                {filteredFundParticipants.map(fundParticipant => {
                    const editInformationModalFields = informationModalFields(fundParticipant);
                    const fundAccounts = fundParticipant.relatedParties.filter(fundAccount => fundParticipantFilters.active ? fundAccount.Active : !fundAccount.Active);

                    return (
                        <IonRow class='divider'>
                            <IonCol>
                                {fundParticipant.firstName && fundParticipant.lastName &&
                                    <IonRow class='mt-1'>
                                        <IonCol size='4'>
                                            <p className='m-0'><b>Name</b>:</p>
                                        </IonCol>
                                        <IonCol size='8'>
                                            <p className='m-0'>{`${fundParticipant.firstName} ${fundParticipant.lastName}`}</p>
                                        </IonCol>
                                    </IonRow>
                                }
                                <IonRow>
                                <IonCol size='4'>
                                            <p className='m-0'><b>Contact Info</b>:</p>
                                        </IonCol>
                                        <IonCol size='8'>
                                            {fundParticipant.email &&
                                                <p className='m-0'>{fundParticipant.email}</p>
                                            }
                                            {fundParticipant.mailingStreet && fundParticipant.mailingCity && fundParticipant.mailingState && fundParticipant.mailingPostalCode &&
                                                <>
                                                    <p className='m-0'>{fundParticipant.mailingStreet}</p>
                                                    <p className='m-0'>{`${fundParticipant.mailingCity}, ${fundParticipant.mailingState} ${fundParticipant.mailingPostalCode}`}</p>
                                                </>
                                            }
                                        </IonCol>
                                </IonRow>
                                {isFundManagerFull &&
                                    <IonRow>
                                        {editInformationButton(editInformationModalFields)}
                                    </IonRow>
                                }
                                {fundAccounts && fundAccounts.length > 0 &&
                                    <IonRow>
                                        <IonCol size='4'>
                                            <p className='m-0'><b>Fund(s)</b>:</p>
                                        </IonCol>
                                        <IonCol size='8'>
                                            {fundAccounts.map(relatedParty => {
                                                
                                                return <>
                                                    <p className='m-0'>{`${relatedParty.RetirementAccount.Name} - ${relatedParty.RetirementAccount.ClientFirstName} ${relatedParty.RetirementAccount.ClientLastName}`}</p>
                                                    {relatedParty?.InactiveDate &&
                                                        <p className='ml-1 mt-0'>{`- Inactive: ${formatInactiveDate(relatedParty.InactiveDate)}`}</p>
                                                    }
                                                </>
                                            })}
                                        </IonCol>
                                    </IonRow>
                                }
                                {isFundManagerFull &&
                                    <IonRow>
                                        {editParticipationButton(fundParticipant)}
                                    </IonRow>
                                }
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonList> :
            <IonGrid data-testid='investor-table'>
                <IonRow class='mb-2'>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        {renderFilterInput('name')}
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        {renderFilterInput('fundAccountName')}
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        {renderActiveSelect()}
                    </IonCol>
                </IonRow>
                <IonRow class='mb-1'>
                    <IonCol size='3'>Name</IonCol>
                    <IonCol size='4'>Contact Information</IonCol>
                    <IonCol size='4'>Fund(s)</IonCol>
                </IonRow>
                {filteredFundParticipants.map(fundParticipant => {
                    const editInformationModalFields = informationModalFields(fundParticipant);
                    const fundAccounts = fundParticipant.relatedParties.filter(fundAccount => fundParticipantFilters.active ? fundAccount.Active : !fundAccount.Active);
                    return (
                        <IonRow>
                            <IonCol size={isFundManagerFull ? '3' : '4'} class='gr-border-thin'>{`${fundParticipant.firstName} ${fundParticipant.lastName}`}</IonCol>
                            <IonCol size='4' class='gr-border-thin'>
                                {fundParticipant.email &&
                                    <IonRow>{fundParticipant.email}</IonRow>
                                }
                                {fundParticipant.mailingStreet && fundParticipant.mailingCity && fundParticipant.mailingState && fundParticipant.mailingPostalCode &&
                                    <>
                                        <IonRow class={fundParticipant.email ? 'mt-1' : ''}>{`${fundParticipant.mailingStreet}`}</IonRow>
                                        <IonRow>{`${fundParticipant.mailingCity}, ${fundParticipant.mailingState} ${fundParticipant.mailingPostalCode}`}</IonRow>
                                    </>
                                }
                                {isFundManagerFull &&
                                    <IonRow>
                                        {editInformationButton(editInformationModalFields)}
                                    </IonRow>
                                }
                            </IonCol>
                            <IonCol size='4' class='gr-border-thin'>
                                {fundAccounts.map(relatedParty => {
                                    return <>
                                        <IonRow>{`${relatedParty.RetirementAccount.Name} - ${relatedParty.RetirementAccount.ClientFirstName} ${relatedParty.RetirementAccount.ClientLastName}`}</IonRow>
                                        {relatedParty?.InactiveDate &&
                                            <IonRow class='ml-2'>{`- Inactive: ${formatInactiveDate(relatedParty.InactiveDate)}`}</IonRow>
                                        }
                                    </>
                                })}
                                {isFundManagerFull &&
                                    <IonRow>
                                        {editParticipationButton(fundParticipant)}
                                    </IonRow>
                                }
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonGrid>
        )
    }

    return (
        <IonRow class='container'>
            {toast.showToast &&
                <IonToast isOpen={toast.showToast} color={toast.color} position='top' message={toast.message} onDidDismiss={() => setToast({...toast, showToast: false})} buttons={[{icon: 'close-circle'}]} />
            }
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                <IonRow>
                    <IonCol class="p-1 light-gr-bg">
                        <IonRow>
                            <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                <IonRow class='d-flex justify-space-between align-items-center'>
                                    <h1>Investor Roster</h1>
                                    {isFundManagerFull &&
                                        <IonButton color='primary' onClick={() => history.push('/add-investor')}>Add Investor</IonButton>
                                    }
                                </IonRow>
                                {showSpinner ? <Spinner /> : renderInvesterRoster()}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
            <EditInvestorInformationModal setShowEditModal={setShowEditInformationModal} showEditModal={showEditInformationModal} fundParticipants={fundParticipants} setFundParticipants={setFundParticipants} filteredFundParticipants={filteredFundParticipants} setFilteredFundParticipants={setFilteredFundParticipants} investorInformation={editFundParicipant} setToast={setToast} />
        </IonRow>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        availableAccounts: rootState.CachedObjects.accounts
    }
};

export default connect(mapStateToProps)(InvestorRoster);