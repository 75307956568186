import React, { useEffect, useState } from 'react';
import {
  IonRow,
  IonCol,
  IonToast,
  IonList,
  IonItem,
  IonButton
} from '@ionic/react';
import Sidebar from './Sidebar';
import TransactionsListLoader from './TransactionsListLoader';
import ToggleComponent from './ToggleComponent';
import ContainerComponent from './ContainerComponent';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import calloutHelpers from '../helpers/calloutHelpers';
import { formatValueAsCurrency } from '../helpers/Utils';

const TransactionsListContainer: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {  
  const history = useHistory();
  const toastHistory = useHistory<{ Toast?: { message: 'Transaction Approved', color: 'success' } }>();
  const toast = toastHistory?.location?.state?.Toast;
  const [ recurringTransactions, setRecurringTransactions ] = useState<RecurringTransaction[]>([]);

  
  useEffect(() => {
    calloutHelpers.getRecurringTransactions(selectedAccount?.id).then(
      result => {
        setRecurringTransactions(result.data);
      }
    );
  }, [selectedAccount])

  const renderRecurringTransactions = () => {

    return recurringTransactions.map((recurringTransaction: RecurringTransaction) => {
      return <IonList key={recurringTransaction.Id}>
        <IonItem key={recurringTransaction.Id}>
          <p>
            <b>Transaction Type</b>: {recurringTransaction.TransactionType} <br />
            <b>Status</b>: {recurringTransaction.Status} <br />
            <b>Frequency</b>: {recurringTransaction.Frequency}<br />
            <b>Amount (Cash Total)</b>: {formatValueAsCurrency(recurringTransaction.AmountCash)} <br />
            <b>To/From</b>: {recurringTransaction.ToFrom} <br />
            <b>Delivery Method</b>: {recurringTransaction.DeliveryMethod} <br />
            <IonButton onClick={() => goToRecurringTransaction(recurringTransaction.Id)} size='small'>More Details</IonButton>
          </p>
        </IonItem>
      </IonList>
    })
  };

  const goToRecurringTransaction = (recurringTransactionId: string) => {
    history.push({
      pathname: '/recurring-transaction',
      state: { recurringTransactionId: recurringTransactionId }
    });
  };
  
  return (
    <>
      <IonToast isOpen={(toast?.message !== undefined && toast.color !== undefined)} color={toast?.color} position='top' message={toast?.message} duration={3 * 1000} buttons={[{ icon: 'close-circle' }]} />
      <IonRow class="container" data-test="component-transactions-list-container">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
          <ContainerComponent title="Pending Transactions" content={
            <TransactionsListLoader pendingOrPosted={'pending'} queryPendingTransactionsBy='selectedAccount' />
          } />
          <ToggleComponent isActive={false} title="Posted Transactions" bodyData={
            <TransactionsListLoader pendingOrPosted={'posted'} queryPendingTransactionsBy='selectedAccount' />
          } />
          {recurringTransactions.length > 0 &&
            <ToggleComponent isActive={false} title="Recurring Transactions" bodyData={
              selectedAccount && selectedAccount?.id && renderRecurringTransactions()
            } />
          }
        </IonCol>
        <Sidebar />
      </IonRow>
    </>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount
  }
}

export default connect(mapStateToProps)(TransactionsListContainer);