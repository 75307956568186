import { FieldErrors } from 'react-hook-form'
import { randomStringGen } from './authHelpers/authServiceHelpers'

export type GenericSubmitFieldsCallback<T, P = undefined, D = undefined> = (submitState: 
    {status: 'ok', formFields: Partial<T>, altPayload?: P} | 
    {status: 'error', formFields: Partial<T>, errors: FieldErrors<Partial<T>>, altPayload?: P} |
    {status: 'goTo', formFields: Partial<T>, altPayload?: P, step: D} |
    {status: 'exit', formFields: Partial<T>, altPayload?: P} 
)=> any | Promise<any> //Should hard type return as generic

export type GenericInputComponents<T, P> = {
    formRef: React.MutableRefObject<HTMLFormElement>,
    submitFields: P,
    savedFields?: Partial<T>
}

export type GenericHistory<S,F> = {step?: S, formFields?: F, wizardToken?: string | null, Toast?: {message: string, color: string}}

export const getProgressRatio = <Type>(currentPosition: Type, allPositions: Array<Type>) => {
    if(!allPositions || allPositions.length === 0) {
        return 0
    }
    const currentPositionIndex = allPositions.findIndex((position) => position === currentPosition)
    const ratio = (currentPositionIndex + 1 * 1.0) / (allPositions.length)
    return ratio
}

const updateTokenFromSession = (WIZARD_TOKEN: string = 'WIZARD_TOKEN') =>{
    let token = randomStringGen()
    sessionStorage.setItem(WIZARD_TOKEN, token)
    return token
}

const getTokenFromSession = (WIZARD_TOKEN: string = 'WIZARD_TOKEN') => sessionStorage.getItem(WIZARD_TOKEN)

const clearTokenFromSession = (WIZARD_TOKEN: string = 'WIZARD_TOKEN') => sessionStorage.removeItem(WIZARD_TOKEN)

export const TokenServices = {
    updateTokenFromSession,
    getTokenFromSession,
    clearTokenFromSession
}
/**
 * I'm hoping more generalized logic like stepping will be moved here.
 */

 export const getNextArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember);
    if (currentMemberIndex === array.length - 1 || currentMemberIndex === -1) {
        return array[currentMemberIndex];
    };
    return array[currentMemberIndex + 1];
};

export const getPreviousArrayMember = <Type>(currentMember: Type, array: Array<Type>) => {
    const currentMemberIndex = array.findIndex((member) => member === currentMember);
    if (currentMemberIndex === 0 || currentMemberIndex === -1) {
        return array[currentMemberIndex];
    };
    return array[currentMemberIndex - 1];
};