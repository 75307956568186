import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  IonRow,
  IonCol,
  IonItem,
} from '@ionic/react';
import AdditionalBuyStepOne from './AdditionalBuySteps/AdditionalBuyStepOne';
import AdditionalBuyStepTwo from './AdditionalBuySteps/AdditionalBuyStepTwo';
import AdditionalBuyReview from './AdditionalBuySteps/AdditionalBuyReview';
import { SubmitFieldsCallback, steps, AltPayloads } from './AdditionalBuyTypes';
import calloutHelper from '../../helpers/calloutHelpers';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { GenericWizardButtons } from '../WizardButtons';
import { TokenServices } from '../../helpers/wizardHelpers';
import axios from 'axios';
import AdditionalBuyPendingTransactionStep from './AdditionalBuySteps/AdditionalBuyPendingTransactionStep';

type FullForm = AdditionalBuyWizard.FullForm

const emptyInstructions: AdditionalBuyFundingInstructions = {
  id: '',
  deliveryMethod: '',
  creditName: '',
  creditAccount: '',
  aba: '',
  bankName: '',
  bankStreet: '',
  bankCity: '',
  bankState: '',
  bankZip: ''
}

const AdditionalBuyWizard: React.FC<{ availableAccounts: Array<RetirementAccount>, scrollToTop: Function }> = ({ scrollToTop, availableAccounts }) => {
  /**
   * Initialized
   * If wizzard is not initialized, no matter what step you're on you get redirected to step one which flips the init state
   * Then you can travel to whatever step you wish
   * Initialize must be reset on 
   */

  const direction = useRef<'next' | 'back'>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [TOSConfirmed, setTOSConfirmed] = useState(false);
  const [files, setFiles] = useState<Array<SFFile>>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [pendingTransactions, setPendingTransactions] = useState<Array<any> | undefined>();
  const [additionalBuyFundingInstructions, setAdditionalBuyFundingInstructions] = useState<AdditionalBuyFundingInstructions>();
  const [includeFundingInstructions, setIncludeFundingInstructions] = useState<boolean>(false);

  const history: any = useHistory();
  const savedFields = history?.location?.state?.formFields || {};
  const additionalBuyTokenFromLocation = history?.location?.state?.wizardToken || null;
  const fromHolding = history?.location?.state?.fromHolding;
  const holding = history?.location?.state?.state?.holding;
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const stepsArray: Array<steps> = fromHolding ? ['step-two', 'review'] : ['step-one', 'step-two', 'review'];
  const step: steps = history?.location?.state?.step || (fromHolding && 'step-two') || 'step-one';

  const getForwardStep = useCallback(() => {
    let stepPosition = stepsArray.indexOf(step);

    if (stepPosition === stepsArray.length - 1) {
      return stepsArray[stepPosition];
    }

    let nextStep = stepPosition + 1;
    return stepsArray[nextStep];
    
  }, [step, stepsArray])

  const getPrevStep = useCallback(() => {
    let stepPosition = stepsArray.indexOf(step);

    if (stepPosition === 0) {
      return stepsArray[stepPosition];
    }

    let prevStep = stepPosition - 1;
    return stepsArray[prevStep];
    
  }, [step, stepsArray])

  useEffect(() => {
    scrollToTop();
  }, [step, scrollToTop]);
  
  useEffect(() => {
      let source = axios.CancelToken.source();
      const getAdditionalBuyFundingInstructions = async (token: any) => {
        try {
          if (!savedFields?.holdingId && !holding?.Id) {
            setAdditionalBuyFundingInstructions(undefined);
            return;
          };
          const additionalBuyOptionsResult = await calloutHelper.getAdditionalBuyFundingInstructions(fromHolding ? holding?.Id : savedFields?.holdingId || '', token);
          const additionalBuyOptions = additionalBuyOptionsResult.data;
          setAdditionalBuyFundingInstructions(additionalBuyOptions);
          
          if (!additionalBuyOptions.deliveryMethod || !additionalBuyOptions.aba || !additionalBuyOptions.creditAccount || !additionalBuyOptions.creditName) {
            setIncludeFundingInstructions(true);
          } 
          else {
            setIncludeFundingInstructions(false);
          }
        } 
        catch (err) {
          setAdditionalBuyFundingInstructions(undefined);
        }
      };

      getAdditionalBuyFundingInstructions(source.token);
      return (() => {
        source.cancel('Cancelling in cleanup');
      });
  }, [fromHolding, savedFields?.holdingId, holding?.Id, step]);

  const mergeSavedFieldsAndFormFields = useCallback<(formFields: Partial<FullForm>, altPayload?: AltPayloads) => Partial<FullForm>>((formFields, altPayload) => {
    const persistFiles = () => {
      if (altPayload?.type === 'files') {
        setFiles(altPayload.files || [])
      }
    }
    const stepOneFieldMerge = () => {
      persistFiles();
      let mergedFields: Partial<FullForm> = { ...savedFields }
      mergedFields = {...mergedFields, ...formFields}
      return mergedFields
    }

    const stepTwoFieldMerge = () => {
      persistFiles();
      return { ...savedFields, ...formFields }
    }

    switch(step){
      case 'step-one':
        return stepOneFieldMerge();
      case 'step-two':
        return stepTwoFieldMerge();
      default:
        persistFiles();
        return {...savedFields, ...formFields}
    }
  },[step, savedFields, setFiles])
  
  const incrementStep = useCallback<SubmitFieldsCallback>((submitState) => {
    if(submitState.status === 'error' && direction.current === 'next'){
      return;
    }

    if (submitState.status === 'exit' && fromHolding) {
      history.push('/holdings')
      return
    }
    
    let newStep = direction.current === 'next' ? (getForwardStep()) : (getPrevStep())

    if(step === newStep){
      return;
    }
    
    let mergedFields = mergeSavedFieldsAndFormFields(submitState.formFields, submitState.altPayload)
  
    let historyAdditionalBuyToken = step === 'step-one' ? TokenServices.updateTokenFromSession() : additionalBuyTokenFromLocation

    history.push('/additional-buy',{
      step: newStep,
      formFields: mergedFields,
      wizardToken: historyAdditionalBuyToken,
      fromHolding: fromHolding,
      state: {
        holding: holding
      }
    })
  }, [getForwardStep, getPrevStep, step, mergeSavedFieldsAndFormFields, additionalBuyTokenFromLocation, history, fromHolding, holding]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 10000)
  }

  const submitStep = (go: 'next' | 'back') => () => {
    direction.current = go
    if (step === 'review') {
      return incrementStep({ 'status': 'ok', formFields: savedFields })
    }

    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', {cancelable : true}));
    }
  }

  const displayStep = useCallback((currentStep: steps) => {
    let step = currentStep;
    const componentProps = { formRef, submitFields: incrementStep, savedFields }

    if(step === 'step-two' && pendingTransactions) {
      step = 'pendingTransaction'
    }

    const renderStepTwo = () => {
      return <AdditionalBuyStepTwo savedFiles={files} holding={holding} fromHolding={fromHolding} setErrorMessage={() => {}} clearErrorMessage={() => {}} includeFundingInstructions={includeFundingInstructions} {...componentProps}/>
    }

    const renderReview = () => { 
      let accountId = availableAccounts.find((account)=>{
        return account.id === savedFields?.accountId
      })
      let retirementAccountLabel = `${accountId?.accountNumber} - ${accountId?.firstName} ${accountId?.lastName} - ${accountId?.accountType}`
      
      return <AdditionalBuyReview additionalBuyFundingInstructions={additionalBuyFundingInstructions} holding={holding} fromHolding={fromHolding} retirementAccountLabel={retirementAccountLabel} setTOSState={setTOSConfirmed} tosState={TOSConfirmed} savedFields={savedFields}/>
    }

    const renderPendingTransaction = () => {
      if(pendingTransactions && pendingTransactions.length > 0) {
        const transaction = pendingTransactions[0]
        const account = availableAccounts.find(account => account.id === transaction.accountId)
        const retirementAccountLabel = account ? `${account.accountNumber} - ${account.firstName} ${account.lastName} - ${account.accountType}` : ''

        const goToTransaction = () => {
          history.push({
              pathname: '/transaction',
              state: {PendingOrPosted: 'pending', TransactionId: transaction.transactionId}
          })
        }

        return transaction.clientApprovalPending ? goToTransaction() : <AdditionalBuyPendingTransactionStep {...componentProps} transaction={transaction} multipleTransactionsFound={pendingTransactions.length > 1} retirementAccountLabel={retirementAccountLabel} />
      }
    }

    //return components[step]
    switch (step) {
      case 'step-two':
        return renderStepTwo();
      case 'review':
        return renderReview();
      case 'pendingTransaction':
        return renderPendingTransaction();
      default:
        return <AdditionalBuyStepOne holding={holding} fromHolding={fromHolding} setErrorMessage={() => { }} clearErrorMessage={() => { }} {...componentProps} />
    }
  }, [incrementStep, savedFields, fromHolding, files, holding, availableAccounts, additionalBuyFundingInstructions, TOSConfirmed])
  
  const submitFailed = () => {
    setShowSpinner(false)
    setErrorMessage('There was an issue while trying to create your additional buy.');
  }

  const submitAdditionalBuy = async () => {
    clearErrorMessage();
    scrollToTop();
    setShowSpinner(true);
    try {
      const additionalBuyBody: AdditionalBuyWizard.ClientAdditionalBuyBody = {
        ...(savedFields as FullForm),
        files: files || []
      }

      let response = await calloutHelper.postAdditionalBuy(additionalBuyBody);

      setShowSpinner(false);
      TokenServices.clearTokenFromSession();
      if (response.status === 200 && response.data) {
        history.push({
              pathname: '/transaction',
              state: {PendingOrPosted: 'pending', TransactionId: response.data}
          })
      } else {
        history.push('/transactions');
      }
    }
    catch (err) {
      submitFailed();
    }
  }


  useEffect(() => {
    setPendingTransactions(undefined)
    if(step === 'step-two') {
      const holdingId = holding?.Id || savedFields?.holdingId
      if(holdingId) {
        setShowSpinner(true)
        getPendingAdditionalBuyTransactions(holdingId).then((result) => {
          if(result && result.length > 0) {
            setPendingTransactions(result)
          }
        }).finally(() => {
          setShowSpinner(false)
        })
      }
    }
  }, [step, fromHolding, holding])

  const getPendingAdditionalBuyTransactions = async (holdingId) => {
    const pendingTransactionResult = await calloutHelper.getPendingAdditionalBuyTransactions(holdingId)
    return pendingTransactionResult?.data
  }

  return (
      <IonRow class="container" data-test="bill-pay">
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
          <IonRow>
            <IonCol class="p-1 light-gr-bg">
              <IonRow>
                <IonCol class="pl-2 pr-2 pt-1 pb-3 gr-border white-bg">
                  {(errorMessage !== '') &&
                  (<IonItem class="mt-1" color="danger">
                    <p className="white-color">{errorMessage}</p>
                    </IonItem>)} 
                    {showSpinner ? <Spinner/> :<>
                      {displayStep(step)}
                      <IonRow> 
                        <IonCol>
                          <IonRow>
                            <IonCol>
                              <GenericWizardButtons submitText="Next" steps={stepsArray} activeStep={step} disableSubmit={false} handleStep={submitStep} handleSubmit={submitAdditionalBuy} backButtonState={pendingTransactions ? 'visible' : undefined} nextButtonState={pendingTransactions ? 'hidden' : undefined}/>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </>}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
  );
};

const mapStateToProps = (rootState: RootState) => {
  return {
    availableAccounts: rootState.CachedObjects.accounts //could either apply filter here, in a const up top or in the selection box. I'm leaning toward the front two
  }
}

export default connect(mapStateToProps)(AdditionalBuyWizard);