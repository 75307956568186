import React from 'react';
import {
    IonRow,
    IonCol,
} from '@ionic/react';
import { useHistory } from 'react-router';
import calloutHelper from '../helpers/calloutHelpers';
import RecurringTransaction from './RecurringTransaction';
import ContainerComponent from './ContainerComponent';
import Sidebar from './Sidebar';
import DataLoader from './DataLoader';

const RecurringTransactionDetails: React.FC = () => {
    const history = useHistory<{recurringTransactionId?: string}>();
    const stateDetail = history.location.state;

    const renderRecurringTransaction = (recurringTransaction : any) => {
        if (!stateDetail || !recurringTransaction) return;
        const title = 'Recurring Transaction';
        const content = <RecurringTransaction recurringTransaction={recurringTransaction}/>;
        const props = {title, content}
        return <ContainerComponent {...props}/>  
    }
    
    return (
        <IonRow class='container'>
            <IonCol class='p-1' sizeXs='12' sizeSm='12' sizeMd='9' sizeLg='9' sizeXl='9'>
                {stateDetail?.recurringTransactionId &&
                    <DataLoader apiCall={() => calloutHelper.getRecurringTransaction(stateDetail?.recurringTransactionId as string)} render={renderRecurringTransaction} dependencies={[stateDetail.recurringTransactionId]}/>
                }
            </IonCol>
            <Sidebar />
        </IonRow>
    )
}

export default RecurringTransactionDetails;