import React, { useEffect, useState } from 'react';
import { useForm, Controller, Control, RegisterOptions, SubmitHandler, FormProvider } from 'react-hook-form';
import { IonModal, IonRow, IonLabel, IonInput, IonItem, IonButton, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import calloutHelper from '../helpers/calloutHelpers';
import StateSelector from './StateSelector';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import { isDarkMode } from '../helpers/Utils';

const EditBankPayeeModal: React.FC<{setShowEditModal: Function, showEditModal: boolean, bankOrPayee: {savedType: 'payee' | 'bank' | '', payeeOrBankId: string}, savedPayee: any, setSavedPayeeLists: Function, savedPayeeLists: {savedPayeesResult: SavedPayee[], savedBanksResult: SavedBankFields[]}, setToastMessage: Function, setShowToast: Function}> = ({setShowEditModal, showEditModal, bankOrPayee, savedPayee, setSavedPayeeLists, savedPayeeLists, setToastMessage, setShowToast}) => {
    const [ submittingSpinner, setSubmittingSpinner ] = useState<boolean>(false);
    const [ submitError, setSubmitError ] = useState<'Please input at least one change to update.' | ''>('')

    const getButtonColor = () => isDarkMode() ? 'primary' : 'secondary'

    const getDefaultValues = (): SavedPayeeFields => {
        return {
            name: savedPayee?.name || '',
            payableTo: savedPayee?.payableTo || '',
            deliveryMethod: savedPayee?.deliveryMethod || '',
            mailingStreet: savedPayee?.mailingStreet || '',
            mailingCity: savedPayee?.mailingCity || '',
            mailingState: savedPayee?.mailingState || '',
            mailingZip: savedPayee?.mailingZip || '',
            bankName: savedPayee?.bankName || '',
            routingNumber: savedPayee?.routingNumber || '',
            creditAccountName: savedPayee?.creditAccountName || '',
            creditAccountNumber: savedPayee?.creditAccountNumber || '',
            creditAccountType: savedPayee?.creditAccountType || '',
            paymentMemo: savedPayee?.paymentMemo || '',
            billPaymentType: savedPayee?.billPaymentType || '',
            expenseType: savedPayee?.expenseType || '',
            CO: savedPayee?.CO || ''
        }
    }

    const fieldToLabel: {[key in keyof SavedPayeeFields]: string} = {
        name: 'Name',
        payableTo: 'Payable To',
        deliveryMethod: 'Delivery Method',
        mailingStreet: 'Mailing Street',
        mailingCity: 'Mailing City',
        mailingState: 'Mailing State',
        mailingZip: 'Mailing Zip',
        bankName: 'Bank Name',
        routingNumber: 'Routing Number',
        creditAccountName: 'Name on Bank Account',
        creditAccountNumber: 'Bank Account Number',
        creditAccountType: 'Bank Account Type',
        paymentMemo: 'Payment Memo',
        billPaymentType: 'Bill Payment Type',
        expenseType: 'Expense Type',
        CO: 'CO'
    }
    
    const methods = useForm<SavedPayeeFields>({
        mode: 'onChange',
        defaultValues: getDefaultValues()
    });

    const deliveryMethod = methods.watch('deliveryMethod');
    const billPaymentType = methods.watch('billPaymentType');

    const numberFilter = /\d/

    useEffect(() => {
        methods.reset(getDefaultValues())
    }, [showEditModal])

    useEffect(() => {
        if (billPaymentType !== 'Bill Payment') {
            methods.setValue('expenseType', '');
            methods.clearErrors('expenseType');
        }
    }, [billPaymentType])

    const validateRoutingNumber = async (event, onChange: Function) => {
        onChange(event)
        const routingNumber = event.detail.value
        try {
            if (routingNumber && routingNumber.length <= 9) {
                methods.setValue('routingNumber', routingNumber)
            }
            if(routingNumber && routingNumber.length !== 9) {
                methods.setValue('bankName', '');
            }
            if (routingNumber && routingNumber.length === 9) {
                const checkRoutingNumberResult = await calloutHelper.checkRoutingNumber(routingNumber, deliveryMethod);
                if (checkRoutingNumberResult.data.status === 'Valid') {
                    methods.setValue('bankName', checkRoutingNumberResult.data.bankName);
                    methods.clearErrors('routingNumber');
                    methods.clearErrors('bankName')
                } else if (checkRoutingNumberResult.data.status === 'Invalid') {
                    let errorMessage = 'Routing numbers need a valid delivery method.'
                    if (deliveryMethod.includes('wire')) {
                        errorMessage = 'The provided routing number cannot be used for wire transfers.';
                    } else if (deliveryMethod.includes('direct')) {
                        errorMessage = 'The provided routing number cannot be used for direct deposits.';
                    };
                    methods.setError && methods.setError('routingNumber', {type: 'validate', message: errorMessage});
                    methods.setValue('bankName', '');
                } else {
                    methods.setError && methods.setError('routingNumber', {type: 'validate', message: 'Invalid routing number, try a different one.'});
                    methods.setValue('bankName', '');
                };
            }
        } catch (err) {
            methods.setError && methods.setError('routingNumber', {type: 'validate', message: 'Failed to look up routing number.'});
            methods.setValue('bankName', '');
        };
    };
    
    const FieldInput: React.FC<{
        control: Control<SavedPayeeFields>,
        name: keyof SavedPayeeFields,
        rules?: RegisterOptions<SavedPayeeFields>,
        readOnly?: boolean,
        characterFilter?: RegExp,
    }> = ({control, name, rules, readOnly, characterFilter}) => {
        const label = fieldToLabel[name]

        return <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => 
                <>
                    <IonRow class='mt-1 mb-1 ml-1 mr-1'>
                        <IonLabel><b>{`${label}: `}</b></IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonInput class={readOnly ? 'edit-input ml-1 mr-1 remove-focus' : 'gr-border edit-input ml-1 mr-1'} mode='ios' type='text' readonly={readOnly} value={field.value} ref={field.ref} name={field.name} onIonBlur={field.onBlur} onIonChange={field.onChange} onKeyPress={(event) => {
                                if (characterFilter && !characterFilter.test(event.key)) {
                                    return event.preventDefault();
                                }
                            }}
                            />
                    </IonRow>
                    {methods.formState.errors[name] && 
                        <IonRow>
                            <IonItem class='mt-1 ml-1 mr-1' color='danger'><p className='white-color'>{methods.formState.errors[name]?.message}</p></IonItem>
                        </IonRow>
                    }
                </>
            }
        />
    }

    const FieldSelect: React.FC<{
        control: Control<SavedPayeeFields>,
        name: keyof SavedPayeeFields,
        rules: RegisterOptions<SavedPayeeFields>
    }> = ({control, name, rules}) => {
        const label = fieldToLabel[name];

        const creditAccountTypeOptions = [
            {value: 'Checking', description: 'Checking'},
            {value: 'Savings', description: 'Savings'}
        ]

        const billPaymentTypeOptions = [
            {value: 'Bill Payment', description: 'Bill Payment'},
            {value: 'Note Payable Payment', description: 'Note Payable Payment'},
            {value: 'Real Estate Tax Payment', description: 'Real Estate Tax Payment'}
        ]

        const expenseTypeOptions = [
            {value: 'Insurance', description: 'Insurance'},
            {value: 'Homeowner Dues', description: 'Homeowner Dues'},
            {value: 'Taxes', description: 'Taxes'},
            {value: 'Utilities', description: 'Utilities'},
            {value: 'Other', description: 'Other'}
        ]

        const options = (name: string) => {
            switch (name) {
                case 'creditAccountType':
                    return creditAccountTypeOptions;
                case 'billPaymentType':
                    return billPaymentTypeOptions;
                case 'expenseType':
                    return expenseTypeOptions;
                default:
                    return creditAccountTypeOptions;
            }
        }

        return <Controller 
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => 
                <>
                    <IonRow class='mt-1 mb-1 ml-1 mr-1'>
                        <IonLabel><b>{`${label}: `}</b></IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonSelect class='gr-border pl-1 pb-1 ml-1 mr-1 edit-payee-select' interface='action-sheet' placeholder='Please Select' tabIndex={0} mode="ios" name={name} value={field.value} onIonChange={field.onChange}>
                            {options(name).map((option, index) => {
                                return <IonSelectOption key={index} value={option.value}>{option.description}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonRow>
                    {methods.formState.errors[name] && 
                        <IonRow>
                            <IonItem class='mt-1 ml-1 mr-1' color='danger'><p className='white-color'>{methods.formState.errors[name]?.message}</p></IonItem>
                        </IonRow>
                    }
                </>
            }
        />
    }

    const PayeeEdit: React.FC = () => {
        return <>
            <FieldInput control={methods.control} name='payableTo' rules={{
                required: {
                    value: true,
                    message: 'Please add the name for who you\'d like to pay.'
                },
                maxLength: {
                    message: 'Payable To cannot exceed 35 characters.',
                    value: 35
                }
            }} />
            {billPaymentType && !deliveryMethod.includes('Hold') &&
                <>
                    <FieldSelect control={methods.control} name='billPaymentType' rules={{
                        required: {
                            value: true,
                            message: 'Please select bill payment type.'
                        }
                    }} />
                    {billPaymentType === 'Bill Payment' &&
                        <FieldSelect control={methods.control} name='expenseType' rules={{
                            required: {
                                value: true,
                                message: 'Please select expense type.'
                            }
                        }} />
                    }
                </>
            }
            <FieldInput control={methods.control} name='paymentMemo' rules={{
                required: {
                    value: true,
                    message: 'Please enter memo or parcel number to help recipient properly credit your payment.'
                }
            }} />

            {(deliveryMethod === 'ACH' || deliveryMethod.includes('Wire')) &&
                <BankEdit/>
            }

            {(deliveryMethod.includes('Check') || deliveryMethod.includes('Wire')) && !deliveryMethod.includes('Hold') &&
                <>
                    <FieldInput control={methods.control} name='mailingStreet' rules={{
                        required: {
                            value: true,
                            message: 'Please enter mailing street.'
                        }, maxLength: {
                            message: 'Mailing street cannot exceed 33 characters.',
                            value: 33
                        }
                    }} />
                    <FieldInput control={methods.control} name='mailingCity' rules={{
                        required: {
                            value: true,
                            message: 'Please enter mailing city.'
                        }
                    }} />
                    <IonRow class='mt-1 mb-1 ml-1'>
                        <IonLabel><b>Mailing State:</b></IonLabel>
                    </IonRow>
                    <StateSelector name='mailingState' disableField={false} editingPayee={true}/>
                    <FieldInput control={methods.control} name='mailingZip' rules={{
                        required: {
                            value: true,
                            message: 'Please enter mailing zip code.'
                        }
                    }} characterFilter={numberFilter} />
                    {!deliveryMethod.includes('Wire') &&
                        <FieldInput control={methods.control} name='CO' />
                    }
                </>
            }
        </>
    };

    const BankEdit: React.FC = () => {
        return <>
            <Controller control={methods.control} name='routingNumber'rules={{
                required: {
                    value: true,
                    message: 'Please enter a routing number'
                }
            }} render={({ field }) => 
                <>
                    <IonRow class='mt-1 mb-1 ml-1 mr-1'>
                        <IonLabel><b>Routing Number:</b></IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonInput class='gr-border edit-input ml-1 mr-1' mode='ios' type='text' value={field.value} ref={field.ref} name={field.name} onIonBlur={field.onBlur} onIonChange={(event) => validateRoutingNumber(event, field.onChange)} onKeyPress={(event) => {
                                if (!numberFilter.test(event.key)) {
                                    return event.preventDefault();
                                }
                            }}
                            />
                    </IonRow>
                    {methods.formState.errors['routingNumber'] && 
                        <IonRow>
                            <IonItem class='mt-1 ml-1 mr-1' color='danger'><p className='white-color'>{methods.formState.errors['routingNumber']?.message}</p></IonItem>
                        </IonRow>
                    }
                </>}
            />
            <FieldInput control={methods.control} name='bankName' readOnly={true} rules={{
                required: {
                    value: true,
                    message: 'A valid routing number is required.'
                }
            }} />
            <FieldInput control={methods.control} name='creditAccountName' rules={{
                required: {
                    value: true,
                    message: 'Please enter name on bank account(first and last).'
                }, maxLength: {
                    message: 'Name on bank account cannot exceed 35 characters.',
                    value: 35
                }
            }} />
            <FieldInput control={methods.control} name='creditAccountNumber' rules={{
                required: {
                    value: true,
                    message: 'Please enter bank account number.'
                }, maxLength: {
                    value: 17,
                    message: 'Bank account number cannot exceed 17 characters.'
                }
            }} characterFilter={numberFilter} />
            {!deliveryMethod.includes('Wire') &&
                <FieldSelect control={methods.control} name='creditAccountType' rules={{
                    required: {
                        value: true,
                        message: 'Please select bank account type.'
                    }
                }} />
            }
        </>
    };

    const onSubmit: SubmitHandler<SavedPayeeFields> = async (data) => {
        setSubmittingSpinner(true)

        try {
            if (JSON.stringify(data) != JSON.stringify(getDefaultValues())){
                const updatePayeeResult = await calloutHelper.updatePayee(bankOrPayee.payeeOrBankId, data);
                if (updatePayeeResult.data === 'success') {
                    if (bankOrPayee.savedType === 'payee') {
                        const payees = savedPayeeLists.savedPayeesResult.filter(payees => payees.payeeId !== bankOrPayee.payeeOrBankId);
                        payees.unshift({payeeId: bankOrPayee.payeeOrBankId, ...data});
                        setSavedPayeeLists(prevState => { return {savedPayeesResult: payees, savedBanksResult: prevState.savedBanksResult} });
                        setToastMessage({message: 'Payee updated', color: 'success'});
                    };
                    if (bankOrPayee.savedType === 'bank') {
                        const banks = savedPayeeLists.savedBanksResult.filter(banks => banks.bankId !== bankOrPayee.payeeOrBankId);
                        banks.unshift({
                            bankId: bankOrPayee.payeeOrBankId,
                            bankName: data.bankName,
                            creditAccountName: data.creditAccountName,
                            creditAccountNumber: data.creditAccountNumber,
                            creditAccountType: data.creditAccountType,
                            routingNumber: data.routingNumber
                        });
                        setSavedPayeeLists(prevState => { return {savedBanksResult: banks, savedPayeesResult: prevState.savedPayeesResult}})
                        setToastMessage({message: 'Bank updated', color: 'success'})
                    };
                    setSubmittingSpinner(false);
                    setSubmitError('');
                    setShowEditModal(false);
                    setShowToast(true);
                }
            } else {
                setSubmittingSpinner(false);
                setSubmitError('Please input at least one change to update.');
            }
        } catch (err) {
            setSubmittingSpinner(false)
            if (bankOrPayee.savedType === 'payee') {
                setToastMessage({message: 'Payee updating error', color: 'danger'});
            };
            if (bankOrPayee.savedType === 'bank') {
                setToastMessage({message: 'Bank updating error', color: 'danger'});
            };
            setShowToast(true);
        };
    }

    const SubmitButton: React.FC = () => {
        return <IonButton
            class='edit-modal-submit-button w-100'
            color={getButtonColor()}
            onClick={
                () => {
                        methods.handleSubmit(onSubmit, (errors) => {})()
                }
            }
            >Save</IonButton>
    }

    return (
        <IonModal cssClass={savedPayee?.deliveryMethod && bankOrPayee.savedType === 'payee' && savedPayee?.deliveryMethod?.includes('Hold') ? 'edit-payee-modal-hold' : 'edit-modal'} mode='ios' isOpen={showEditModal} onDidDismiss={() => {
            setShowEditModal(false);
            setSubmittingSpinner(false);
            setSubmitError('');
            methods.reset();
        }}>
            <FormProvider {...methods}>
                <IonToolbar>
                    <IonTitle>Edit {bankOrPayee.savedType === 'payee' ? 'Payee' : 'Bank'}</IonTitle>
                    <IonButton slot='end' size='small' color={getButtonColor()} onClick={() => (setShowEditModal(false), setSubmittingSpinner(false), setSubmitError(''), methods.reset())}>X</IonButton>
                </IonToolbar>
                {submitError && !submittingSpinner &&
                    <IonRow>
                        <IonItem class='mt-1 ml-1 mr-1 w-100' color='danger'><p className='white-color'>{submitError}</p></IonItem>
                    </IonRow>
                }
                {submittingSpinner ? <Spinner/> :
                    <form className='edit-form'>
                        {bankOrPayee.savedType === 'bank' &&
                            <BankEdit/>
                        }
                        {bankOrPayee.savedType === 'payee' &&
                            <PayeeEdit/>
                        }
                    </form>
                }
                <IonToolbar>
                <SubmitButton/>

                </IonToolbar>

            </FormProvider>
        </IonModal>
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
      availableAccounts: rootState.CachedObjects.accounts,
      selectedAccount: rootState.UserState.selectedAccount
    }
  }  

export default connect(mapStateToProps)(EditBankPayeeModal)