import axios from 'axios';

const caseCalloutHelper = {

    getCases: (accountId?: string) => axios.get('/api/case/cases', { params: { accountId } }),

    getCasesForUpdatingAssetValue : (holding : Holding) => {
        if (!holding) {
            throw Error('No holding provided');
        }
        return axios.get('/api/case/cases-for-updating-asset-value', {params: {holdingId: holding.Id}});
    },

    createFairMarketValueCase: (fairMarketValueCase : Partial<FairMarketValueCase>, accountId : string) => {
        if (!accountId) {
            throw Error('No accountId provided');
        }
        return axios.post('/api/case/fair-market-value-case', {accountId: accountId, case: fairMarketValueCase});
    },

    cancelFairMarketValueCase: (caseId : string) => {
        if (!caseId) {
            throw Error('No caseId provided');
        }
        return axios.post('/api/case/cancel-fair-market-value-case', {caseId : caseId});
    }
}

export default caseCalloutHelper; 