import React from 'react';
import { IonRow, IonCol, IonItem, IonList, IonToolbar, IonHeader, IonButton, IonLabel, IonProgressBar, IonInput } from '@ionic/react';import {InputChangeEventDetail} from '@ionic/core'
import progressBarReview from '../../../images/review.svg';
import progressBarReviewIfNotEditSavedPayee from '../../../images/saved-payee-review.svg';
import { isPhone } from '../../../helpers/Utils';
import { formatDateString } from 'shared-utils';
import { InputComponents, BillPayStepString, ReviewFields, deliveryMethods } from '../BillPayTypes';
import { useForm, Controller, FieldErrors } from 'react-hook-form'
import TermsOfServiceComponent, { standardTOS } from '../../TermsOfServiceComponent';

export interface BillPayReviewForm extends InputComponents {
  setHasAgreed: any,
  hasAgreed: boolean,
  selectedAccount? : RetirementAccount,
  isFundManagerFull: Boolean
}

const BillPayReview: React.FC<BillPayReviewForm> = ({ savedFields, setHasAgreed, hasAgreed, submitFields, formRef, selectedAccount, isFundManagerFull }) => {
  const { control, handleSubmit, formState: { errors } } = useForm<ReviewFields>({
    mode: 'onChange',
    defaultValues:{
      ssnSigFirstThree: '',
      ssnSigNextTwo: ''
    }
  })

  const deliveryMethodText = deliveryMethods.find(deliveryMethod => deliveryMethod.value === savedFields?.deliveryMethod)?.description;

  const isAddOrEditSavedPayee = () => {
    return (savedFields?.payeeId === 'Add Payee' || savedFields?.editPayee);
  }

  const displayProgressBar = () => {
    if (isPhone()) return <IonProgressBar color="primary" value={1}></IonProgressBar>;
      return (isAddOrEditSavedPayee() ? <img data-test='image-with-four-steps' src={progressBarReview} alt="progress bar" width="100%" /> : <img data-test='image-with-two-steps' src={progressBarReviewIfNotEditSavedPayee} alt="progress bar" width="100%" />
    )
  }

  //use new goTo param on submit
  const goToEditStep = (step: BillPayStepString) => {
    let formFields: Partial<BillPaymentWizard.FullForm> = {...savedFields, editPayee: true}
    return () => submitFields({status: 'goTo', formFields, step})
  }

  const displayDeliveryInfo = () => {
    const mailingInfo = (
      <>
        <span data-test="mailing-street">
          <b>Mailing Street</b>: {savedFields?.mailingStreet} <br />
        </span>
        <span data-test="mailing-city">
          <b>Mailing City</b>: {savedFields?.mailingCity} <br />
        </span>
        <span data-test="mailing-state">
          <b>Mailing State</b>: {savedFields?.mailingState} <br/>
        </span>
        <span data-test="mailing-zip">
          <b>Mailing Zip</b>: {savedFields?.mailingZip} <br/>
        </span>
      </>
    )
    const bankInfo = (
      <>
        <span data-test="routing-number">
          <b>Bank Routing Number</b>: {savedFields?.routingNumber}<br />
        </span>
        <span data-test="bank-name">
          <b>Bank Name</b>: {savedFields?.bankName}<br />
        </span>
        <span data-test="credit-account">
          <b>Bank Account Number</b>: {savedFields?.creditAccountNumber}<br />
        </span>
        <span data-test="credit-account-name">
          <b>Name on Bank Account</b>: {savedFields?.creditAccountName}<br />
        </span>
      </>
    )

    if (savedFields?.deliveryMethod?.toLowerCase().includes('check') && !savedFields?.deliveryMethod?.toLowerCase().includes('hold')) {
     return  (
      <>
        <span data-test="co">
          <b>C/O</b>: {savedFields?.CO}<br />
        </span>
        {mailingInfo}
      </>
     );
    }

    if (deliveryMethodText?.includes('Direct Deposit')) {
      return (
        <>
          {bankInfo}
          <span data-test="credit-account-type">
            <b>Bank Account Type</b>: {savedFields?.creditAccountType}
          </span>
        </>
      )
    }

    if (savedFields?.deliveryMethod?.toLowerCase().includes('domestic wire')) {
      return (
        <>
          {mailingInfo}
          {bankInfo}
        </>
      );
    }
  }

  const displayBillPaymentSpecificInfo = () => {
    switch (savedFields?.billPaymentType) {
      case 'Bill Payment':
        return (
        <span data-test="expense-type">
          <b>Expense Type</b>: {savedFields.expenseType} <br />
        </span>);
      case 'Real Estate Tax Payment':
        return (
          <>
            <span data-test='tax-year'>
              <b>Tax Year</b>: {savedFields.taxYear} <br />
            </span>
            <span data-test='full-partial'>
              <b>Full/Partial Payment</b>: {savedFields.fullOrPartial} <br />
            </span>
          </>
        );
      default:
        return <></>;
    }
  }


  const displaySSNVerifyInputs = () => (
    (savedFields?.amount && +savedFields.amount >= 1000) && !isFundManagerFull && 
      <IonRow>
        <IonLabel class="w-100 d-block"><b>Digital Signature</b></IonLabel>
          <p className="w-100 d-block">Enter the FIRST FIVE digits of your SSN to digitally sign this request</p>
          <Controller control={control} name={"ssnSigFirstThree"} render={({ field: { name, value, onChange }})=>{
            const handleOnChange = (event: CustomEvent<InputChangeEventDetail> | undefined)=>{
              const inputValue = event?.detail.value
              onChange(inputValue)
              if(inputValue && inputValue.length >= 3){
                (document.getElementById('ssn-second-part') as HTMLIonInputElement | undefined)?.setFocus()
              }
            }
            return <>
              <IonInput data-test="ssn-first-three" className="mt-0 gr-border ion-padding-left ion-padding-right pl-1 pr-1" type="password" size={3} maxlength={3} name={name} onIonChange={handleOnChange} value={value} /><span className="last-four p-1">-</span>
            </>}
          }
          rules={
            {
              required: 'Part of SSN signature missing',
              pattern: {
                message: 'First three ssn fields must be 3 digits',
                value: /\d{3}/
              }
            }
          }
          />
        <Controller control={control} name={"ssnSigNextTwo"} render={({ field: { name, value, onChange }})=>{
            return <>
              <IonInput id='ssn-second-part' data-test="ssn-next-two" className="mt-0 gr-border p-1 ion-padding-left ion-padding-right pl-1 pr-1" type="password" size={2} maxlength={2} name={name} value={value} onIonChange={onChange} /><span className="last-four p-1">- XXXX</span>
            </>}}
            rules={
              {
                required: 'Part of SSN signature missing',
                pattern: {
                  message: 'Last two ssn fields must be 2 digits',
                  value: /\d{2}/
                }
              }
            }
          />
        
      </IonRow>
  )

  const displayTOSContent = () => {
    return standardTOS();
  }

  const onValid = (formFields: ReviewFields)=>{
    submitFields({status: 'ok', formFields: {}, altPayload: {type: 'ssnFields', ssn: formFields}})
  }

  const onInvalid = (errors: FieldErrors) => {
    submitFields({status: 'error', formFields: {}, errors})
  }

  return (
    <div data-test='bill-pay-review-component'>
       <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol className="p-1" sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {displayProgressBar()} 
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <h1>Review and Submit</h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeXs="12">
          <IonList>
            <IonToolbar color="secondary">
              <IonHeader slot="start" className="review-header pl-2 pr-2 bar-header">Instructions</IonHeader>
              <IonButton slot="end" fill="clear" className="white-color" data-test='step-one-edit-button' data-step="select-account" onClick={goToEditStep("select-account")}>Edit</IonButton>
            </IonToolbar>
            <IonItem>
              <p>
                <span data-test='account-info'>
                  <b>Account</b>: {selectedAccount?.accountNumber} - {selectedAccount?.firstName} {selectedAccount?.lastName} - {selectedAccount?.accountType} <br />
                </span>
                <span data-test='asset-info'>
                  <b>Related Asset</b>: {savedFields?.assetLabel} <br/>
                </span>
                <span data-test='frequency'>
                  <b>Frequency</b>: {savedFields?.frequency} <br/>
                </span>
                {savedFields?.recurringTransactionData?.duration === 'Until I Cancel' &&
                    <span data-test='duration'>
                      <b>Duration</b>: {savedFields?.recurringTransactionData?.duration} <br/>
                    </span>
                }
                {savedFields?.frequency === 'Recurring' &&
                  <span data-test='schedule'>
                    <b>Schedule</b>: {savedFields?.recurringTransactionData?.schedule} <br/>
                  </span>
                }
                <span data-test='process-date'>
                  <b>{savedFields?.recurringTransactionData?.duration === 'Until I Cancel' ? 'Process Date' : 'First Process Date'}</b>: {formatDateString(savedFields?.processDate)} <br/>
                </span>
                {savedFields?.frequency === 'Recurring' && savedFields?.recurringTransactionData?.duration === 'Specified Date' &&
                  <span data-test='end-date'>
                    <b>End Date</b>: {formatDateString(savedFields?.recurringTransactionData?.endDate)}
                  </span>
                }
              </p>
            </IonItem>
            <IonToolbar color="secondary">
              <IonHeader slot="start" className="review-header pl-2 pr-2 bar-header">Payment to</IonHeader>
              <IonButton slot="end" fill="clear" className="white-color" onClick={goToEditStep("select-payee")} data-test='step-two-edit-button' data-step="select-payee">Edit</IonButton>
            </IonToolbar>
            <IonItem>
              <p>
                <span data-test='payable-to'>
                  <b>Payable to</b>: {savedFields?.payableTo} <br />
                </span>
                <span data-test='amount'>
                  <b>Amount</b>: $ {savedFields?.amount}<br/>
                </span>
              </p>
            </IonItem>
            <IonToolbar color="secondary">
              <IonHeader slot="start" className="review-header pl-2 pr-2 bar-header">Specifics</IonHeader>
              <IonButton slot="end" fill="clear" className="white-color" onClick={goToEditStep("bill-pay-type")} data-step="bill-pay-type">Edit</IonButton>
            </IonToolbar>
            <IonItem>
              <p>
                {!isFundManagerFull &&
                  <span data-test='payment-type'>
                    <b>Bill Payment Type</b>: {savedFields?.billPaymentType} <br />
                  </span>
                }
                {displayBillPaymentSpecificInfo()}
                <span data-test='payment-memo'>
                  <b>Payment Memo</b>: {savedFields?.paymentMemo}
                </span>
                </p>
              </IonItem>
              <IonToolbar color="secondary">
                <IonHeader slot="start" class="review-header pl-2 pr-2 bar-header">Send via</IonHeader>
                <IonButton slot="end" fill="clear" className="white-color" onClick={goToEditStep("delivery-method")} data-step="delivery-method">Edit</IonButton>
              </IonToolbar>
              <IonItem lines="none">
                <p>
                  <b>Delivery Method</b>: {deliveryMethodText} <br />
                  {displayDeliveryInfo()} 
                </p>
              </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
      <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
      <TermsOfServiceComponent modalBodyText={displayTOSContent()} modalButtonText="Close" checkboxEvent={(event: any) => setHasAgreed(event.detail.checked)} checked={hasAgreed} dynamicContent={displaySSNVerifyInputs()} ionFloat="ion-float-right" />
      </form>
      { (errors.ssnSigFirstThree || errors.ssnSigNextTwo) && (
        <IonItem className="mt-1" color="danger">
            <p className="white-color">Digital Signature required: {errors.ssnSigFirstThree?.message || errors.ssnSigNextTwo?.message}</p>
        </IonItem>)
      }
    </div>
  );
};

export default BillPayReview;
