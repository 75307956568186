import React from 'react';
import { IonRow, IonCol, IonList } from '@ionic/react';
import { connect } from 'react-redux';
import calloutHelper from '../../helpers/calloutHelpers';
import DataLoader from '../DataLoader';
import ToggleComponent from '../ToggleComponent';
import { formatDateToString } from 'shared-utils';

const VaultMovements: React.FC<{ selectedAccount?: RetirementAccount | undefined }> = ({ selectedAccount }) => {
    const renderVaultMovements = (vaultMovementsData:{data: VaultMovement[]}) => {
        const vaultMovements = vaultMovementsData.data
        if (!vaultMovements || (vaultMovements.length < 1)) {
            return <p>This account does not have any vault movements.</p>
        }

        return (
            <IonList data-testid='vault-movements-list' class='pl-2 pr-2'>
                {vaultMovements.map(vaultMovement => {
                    return (
                        <IonRow key={vaultMovement.Id} class='divider'>
                            <IonCol class='m-0 p-0' size='10'>
                                <IonRow class='mt-1'>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Transaction Date</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p data-testid={`transaction-date-${vaultMovement.Id}`} className="m-0">{formatDateToString(new Date(vaultMovement.TransactionDate))}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol class="p-0" sizeXs="12" sizeSm="12" sizeMd="3" sizeLg="3" sizeXl="3">
                                        <p className='m-0'><b>Details</b>:</p>
                                    </IonCol>
                                    <IonCol class="p-0 mb-1" sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9" sizeXl="9">
                                        <p className="m-0">{vaultMovement.Comment1}</p>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonList>
        )
    }

    return (
        <ToggleComponent isActive={false} title='Vault Movements' bodyData={<DataLoader apiCall={() => calloutHelper.getPostedTransactions(selectedAccount?.id || '')} render={renderVaultMovements} dependencies={[selectedAccount?.id]} />} />
    )
}

const mapStateToProps = (rootState: RootState) => {
    return {
        selectedAccount: rootState.UserState.selectedAccount
    }
};

export default connect(mapStateToProps)(VaultMovements);