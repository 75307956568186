import React from 'react';
import '../pages/Style.css';
import { 
  IonRow,
  IonCol,
} from '@ionic/react';

interface ContainerComponentProps {
  title?: string;
  content: any;
  className?: string;
}

const ContainerComponent: React.FC<ContainerComponentProps> = ({content, title, className}) => {
  return (
    <IonRow>
      <IonCol class={className || "p-1 light-gr-bg mb-2"}>
        <IonRow class="gr-border">
          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12" class="pb-1 white-bg">
            <IonRow>
              <IonCol size="12">
                {(title) && <h2 className="ion-text-left pl-1">{title}</h2>}
              </IonCol>
              <IonCol>
                {content}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default ContainerComponent;
