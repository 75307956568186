import { IonCol, IonInput, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {StateSelectorWithErrorMessage as StateSelector} from '../../StateSelector'
import { AddressTypes, MyProfileFormInputs } from '../myProfileTypes'
import { ShowFieldError, mapFieldPropsToIonProps } from './MyProfileUtils'

const AddressFields: React.FC<{addressType: AddressTypes}> = ({addressType}) => {
    const { control, formState:{ errors } } = useFormContext<MyProfileFormInputs>()
  return <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
    
        <IonCol size="12" class="d-block mt-1">
          <IonLabel className="p-0 m-0">{`${addressType} Street:`}</IonLabel>
        </IonCol>
        <Controller name={`${addressType}.street`} control={control} render={(props) =>
          <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} maxlength={40} />
        } rules={{
          required: `Please enter ${addressType.toLocaleLowerCase()} street.`
        }} />
        <ShowFieldError name={addressType} subName={'street'} errors={errors}/>

        <IonCol size="12" class="d-block mt-1">
          <IonLabel className="p-0 m-0">{`${addressType} City:`}</IonLabel>
        </IonCol>
        <Controller name={`${addressType}.city`} control={control} render={(props) =>
          <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} maxlength={17} />
        } rules={{
          required: `Please enter ${addressType.toLocaleLowerCase()} city.`
        }} />
        <ShowFieldError name={addressType} subName={'city'} errors={errors}/>

      <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl="4">
        <IonRow class="mb-1">
          <IonLabel>{`${addressType} State`}</IonLabel>
        </IonRow>
        <IonRow class="m-0 p-0">
          <IonCol class="m-0 p-0 ">
            <StateSelector name={`${addressType}.state`}/>
          </IonCol>
        </IonRow>
        <ShowFieldError name={addressType} subName={'state'} errors={errors}/>
      </IonCol>
        <IonCol size="12" class="d-block">
          <IonLabel className="p-0 m-0">{`${addressType} Postal Code:`}</IonLabel>
        </IonCol>
        <Controller name={`${addressType}.postalCode`} control={control} render={(props) =>
          <IonInput class="ion-text-left gr-border" {...mapFieldPropsToIonProps(props.field)} maxlength={10}/>
        } rules={{
          required: `Please enter ${addressType.toLocaleLowerCase()} postal code.`,
          validate: (val)=>{
            if(typeof val === 'string' && /\D/.test(val)){
              return 'Only numbers allowed in postal code.'
            }
          },
          minLength: {
            value: 5,
            message: 'Postal code must be at least 5 numbers.'
          }
        }} />
        <ShowFieldError name={addressType} subName={'postalCode'} errors={errors}/>
  </IonCol>
}

export default AddressFields