import React, { useState, useEffect} from 'react';
import {IonRow, IonCol, IonItem, IonItemGroup, IonLabel, IonNote, IonList, IonButton, IonLoading, IonToast} from '@ionic/react';
import BillingFilterForm from '../components/BillingFilterForm';
import Sidebar from './Sidebar';
import Spinner from './Spinner';
import calloutHelpers from '../helpers/calloutHelpers';
import billingItemCalloutHelper, { BillingItem, BillingFilter } from '../helpers/calloutHelpers/billingItemCalloutHelper';
import { formatDateString, formatNumber } from 'shared-utils';
import ContainerComponent from '../components/ContainerComponent';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PartyTypeConditionalAccess from './PartyTypeConditionalAccess';
import DocCustodySidebar from './DocCustody/DocCustodySidebar';

const Billing: React.FC<{ selectedAccount?: RetirementAccount, viewMode: ViewMode }> = ({ selectedAccount, viewMode }) => {
  const toastHistory = useHistory<{ Toast?: { message: '', color: 'success' } }>();
  const [toast, setToast] = useState<any>(toastHistory?.location?.state?.Toast);

  let date = new Date();
  date.setMonth(date.getMonth() - 3);
  const dateThreeMonthsBack = date.toLocaleString().split(',')[0];
  const [showSpinner, setShowSpinner] = useState<Boolean>(false)
  const [billingFilters, setBillingFilters] = useState<BillingFilter>({});
  const [billingItemsList, setBillingItemsList] = useState<any>([]);
  const [openChargesLeft, setOpenChargesLeft] = useState<number | null>(selectedAccount?.openChargesLeft || null)
  const [showLoading, setShowLoading] = useState<boolean>(false)

  useEffect(() => {
    setBillingFilters({
      fromDate: new Date(dateThreeMonthsBack).toLocaleDateString(),
      toDate: new Date().toLocaleDateString()
    })
  }, []);

  useEffect(() => {
    setShowLoading(true)
    setOpenChargesLeft(null)
    if (selectedAccount?.id) {
      calloutHelpers
        .getRetirementAccountWithCreditCard(selectedAccount?.id)
        .then((result) => {
          if (result) {
            setOpenChargesLeft(result?.data?.openChargesLeft)
            setShowLoading(false)
          }
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }, [selectedAccount?.id])

  useEffect(() => {
    if (!selectedAccount) return;
    const getBillingItemsList = async () => {
      const fromDate = billingFilters.fromDate;
      const toDate = billingFilters.toDate;
      const regex = /^([1-9]|1[0-2])\/([1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
      if (fromDate && toDate && regex.test(fromDate) && regex.test(toDate)) {
        setShowSpinner(true);
        try {
          setBillingItemsList([]);
          let accountId = selectedAccount.id;
          let billingItemResult = await billingItemCalloutHelper.getBillingItems(accountId, billingFilters);
          if (billingItemResult.data) {
            setBillingItemsList([...billingItemResult.data])
          }
  
          setShowSpinner(false);
  
        } catch (err) {
          setShowSpinner(false);
        }
      }
    }

    getBillingItemsList();

  }, [billingFilters, selectedAccount])

  const renderList = () => {
    if (showSpinner) {
      return (
        <Spinner />
      )
    }
    return <>
      <IonList data-test="transactions-list" class="w-100">
        <BillingFilterForm onFilterFormChange={setChangedFilterName} billingFilters={billingFilters}/>
        {(billingItemsList.length < 1) ? <p data-test="no-transactions" className="pl-1 pr-2">We could not find any billing information for your account.</p>  :

          billingItemsList.map(billingItem => (
          <IonItemGroup key={billingItem.id}>
            <IonItem class="ion-no-padding billing-item">
              <IonLabel className="ion-text-wrap pl-1 pr-1"><p><b>{billingItem.description}</b><br /><span className="billing-date">{formatDateString(billingItem.date)}</span></p></IonLabel>
              <IonNote slot="end"><p className={transactionColor(billingItem)}>{displayTransaction(billingItem)}</p></IonNote>
            </IonItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  }

  const setChangedFilterName = (filterKey: string) => {
    const onFilterFormChange: (date: any) => void = (date: any) => {
      if (date) {
        let filterValue = date.toLocaleDateString();
        setBillingFilters(prevState => {
          return {
            ...prevState,
            [filterKey]: filterValue
          }
        });
      }
    }
    return onFilterFormChange
  }

  const transactionColor = (billingItem: BillingItem) => {
    let transType = billingItem.transactionType.toLowerCase();
    return (transType === 'charge' || transType === 'refund') ?
      'negative-amount' : 'positive-amount';
  }

  const displayTransaction = (billingItem: BillingItem) => {
    let transType = billingItem.transactionType.toLowerCase();
    return (transType === 'payment' || transType === 'credit') ?
      `(${formatNumber(billingItem.amount)})` : formatNumber(billingItem.amount);
  }

  return (
    <IonRow class="container">
      <IonToast
        isOpen={(toast?.message !== undefined && toast.color !== undefined)}
        color={toast?.color}
        onDidDismiss={() => setToast({})}
        position='top'
        message={toast?.message}
        duration={3 * 1000}
      />
      <IonCol class="pt-1" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" sizeXl="8">
        <ContainerComponent content={
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="7" sizeLg="7">
                <p className="pl-1 mt-0 mb-0">
                  <b>Balance Due</b>: ${(typeof openChargesLeft === 'number' || openChargesLeft !== null || !showLoading) ? (
                  openChargesLeft
                ) : (
                  <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={"Please wait..."}
                    duration={5000}
                  />
                )}<br />
                  <b>Fee Schedule</b>: {selectedAccount?.feeSchedule}<br />
                  <b>Preferred Billing Method</b>: {selectedAccount?.preferredBillingMethod}<br />
                  <b>Billed On Date</b>: {selectedAccount?.billingFrequencyPortal}<br/>
                </p>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="5" sizeLg="5">
                <div className='d-block'>
                  <PartyTypeConditionalAccess featureDescription='makePayment'>
                    <Link to="/card-payment">
                      <button className='w-120 white-bg'>
                          <IonButton className='w-100'> Make Payment</IonButton>
                        </button>
                    </Link>
                  </PartyTypeConditionalAccess>
                  <PartyTypeConditionalAccess featureDescription='updatePaymentMethod'>
                    <Link to="/update-payment-method">
                      <button className='w-120 white-bg'>
                        <IonButton className='w-100'> Update Payment Method </IonButton>
                      </button>
                    </Link>
                  </PartyTypeConditionalAccess>
                </div>
              </IonCol>
          </IonRow>
        } />
        <ContainerComponent content={renderList()} />
      </IonCol>
      {viewMode !== 'DOC_CUSTODY' ? <Sidebar /> : <DocCustodySidebar />}
    </IonRow>
  );
};

export const mapStateToProps = (rootState: RootState) => {
  return {
    selectedAccount: rootState.UserState.selectedAccount,
    viewMode: rootState.CachedObjects.viewMode
  }
}

export default connect(mapStateToProps)(Billing);