import React, { useState, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonItem,
  IonProgressBar,
  IonLabel,
  IonInput
} from '@ionic/react';
import progressBarThreeStepStepTwo from '../../../images/three-steps-step-two.svg';
import progressBarTwoStepStepOne from '../../../images/two-step-step-one.svg';
import { amountRegex, isPhone } from '../../../helpers/Utils';
import { useForm, Controller, FieldErrors, FormProvider } from 'react-hook-form';
import { InputComponents } from '../AdditionalBuyTypes';
import FileUpload, { uploadCallBackInput } from '../../helperComponents/FileUpload';
import axios from 'axios';
import tradingAccountCalloutHelper from '../../../helpers/calloutHelpers/tradingAccountCalloutHelper';
import ConditionalSelect from '../../ConditionalSelect';
import Spinner from '../../Spinner';
import BankFields from '../../ConditionalFieldComponents/BankFields';

export interface AdditionalBuyStepTwoType extends InputComponents {
  holding: any,
  savedFiles: SFFile[],
  fromHolding: boolean,
  includeFundingInstructions: boolean
}

interface Option {
  value: string,
  description: string
}

const AdditionalBuyStepTwo: React.FC<AdditionalBuyStepTwoType> = ({ formRef, submitFields, savedFields, savedFiles, fromHolding, holding, setErrorMessage, clearErrorMessage, includeFundingInstructions }) => {
  type StepTwoFields = AdditionalBuyWizard.StepTwoFields

  const [files, setFiles] = useState<SFFile[]>(savedFiles)
  const [tradingAccountOptions, setTradingAccountOptions] = useState<Option[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const tradingAccountList = ['Futures/Forex Acct', 'Brokerage Acct', 'Bank Acct - Other Institution', 'Cryptocurrency'];
  const deliveryMethodOptions = [
    {value: 'ACH', description: 'Direct Deposit (No Fee)'},
    {value: 'Domestic Wire', description: 'Wire Transfer ($30 Fee)'}
  ]

  const getDefaultValues = ():StepTwoFields => {
    if (fromHolding) {
      return {
        account: holding.Account.ClientFirstName + ' ' + holding.Account.ClientLastName + ' - ' + holding.AssetDescription || '',
        holdingId: holding.Id || '',
        assetLabel: holding.AssetDescription || '',
        tradingAccountId: savedFields?.tradingAccountId || '',
        tradingAccountLabel: savedFields?.tradingAccountLabel || '',
        amount: savedFields?.amount || null,
        deliveryMethod: savedFields?.deliveryMethod || '',
        payableTo: savedFields?.payableTo || '',
        routingNumber: savedFields?.routingNumber || '',
        bankName: savedFields?.bankName || '',
        creditAccountName: savedFields?.creditAccountName || '',
        creditAccountNumber: savedFields?.creditAccountNumber || '',
        creditAccountType: savedFields?.creditAccountType || '',
        mailingStreet: savedFields?.mailingStreet || '',
        mailingCity: savedFields?.mailingCity || '',
        mailingState: savedFields?.mailingState || '',
        mailingZip: savedFields?.mailingZip || ''
      }
    }
    return {
      tradingAccountId: savedFields?.tradingAccountId || '',
      tradingAccountLabel: savedFields?.tradingAccountLabel || '',
      amount: savedFields?.amount || null,
      deliveryMethod: savedFields?.deliveryMethod || '',
      payableTo: savedFields?.payableTo || '',
      routingNumber: savedFields?.routingNumber || '',
      bankName: savedFields?.bankName || '',
      creditAccountName: savedFields?.creditAccountName || '',
      creditAccountNumber: savedFields?.creditAccountNumber || '',
      creditAccountType: savedFields?.creditAccountType || '',
      mailingStreet: savedFields?.mailingStreet || '',
      mailingCity: savedFields?.mailingCity || '',
      mailingState: savedFields?.mailingState || '',
      mailingZip: savedFields?.mailingZip || ''
    }
  }

  const stepTwoForm = useForm<StepTwoFields>({
    mode: 'onChange',
    defaultValues: getDefaultValues()
  })
 
  const { control, formState: { errors }, handleSubmit, getValues, watch, setValue } = stepTwoForm;

  const deliveryMethod = watch('deliveryMethod');

  useEffect(() => {
    if (deliveryMethod === 'ACH') {
      setValue('mailingStreet', '');
      setValue('mailingCity', '');
      setValue('mailingState', '');
      setValue('mailingZip', '');
    };
    if (deliveryMethod === 'Domestic Wire') {
      setValue('creditAccountType', '');
    };
  }, [deliveryMethod])

  useEffect(() => {
    if (!includeFundingInstructions) {
      setValue('deliveryMethod', '');
      setValue('payableTo', '');
      setValue('routingNumber', '');
      setValue('bankName', '');
      setValue('creditAccountName', '');
      setValue('creditAccountNumber', '');
      setValue('creditAccountType', '');
      setValue('mailingStreet', '');
      setValue('mailingCity', '');
      setValue('mailingState', '');
      setValue('mailingZip', '');
    }
  }, [includeFundingInstructions])

  const deleteFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files])
  }

  const onFileChange = async (fileUploadResponse: uploadCallBackInput) => {
    if (fileUploadResponse.status === 'success') {
     setFiles(fileUploadResponse.files)
   }else{
    setFiles([]);
   }
  }

  const displayProgressBar = () => {
    if(fromHolding) {
      return (!isPhone()) ? <img src={progressBarTwoStepStepOne} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.0}></IonProgressBar>
    } else {
      return (!isPhone()) ? <img src={progressBarThreeStepStepTwo} alt="progress bar" width="100%" /> : <IonProgressBar color="primary" value={0.5}></IonProgressBar>
    }
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    const getTradingAccountOptions = async (token: any) => {
      try {
        setShowSpinner(true);
        const tradingAccountOptionsResult = await tradingAccountCalloutHelper.getTradingAccounts(fromHolding ? holding.Id : savedFields?.holdingId || '', token);
        setTradingAccountOptions([...tradingAccountOptionsResult.data]);
        setShowSpinner(false);
      } catch (err) {
        setShowSpinner(false);
        setTradingAccountOptions([]);
        setErrorMessage('Error retrieving asset options');
        clearErrorMessage();
      }
    };
    
    getTradingAccountOptions(source.token);
    return(() => {
      source.cancel('Cancelling in cleanup');
    });
  }, [clearErrorMessage, fromHolding, holding?.Id, savedFields?.holdingId, setErrorMessage]);

  const renderStepTwo = () => {
    const onValid = (formFields: StepTwoFields) => {
      let selectedTradingAccount = tradingAccountOptions.find(tradingAccount => tradingAccount.value === formFields.tradingAccountId);

      if (selectedTradingAccount) {
        formFields.tradingAccountLabel = selectedTradingAccount?.description;
      }
      
      submitFields({status: 'ok', formFields, altPayload: {type: 'files', files}})
    }
    
    const onInvalid = (errors: FieldErrors) => {
      submitFields({status: 'error', formFields: getValues(), errors, altPayload: {type: 'files', files}})
    }

    return <FormProvider {...stepTwoForm}>
      {showSpinner ? (<Spinner />) : <form ref={formRef} onSubmit={handleSubmit(onValid, onInvalid)}>
        <IonRow class="divider">
          <h1 className="ion-text-left">Send funds to {(fromHolding) ? holding.AssetDescription : 'existing investment'}</h1>
        </IonRow>
        {((fromHolding && tradingAccountList.includes(holding.AssetType)) || (tradingAccountOptions.length > 0)) && <>
          <ConditionalSelect controllerName="tradingAccountId" title="Trading Account" errorMessage="Please select a trading account." dataList={tradingAccountOptions} />
        </>}
        <IonRow class="mt-2 mb-1">
          <IonLabel>Amount (USD)</IonLabel>
        </IonRow>
        <IonRow class="m-0 p-0">
          <IonCol class="m-0 p-0" sizeXs="12" sizeSm="12" sizeMd="2" sizeLg="2" sizeXl="2">
            <Controller name='amount' control={control} render={({ field: { value, onBlur, name, onChange } }) =>
              <IonItem class="gr-border" lines="none">$<IonInput data-testid='amount-input' class="ion-text-left ml-1" name={name} type="number" value={value} onIonBlur={onBlur} onIonChange={event=>{onChange(event.detail.value)}} /></IonItem>
            } rules={{
              required: {
                value: true,
                message: 'Please enter an amount to withdraw.'
              },
              pattern: {
                value: amountRegex,
                message: 'Invalid Amount'
              },
              validate: {
                greaterThanZero: (value) => ((value && (+value > 0)) ? undefined : 'Amount value must be greater than 0'),
                isANumber: (value) => ((value && isNaN(value)) ? 'Value must be a valid number' : undefined),
                greaterThan100kNonWire: (value) => ((value && (+value >= 100000 ) && (deliveryMethod === 'ACH')) ? 'Amounts of $100,000 and above are only possible by wire transfer.' : undefined)
              }
            }} />
          </IonCol>
        </IonRow>
        {errors['amount'] && errors['amount']?.type !== 'fileUploadNeed' && <IonItem data-testid='amount-error' class="mt-1" color="danger"><p className="white-color">{errors['amount']?.message}</p></IonItem>}
        {((!tradingAccountList.includes(holding?.AssetType)) && (tradingAccountOptions.length === 0)) &&
          <>
            <IonRow class="mt-2 mb-1">
              <IonLabel>Upload Document(s)</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0 w-100 d-block">
              <IonCol class="m-0 p-0" sizeXs="12" sizeSm="10" sizeMd="8" sizeLg="6" sizeXl="4">
                <FileUpload files={files} filesUploadedCallback={onFileChange} deleteFile={deleteFile} />
              </IonCol>
            </IonRow>
          </>
        }
        {includeFundingInstructions &&
          <ConditionalSelect controllerName={'deliveryMethod'} title={'How would you like your funds to be delivered?'} errorMessage={'Please select a delivery method.'} dataList={deliveryMethodOptions} />
        }
        {deliveryMethod &&
          <>
            <IonRow class='mt-2 mb-1'>
              <IonLabel>Payable To:</IonLabel>
            </IonRow>
            <IonRow class="m-0 p-0">
              <Controller name='payableTo' control={control} render={({field: { value, onBlur, name, onChange }}) => 
                <IonInput data-testid='payable-to-input' className='ion-text-left gr-border pl-1 pr-1' name={name} type='text' onIonChange={event => onChange(event.detail.value)} onIonBlur={onBlur} value={value} />
              } rules={
                {required: {
                  value: true,
                  message: "Please add the name for who you would like to pay."
                },
                maxLength: {
                    message: 'Payable To cannot exceed 100 characters.',
                    value: 100
                }
              }
              } />
            </IonRow>
            {errors.payableTo && <IonItem className='mt-1' color='danger'><p className='white-color'>{errors.payableTo.message}</p></IonItem>}
            <BankFields deliveryMethod={deliveryMethod === 'Domestic Wire' ? 'wireTransfer' : 'directDeposit'} hideCreditAccountType={deliveryMethod === 'Domestic Wire'} />
          </>
        }
      </form>}
      </FormProvider>
  }
  return (
    <>
      <IonRow class={(!isPhone()) ? "divider" : ''}>
        <IonCol class="p-1" size="12" sizeSm="12" sizeMd="7" sizeLg="7" sizeXl="7">
          {displayProgressBar()}
        </IonCol>
      </IonRow>
      {renderStepTwo()}
    </>
  );
};

export default AdditionalBuyStepTwo;
